/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import Typography from '@mui/material/Typography';
import React from 'react';
import { SchemaDataProduct } from '../../../../../api/dataProduct/dataProductApi';
import { DatasetDetails } from './DatasetDetails';
import { OverviewSubsectionClasses } from '../useSubsectionClasses';

interface OverviewDatasetsProps {
    dataProduct: SchemaDataProduct;
    classes?: OverviewSubsectionClasses;
}

export const OverviewDatasets = ({ dataProduct, classes }: OverviewDatasetsProps) => {
    return (
        <>
            <Typography variant="h4" className={classes?.header}>
                Datasets
            </Typography>
            <DatasetDetails
                dataProductId={dataProduct.id}
                catalogName={dataProduct.catalogName}
                schemaName={dataProduct.schemaName}
                views={dataProduct.views}
                materializedViews={dataProduct.materializedViews}
            />
        </>
    );
};
