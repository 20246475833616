/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { FilterCriteria } from '../features/query/history/queryFilters';
import { formatQueryCriteria } from './queriesApi';
import { baseUrl } from './api';
import axios from 'axios';

export type QueriesByUser = {
    user: string;
    numberOfQueries: number;
}[];

export type CpuTimeByUser = {
    user: string;
    cpuTime: number;
}[];

export type QueriesByTime = {
    intervalStart: string;
    intervalEnd: string;
    numberOfQueries: number;
}[];

export type InputDataByUser = {
    user: string;
    inputBytes: number;
}[];

export type InputDataBySource = {
    source: string;
    inputBytes: number;
}[];

export type OutputDataByUser = {
    user: string;
    outputBytes: number;
}[];

export type OutputDataBySource = {
    source: string;
    outputBytes: number;
}[];

type Table = {
    table: {
        catalogName: string;
        schemaName: string;
        tableName: string;
    };
};

export type QueriesByTable = (Table & {
    numberOfQueries: number;
})[];

export type InputDataByTable = (Table & {
    inputBytes: number;
})[];

export type OutputDataByTable = (Table & {
    outputBytes: number;
})[];

export type QueriesBySource = {
    source: string;
    numberOfQueries: number;
}[];

export type CpuTimeBySource = {
    source: string;
    cpuTime: number;
}[];

export type LeastActiveQueries = {
    queryId: string;
    query: string;
    user: string;
    cpuTime: number;
    scheduledTime: number;
    utilizationRatio: number;
    inputOutputBytes: number;
}[];

const reportsBaseUrl = `${baseUrl}/history/queries/reports`;

const getReport =
    <T>(reportName: string) =>
    (criteria: FilterCriteria | null): Promise<T> => {
        return axios
            .get(`${reportsBaseUrl}/${reportName}`, {
                params: formatQueryCriteria(criteria),
            })
            .then((response) => response.data);
    };

export const getQueriesByTime = getReport<QueriesByTime>('queriesByTime');
export const getLeastActiveQueries = getReport<LeastActiveQueries>('leastActiveQueries');

export const getQueriesByUser = getReport<QueriesByUser>('queriesByUser');
export const getCpuTimeByUser = getReport<CpuTimeByUser>('cpuTimeByUser');
export const getInputDataByUser = getReport<InputDataByUser>('inputDataByUser');
export const getOutputDataByUser = getReport<OutputDataByUser>('outputDataByUser');

export const getQueriesBySource = getReport<QueriesBySource>('queriesBySource');
export const getCpuTimeBySource = getReport<CpuTimeBySource>('cpuTimeBySource');
export const getInputDataBySource = getReport<InputDataBySource>('inputDataBySource');
export const getOutputDataBySource = getReport<OutputDataBySource>('outputDataBySource');

export const getQueriesByTable = getReport<QueriesByTable>('queriesByTable');
export const getInputDataByTable = getReport<InputDataByTable>('inputDataByTable');
export const getOutputDataByTable = getReport<OutputDataByTable>('outputDataByTable');
