/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';
import clsx from 'clsx';
import { createUseStyles } from 'react-jss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/pro-regular-svg-icons';
import { ConditionalKeys } from 'type-fest';
import { Theme } from '@mui/material/styles';
import { paletteSwitch } from '../../themes/palette';

type Trending = 'increasing' | 'decreasing' | 'stable';

interface ValueTrendingProps<T extends Record<string, unknown>> {
    children: React.ReactNode;
    object: T;
    previousObjects: T[] | undefined;
    idKey: keyof T;
    valueKey: ConditionalKeys<T, number>;
    tolerance?: number;
}

const useStyles = createUseStyles((theme: Theme) => ({
    decreasingUsage: {
        color: paletteSwitch(theme).successDark,
    },
    increasingUsage: {
        color: paletteSwitch(theme).errorDark,
    },
    margin: {
        marginLeft: '0.25rem',
    },
}));

export const ValueTrending = <T extends Record<string, unknown>>({
    children,
    object,
    idKey,
    valueKey,
    previousObjects,
    tolerance = 0.01,
}: ValueTrendingProps<T>): JSX.Element => {
    const classes = useStyles();

    const getTrending = (): Trending => {
        const prevObject = previousObjects?.find((prevObj) => prevObj[idKey] === object[idKey]);
        if (!prevObject) {
            return 'stable';
        }

        const prevValue = prevObject[valueKey] as number;
        const currentValue = object[valueKey] as number;

        if (currentValue >= prevValue - tolerance && currentValue <= prevValue + tolerance) {
            return 'stable';
        } else if (currentValue < prevValue) {
            return 'decreasing';
        } else {
            return 'increasing';
        }
    };

    const trending = getTrending();

    const trendingClass = (): string => {
        return clsx({
            [classes.increasingUsage]: trending === 'increasing',
            [classes.decreasingUsage]: trending === 'decreasing',
        });
    };

    const trendingIcon = (): React.ReactNode => {
        if (trending === 'increasing') {
            return <FontAwesomeIcon className={classes.margin} icon={faArrowUp} />;
        } else if (trending === 'decreasing') {
            return <FontAwesomeIcon className={classes.margin} icon={faArrowDown} />;
        }
    };

    return (
        <span className={trendingClass()}>
            {children} {trendingIcon()}
        </span>
    );
};
