/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import { createUseStyles } from 'react-jss';
import { Biac } from './Biac';
import { paletteSwitch } from '../../themes/palette';
import { ConfigContext } from '../../app/ConfigContextProvider';
import { Spinner } from '../spinner/Spinner';
import { AuthorizedUserBiac } from './AuthorizedUserBiac';
import { UiFeatures } from '../../api/configApi';
import { Theme } from '@mui/material/styles';

const useStyles = createUseStyles((theme: Theme) => ({
    config: {
        backgroundColor: paletteSwitch(theme).black2,
        padding: '8px 0',
        border: `1px solid ${paletteSwitch(theme).black12}`,
    },
}));

interface MetricsCollectionEnabledBiacProps {
    children: React.ReactElement;
}

export const MetricsCollectionEnabledBiac: React.FunctionComponent<
    MetricsCollectionEnabledBiacProps
> = ({ children }) => {
    const classes = useStyles();
    const clusterInfo = useContext(ConfigContext);

    if (clusterInfo === undefined) {
        return <Spinner position="relative" delay={500} />;
    }

    return (
        <Biac
            title={'This view requires additional configuration'}
            description={
                <Box my={3}>
                    To make it work, add the following entry to the server configuration file:
                    <pre className={classes.config}>insights.metrics-persistence-enabled=true</pre>
                </Box>
            }
            enabled={() => clusterInfo.metricsPersistenceEnabled}>
            <AuthorizedUserBiac uiFeature={UiFeatures.CLUSTER_HISTORY}>
                {children}
            </AuthorizedUserBiac>
        </Biac>
    );
};
