/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import moment from 'moment';

export const formatDuration = (millis: number): string => {
    const duration = moment.duration(millis, 'milliseconds');

    if (duration.asMinutes() < 1) {
        return duration.format('s[s]', 2);
    } else {
        return duration.format('d[d] h[h] m[m] s[s]', {
            largest: 2,
            trim: 'large',
        });
    }
};

export const formatDurationReadable = (millis: number, trimBoth = false): string => {
    const duration = moment.duration(millis, 'milliseconds');

    if (duration.asMinutes() < 1) {
        return duration.format('s[ seconds]', 0);
    } else {
        return duration.format('d[ days] h[ hours] m[ minutes] s[ seconds]', {
            largest: 2,
            trim: trimBoth ? 'both' : 'large',
        });
    }
};

export const formatDurationInHours = (millis: number): string => {
    const duration = moment.duration(millis, 'milliseconds');

    if (duration.asMinutes() < 1) {
        return duration.format('s[s]', 2);
    } else {
        return duration.format('h[h] m[m] s[s]', {
            largest: duration.asHours() < 24 ? 2 : 1,
            trim: 'large',
        });
    }
};
