/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { ChooserErrorIcon } from '../../grants/ChooserErrorIcon';
import { EmptyOrValue } from '../../../../utils/value';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import { addPrivilegesStyles } from '../add-privileges-styles';
import { getUdfs } from '../../../../api/biac/entities';
import { CustomItemSelector } from './CustomItemSelector';

interface FunctionsSelectorProps {
    disabled: boolean;
    shouldDisplaySelectAllCheckbox: boolean;
    schemaName: string | null;
    isTableFunction: boolean;
    currentRoleName: string;
    value: EmptyOrValue<string[] | null>;
    handleChange: React.Dispatch<React.SetStateAction<EmptyOrValue<string[] | null>>>;
}

export const FunctionsSelector: React.FunctionComponent<FunctionsSelectorProps> = ({
    disabled,
    shouldDisplaySelectAllCheckbox,
    schemaName,
    isTableFunction,
    currentRoleName,
    value,
    handleChange,
}) => {
    const classes = addPrivilegesStyles();
    const [busy, setBusy] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [functionsFound, setFunctionsFound] = useState<string[]>([]);

    useEffect(() => {
        setBusy(true);
        setError(null);
        getUdfs(currentRoleName)
            .then((udfs) => {
                const functions = udfs.sort().map<string>((udfName) => udfName);
                setBusy(false);
                setFunctionsFound([...functions]);
            })
            .catch((e) => {
                setBusy(false);
                setError(e.message);
            });
    }, [currentRoleName]);

    useEffect(() => {
        handleChange({ empty: true, value: null });
        return () => handleChange({ empty: true, value: null });
    }, [schemaName]);

    const renderPopupIcon = useCallback(() => {
        if (busy) {
            return <CircularProgress size={20} />;
        } else if (error) {
            return (
                <div>
                    <ChooserErrorIcon title="Loading functions failed" />
                </div>
            );
        }
    }, [busy, error]);

    const allChecked = !value.empty && value.value === null;
    const handleCheckStar = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            handleChange({
                empty: false,
                value: null,
            });
        } else {
            handleChange({
                empty: true,
                value: null,
            });
        }
    }, []);

    return (
        <Grid pl={1}>
            <Typography variant="h5">Which functions would you like to select?</Typography>
            <Typography className={classes.questionInfo}>
                Use the dropdown to select the functions.{' '}
                {shouldDisplaySelectAllCheckbox && (
                    <>
                        To select all currently defined functions, as well as functions defined in
                        the future, check the <b>All functions</b> box instead.
                    </>
                )}
            </Typography>
            {shouldDisplaySelectAllCheckbox && (
                <Box mb={1.5}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={allChecked}
                                onChange={handleCheckStar}
                                id="select-star"
                            />
                        }
                        label={
                            <span>
                                All functions{' '}
                                <span className={classes.cautionText}>(use with caution)</span>
                            </span>
                        }
                    />
                </Box>
            )}
            <Box mb={2}>
                <CustomItemSelector
                    disabled={disabled || allChecked}
                    label="Functions"
                    newTagOptionPostfix={' (new function)'}
                    value={value}
                    handleChange={handleChange}
                    suggestions={isTableFunction ? [] : functionsFound}
                    textFieldEndAdornment={renderPopupIcon()}
                />
            </Box>
        </Grid>
    );
};
