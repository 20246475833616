/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React from 'react';
import Tooltip from '@mui/material/Tooltip';

interface TableButtonTooltipProps {
    title: string;
    children: React.ReactNode;
}

export const TableButtonTooltip = ({ title, children }: TableButtonTooltipProps) => {
    return (
        <Tooltip
            PopperProps={{
                popperOptions: {
                    strategy: 'fixed',
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 5],
                            },
                        },
                    ],
                },
            }}
            title={title}>
            <span>{children}</span>
        </Tooltip>
    );
};
