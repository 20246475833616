/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';
import { ChartWrapper } from '../../../components/chart/ChartWrapper';
import { TimeLineChart } from '../../../components/chart/TimeLineChart';
import { RecentQueriesInTime } from '../../../api/overviewApi';
import { mergeLines, prepareLineChartData } from '../../../utils/lineChart';
import { defaultChartPalette, palette } from '../../../themes/palette';
import { ChartPoint } from 'chart.js';
import startCase from 'lodash/startCase';

interface RunningQueriesChartProps {
    queriesInTime: RecentQueriesInTime | 'loading' | 'error';
    height: number;
}

export const RunningQueriesChart: React.FunctionComponent<RunningQueriesChartProps> = ({
    queriesInTime,
    height,
}) => {
    const prepareLine = (
        label: string,
        prop: keyof RecentQueriesInTime[number]['metric'],
        color?: string
    ) => {
        return prepareLineChartData(label, queriesInTime, 'time', ({ metric }) => metric[prop], {
            color,
            fill: 'origin',
        });
    };

    const runningQueries = prepareLine('Queries running', 'runningQueries');
    const queuedQueries = prepareLine('Queries queued', 'queuedQueries', defaultChartPalette[1]);
    const failedQueries = prepareLine('Queries failed', 'failedQueries', palette.error);

    const data = mergeLines([runningQueries, queuedQueries, failedQueries]);

    const legendValueFormatter = (
        value: number | null | undefined | number[] | ChartPoint
    ): string => {
        if (!value) {
            return '-';
        }

        return (value as ChartPoint).y as string;
    };

    return (
        <ChartWrapper
            data={data}
            title={'Queries'}
            height={height}
            legend={{
                type: 'dataset',
                variant: 'inline',
                valueFormatter: legendValueFormatter,
                labelFormatter: (inputLabel) => startCase(inputLabel.replace('Queries', '')).trim(),
            }}>
            {(chartData): React.ReactNode => (
                <TimeLineChart
                    data={chartData}
                    height={height}
                    fixedUnit={'minute'}
                    yTicksLimit={3}
                    xTicksLimit={5}
                    xTicksMaxRotation={0}
                    yMax={2}
                    tooltipDateFormat={{
                        '12h': 'h:mm aaa',
                        '24h': 'H:mm',
                    }}
                    labelWidth={50}
                />
            )}
        </ChartWrapper>
    );
};
