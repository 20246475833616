/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';
import { Tooltip } from '../../../../../../components/tooltip/Tooltip';
import { truncateString } from '../utils';
import { PlanNodeInfo } from '../graphUtils';
import { paletteSwitch } from '../../../../../../themes/palette';
import { createUseStyles } from 'react-jss';
import { Theme } from '@mui/material/styles';

type PlanNodeProps = PlanNodeInfo;

export const PlanNode: React.FunctionComponent<PlanNodeProps> = ({
    details,
    name,
    identifier,
}: PlanNodeProps) => {
    let distribution = '';
    const classes = useStyles();
    const matchArray = details.match(/Distribution:\s+(\w+)/);
    if (matchArray !== null) {
        distribution = ' (' + matchArray[1] + ')';
    }

    return (
        <Tooltip
            title={<PlanNodeTooltipContent name={name} identifier={identifier} />}
            delayToolip={300}>
            <div className={classes.planNode}>
                <strong>{name + distribution}</strong>
                <div>{truncateString(identifier, 35)}</div>
            </div>
        </Tooltip>
    );
};

const useStyles = createUseStyles((theme: Theme) => ({
    planNode: {
        color: paletteSwitch(theme).nightBlue,
        height: '100%',
        textAlign: 'center',
    },
}));

interface PlanNodeTooltipContentProps {
    name: string;
    identifier: string;
}

const PlanNodeTooltipContent = ({ name, identifier }: PlanNodeTooltipContentProps) => {
    return (
        <div style={{ padding: '0.5rem', textAlign: 'center' }}>
            <div style={{ fontSize: '1rem', fontWeight: 500 }}>{name}</div>
            <div style={{ paddingTop: '0.5rem' }}>{identifier}</div>
        </div>
    );
};
