/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { ReactElement, FunctionComponent, useCallback } from 'react';
import { DeleteDialog } from '../../../components/dialog/DeleteDialog';
import { useDialogOpenState } from '../../../components/dialog/useDialogOpenState';
import { deleteDataProductComment } from '../../../api/dataProduct/comment/commentApi';
import { formatDatetime } from '../../../utils/formatDateInterval';

interface DeleteCommentModalProps {
    commentId: string;
    dataProductId: string;
    createdAt: string;
    createdBy: string;
    opener: (open: () => void) => ReactElement<HTMLElement>;
}

export const DeleteCommentModal: FunctionComponent<DeleteCommentModalProps> = ({
    commentId,
    dataProductId,
    createdAt,
    createdBy,
    opener,
}: DeleteCommentModalProps) => {
    const { isOpen, close, open } = useDialogOpenState();
    const handleDeletion = useCallback(
        () => deleteDataProductComment(dataProductId, commentId),
        [dataProductId, commentId]
    );

    return (
        <>
            {opener(open)}
            {isOpen && (
                <DeleteDialog
                    isOpen={isOpen}
                    close={close}
                    onConfirm={handleDeletion}
                    entityType="comment"
                    entityHumanName={`posted on ${formatDatetime(createdAt)} by ${createdBy}`}
                />
            )}
        </>
    );
};
