/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import React from 'react';
import { useTabStyles } from '../../../themes/useTabStyles';
import { Link } from 'react-router-dom';
import { DefaultTab } from '../../../components/tab/DefaultTab';

export enum QueryDetailsTab {
    GENERAL = 'general',
    CLUSTER = 'cluster-resources',
    STAGES = 'stages',
    ADVANCED = 'advanced',
    ACCELERATION = 'lake-acceleration',
}

interface QueryDetailsTabsBarProps {
    tab: QueryDetailsTab;
    shouldDisplayLakeAccelerationTab: boolean;
}

export const QueryDetailsTabsBar: React.FunctionComponent<QueryDetailsTabsBarProps> = ({
    tab,
    shouldDisplayLakeAccelerationTab,
}) => {
    const tabClasses = useTabStyles();
    return (
        <Box className={tabClasses.tabs}>
            <Tabs value={tab} indicatorColor="primary" textColor="primary">
                <DefaultTab
                    label="General"
                    value={QueryDetailsTab.GENERAL}
                    component={Link}
                    to={QueryDetailsTab.GENERAL}
                    disableRipple
                    replace
                />

                <DefaultTab
                    value={QueryDetailsTab.CLUSTER}
                    component={Link}
                    label="Cluster resources"
                    to={QueryDetailsTab.CLUSTER}
                    replace
                />
                <DefaultTab
                    value={QueryDetailsTab.STAGES}
                    component={Link}
                    label="Stages"
                    to={QueryDetailsTab.STAGES}
                    replace
                />
                <DefaultTab
                    value={QueryDetailsTab.ADVANCED}
                    component={Link}
                    label="Advanced"
                    to={QueryDetailsTab.ADVANCED}
                    replace
                />
                {shouldDisplayLakeAccelerationTab && (
                    <DefaultTab
                        value={QueryDetailsTab.ACCELERATION}
                        component={Link}
                        label="Index and cache usage"
                        to={QueryDetailsTab.ACCELERATION}
                        disableRipple
                        replace
                    />
                )}
            </Tabs>
        </Box>
    );
};
