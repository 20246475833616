/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import React from 'react';
import { faBomb } from '@fortawesome/pro-duotone-svg-icons';
import { paletteSwitch } from '../../themes/palette';
import { createUseStyles } from 'react-jss';
import { Theme } from '@mui/material/styles';

interface ErrorBoxProps {
    height?: number;
    text: string;
}

const useStyles = createUseStyles((theme: Theme) => ({
    icon: {
        opacity: 0.85,
        marginLeft: 'auto',
        marginRight: 'auto',
        '--fa-primary-color': paletteSwitch(theme).black12,
        '--fa-secondary-color': paletteSwitch(theme).error,
        '--fa-primary-opacity': 1,
        '--fa-secondary-opacity': 1,
    },
    text: {
        color: paletteSwitch(theme).black54,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '1rem',
        fontWeight: 400,
        fontSize: '1rem',
    },
}));

export const ErrorBox: React.FunctionComponent<ErrorBoxProps> = ({ height = 400, text }) => {
    const classes = useStyles();

    return (
        <Box display={'flex'} flexDirection={'column'} style={{ height }}>
            <FontAwesomeIcon
                className={classes.icon}
                style={{
                    marginTop: height > 150 ? height / 12 : 0,
                    fontSize: Math.min(110, height * 0.6),
                }}
                icon={faBomb}
            />
            <Box className={classes.text}>{text}</Box>
        </Box>
    );
};
