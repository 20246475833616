/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { Dispatch, ReactNode } from 'react';
import TextField from '@mui/material/TextField';
import { createUseStyles } from 'react-jss';

import { useTextInputValueChange } from '../../../../utils/useTextInputValueChange';
import { createDescriptionHelperText, mapDomainDescription } from '../dataDomainUtils';
import {
    createSetDescriptionAction,
    createSetNameAction,
    createSetSchemaLocationAction,
    DomainFormAction,
} from './useDomainFormReducer';
import { DataDomainFormData } from './domainFormService';
import { maximumTitleLength } from '../../util/formUtils';

const useStyles = createUseStyles({
    description: {
        marginTop: '1rem',
    },
    instructionText: {
        marginTop: '1rem',
        marginBottom: '0.5rem',
    },
});

export interface CreateDomainFormProps {
    formData: DataDomainFormData;
    dispatch: Dispatch<DomainFormAction>;
    error?: ReactNode;
    className?: string;
    isEditMode?: boolean;
}

export const DomainForm: React.FunctionComponent<CreateDomainFormProps> = ({
    formData,
    dispatch,
    className,
    error = null,
    isEditMode = false,
}) => {
    const styles = useStyles();
    const handleNameChange = useTextInputValueChange(
        (value) => dispatch(createSetNameAction(value)),
        []
    );
    const handleSchemaLocationChange = useTextInputValueChange(
        (value) => dispatch(createSetSchemaLocationAction(value)),
        []
    );
    const handleDescriptionChange = useTextInputValueChange(
        (value) => dispatch(createSetDescriptionAction(mapDomainDescription(value))),
        []
    );
    return (
        <div className={className}>
            <TextField
                value={formData.name}
                variant="outlined"
                label="Domain name"
                size="small"
                fullWidth
                required
                disabled={isEditMode}
                onChange={handleNameChange}
                inputProps={{ maxLength: maximumTitleLength }}
            />
            <TextField
                className={styles.description}
                value={formData.description}
                label="Domain description"
                size="small"
                variant="outlined"
                multiline
                minRows={4}
                fullWidth
                onChange={handleDescriptionChange}
                helperText={createDescriptionHelperText(formData.description)}
            />
            {error}
            <div className={styles.instructionText}>
                Data Products under this domain will be created as schemas in this location.
            </div>
            <TextField
                value={formData.schemaLocation}
                label="Schema location URI"
                size="small"
                variant="outlined"
                fullWidth
                onChange={handleSchemaLocationChange}
            />
        </div>
    );
};
