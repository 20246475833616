/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { logoutRoute } from './login/routing/LoginRouting';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import { createUseStyles } from 'react-jss';
import axios from 'axios';

const useStyles = createUseStyles({
    dialogMessage: {
        fontFamily: 'montserrat, sans-serif',
        fontSize: '1.25rem',
        fontWeight: 600,
    },
});

export const UnauthorizedDialog: React.FunctionComponent = () => {
    const classes = useStyles();
    const history = useHistory();
    const [openDialog, setOpenDialog] = useState(false);
    const [authExpired, setAuthExpired] = useState(false);

    useEffect(() => {
        if (authExpired) {
            return;
        }
        // For Unauthorized requests or session timeout requests
        const interceptorId = axios.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response?.status === 401) {
                    setAuthExpired(true);
                    setOpenDialog(true);
                }
                return Promise.reject(error);
            }
        );
        return () => axios.interceptors.response.eject(interceptorId);
    }, [authExpired]);

    const handleDismiss = () => setOpenDialog(false);
    const handleLogout = () => history.push(logoutRoute);

    return (
        <Dialog open={openDialog} disableEscapeKeyDown>
            <DialogTitle>
                <div className={classes.dialogMessage}>Expired session</div>
            </DialogTitle>
            <DialogContent style={{ width: '30rem' }}>
                <DialogContentText>
                    Your session has expired. Log back in to start a new session.
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ padding: '0 1rem 1rem 0' }}>
                <Button onClick={handleDismiss} color="primary" variant="outlined">
                    Dismiss
                </Button>
                <Button onClick={handleLogout} color="primary" variant="contained">
                    Log out
                </Button>
            </DialogActions>
        </Dialog>
    );
};
