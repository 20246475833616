/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';
import Chip from '@mui/material/Chip';
import { createUseStyles } from 'react-jss';
import { FilterCriteria, queryFilters } from './queryFilters';

const useStyles = createUseStyles({
    chip: {
        marginLeft: '0.375rem',
        maxWidth: '300px',
        '&:focus': {
            backgroundColor: 'transparent!important',
        },
    },
});

interface FilterChipsProps {
    appliedFilters: FilterCriteria | null;
    removeFilter: (filterBy: keyof FilterCriteria) => void;
}

export const FilterChips: React.FunctionComponent<FilterChipsProps> = ({
    appliedFilters,
    removeFilter,
}) => {
    const classes = useStyles();

    const getFilter = (filterBy: string): undefined | ReturnType<typeof queryFilters>[number] => {
        return queryFilters().find((filter) => filter.filterBy === filterBy);
    };

    if (!appliedFilters) {
        return null;
    }

    return (
        <>
            {Object.entries(appliedFilters)
                .filter(([, value]) => !!value)
                .map(([key, value]) => (
                    <Chip
                        key={key}
                        label={`${getFilter(key)?.label}: ${getFilter(key)?.formatValue(value)}`}
                        onDelete={(): void => removeFilter(key as keyof FilterCriteria)}
                        variant="outlined"
                        className={classes.chip}
                    />
                ))}
        </>
    );
};
