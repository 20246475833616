/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import TableRow from '@mui/material/TableRow';
import ceil from 'lodash/ceil';
import { TableRowData } from './useTableSorting';
import TableFooter from '@mui/material/TableFooter';
import { useTableStyles } from './useTableStyles';
import Pagination from '@mui/material/TablePagination';

const MAX_ROWS = 50;

const paginationEnabled = (count: number): boolean => {
    return count > MAX_ROWS;
};

const getPaginatedItems = <T extends TableRowData>(
    items: T[],
    page: number,
    rowsPerPage: number
): T[] => {
    return paginationEnabled(items.length)
        ? items.slice(rowsPerPage * page, rowsPerPage * (page + 1))
        : items;
};

interface TablePaginationProps {
    page: number;
    setPage: (page: number) => void;
    rowsPerPage: number;
    setRowsPerPage: (rowsPerPage: number) => void;
    count: number;
    labelRowsPerPage: string;
}

const TablePagination = ({
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    count,
    labelRowsPerPage,
}: TablePaginationProps) => {
    const handlePageChange = useCallback(
        (_, newPage) => {
            setPage(newPage);
        },
        [setPage]
    );

    const handleRowsPerPageChange = useCallback(
        ({ target: { value } }) => {
            setRowsPerPage(parseInt(value, 10));
            setPage(0);
        },
        [setRowsPerPage, setPage]
    );

    const lastPage = useMemo(() => ceil(count / rowsPerPage) - 1, [count, rowsPerPage]);
    const actualPage = useMemo(() => (page > lastPage ? lastPage : page), [page, lastPage]);

    useEffect(() => {
        setPage(actualPage);
    }, [setPage, actualPage]);

    const tableClasses = useTableStyles();

    return (
        <TableFooter>
            <TableRow>
                <Pagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    count={count}
                    page={actualPage}
                    onPageChange={handlePageChange}
                    labelRowsPerPage={labelRowsPerPage}
                    className={tableClasses.pagination}
                    classes={{
                        root: tableClasses.paginationRoot,
                        actions: tableClasses.paginationActions,
                        input: tableClasses.paginationInput,
                        menuItem: tableClasses.paginationMenuItem,
                    }}
                />
            </TableRow>
        </TableFooter>
    );
};

export function useTablePagination<T>(items: T[], labelRowsPerPage: string) {
    const [page, setPage] = useState<number>(0);
    const [itemsPerPage, setItemsPerPage] = useState<number>(50);

    const paginatedItems = useMemo(
        () => getPaginatedItems(items, page, itemsPerPage),
        [items, page, itemsPerPage]
    );

    const count = useMemo(() => items.length, [items]);

    const Pagination = useCallback(() => {
        if (!paginationEnabled(count)) {
            return null;
        }

        return (
            <TablePagination
                page={page}
                setPage={setPage}
                rowsPerPage={itemsPerPage}
                setRowsPerPage={setItemsPerPage}
                count={count}
                labelRowsPerPage={labelRowsPerPage}
            />
        );
    }, [page, setPage, itemsPerPage, setItemsPerPage, count, labelRowsPerPage]);

    const result: {
        TablePagination: () => JSX.Element | null;
        paginatedItems: T[];
    } = useMemo(
        () => ({
            TablePagination: Pagination,
            paginatedItems,
        }),
        [Pagination, paginatedItems]
    );

    return result;
}
