/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useEffect } from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { ChooserErrorIcon } from '../../grants/ChooserErrorIcon';
import { EmptyOrValue } from '../../../../utils/value';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import { addPrivilegesStyles } from '../add-privileges-styles';
import { useSubjects } from '../../grants/useSubjects';
import { CustomItemSelector } from './CustomItemSelector';

interface SubjectsSelectorProps {
    currentRoleName: string;
    value: EmptyOrValue<string[] | null>;
    handleChange: React.Dispatch<React.SetStateAction<EmptyOrValue<string[] | null>>>;
}
export const UsersSelector: React.FunctionComponent<SubjectsSelectorProps> = ({
    currentRoleName,
    value,
    handleChange,
}) => {
    const classes = addPrivilegesStyles();
    const { subjects, busy, error } = useSubjects(currentRoleName, 'user');

    useEffect(() => {
        return () => handleChange({ empty: true, value: null });
    }, []);

    const renderPopupIcon = useCallback(() => {
        if (busy) {
            return <CircularProgress size={20} />;
        } else if (error) {
            return (
                <div>
                    <ChooserErrorIcon title="Loading users failed" />
                </div>
            );
        }
    }, [busy, error]);

    const allChecked = !value.empty && value.value === null;
    const handleCheckStar = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            handleChange({
                empty: false,
                value: null,
            });
        } else {
            handleChange({
                empty: true,
                value: null,
            });
        }
    }, []);

    return (
        <Grid pl={1} maxWidth="fit-content">
            <Typography variant="h5">Which users would you like to select?</Typography>
            <Typography className={classes.questionInfo}>
                To select multiple current users use the below dropdown. Select <b>All users</b> to
                allow or deny access to all current and future users.
            </Typography>
            <Box mb={1.5}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={allChecked}
                            onChange={handleCheckStar}
                            id="select-star"
                        />
                    }
                    label="All users granted this role"
                />
            </Box>
            <Box mb={2}>
                <CustomItemSelector
                    disabled={allChecked}
                    label="Users"
                    newTagOptionPostfix={' (new user)'}
                    value={value}
                    handleChange={handleChange}
                    suggestions={subjects}
                    textFieldEndAdornment={renderPopupIcon()}
                    inputValidationRequired
                />
            </Box>
        </Grid>
    );
};
