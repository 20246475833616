/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';
import { createUseStyles } from 'react-jss';
import logo from '../../images/enterprise-logo.svg';

const useStyles = createUseStyles({
    logoContainer: {
        display: 'flex',
        flexGrow: 1,
        paddingTop: '8px',
        verticalAlign: 'center',
    },
    logoStars: {
        position: 'relative',
    },
});

interface EnterpriseLogoProps {
    width?: number;
    height?: number;
}

const DEFAULT_WIDTH = 431;
const DEFAULT_HEIGHT = 70;

export const EnterpriseLogo: React.FunctionComponent<EnterpriseLogoProps> = ({
    width = DEFAULT_WIDTH,
    height = DEFAULT_HEIGHT,
}) => {
    const classes = useStyles();

    return (
        <div className={classes.logoContainer}>
            <img
                className={classes.logoStars}
                style={{ top: `-${6 * (height / DEFAULT_HEIGHT)}px` }}
                src={logo}
                width={width}
                height={height}
                alt="Starburst Enterprise"
            />
        </div>
    );
};
