/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import { paletteSwitch } from '../../../themes/palette';
import { createUseStyles } from 'react-jss';
import { Theme } from '@mui/material/styles';

export const useRoleDetailsStyles = createUseStyles((theme: Theme) => ({
    errorMessage: {
        width: '100%',
    },
    list: {
        maxHeight: 'calc(100vh - 15rem)',
        overflowY: 'auto',
    },
    listShort: {
        maxHeight: 'calc(100vh - 19.5rem)',
        overflowY: 'auto',
    },
    header: {
        padding: '16px',
        fontSize: '0.875rem',
        fontWeight: 600,
    },
    endBorder: {
        borderTop: `1px solid ${paletteSwitch(theme).black12}`,
    },
    reloadLink: {
        cursor: 'pointer',
        fontWeight: 600,
    },
    noContent: {
        color: paletteSwitch(theme).black54,
        fontWeight: 500,
        textAlign: 'center',
        letterSpacing: 0.1,
    },
}));
