/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React from 'react';
import { paletteSwitch } from '../../themes/palette';
import { createUseStyles } from 'react-jss';
import { Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SearchField } from '../../features/dataproduct/components/search/SearchField';

const useLocalStyles = createUseStyles((theme: Theme) => ({
    rightContainer: {
        marginTop: theme.spacing(1),
        marginLeft: 'auto',
        display: 'flex',
        flexWrap: 'wrap-reverse',
    },
    count: {
        margin: 'auto',
        marginLeft: '1rem',
        marginRight: '1rem',
        color: paletteSwitch(theme).black,
    },
    commonActionBar: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        minHeight: '3rem',
    },
}));

interface TableActionBarProps {
    action?: React.ReactElement;
    itemsCount: number;
    getItemsStr: (itemsCount: number) => string;
    filter: string;
    setFilter: (filter: string) => void;
    hideFilter?: boolean;
}

export const TableActionBar = ({
    itemsCount,
    getItemsStr,
    action = <div />,
    filter,
    setFilter,
    hideFilter = false,
}: TableActionBarProps) => {
    const localClasses = useLocalStyles();

    return (
        <div className={localClasses.commonActionBar}>
            {action}
            {!hideFilter && (
                <Box className={localClasses.rightContainer}>
                    <Typography
                        variant="body2"
                        className={localClasses.count}>{`${itemsCount} ${getItemsStr(
                        itemsCount
                    )}`}</Typography>
                    <SearchField
                        value={filter}
                        onValueChange={setFilter}
                        placeholder={`Search ${getItemsStr(2)}`}
                        hasHelperText={false}
                        overrideInputRootStyles={false}
                    />
                </Box>
            )}
        </div>
    );
};
