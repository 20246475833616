/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useState } from 'react';
import {
    Control,
    Controller,
    FieldPath,
    FieldPathValue,
    FieldValues,
    UnpackNestedValue,
    UseControllerProps,
    useFormState,
} from 'react-hook-form';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { InputProps as StandardInputProps } from '@mui/material/Input';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { isRequired } from '../utils/formUtils';
import { Theme } from '@mui/material/styles';
import { paletteSwitch } from '../themes/palette';

interface Props<TFieldValues> extends UseControllerProps<TFieldValues> {
    className?: string;
    control: Control<TFieldValues>;
    defaultValue?: UnpackNestedValue<FieldPathValue<TFieldValues, FieldPath<TFieldValues>>>;
    disabled?: boolean;
    finePrint?: string;
    fullWidth?: boolean;
    label?: string;
    placeholder?: string;
    type?: string;
    rows?: number;
    variant?: 'standard' | 'outlined' | 'filled';
    size?: 'small' | 'medium';
    InputProps?: Partial<StandardInputProps>;
    inputProps?: {
        [key: string]: string;
    };
    autoFocus?: boolean;
}

const useAdornmentStyles = createUseStyles((theme: Theme) => ({
    passwordAdornment: {
        marginRight: '-6px',
        color: paletteSwitch(theme).nebulaNavy200,
    },
    mediumPasswordAdornment: {
        padding: '7px',
    },
    smallPasswordAdornment: {
        padding: '4px',
    },
}));

export const InputField = <TFieldValues extends FieldValues = FieldValues>({
    control,
    defaultValue,
    disabled,
    finePrint,
    InputProps,
    name,
    rules,
    type,
    autoFocus,
    ...rest
}: Props<TFieldValues>) => {
    const adornmentStyles = useAdornmentStyles();
    const { isSubmitting } = useFormState({ control });
    const [visible, setVisible] = useState(false);

    const toggleVisible = () => setVisible((prev) => !prev);

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            defaultValue={defaultValue}
            render={({ field, fieldState }) => {
                const hasError = Boolean(fieldState.error);
                return (
                    <TextField
                        {...rest}
                        {...field}
                        type={visible ? 'text' : type}
                        disabled={disabled || isSubmitting}
                        autoFocus={autoFocus}
                        value={field.value || ''}
                        InputLabelProps={{
                            required: isRequired(rules),
                        }}
                        error={hasError}
                        helperText={fieldState.error?.message || finePrint}
                        InputProps={{
                            endAdornment: type === 'password' && (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={toggleVisible}
                                        className={clsx(
                                            adornmentStyles.passwordAdornment,
                                            rest.size === 'medium'
                                                ? adornmentStyles.mediumPasswordAdornment
                                                : adornmentStyles.smallPasswordAdornment
                                        )}
                                        size="large">
                                        {visible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                            ...InputProps,
                        }}
                    />
                );
            }}
        />
    );
};
