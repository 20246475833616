/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { useFetchingState } from '../../domain/useFetchingState';
import { useEffect } from 'react';
import {
    DataProductStatistics,
    getDataProductStatistics$,
} from '../../../../api/dataProduct/dataProductApi';

export const useMetricsFetchingState = (dataProductId: string) => {
    const state = useFetchingState<DataProductStatistics | null>();
    useEffect(() => {
        state.setFetching();
        const subscription = getDataProductStatistics$(dataProductId).subscribe(
            state.setData,
            ({ message }) => state.setError(message)
        );
        return () => subscription.unsubscribe();
    }, [dataProductId]);
    return state;
};
