/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React, { useCallback, useState } from 'react';

import Typography from '@mui/material/Typography';
import { createUseStyles } from 'react-jss';
import { paletteSwitch } from '../../../themes/palette';
import {
    changeWorksheetSharing,
    Worksheet,
    AccessMode,
    WorksheetPermissionRow,
} from '../../../api/savedQueriesApi';
import { Theme } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import Dialog from '@mui/material/Dialog';
import { DialogHeader } from '../../../components/dialog/DialogHeader';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { LoadingButton } from '../../../components/LoadingButton';
import Alert from '@mui/material/Alert';
import { ShareQueryWithRole } from './ShareQueryWithRole';
import { EditQueryAccessMode } from './EditQueryAccessMode';

const useLocalStyles = createUseStyles((theme: Theme) => ({
    subTitle: {
        marginTop: '-1.5rem',
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: '24px',
        letterSpacing: '0.5px',
        color: `${paletteSwitch(theme).black}`,
    },
    form: {
        marginTop: '1.5rem',
    },
    dialogPaper: {
        width: 500,
        paddingLeft: '0.625rem',
    },
    dialogContent: {
        paddingBottom: theme.spacing(3),
    },
}));

interface ShareQueryDialogProps {
    worksheet: Worksheet;
    onClose: (shouldReloadWorksheets: boolean) => void;
}

export interface QuerySharingFormState {
    newAliases: string[];
    newRolesMode: AccessMode;
    permissions: WorksheetPermissionRow[];
}

const sortPermissions = (currRole: WorksheetPermissionRow, nextRole: WorksheetPermissionRow) => {
    const currRoleName = currRole.subjectName.toLowerCase();
    const nextRoleName = nextRole.subjectName.toLowerCase();

    if (currRoleName < nextRoleName) {
        return -1;
    }
    if (currRoleName > nextRoleName) {
        return 1;
    }
    return 0;
};

export const ShareQueryDialog: React.FunctionComponent<ShareQueryDialogProps> = ({
    worksheet,
    onClose,
}) => {
    const classes = useLocalStyles();

    const [error, setError] = useState<string>('');

    const { handleSubmit, formState, control, setValue } = useForm<QuerySharingFormState>({
        defaultValues: {
            newAliases: [],
            newRolesMode: 'CAN_EDIT',
            permissions: [...worksheet.permissions].sort(sortPermissions),
        },
    });

    const onSubmit = useCallback(
        async (newSharingState: QuerySharingFormState) => {
            try {
                const newState: WorksheetPermissionRow[] = [];

                if (newSharingState.newAliases.length > 0) {
                    newSharingState.newAliases.forEach((newAlias) => {
                        newState.push({
                            subjectName: newAlias,
                            subjectType: 'ROLE',
                            access: newSharingState.newRolesMode,
                        });
                    });
                }
                newSharingState.permissions
                    .filter((changedPermission) => {
                        const oldPermission = worksheet.permissions.find(
                            (it) =>
                                it.subjectName === changedPermission.subjectName &&
                                it.subjectType === changedPermission.subjectType
                        );
                        return oldPermission?.access !== changedPermission.access;
                    })
                    .forEach((it) => newState.push(it));
                if (newState.length > 0) {
                    await changeWorksheetSharing(worksheet.id, newState);
                    onClose(true);
                } else {
                    onClose(false);
                }
            } catch (e) {
                setError(e.message);
            }
        },
        [onClose]
    );

    return (
        <Dialog open={true} classes={{ paper: classes.dialogPaper }}>
            <DialogHeader title="Share query" onClose={() => onClose(false)} />
            <DialogContent className={classes.dialogContent}>
                <Typography typography="body1" className={classes.subTitle}>
                    Share this query tab with users in the following roles:
                </Typography>
                {error && (
                    <Grid item xs={12}>
                        <Alert severity="error">{error}</Alert>
                    </Grid>
                )}
                <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
                    <Grid container spacing={3}>
                        <ShareQueryWithRole setValue={setValue} control={control} />
                        <EditQueryAccessMode control={control} />
                        <Grid
                            item
                            xs={12}
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                paddingRight: '0.625rem',
                            }}>
                            <Button
                                variant="outlined"
                                style={{ marginRight: '1rem' }}
                                onClick={() => onClose(false)}>
                                Cancel
                            </Button>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                color="primary"
                                loading={formState.isValidating || formState.isSubmitting}
                                disabled={
                                    !formState.dirtyFields['permissions'] &&
                                    !formState.dirtyFields['newAliases']
                                }>
                                Done
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </Dialog>
    );
};
