/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useState } from 'react';
import Button from '@mui/material/Button';
import { CreateExpressionDialog } from './CreateExpressionDialog';
import { ExpressionTable } from './ExpressionTable';
import { createUseStyles } from 'react-jss';
import { DeleteDialog } from '../../../../components/dialog/DeleteDialog';
import { EditExpression } from './EditExpression';
import Box from '@mui/material/Box';
import { deleteRowExpression } from './rowExpressionApi';
import { Persisted } from '../../../../api/biac/common';
import { Expression } from '../../../../api/biac/biacApi';
import { getEntityDisplayName, RowExpressionType } from '../MasksAndFilters';
import { NoData } from '../../../../components/error/NoData';
import { paletteSwitch } from '../../../../themes/palette';
import { Spinner } from '../../../../components/spinner/Spinner';
import { Theme } from '@mui/material/styles';

const useStyles = createUseStyles((theme: Theme) => ({
    sidePanel: {
        position: 'fixed',
        borderLeft: `1px solid ${paletteSwitch(theme).nebulaNavy100}`,
        top: '56px',
        bottom: '0',
        right: '0',
        width: '512px',
    },
}));

interface RowExpressionsOfTypeProps {
    setSidePanelOpen: (open: boolean) => void;
    rowExpressionType: RowExpressionType;
    expressions: Persisted<Expression>[];
    reload: () => void;
    isLoading: boolean;
}

export const RowExpressionsOfType: React.FunctionComponent<RowExpressionsOfTypeProps> = (
    params
) => {
    const classes = useStyles();
    const [selectedExpression, setSelectedExpression] = useState<Persisted<Expression>>();
    const [deleteExpressionDialogOpen, setDeleteExpressionDialogOpen] = useState(false);

    const [createExpressionDialogOpen, setCreateExpressionDialogOpen] = useState(false);

    const [deleteDialogExpression, setDeleteDialogExpression] = useState<Persisted<Expression>>();

    const [expressionToEdit, setExpressionToEdit] = useState<Persisted<Expression>>();

    const updateEditExpression = useCallback(
        (expression?: Persisted<Expression>) => {
            params.setSidePanelOpen(expression !== undefined);
            setExpressionToEdit(expression);
        },
        [params.setSidePanelOpen]
    );

    const handleDeleteExpressionConfirmed = useCallback(async () => {
        if (deleteDialogExpression) {
            await deleteRowExpression(deleteDialogExpression, params.rowExpressionType);
            setDeleteExpressionDialogOpen(false);
            updateEditExpression(undefined);
            params.reload();
        }
    }, [deleteDialogExpression, params.rowExpressionType, updateEditExpression, params.reload]);

    const handleCloseEditDialog = useCallback(
        (shouldRefresh = false) => {
            updateEditExpression(undefined);
            if (shouldRefresh) {
                params.reload();
            }
        },
        [params.reload, updateEditExpression]
    );

    const handleCloseCreateExpressionDialog = useCallback(() => {
        setCreateExpressionDialogOpen(false);
    }, []);

    const showDeleteExpressionDialog = useCallback((expression: Persisted<Expression>) => {
        setDeleteDialogExpression(expression);
        setDeleteExpressionDialogOpen(true);
    }, []);

    const showCreateDialog = useCallback(() => {
        setCreateExpressionDialogOpen(true);
    }, []);

    return (
        <>
            <CreateExpressionDialog
                rowExpressionType={params.rowExpressionType}
                reloadRowExpressions={params.reload}
                isOpen={createExpressionDialogOpen}
                close={handleCloseCreateExpressionDialog}
            />
            {deleteDialogExpression && (
                <DeleteDialog
                    onConfirm={handleDeleteExpressionConfirmed}
                    isOpen={deleteExpressionDialogOpen}
                    close={() => setDeleteExpressionDialogOpen(false)}
                    entityType={getEntityDisplayName(params.rowExpressionType).toLowerCase()}
                    entityHumanName={deleteDialogExpression.object.name}
                />
            )}
            {params.isLoading && <Spinner position="relative" />}
            {!params.isLoading && (
                <>
                    <Box mb={3}>
                        <Button variant="contained" color="primary" onClick={showCreateDialog}>
                            Create new&nbsp;
                            {getEntityDisplayName(params.rowExpressionType).toLowerCase()}
                        </Button>
                    </Box>

                    {params.expressions.length !== 0 && (
                        <ExpressionTable
                            expressions={params.expressions}
                            reloadExpressionList={params.reload}
                            selectedRowExpression={selectedExpression}
                            setSelectedRowExpression={setSelectedExpression}
                            deleteRowExpression={showDeleteExpressionDialog}
                            editRowExpression={updateEditExpression}
                            rowExpressionType={params.rowExpressionType}
                        />
                    )}
                    {!params.expressions.length && (
                        <NoData
                            height={130}
                            icon="table"
                            text={`No ${getEntityDisplayName(
                                params.rowExpressionType
                            ).toLowerCase()}s available`}
                        />
                    )}

                    {expressionToEdit && (
                        <Box p={3} className={classes.sidePanel}>
                            <EditExpression
                                rowExpression={expressionToEdit}
                                close={handleCloseEditDialog}
                                deleteExpression={showDeleteExpressionDialog}
                                rowExpressionType={params.rowExpressionType}
                            />
                        </Box>
                    )}
                </>
            )}
        </>
    );
};
