/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useMemo } from 'react';
import { SingleQueryDetails } from '../../../../api/queryApi';
import Box from '@mui/material/Box';
import { DetailsRow } from '../../../../components/details/DetailsRow';
import { DetailsCell } from '../../../../components/details/DetailsCell';
import { useSectionStyles } from './useSectionStyles';
import { getAcceleratedCatalogs, getOverallLakeAccelerationData } from '../computeLakeAcceleration';
import { HintTooltip } from '../../../../components/tooltip/HintTooltip';

interface QueryAccelerationSectionProps {
    query: Readonly<SingleQueryDetails>;
}
export const QueryAccelerationSection: React.FunctionComponent<QueryAccelerationSectionProps> = (
    params
) => {
    const classes = useSectionStyles();

    const { overall, filtering, projection } = useMemo(
        () => getOverallLakeAccelerationData(params.query),
        [params.query]
    );

    const acceleratedCatalogs = useMemo(() => getAcceleratedCatalogs(params.query), [params.query]);
    return (
        <Box mb={1}>
            <div className={classes.header}>
                Index and cache usage
                <HintTooltip title="The percentage of query splits that were handled by Smart Indexing and Caching out of the total number of query splits" />
            </div>
            <Box className={classes.subHeader} mb={2}>
                Accelerated Catalogs:{' '}
                <span className={classes.subHeaderValue}>
                    {acceleratedCatalogs.length > 0 ? acceleratedCatalogs.join(', ') : 'None'}
                </span>
            </Box>
            <Box>
                <DetailsRow>
                    <DetailsCell
                        header="Overall"
                        value={Math.round(overall * 100) + '%'}
                        classes={{ value: classes.itemValue }}
                        tooltip="Combined score for Filtering operations (WHERE clause) and Projection operations (SELECT clause)"
                    />
                    <DetailsCell
                        header="Filtering (WHERE Clause)"
                        value={isNaN(filtering) ? 'N/A' : Math.round(filtering * 100) + '%'}
                        classes={{ value: classes.itemValue }}
                        tooltip="Score for Filtering operations (WHERE clause) only"
                    />
                    <DetailsCell
                        header="Projection (SELECT Clause)"
                        value={Math.round(projection * 100) + '%'}
                        classes={{ value: classes.itemValue }}
                        tooltip="Score for Projection operations (SELECT clause) only"
                    />
                </DetailsRow>
            </Box>
        </Box>
    );
};
