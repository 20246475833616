/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { ReactElement, useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FiltersRow, FiltersRowModel } from './FiltersRow';
import { v4 as uuidv4 } from 'uuid';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { FilterCriteria, queryFilters } from './queryFilters';
import { FilterChips } from './FilterChips';
import { createUseStyles } from 'react-jss';
import { Toggle } from '../../../components/toggle/Toggle';
import { ConfigContext } from '../../../app/ConfigContextProvider';
import { initialQueryHistoryFilters } from './queryHistorySlice';

interface QueriesSearchProps {
    appliedFilters: FilterCriteria | null;
    onFiltersApply: (criteria: FilterCriteria | null) => void;
}

const useStyles = createUseStyles({
    card: {
        marginBottom: '16px',
    },
    filtersRow: {
        minHeight: '32px',
    },
});

export const QueriesSearch: React.FunctionComponent<QueriesSearchProps> = ({
    appliedFilters,
    onFiltersApply,
}) => {
    const classes = useStyles();
    const allQueries = useContext(ConfigContext)?.allQueries;
    const newFilter = (filterBy = queryFilters({ allQueries })[0].filterBy): FiltersRowModel => {
        return {
            id: uuidv4(),
            filterBy,
            filterValue: null,
            valid: true,
        };
    };

    const parseFilters = (filters: FilterCriteria | null = appliedFilters): FiltersRowModel[] => {
        if (!filters) {
            return [];
        }

        return Object.entries(filters)
            .filter(([, value]) => !!value)
            .map(
                ([key, value]): FiltersRowModel => ({
                    id: uuidv4(),
                    filterBy: key as keyof FilterCriteria,
                    filterValue: value,
                    valid: true,
                })
            );
    };

    const [filters, setFilters] = useState<FiltersRowModel[]>(() => {
        const parsed = parseFilters();
        return parsed && parsed.length > 0 ? parsed : [newFilter()];
    });

    const setFiltersElement =
        (id: string) =>
        (setFn: (prevState: FiltersRowModel) => FiltersRowModel): void => {
            setFilters((prevState) =>
                prevState.map((iteratee) => (iteratee.id !== id ? iteratee : setFn(iteratee)))
            );
        };

    const availableFilters = (ignoreFilter?: FiltersRowModel): Array<keyof FilterCriteria> =>
        queryFilters({ allQueries })
            .map((queryFilter) => queryFilter.filterBy)
            .filter(
                (queryFilter) =>
                    !filters
                        .filter((filter) => !ignoreFilter || filter.id !== ignoreFilter.id)
                        .map((f) => f.filterBy)
                        .includes(queryFilter)
            );

    const validateIfArray = (filter: FiltersRowModel) => {
        return Array.isArray(filter.filterValue) ? (filter.filterValue as []).length : true;
    };

    const applyFilters = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const criteria: FilterCriteria = filters
            .map((filter) =>
                filter.filterBy && filter.filterValue && validateIfArray(filter)
                    ? {
                          [filter.filterBy]: filter.filterValue,
                      }
                    : {}
            )
            .reduce((prev, curr) => ({
                ...prev,
                ...curr,
            })) as unknown as FilterCriteria;

        onFiltersApply(criteria);
    };

    const resetFilters = (): void => {
        const newCriteria = initialQueryHistoryFilters();
        setFilters(parseFilters(newCriteria));
        onFiltersApply(newCriteria);
    };

    const addNewFilter = (): void => {
        const availableFilterBy = availableFilters();
        if (!!availableFilterBy) {
            setFilters((prevState) => [...prevState, newFilter(availableFilterBy[0])]);
        }
    };

    const canRemoveFilter = (): boolean => {
        return filters.length > 1;
    };

    const deleteFilter = (id: string) => (): void => {
        if (canRemoveFilter()) {
            setFilters((prevState) => prevState.filter((filter) => filter.id !== id));
        } else {
            setFilters([newFilter()]);
        }
    };

    const deleteFilterAndApplyChange = (filterBy: keyof FilterCriteria): void => {
        const localFilter = filters.find((filter) => filter.filterBy === filterBy);
        if (localFilter?.id) {
            deleteFilter(localFilter.id)();
        }

        onFiltersApply(appliedFilters ? { ...appliedFilters, [filterBy]: null } : null);
    };

    const deleteRowComponent = (id: string): ReactElement<HTMLElement> => (
        <div style={{ marginTop: '8px' }}>
            <IconButton
                onClick={deleteFilter(id)}
                disabled={!canRemoveFilter()}
                title="Delete filter"
                color="primary"
                style={{ padding: '8px' }}
                size="large">
                <RemoveCircleOutlineIcon />
            </IconButton>
        </div>
    );

    return (
        <Card className={classes.card}>
            <Box m={1} ml={2}>
                <Toggle
                    id="searchToggle"
                    label={(expanded): string => (expanded ? 'Hide filters' : 'Show filters')}
                    summary={(expanded): JSX.Element | null =>
                        expanded ? null : (
                            <FilterChips
                                appliedFilters={appliedFilters}
                                removeFilter={deleteFilterAndApplyChange}
                            />
                        )
                    }
                    className={classes.filtersRow}>
                    <form noValidate autoComplete="off" onSubmit={applyFilters}>
                        <Box mt={2}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    {filters.map((filter) => (
                                        <FiltersRow
                                            key={filter.id}
                                            model={filter}
                                            setModel={setFiltersElement(filter.id)}
                                            availableFilterBy={availableFilters(filter)}
                                            deleteRow={deleteRowComponent(filter.id)}
                                        />
                                    ))}
                                </Grid>

                                <Grid item xs={12}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={addNewFilter}
                                        disabled={availableFilters().length === 0}
                                        style={{ marginTop: '4px' }}>
                                        <FontAwesomeIcon
                                            icon={faPlus}
                                            style={{ marginRight: '8px' }}
                                        />
                                        And
                                    </Button>
                                </Grid>

                                <Grid item xs={12}>
                                    <Box display="flex" justifyContent={'center'} mb={1}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={!filters.every((f) => f.valid)}>
                                            Apply filters
                                        </Button>

                                        <Button
                                            style={{ marginLeft: '0.5rem' }}
                                            variant="outlined"
                                            color="primary"
                                            onClick={resetFilters}>
                                            Reset filters
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </form>
                </Toggle>
            </Box>
        </Card>
    );
};
