/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createUseStyles } from 'react-jss';
import { paletteSwitch } from '../../themes/palette';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import { Theme } from '@mui/material/styles';

const useStyles = createUseStyles((theme: Theme) => ({
    dialogHeader: {
        display: 'flex',
        padding: '0.5rem 1.5rem 0 1.5rem',
        alignItems: 'center',
    },
    dialogTitle: {
        maxWidth: '27.5rem',
        marginLeft: '0',
        paddingTop: '8px',
        color: paletteSwitch(theme).nebulaNavy,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    closeButton: {
        height: '2rem',
        width: '2rem',
        color: paletteSwitch(theme).nebulaNavy,
        marginTop: 'auto',
        marginBottom: 'auto',
        marginLeft: 'auto',
    },
}));

interface DialogHeaderProps {
    title: string;
    onClose: () => void;
    disableClose?: boolean;
}

export const DialogHeader: React.FunctionComponent<DialogHeaderProps> = ({
    title,
    disableClose,
    onClose,
}) => {
    const classes = useStyles();

    return (
        <Box className={classes.dialogHeader}>
            <Typography variant="h4" className={classes.dialogTitle}>
                {title}
            </Typography>
            <IconButton
                id="icon-close"
                className={classes.closeButton}
                onClick={onClose}
                disabled={disableClose}
                size="large">
                <Close />
            </IconButton>
        </Box>
    );
};
