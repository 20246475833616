/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { EmptyOrValue } from '../../../../utils/value';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import { addPrivilegesStyles } from '../add-privileges-styles';
import { CustomItemSelector } from './CustomItemSelector';
import { EntityCategory } from '../../../../api/biac/biacApi';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { maximumTitleLength } from '../../../dataproduct/util/formUtils';

interface PropertiesSelectorProps {
    entityCategory: EntityCategory;
    schemaName: EmptyOrValue<string | null>;
    tableName: EmptyOrValue<string | null>;
    disabled: boolean;
    propertyName: EmptyOrValue<string | null>;
    handlePropertyNameChange: React.Dispatch<React.SetStateAction<EmptyOrValue<string | null>>>;
    propertyValues: EmptyOrValue<string[] | null>;
    handlePropertyValuesChange: React.Dispatch<React.SetStateAction<EmptyOrValue<string[] | null>>>;
}

export const PropertiesSelector: React.FunctionComponent<PropertiesSelectorProps> = ({
    entityCategory,
    schemaName,
    tableName,
    propertyName,
    handlePropertyNameChange: handlePropertyNameChange,
    propertyValues,
    handlePropertyValuesChange: handleValuesChange,
    disabled,
}) => {
    const classes = addPrivilegesStyles();

    const [selectedPropertyName, setSelectedPropertyName] = useState<string>('');
    const [isValidPropertyName, setValidPropertyName] = useState<boolean>(false);

    const allPropertiesChecked = !propertyName.empty && propertyName.value === null;
    const allValuesChecked = !propertyValues.empty && propertyValues.value === null;

    useEffect(() => {
        handlePropertyNameChange({ empty: true, value: null });
        handleValuesChange({ empty: true, value: null });
        setSelectedPropertyName('');
    }, [entityCategory, schemaName, tableName, disabled]);

    const handlePropertyCheckStar = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            handlePropertyNameChange({ empty: false, value: null });
        } else {
            handlePropertyNameChange({ empty: true, value: null });
        }
        setSelectedPropertyName('');
    };

    const handleValueCheckStar = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            handleValuesChange({ empty: false, value: null });
        } else {
            handleValuesChange({ empty: true, value: null });
        }
    };

    const onPropertyNameChange = (event: React.SyntheticEvent, value: string | null) => {
        if (!value) {
            setSelectedPropertyName('');
            handlePropertyNameChange({ empty: true, value: null });
            setValidPropertyName(false);
        } else {
            setSelectedPropertyName(value);
            const isNameValid =
                value.length > 0 && value.length <= 128 && /^[a-z\d_]+$/.test(value);
            setValidPropertyName(isNameValid);
            handlePropertyNameChange({ empty: !isNameValid, value: value });
        }
    };

    return (
        <Grid pl={1} maxWidth="fit-content">
            <Typography variant="h5">Which property would you like to select?</Typography>
            <Typography className={classes.questionInfo}>
                Type the property name. To select all currently defined properties, as well as
                properties defined in the future, check the <b>All properties</b> box instead.
            </Typography>
            <Box mb={1.5}>
                <FormControlLabel
                    control={
                        <Checkbox
                            disabled={disabled}
                            checked={allPropertiesChecked}
                            onChange={handlePropertyCheckStar}
                            id="select-star"
                        />
                    }
                    label={
                        <span>
                            All properties{' '}
                            <span className={classes.cautionText}>(use with caution)</span>
                        </span>
                    }
                />
            </Box>
            <Box mb={2}>
                <Autocomplete
                    disabled={disabled}
                    fullWidth
                    value={selectedPropertyName}
                    onInputChange={onPropertyNameChange}
                    options={[]}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            margin="dense"
                            label="Property name"
                            inputProps={{
                                ...params.inputProps,
                                maxLength: maximumTitleLength,
                            }}
                            error={
                                !isValidPropertyName && selectedPropertyName
                                    ? selectedPropertyName.length > 0
                                    : false
                            }
                            helperText={
                                !isValidPropertyName && selectedPropertyName
                                    ? 'The property name must be up to 128 characters, may contain only lowercase latin characters (a-z), numbers (0-9) and underscores (_)'
                                    : undefined
                            }
                        />
                    )}
                    selectOnFocus
                    clearOnBlur
                    blurOnSelect
                    handleHomeEndKeys
                    freeSolo
                />
            </Box>
            <Typography variant="h5">Which property values would you like to select?</Typography>
            <Typography className={classes.questionInfo}>
                Type property values. To select all possible property values, as well as values
                defined in the future, check the <b>All values</b> box instead.
            </Typography>
            <Box mb={1.5}>
                <FormControlLabel
                    control={
                        <Checkbox
                            disabled={disabled || !propertyName.value || allPropertiesChecked}
                            checked={allValuesChecked}
                            onChange={handleValueCheckStar}
                            id="select-star"
                        />
                    }
                    label={
                        <span>
                            All values{' '}
                            <span className={classes.cautionText}>(use with caution)</span>
                        </span>
                    }
                />
            </Box>
            <Box mb={2}>
                <CustomItemSelector
                    disabled={disabled || !isValidPropertyName || allValuesChecked}
                    label="Values"
                    newTagOptionPostfix={' (new value)'}
                    value={propertyValues}
                    handleChange={handleValuesChange}
                    suggestions={[]}
                />
            </Box>
        </Grid>
    );
};
