/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React from 'react';
import clsx from 'clsx';
import truncate from 'lodash/truncate';
import { createUseStyles } from 'react-jss';
import { Theme } from '@mui/material/styles';
import { paletteSwitch } from '../../themes/palette';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface NoFilteredItemsBannerProps {
    entityName: string;
    searchPhrase: string;
}
const useStyles = createUseStyles((theme: Theme) => ({
    noItemsBanner: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: paletteSwitch(theme).nebulaNavy50,
        marginTop: '2.625rem',
        marginRight: '1.5rem',
        padding: '2rem',
    },
    noItemsBannerTitle: {
        textAlign: 'center',
        marginTop: theme.spacing(1),
        fontFamily: 'montserrat, sans-serif',
        fontSize: '1.25rem',
    },
    noFilteredItemsBanner: {
        marginTop: 0,
        marginRight: 0,
        wordBreak: 'break-word',
    },
}));
export const NoFilteredItemsBanner = ({ entityName, searchPhrase }: NoFilteredItemsBannerProps) => {
    const classes = useStyles();

    return (
        <Box className={clsx(classes.noItemsBanner, classes.noFilteredItemsBanner)}>
            <Typography className={classes.noItemsBannerTitle}>
                Oh snap, there are no {entityName} matching &quot;{truncate(searchPhrase)}
                &quot;!
            </Typography>
            <Typography className={classes.noItemsBannerTitle}>
                Try clearing the search field or use another search phrase.
            </Typography>
        </Box>
    );
};
