/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useMemo } from 'react';
import capitalize from 'lodash/capitalize';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useHistory } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserShield } from '@fortawesome/pro-solid-svg-icons/faUserShield';
import { CreateGrantError } from '../../../../api/biac/biacApi';
import { allowEffect } from '../../role-details/entry/PrivilegeEntry';
import { Tooltip } from '../../../../components/tooltip/Tooltip';

interface SavePrivilegesDialogProps {
    reset(): void;
    open: boolean;
    setOpen(newState: boolean): void;
    error: CreateGrantError | null;
    forceSave: () => Promise<void>;
}

export const SavePrivilegesDialog: React.FunctionComponent<SavePrivilegesDialogProps> = ({
    reset,
    open,
    setOpen,
    error,
    forceSave,
}) => {
    const history = useHistory();
    const goBack = useCallback(() => {
        history.push('/roles');
    }, [history]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleAddLater = () => {
        handleClose();
        reset();
        goBack();
    };

    const handleCloseAndReset = () => {
        reset();
        handleClose();
    };

    const grantCollisionErrors = useGrantCollisionErrors(error);

    return (
        <Dialog
            open={open}
            aria-labelledby="save-dialog-title"
            aria-describedby="save-dialog-description"
            PaperProps={{ sx: { width: '30%' } }}>
            <DialogTitle
                id="save-dialog-title"
                fontWeight={600}
                fontFamily={'montserrat, sans-serif'}>
                {error ? 'Could not add privilege :' : 'Add another privilege?'}
            </DialogTitle>
            <DialogContent>
                {error ? (
                    grantCollisionErrors.hasCollision ? (
                        <Alert variant="standard" severity="warning">
                            {grantCollisionErrors.collisionMessage}
                            {grantCollisionErrors.hasConflicts && (
                                <TableContainer>
                                    <Table size="small" stickyHeader>
                                        <TableBody>
                                            {grantCollisionErrors.conflictingGrants?.map(
                                                ({ oldGrant }, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>
                                                            {
                                                                oldGrant.entity.printableValue
                                                                    .entityText
                                                            }
                                                            {oldGrant.effect ===
                                                                'ALLOW_WITH_GRANT_OPTION' && (
                                                                <Tooltip
                                                                    title="Allow role receiving grant to grant to others"
                                                                    disableInteractive>
                                                                    <span>
                                                                        {' '}
                                                                        <FontAwesomeIcon
                                                                            size="sm"
                                                                            icon={faUserShield}
                                                                        />
                                                                    </span>
                                                                </Tooltip>
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            {allowEffect.includes(oldGrant.effect)
                                                                ? 'Allow'
                                                                : capitalize(oldGrant.effect)}
                                                            : {capitalize(oldGrant.action)}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                        </Alert>
                    ) : (
                        <Alert variant="standard" severity="warning">
                            {error.message}
                        </Alert>
                    )
                ) : (
                    <Alert severity="success">Privileges successfully created!</Alert>
                )}
            </DialogContent>
            <DialogActions sx={{ padding: '0 1.5rem 1rem' }}>
                {!grantCollisionErrors.hasCollision && (
                    <Button onClick={handleAddLater} variant="outlined">
                        {error ? 'Cancel' : 'No, add later'}
                    </Button>
                )}
                {error ? (
                    <Button
                        onClick={handleClose}
                        variant={grantCollisionErrors.hasCollision ? 'outlined' : 'contained'}>
                        Go back to editing
                    </Button>
                ) : (
                    <Button onClick={handleCloseAndReset} variant="contained">
                        Yes, add another privilege
                    </Button>
                )}
                {grantCollisionErrors.hasCollision &&
                    (grantCollisionErrors.allNewAreDuplicates ? (
                        <Button onClick={goBack} variant="contained">
                            Confirm
                        </Button>
                    ) : (
                        <Button onClick={forceSave} variant="contained">
                            Save privileges
                        </Button>
                    ))}
            </DialogActions>
        </Dialog>
    );
};

function extractGrantCollisionErrors(error: CreateGrantError | null) {
    if (error?.response?.status !== 409) {
        return {
            hasCollision: false,
            hasConflicts: false,
            hasDuplicates: false,
            allNewAreDuplicates: false,
            conflictingGrants: null,
            duplicateGrants: null,
            collisionMessage: null,
        };
    }
    const { conflictingGrants, duplicateGrants } = error.response.data.details;
    const grantsToSave = JSON.parse(error.config.data).grants;
    const hasConflicts = !!(conflictingGrants && conflictingGrants.length);
    const hasDuplicates = !!(duplicateGrants && duplicateGrants.length);
    const allNewAreDuplicates = grantsToSave.length === duplicateGrants.length;
    let collisionMessage = null;
    if (hasConflicts && hasDuplicates) {
        collisionMessage =
            'One or more of these privileges already exists. Saving these privileges will override existing:';
    } else if (hasConflicts) {
        collisionMessage = 'Saving these privileges will override existing:';
    } else if (hasDuplicates) {
        if (allNewAreDuplicates) {
            collisionMessage = 'These privileges already exist.';
        } else {
            collisionMessage =
                'One or more of these privileges already exists, saving will have no effect for those duplicates but new ones will be added.';
        }
    }
    return {
        hasCollision: hasConflicts || hasDuplicates,
        hasConflicts,
        hasDuplicates,
        allNewAreDuplicates,
        conflictingGrants,
        duplicateGrants,
        collisionMessage,
    };
}

const useGrantCollisionErrors = (error: CreateGrantError | null) =>
    useMemo(() => extractGrantCollisionErrors(error), [error]);
