/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import { Worksheet } from '../../../api/savedQueriesApi';

export const isWorksheetOwner = (worksheet: Worksheet) => {
    return worksheet.userAccess === 'OWNER';
};

export const isReadOnlyWorksheet = (worksheet: Worksheet) => {
    return worksheet.userAccess === 'CAN_VIEW';
};
