/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import { useCallback, useEffect, useMemo, useState } from 'react';

interface TableFilter<T> {
    filteredItems: T[] | undefined;
    filter: string;
    setFilter: (filter: string) => void;
}

export const useTableFilter = <T>(
    items: T[] | null | undefined,
    filterBy: (item: T) => string[]
): TableFilter<T> => {
    const [filter, setFilter] = useState<string>('');

    useEffect(() => {
        if (!items || !items.length) {
            setFilter('');
        }
    }, [items]);

    const matchFilter = useCallback(
        (values: string[]): boolean =>
            values.some((value) => value.toLowerCase().includes(filter.toLowerCase())),
        [filter]
    );

    const filteredItems = useMemo(
        () => items?.filter((item) => matchFilter(filterBy(item))),
        [items, matchFilter, filterBy]
    );

    return {
        filteredItems,
        filter,
        setFilter,
    };
};
