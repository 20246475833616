/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { FunctionComponent } from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { paletteSwitch } from '../../../../themes/palette';
import { Spinner } from '../../../../components/spinner/Spinner';
import { ErrorIndicator } from '../../../../components/error/ErrorIndicator';
import { NoData } from '../../../../components/error/NoData';
import { useMetricsFetchingState } from './useMetricsFetchingState';
import { Theme } from '@mui/material/styles';

const useStyles = createUseStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        backgroundColor: paletteSwitch(theme).nebulaNavy15,
        border: `1px solid ${paletteSwitch(theme).nebulaNavy100}`,
        borderRadius: '0.25rem',
    },
    errorIndicatorContainer: {
        flexGrow: 1,
        backgroundColor: paletteSwitch(theme).white,
    },
    errorIndicator: {
        margin: 0,
    },
}));

interface DataProductMetricsProps {
    dataProductId: string;
    className?: string;
}

export const DataProductMetrics: FunctionComponent<DataProductMetricsProps> = ({
    dataProductId,
    className,
}: DataProductMetricsProps) => {
    const styles = useStyles();
    const {
        isFetching,
        isError,
        errorMessage,
        data: metrics,
    } = useMetricsFetchingState(dataProductId);

    return (
        <div className={clsx(styles.root, className)}>
            {isFetching ? (
                <Spinner size={150} position="relative" />
            ) : isError ? (
                <div className={styles.errorIndicatorContainer}>
                    <ErrorIndicator text={errorMessage} className={styles.errorIndicator} />
                </div>
            ) : metrics ? (
                <>
                    <SingleMetric label="Number of queries" dividerMode="small" />
                    <SingleMetric
                        label="Past 7 days"
                        value={metrics.sevenDayQueryCount}
                        dividerMode="small"
                        labelMode="secondary"
                    />
                    <SingleMetric
                        label="Past 30 days"
                        value={metrics.thirtyDayQueryCount}
                        dividerMode="big"
                        labelMode="secondary"
                    />
                    <SingleMetric label="Number of users" dividerMode="small" />
                    <SingleMetric
                        label="Past 7 days"
                        value={metrics.sevenDayUserCount}
                        dividerMode="small"
                        labelMode="secondary"
                    />
                    <SingleMetric
                        label="Past 30 days"
                        value={metrics.thirtyDayUserCount}
                        dividerMode="none"
                        labelMode="secondary"
                    />
                </>
            ) : (
                <NoData height={250} text="No statistics to display" />
            )}
        </div>
    );
};

const useSingleMetricStyles = createUseStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
    },
    value: {
        fontWeight: 600,
        fontSize: '1.25rem',
        color: paletteSwitch(theme).nebulaNavy700,
    },
    primaryLabelStyle: {
        fontSize: '0.875rem',
        fontWeight: 600,
        color: paletteSwitch(theme).nebulaNavy400,
    },
    secondaryLabelStyle: {
        fontSize: '0.625rem',
        color: paletteSwitch(theme).nebulaNavy400,
    },
    dividerSmallStyle: {
        margin: '0.5625rem 0 0.6875rem 0.875rem',
        paddingRight: '0.875rem',
        borderRight: `1px solid ${paletteSwitch(theme).nebulaNavy100}`,
    },
    dividerBigStyle: {
        padding: '0.5625rem 0.875rem 0.6875rem 0.875rem',
        borderRight: `1px solid ${paletteSwitch(theme).nebulaNavy100}`,
    },
    dividerNoneStyle: {
        margin: '0.5625rem 0.875rem 0.6875rem 0.875rem',
    },
}));

interface SingleMetricProps {
    label: string;
    labelMode?: 'primary' | 'secondary';
    dividerMode: 'small' | 'big' | 'none';
    value?: string | number;
}

const SingleMetric = ({
    label,
    labelMode = 'primary',
    dividerMode = 'small',
    value = '',
}: SingleMetricProps) => {
    const styles = useSingleMetricStyles();
    const labelStyle =
        labelMode === 'primary' ? styles.primaryLabelStyle : styles.secondaryLabelStyle;
    const rootStyle =
        dividerMode === 'small'
            ? styles.dividerSmallStyle
            : dividerMode === 'big'
            ? styles.dividerBigStyle
            : styles.dividerNoneStyle;
    return (
        <div className={clsx(styles.root, rootStyle)}>
            <div className={labelStyle}>{label}</div>
            <div className={styles.value}>{value}</div>
        </div>
    );
};
