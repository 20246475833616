/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { Dataset, DatasetType } from '../../../api/dataProduct/dataProductApi';
import { TextFieldWithHint } from '../components/TextFieldWithHint';
import MenuItem from '@mui/material/MenuItem';
import { defaultRefreshIntervalMinutes } from './dataProductMapper';

interface DatasetTypeSectionProps {
    dataset: Dataset;
    isRefreshIntervalInvalid: boolean;
    isIncrementalColumnInvalid: boolean;
    onDatasetTypeChange: (newValue: DatasetType) => void;
    onRefreshIntervalChange: (newValue: string) => void;
    onIncrementalColumnChange: (newValue: string) => void;
}

const useStyles = createUseStyles({
    radio: {
        display: 'flex',
        flexDirection: 'row',
        gap: '0.75rem',
    },
    title: {
        fontWeight: 600,
        paddingTop: '0.4rem',
    },
    radioLabel: {
        fontSize: '0.8125rem',
    },
    refreshInterval: {
        marginRight: '1.30rem',
    },
});

export const DatasetTypeSection: React.FunctionComponent<DatasetTypeSectionProps> = ({
    dataset,
    isRefreshIntervalInvalid,
    isIncrementalColumnInvalid,
    onDatasetTypeChange,
    onRefreshIntervalChange,
    onIncrementalColumnChange,
}) => {
    const classes = useStyles();

    return (
        <div className={classes.radio}>
            <div className={classes.title}>Dataset Type:</div>
            <RadioGroup
                aria-label="Dataset type"
                // @TODO: we should not use any fallback value below,
                // but currently the typing and the values are two separate things
                // to refactor, please see comment in publishFormReducer.ts
                value={dataset.type || DatasetType.VIEW}
                onChange={(e) => onDatasetTypeChange(e.target.value as DatasetType)}>
                <FormControlLabel
                    value={DatasetType.VIEW}
                    control={<Radio size="small" />}
                    label="View"
                    classes={{
                        label: classes.radioLabel,
                    }}
                />
                <FormControlLabel
                    value={DatasetType.MATERIALIZED_VIEW}
                    control={<Radio size="small" />}
                    label="Materialized View"
                    classes={{
                        label: classes.radioLabel,
                    }}
                />
                {dataset.type === DatasetType.MATERIALIZED_VIEW && (
                    <>
                        <TextField
                            className={classes.refreshInterval}
                            required
                            value={dataset.refreshInterval || `${defaultRefreshIntervalMinutes}`}
                            label="Refresh interval (in minutes)"
                            onChange={(e) => onRefreshIntervalChange(e.target.value)}
                            error={isRefreshIntervalInvalid}
                            helperText={
                                isRefreshIntervalInvalid
                                    ? `Must be a number greater or equal to ${defaultRefreshIntervalMinutes}`
                                    : ''
                            }
                            variant="outlined"
                            margin="dense"
                        />
                        <TextFieldWithHint
                            select={true}
                            value={dataset.incrementalColumn ?? ''}
                            label="Incremental Column"
                            onValueChange={(newValue) => onIncrementalColumnChange(newValue)}
                            error={isIncrementalColumnInvalid}
                            tooltipText={
                                'Specifies the column to be used to identify new data since the last refresh.\n' +
                                'If you do not use this field, a full refresh is performed.'
                            }
                            helperText={
                                isIncrementalColumnInvalid
                                    ? 'Previously selected column is no longer available.'
                                    : 'Run query required to select incremental column'
                            }>
                            <MenuItem value={''}>{`<None>`}</MenuItem>
                            {dataset.columns &&
                                dataset.columns.map((option) => (
                                    <MenuItem key={option.name} value={option.name}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            {isIncrementalColumnInvalid && (
                                <MenuItem value={dataset.incrementalColumn}>{`<Invalid>`}</MenuItem>
                            )}
                        </TextFieldWithHint>
                    </>
                )}
            </RadioGroup>
        </div>
    );
};
