/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { CSSProperties } from 'react';
import { palette, paletteSwitch } from '../../../../../../themes/palette';
import { StageNodeInfo } from '../graphUtils';
import { formatRows, parseAndFormatDataSize } from '../utils';
import capitalize from 'lodash/capitalize';
import Typography from '@mui/material/Typography';
import { createUseStyles } from 'react-jss';
import { Theme } from '@mui/material/styles';

export interface StageStatisticsProps {
    stage: StageNodeInfo;
}

const useStyles = createUseStyles((theme: Theme) => ({
    stageHeader: {
        color: paletteSwitch(theme).nightBlue,
    },
    blockedTime: {
        color: paletteSwitch(theme).errorDark,
    },
}));

export const StageStatistics: React.FunctionComponent<StageStatisticsProps> = ({
    stage,
}: StageStatisticsProps) => {
    const stats = stage.stageStats;
    const classes = useStyles();
    return (
        <div style={rootStyles}>
            <Typography variant={'h5'} style={headerStyles} className={classes.stageHeader}>
                Stage {stage.id} ({capitalize(stage.state)})
            </Typography>
            <hr />
            CPU: {stats.totalCpuTime}
            <br />
            Buffered: {parseAndFormatDataSize(stats.bufferedDataSize)}
            <br />
            {stats.fullyBlocked ? (
                <div className={classes.blockedTime}>Blocked: {stats.totalBlockedTime} </div>
            ) : (
                <div>Blocked: {stats.totalBlockedTime} </div>
            )}
            Memory: {parseAndFormatDataSize(stats.userMemoryReservation)}
            <br />
            Splits:{' '}
            {'Q:' +
                stats.queuedDrivers +
                ', R:' +
                stats.runningDrivers +
                ', F:' +
                stats.completedDrivers}
            <hr />
            Input:{' '}
            {parseAndFormatDataSize(stats.rawInputDataSize) +
                ' / ' +
                formatRows(stats.rawInputPositions)}
        </div>
    );
};

const rootStyles: CSSProperties = {
    textAlign: 'center',
    padding: '1rem',
    color: palette.nightBlue,
};

const headerStyles: CSSProperties = {
    marginBottom: '8px',
};
