/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { ReactElement, useCallback } from 'react';
import { useDataProductDashboardRedirection } from '../routing/dataProductRoutingUtils';
import { Tooltip } from '../../../components/tooltip/Tooltip';
import { useDialogOpenState } from '../../../components/dialog/useDialogOpenState';
import { DeleteDialog } from '../../../components/dialog/DeleteDialog';
import { emitDataProductEvent } from '../../../api/dataProduct/dataProductEvent';
import { useDeleteManager } from '../workflow/deleteManager/useDeleteManager';

interface DeleteDataProductProps {
    dataProductId: string;
    dataProductName: string;
    canUserDelete: boolean | undefined;
    opener: (open: () => void) => ReactElement<HTMLElement>;
}

export const DeleteDataProduct: React.FunctionComponent<DeleteDataProductProps> = ({
    dataProductId,
    dataProductName,
    canUserDelete,
    opener,
}) => {
    const goToDashboard = useDataProductDashboardRedirection();
    const { isOpen, close, open } = useDialogOpenState();

    const deleteManager = useDeleteManager(dataProductId, [dataProductId]);

    const handleConfirm = useCallback(() => {
        return deleteManager.current
            ? deleteManager.current.start().then(() => {
                  emitDataProductEvent({
                      type: 'deleted',
                      payload: { dataProductId },
                  });
                  goToDashboard();
              })
            : Promise.reject({
                  message: 'Delete manager do not exist',
              });
    }, [deleteManager, dataProductId]);

    return (
        <Tooltip
            title={!canUserDelete ? 'You do not have permission to delete this data product' : ''}>
            <span>
                {opener(open)}
                {isOpen && (
                    <DeleteDialog
                        entityType="dataproduct"
                        entityHumanName={dataProductName}
                        onConfirm={handleConfirm}
                        isOpen={isOpen}
                        close={close}
                    />
                )}
            </span>
        </Tooltip>
    );
};
