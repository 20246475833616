/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { TopEntitiesChart } from '../../../../components/chart/TopEntitiesChart';
import React, { ReactElement } from 'react';
import { ChartWrapper } from '../../../../components/chart/ChartWrapper';
import { HorizontalBarChart } from '../../../../components/chart/HorizontalBarChart';
import { formatBytes } from '../../../../utils/formatBytes';

interface IOBaseChartProps<T extends Record<string, unknown>> {
    data: T[] | 'loading' | 'error' | undefined;
    labelKey: keyof T;
    valueKey: keyof T;
    tooltipText: string;
    labelFormatter?: (inputLabel: string) => string;
}

export const IOBaseChart = <T extends Record<string, unknown>>({
    data,
    labelKey,
    valueKey,
    tooltipText,
    labelFormatter,
}: IOBaseChartProps<T>): JSX.Element => {
    const slicesLimit = 5;
    const height = 200;

    return (
        <TopEntitiesChart<T>
            data={data}
            labelKey={labelKey}
            valueKey={valueKey}
            limit={slicesLimit}>
            {(topResults): ReactElement => (
                <ChartWrapper data={topResults} height={height} containerless={true}>
                    {(chartData) => (
                        <HorizontalBarChart
                            data={chartData}
                            tooltipText={tooltipText}
                            height={height}
                            tooltipValueFormatter={formatBytes}
                            xLabelFormatter={formatBytes}
                            yLabelFormatter={labelFormatter}
                        />
                    )}
                </ChartWrapper>
            )}
        </TopEntitiesChart>
    );
};
