/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useContext } from 'react';
import Link from '@mui/material/Link';
import { createUseStyles } from 'react-jss';
import { paletteSwitch } from '../../themes/palette';
import { Biac } from './Biac';
import { ConfigContext } from '../../app/ConfigContextProvider';
import { Spinner } from '../spinner/Spinner';
import { Theme } from '@mui/material/styles';

const useStyles = createUseStyles((theme: Theme) => ({
    link: {
        color: paletteSwitch(theme).link,
        display: 'block',
    },
}));

interface PersistenceEnabledBiacProps {
    children: React.ReactElement;
}

export const PersistenceEnabledBiac: React.FunctionComponent<PersistenceEnabledBiacProps> = ({
    children,
}) => {
    const classes = useStyles();
    const clusterInfo = useContext(ConfigContext);

    if (clusterInfo === undefined) {
        return <Spinner position="relative" delay={500} />;
    }
    const docsUrl = clusterInfo.docsUrl + '/admin/query-logger.html';

    return (
        <Biac
            title={'This view requires the query logger to be enabled'}
            description={
                <p>
                    Follow the instructions to configure it:
                    <Link className={classes.link} rel="noreferrer" target="_blank" href={docsUrl}>
                        {docsUrl}
                    </Link>
                </p>
            }
            enabled={() => clusterInfo.persistenceEnabled}>
            {children}
        </Biac>
    );
};
