/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { ReactElement } from 'react';
import { CpuTimeByUser } from '../../../api/queryReportApi';
import { TopEntitiesChart } from '../../../components/chart/TopEntitiesChart';
import { ChartWrapper } from '../../../components/chart/ChartWrapper';
import { BarChart } from '../../../components/chart/BarChart';
import { formatDuration } from '../../../utils/formatDuration';

interface CpuTimeByUserOverviewChartProps {
    cpuTimeByUser: CpuTimeByUser | 'loading' | 'error';
    height: number;
}

export const CpuTimeByUserOverviewChart: React.FunctionComponent<
    CpuTimeByUserOverviewChartProps
> = ({ cpuTimeByUser, height }) => {
    const slicesLimit = 5;

    return (
        <TopEntitiesChart<CpuTimeByUser[number]>
            data={cpuTimeByUser}
            labelKey="user"
            valueKey="cpuTime"
            limit={slicesLimit}
            otherLabel="other">
            {(topUsersData): ReactElement => (
                <ChartWrapper
                    data={topUsersData}
                    title={null}
                    height={height}
                    legend={{
                        type: 'data',
                        variant: 'right',
                        bulletSize: '1rem',
                        valueFormatter: () => null,
                    }}
                    containerless={true}>
                    {(chartData) => (
                        <BarChart
                            data={chartData}
                            tooltipText="CPU time"
                            height={height}
                            yLabelFormatter={'duration'}
                            tooltipValueFormatter={formatDuration}
                        />
                    )}
                </ChartWrapper>
            )}
        </TopEntitiesChart>
    );
};
