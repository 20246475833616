/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import { refreshDataProductView } from '../../../../api/dataProduct/dataProductApi';
import { BaseManager } from '../BaseManager';

export class RefreshManager extends BaseManager {
    constructor(dataProductId: string, viewName: string) {
        super('refresh', () => refreshDataProductView(dataProductId, viewName));
    }
}
