/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
// todo: unit tests
const getLocaleDateString = (): string => {
    const testDate = new Date(2020, 0, 9);
    const localizedTestDate = testDate.toLocaleDateString();
    const pattern = localizedTestDate
        .replace(`2020`, 'yyyy')
        .replace('20', 'yy')
        .replace('09', 'dd')
        .replace('9', 'dd')
        .replace('01', 'MM')
        .replace('1', 'MM');

    return pattern.includes('y') && pattern.includes('M') && pattern.includes('d')
        ? pattern
        : 'dd/MM/yyyy';
};

export const localeDateFormat = getLocaleDateString();

const isLocaleTime12h = (): boolean => {
    const testDate = new Date(2020, 0, 9, 18);
    const formattedTime = testDate.toLocaleTimeString();

    return formattedTime.toLowerCase().includes('pm');
};

export const clockSystem: 12 | 24 = isLocaleTime12h() ? 12 : 24;
export const localeTimeFormat: string = clockSystem === 12 ? 'hh:mm a' : 'HH:mm';
