/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';
import { TopTablesChart } from './TopTablesChart';
import { QueriesCountBaseChart } from './usage/QueriesCountBaseChart';
import { ReportContainer } from '../../../components/chart/ReportContainer';
import { createUseStyles } from 'react-jss';
import { LazyReport } from './LazyReport';
import { getQueriesByTable } from '../../../api/queryReportApi';

const useStyles = createUseStyles({
    box: {
        paddingTop: '8px',
    },
});

export const QueriesByTableChart: React.FunctionComponent = () => {
    const classes = useStyles();

    return (
        <LazyReport name={'queriesByTable'} load={getQueriesByTable}>
            {(queriesByTable) => (
                <TopTablesChart data={queriesByTable}>
                    {(data, labelFormatter) => (
                        <ReportContainer title={'Top tables by query'}>
                            <QueriesCountBaseChart
                                queriesCount={data}
                                labelKey={'name'}
                                labelFormatter={labelFormatter}
                                className={classes.box}
                            />
                        </ReportContainer>
                    )}
                </TopTablesChart>
            )}
        </LazyReport>
    );
};
