/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import axios from 'axios';
import { baseUrl } from './api';
import { useFetchingState } from '../features/dataproduct/domain/useFetchingState';
import { useCallback, useEffect } from 'react';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';

export interface Worksheet {
    id: string;
    name: string;
    queryText: string;
    catalog?: string;
    schema?: string;
    createTime: string;
    lastUpdateTime: string;
    owner: string;
    userAccess: AccessMode;
    permissions: WorksheetPermissionRow[];
}

export type AccessMode = 'OWNER' | 'CAN_VIEW' | 'CAN_EDIT' | 'NONE';

export interface WorksheetPermissionRow {
    subjectType: 'ROLE' | 'USER';
    subjectName: string;
    access: AccessMode;
}

export interface UpdatedWorksheet {
    id: string;
    name: string;
    queryText: string;
}

export interface CreateWorksheet {
    name: string;
    tabNumber: number;
}

const savedQueriesBaseUrl = `${baseUrl}/worksheets`;

export const createWorksheet = (
    request: CreateWorksheet,
    controller: AbortController
): Promise<Worksheet> =>
    axios
        .post(savedQueriesBaseUrl, request, {
            signal: controller.signal,
        })
        .then(({ data }) => data);

export const openWorksheet = (worksheetId: string): Promise<void> =>
    axios.post(`${savedQueriesBaseUrl}/${worksheetId}/open`);

export const changeWorksheetSharing = (
    worksheetId: string,
    changes: WorksheetPermissionRow[]
): Promise<void> =>
    axios.post(`${savedQueriesBaseUrl}/${worksheetId}/share`, {
        permissionsToChange: changes,
    });

export const updateWorksheet = (updatedWorksheet: UpdatedWorksheet) =>
    axios.put(`${savedQueriesBaseUrl}/${updatedWorksheet.id}`, updatedWorksheet);

export const deleteWorksheet = (worksheetId: string): Promise<void> =>
    axios.delete(`${savedQueriesBaseUrl}/${worksheetId}`);

const getWorksheets = (): Observable<Worksheet[]> => {
    return fromPromise(axios.get(savedQueriesBaseUrl).then(({ data }) => data));
};

export const useSavedWorksheets = (roleHeader: string | null) => {
    const state = useFetchingState<Worksheet[]>();
    const reload = useCallback(() => {
        state.setFetching();
        return getWorksheets().subscribe(state.setData, ({ message }) => state.setError(message));
    }, [roleHeader]);
    useEffect(() => {
        const subscription = reload();
        return () => subscription.unsubscribe();
    }, [reload]);
    return { ...state, reload };
};
