/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone } from '@fortawesome/pro-regular-svg-icons';
import { paletteSwitch } from '../../../../themes/palette';
import { Clipboard } from '../../../../components/clipboard/Clipboard';
import { FullScreenDialog } from '../../../../components/dialog/FullScreenDialog';
import { HintTooltip } from '../../../../components/tooltip/HintTooltip';
import { NoData } from '../../../../components/error/NoData';
import Fullscreen from '@mui/icons-material/Fullscreen';
import { Theme } from '@mui/material/styles';

const useStyles = createUseStyles((theme: Theme) => ({
    header: {
        fontFamily: 'montserrat, sans-serif',
        fontSize: '1.125rem',
        lineHeight: '1.6875rem',
        color: paletteSwitch(theme).nebulaNavy,
        fontWeight: 600,
        letterSpacing: '0.15px',
    },
    headerRow: {
        marginBottom: '1rem',
        display: 'flex',
        justifyContent: 'space-between',
    },
    pre: {
        padding: '1rem',
        border: `1px solid ${paletteSwitch(theme).sqlHighlightBorder}`,
        backgroundColor: paletteSwitch(theme).sqlHighlightBackground,
        borderRadius: '0.25rem',
        overflowX: 'hidden',
        overflowY: 'hidden',
        cursor: 'pointer',
        '&:hover': {
            borderColor: paletteSwitch(theme).nebulaNavy100,
            transition: 'border-color 0.2s linear',
        },
    },
    content: {
        whiteSpace: 'pre',
        fontFamily: 'Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace',
        fontSize: '0.75rem',
        lineHeight: '1.125rem',
        letterSpacing: '0.4px',
    },
    buttons: {
        marginTop: '-0.25rem',
        marginRight: '-0.25rem',
    },
    icon: {
        fontSize: '1.25rem',
    },
    seeMoreDescription: {
        fontSize: '0.75rem',
        marginTop: '1.5rem',
        color: paletteSwitch(theme).black54,
        fontStyle: 'italic',
    },
    hintIcon: {
        fontSize: '1.1875rem',
    },
    hint: {
        height: '1.5rem',
        width: '1.5rem',
        marginLeft: '5px',
        marginTop: '-2px',
    },
}));

interface QueryAnalysisCardProps {
    queryStats: Readonly<string | undefined>;
}

export const QueryAnalysisCard: React.FunctionComponent<QueryAnalysisCardProps> = ({
    queryStats,
}) => {
    const classes = useStyles();

    const fullQueryPlan = (
        button: (open: () => void) => ReactElement<HTMLElement>
    ): ReactElement<HTMLElement> | null => {
        if (!queryStats) {
            return null;
        }

        return (
            <FullScreenDialog header="Query plan" textToCopy={queryStats} button={button}>
                <Box p={3} className={classes.content}>
                    {queryStats}
                </Box>
            </FullScreenDialog>
        );
    };

    return (
        <Box style={{ padding: '8px 8px 16px 0px' }}>
            <div className={classes.headerRow}>
                <div className={classes.header}>
                    <span>Query plan</span>
                    <HintTooltip title="Full explain plan of the submitted query, including costs for each stage. Identical to the output from EXPLAIN ANALYZE <query>" />
                </div>
                {queryStats && (
                    <div className={classes.buttons}>
                        <Clipboard text={queryStats}>
                            <IconButton
                                disableRipple={true}
                                color="secondary"
                                size={'small'}
                                style={{ padding: '8px', marginRight: '8px' }}>
                                <FontAwesomeIcon icon={faClone} />
                            </IconButton>
                        </Clipboard>
                        {fullQueryPlan((open) => (
                            <Tooltip title="See full plan" arrow>
                                <IconButton
                                    color="primary"
                                    style={{ padding: '8px' }}
                                    onClick={open}
                                    size="large">
                                    <Fullscreen />
                                </IconButton>
                            </Tooltip>
                        ))}
                    </div>
                )}
            </div>
            {queryStats ? (
                fullQueryPlan((open) => (
                    <div className={classes.pre} onClick={open}>
                        <div className={classes.content}>
                            {queryStats
                                .split('\n')
                                .slice(0, 9)
                                .map((line, i) => (
                                    <div key={i}>{line}</div>
                                ))}
                        </div>

                        <div className={classes.seeMoreDescription}>Click here to see more</div>
                    </div>
                ))
            ) : (
                <NoData height={130} icon={'ban'} text={'Not available'} />
            )}
        </Box>
    );
};
