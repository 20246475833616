/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { formatDistance } from 'date-fns';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/pro-regular-svg-icons';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { v4 as uuidv4 } from 'uuid';
import { paletteSwitch } from '../../themes/palette';
import { Theme } from '@mui/material/styles';

const useStyles = createUseStyles((theme: Theme) => ({
    lastUpdated: {
        fontSize: '0.75rem',
        color: paletteSwitch(theme).black54,
        margin: '0 1rem 0.25rem 0',
    },
    highlighted: {
        color: paletteSwitch(theme).error,
    },
}));

interface ClusterHistoryRefreshProps {
    refreshReports: () => void;
    lastUpdateDate: Date | null;
}

export const ClusterHistoryRefresh: React.FunctionComponent<ClusterHistoryRefreshProps> = ({
    refreshReports,
    lastUpdateDate,
}) => {
    const classes = useStyles();
    const [, setRefresher] = useState<string>(uuidv4());

    useEffect(() => {
        const intervalId = setInterval(function () {
            setRefresher(uuidv4());
        }, 5000);
        return (): void => clearInterval(intervalId);
    }, []);

    return (
        <Box display="inline-flex" alignItems="center">
            <div className={classes.lastUpdated}>
                {lastUpdateDate ? (
                    `Last updated: ${formatDistance(lastUpdateDate, new Date(), {
                        addSuffix: true,
                    })}`
                ) : (
                    <>&nbsp;</>
                )}
            </div>
            <Button variant="contained" color="primary" onClick={refreshReports}>
                <FontAwesomeIcon icon={faSync} style={{ marginRight: '8px' }} />
                Refresh results
            </Button>
        </Box>
    );
};
