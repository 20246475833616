/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Theme } from '@mui/material/styles';
import MoreVert from '@mui/icons-material/MoreVert';
import { createUseStyles } from 'react-jss';
import SvgIconComponent from '@mui/material/SvgIcon';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import { Tooltip } from '../tooltip/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { TableRowData } from './useTableSorting';
import clsx from 'clsx';

interface ActionMenuContextProps {
    open: (row: unknown, anchor: HTMLElement) => void;
}

const useActionMenuStyles = createUseStyles({
    tooltipPopper: {
        top: '-0.5rem',
        bottom: '-0.5rem',
    },
    item: {
        paddingTop: '0.625rem',
        paddingBottom: '0.625rem',
    },
    icon: {
        minWidth: '2.625rem !important',
    },
});

export interface ActionMenuItem<T> {
    dataId?: string;
    text: string | ((row: T) => string);
    Icon?: typeof SvgIconComponent;
    onClick: (row: T) => void;
    disabled?: (row: T) => boolean;
    hidden?: (row: T) => boolean;
    styles?: string;
    tooltipText?: (row: T) => string;
}

const ActionMenuContext = React.createContext<ActionMenuContextProps>({} as ActionMenuContextProps);

export const TableActionMenu = <T extends TableRowData>({
    items,
    children,
    rows,
    rowIdProperty,
}: {
    items: ActionMenuItem<{ row: T }>[] | undefined;
    children: React.ReactElement;
    rows?: T[];
    rowIdProperty?: keyof T;
}) => {
    const [anchor, setAnchor] = useState<HTMLElement | undefined>();
    const [selectedRow, setSelectedRow] = useState<{ row: T }>();

    const close = useCallback(() => {
        setSelectedRow(undefined);
        setAnchor(undefined);
    }, []);

    const contextValue = useMemo(
        () => ({
            open: (row: unknown, anchor: HTMLElement) => {
                setSelectedRow(row as { row: T });
                setAnchor(anchor);
            },
        }),
        []
    );

    // update opened menu selectedRow in case of status change
    useEffect(() => {
        if (selectedRow && rowIdProperty) {
            const {
                row: { [rowIdProperty]: selectedRowId },
            } = selectedRow;
            const newSelectedRow = rows?.find((row) => row[rowIdProperty] === selectedRowId);
            if (newSelectedRow && newSelectedRow !== selectedRow.row) {
                setSelectedRow({ row: newSelectedRow });
            } else if (!newSelectedRow) {
                close();
            }
        }
    }, [selectedRow, rowIdProperty, rows, close]);

    const actionMenuClasses = useActionMenuStyles();
    return (
        <ActionMenuContext.Provider value={contextValue}>
            <Menu
                data-id="action-menu"
                keepMounted
                open={Boolean(anchor)}
                anchorEl={anchor}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                onClose={close}>
                {selectedRow &&
                    items
                        ?.filter(({ hidden }) => !hidden || !hidden(selectedRow))
                        .map((item, idx) => (
                            <Tooltip
                                key={idx}
                                title={item.tooltipText ? item.tooltipText(selectedRow) : ''}>
                                <div>
                                    <MenuItem
                                        data-id={item.dataId}
                                        key={idx}
                                        disabled={Boolean(
                                            item.disabled && item.disabled(selectedRow)
                                        )}
                                        className={clsx(actionMenuClasses.item, item.styles)}
                                        onClick={() => {
                                            item.onClick(selectedRow);
                                            close();
                                        }}>
                                        {item.Icon && (
                                            <ListItemIcon className={actionMenuClasses.icon}>
                                                <item.Icon className={item.styles} />
                                            </ListItemIcon>
                                        )}
                                        <div className={item.styles}>
                                            {item.text instanceof Function
                                                ? item.text(selectedRow)
                                                : item.text}
                                        </div>
                                    </MenuItem>
                                </div>
                            </Tooltip>
                        ))}
            </Menu>

            {children}
        </ActionMenuContext.Provider>
    );
};

const useButtonStyles = createUseStyles((theme: Theme) => ({
    iconButton: {
        fill: theme.palette.text.primary,
    },
}));

export const TableActionMenuButton = (props: { row: unknown }) => {
    const openMenu = useContext(ActionMenuContext).open;
    const classes = useButtonStyles();

    return (
        <IconButton
            data-id="action-menu-button"
            size="small"
            onClick={(ev) => {
                openMenu(props.row, ev.currentTarget);
            }}>
            <MoreVert className={classes.iconButton} />
        </IconButton>
    );
};
