/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { useDialogOpenState } from '../../../components/dialog/useDialogOpenState';
import IconButton from '@mui/material/IconButton';
import ContentCopyOutlined from '@mui/icons-material/ContentCopyOutlined';
import { SimpleDialog } from '../../../components/dialog/SimpleDialog';
import { cloneDataset, DatasetType, View } from '../../../api/dataProduct/dataProductApi';
import { Tooltip } from '../../../components/tooltip/Tooltip';
import { createUseStyles } from 'react-jss';
import { DatasetNameInput } from '../publish/DatasetNameInput';

interface CloneDataSetProps {
    dataProductId: string;
    namesAlreadyUsed: string[];
    schemaName: string;
    catalogName: string;
    view: View;
    type: DatasetType;
    disabled?: boolean;
    tooltipTitle?: ReactNode;
    handleClone?: (
        cloneName: string,
        view: View,
        type: DatasetType,
        dataProductId: string
    ) => Promise<void>;
}

function defaultCloneHandler(
    cloneName: string,
    view: View,
    type: DatasetType,
    dataProductId: string
): Promise<void> {
    return cloneDataset(
        dataProductId,
        {
            ...view,
            name: cloneName,
        },
        type
    ).then(() => undefined);
}

export const CloneDatasetModal = ({
    view,
    type,
    dataProductId,
    disabled,
    namesAlreadyUsed,
    schemaName,
    catalogName,
    handleClone = defaultCloneHandler,
    tooltipTitle,
}: CloneDataSetProps) => {
    const { isOpen, close, open } = useDialogOpenState();
    return (
        <>
            <Tooltip title={tooltipTitle} delayToolip={500} disableInteractive>
                <span>
                    <IconButton onClick={open} disabled={disabled}>
                        <ContentCopyOutlined color="inherit" />
                    </IconButton>
                </span>
            </Tooltip>
            {isOpen && (
                <CloneDialog
                    dataProductId={dataProductId}
                    namesAlreadyUsed={namesAlreadyUsed}
                    schemaName={schemaName}
                    catalogName={catalogName}
                    onConfirm={handleClone}
                    view={view}
                    type={type}
                    isOpen={isOpen}
                    close={close}
                />
            )}
        </>
    );
};

interface CloneDialogProps {
    isOpen: boolean;
    close: () => void;
    view: View;
    dataProductId: string;
    type: DatasetType;
    namesAlreadyUsed: string[];
    schemaName: string;
    catalogName: string;
    onConfirm: (
        cloneName: string,
        view: View,
        type: DatasetType,
        dataProductId: string
    ) => Promise<void>;
}

const CloneDialog = ({
    isOpen,
    close,
    dataProductId,
    namesAlreadyUsed,
    schemaName,
    catalogName,
    onConfirm,
    view,
    type,
}: CloneDialogProps) => {
    const [cloneName, setCloneName] = useState(`${view.name}_copy`);
    const handleConfirm = useCallback(
        () => onConfirm(cloneName, view, type, dataProductId),
        [onConfirm, dataProductId, view, type, cloneName]
    );
    const isConfirmationButtonDisabled = useMemo<boolean>(
        () => !cloneName.length || namesAlreadyUsed.includes(cloneName),
        [namesAlreadyUsed, cloneName]
    );
    return (
        <SimpleDialog<CloneDialogContentProps>
            isOpen={isOpen}
            close={close}
            title="Clone"
            confirmButtonLabel="Create clone"
            onConfirm={handleConfirm}
            isConfirmationButtonDisabled={isConfirmationButtonDisabled}
            fullWidth={true}
            contentProps={{
                cloneName,
                namesAlreadyUsed,
                schemaName,
                catalogName,
                onCloneNameChange: setCloneName,
            }}
            Content={CloneDialogContent}
        />
    );
};

const useCloneDialogContentClasses = createUseStyles({
    hint: {
        marginBottom: '1rem',
    },
});

interface CloneDialogContentProps {
    cloneName: string;
    namesAlreadyUsed: string[];
    schemaName: string;
    catalogName: string;
    onCloneNameChange: (newName: string) => void;
    error?: ReactNode;
}

const CloneDialogContent = ({
    cloneName,
    namesAlreadyUsed,
    schemaName,
    catalogName,
    onCloneNameChange,
    error,
}: CloneDialogContentProps) => {
    const internalClasses = useCloneDialogContentClasses();
    return (
        <>
            <div className={internalClasses.hint}>Type the name of the new dataset.</div>
            {error}
            <DatasetNameInput
                value={cloneName}
                onChange={onCloneNameChange}
                enableToolTip={false}
                fullWidth={true}
                namesAlreadyUsed={namesAlreadyUsed}
                schemaName={schemaName}
                catalogName={catalogName}
            />
        </>
    );
};
