/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';
import { createUseStyles } from 'react-jss';
import { StageDetail } from '../../../../api/queryApi';
import capitalize from 'lodash/capitalize';
import { formatBuffer, formatDisplayTime, formatTimeDuration, getColorByState } from './stagesUtil';
import { DetailsItem } from '../../../../components/details/DetailsItem';
import { formatBytes } from '../../../../utils/formatBytes';

const useStyles = createUseStyles({
    root: { display: 'flex' },
    stage: {
        fontWeight: 600,
        width: '40px',
        fontSize: '2rem',
        marginRight: '2rem',
        lineHeight: '48px',
    },
    text: {
        display: 'flex',
        flexWrap: 'wrap',
        verticalAlign: 'middle',
    },
    item: {
        width: '120px',
        marginTop: '0.25rem',
    },
    itemValue: {
        fontSize: '18px',
    },
    stateItem: {
        width: '160px',
        marginTop: '0.25rem',
    },
    activeItem: {
        width: '90px',
        marginTop: '0.25rem',
    },
});

interface QueryStagesProps {
    stage: StageDetail;
    maxCpuTime: number;
}

export const QueryStageAccordionHeader: React.FunctionComponent<QueryStagesProps> = ({
    stage,
    maxCpuTime,
}) => {
    const classes = useStyles();
    const activePercentage = () => {
        if (!stage.scheduledTime) {
            return '-';
        } else {
            return `${Math.round((100 * stage.cpuTime) / stage.scheduledTime)}%`;
        }
    };
    const itemClasses = { item: classes.item, value: classes.itemValue };
    const stateItemClasses = { ...itemClasses, item: classes.stateItem };
    const activeItemClasses = { ...itemClasses, item: classes.activeItem };
    return (
        <div className={classes.root}>
            <div
                className={classes.stage}
                style={{
                    color: getColorByState(stage.state, stage.fullyBlocked),
                }}>
                {stage.id}
            </div>
            <div className={classes.text}>
                <DetailsItem
                    header="STATE"
                    value={capitalize(stage.state)}
                    classes={stateItemClasses}
                />
                <DetailsItem
                    header="CPU TIME"
                    value={formatDisplayTime(stage.cpuTime, maxCpuTime)}
                    valueTooltip={formatTimeDuration(stage.cpuTime)}
                    classes={itemClasses}
                />
                <DetailsItem
                    header="ACTIVE"
                    value={activePercentage()}
                    classes={activeItemClasses}
                />
                <DetailsItem
                    header="INPUT BUFFER"
                    value={formatBuffer(stage.inputBuffer)}
                    classes={itemClasses}
                    valueTooltip={formatBytes(stage.inputBuffer)}
                />
                <DetailsItem
                    header="TASKS"
                    value={stage.tasks ? stage.tasks.length : 0}
                    classes={itemClasses}
                />
            </div>
        </div>
    );
};
