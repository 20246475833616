/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { QueriesTable } from './QueriesTable';
import { handleSortingChange } from './queryHistorySlice';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../app/store';
import { Reports } from '../report/Reports';
import { QueryHistoryMode } from './QueryHistory';
import { QueriesRefresh } from './QueriesRefresh';
import Grid from '@mui/material/Grid';
import { DefaultTab } from '../../../components/tab/DefaultTab';
import Tabs from '@mui/material/Tabs';
import { useTabStyles } from '../../../themes/useTabStyles';

interface QueriesContainerProps {
    mode: QueryHistoryMode;
    toggleMode: (newMode: QueryHistoryMode) => void;
    showReports: boolean;
}

export const QueriesContainer: React.FunctionComponent<QueriesContainerProps> = ({
    mode,
    toggleMode,
    showReports,
}) => {
    const tabClasses = useTabStyles();
    const dispatch: AppDispatch = useDispatch();
    const sorting = useSelector(({ queryHistory }: RootState) => queryHistory.sorting);
    return (
        <Grid mt={1}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                mb={4}>
                <Grid item>
                    <Tabs
                        onChange={(e, value) => toggleMode(value)}
                        value={mode}
                        className={tabClasses.tabs}
                        indicatorColor="primary"
                        textColor="primary">
                        <DefaultTab value="table" label="Queries" />
                        <DefaultTab value="chart" label="Reports" />
                    </Tabs>
                </Grid>

                <Grid item>
                    <QueriesRefresh mode={mode} />
                </Grid>
            </Grid>

            {mode === 'table' ? (
                <QueriesTable
                    sorting={sorting}
                    setSorting={(sorting): unknown => dispatch(handleSortingChange(sorting))}
                />
            ) : null}

            {mode === 'chart' ? <Reports showReports={showReports} /> : null}
        </Grid>
    );
};
