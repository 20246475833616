/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { MouseEvent, useCallback, useState } from 'react';
import clsx from 'clsx';
import { createUseStyles } from 'react-jss';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ContentCopyOutlined from '@mui/icons-material/ContentCopyOutlined';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { Tooltip } from '../../../../components/tooltip/Tooltip';
import { paletteSwitch } from '../../../../themes/palette';
import { useDataProductPermissionContext } from '../../permission/DataProductPermissionContext';
import { useDataProductUpdateRedirection } from '../../routing/dataProductRoutingUtils';
import { ActionMenuDialogs } from './ActionMenuDialogs';
import { Theme } from '@mui/material/styles';

const useClasses = createUseStyles((theme: Theme) => ({
    menu: {
        minWidth: '10rem',
    },
    textIcon: {
        display: 'flex',
        fontSize: '14px',
    },
    textLabel: {
        marginLeft: '0.5rem',
    },
    disabledTextIcon: {
        color: paletteSwitch(theme).black54,
    },
    deleteTextIcon: {
        color: paletteSwitch(theme).error,
    },
}));

const useMenuClasses = createUseStyles({
    paper: {
        minWidth: '9.75rem',
    },
});

interface DataProductActionMenuProps {
    dataProductId: string;
    dataProductName: string;
    needMaterialization: boolean;
}

export const DataProductActionMenu = ({
    dataProductId,
    dataProductName,
    needMaterialization,
}: DataProductActionMenuProps) => {
    const internalClasses = useClasses();
    const menuClasses = useMenuClasses();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const userPermissions = useDataProductPermissionContext();
    const goToUpdate = useDataProductUpdateRedirection(dataProductId);

    const openActionMenu = useCallback(({ currentTarget }: MouseEvent<HTMLElement>) => {
        setAnchorEl(currentTarget);
    }, []);
    const closeActionMenu = useCallback(() => {
        setAnchorEl(null);
    }, []);

    return (
        <ActionMenuDialogs
            dataProductId={dataProductId}
            dataProductName={dataProductName}
            needMaterialization={needMaterialization}
            openers={(openCloneDialog, openDeleteDialog) => (
                <>
                    <IconButton size="small" onClick={openActionMenu}>
                        <FontAwesomeIcon icon={faEllipsisV} />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={!!anchorEl}
                        onClose={closeActionMenu}
                        onClick={closeActionMenu}
                        classes={menuClasses}>
                        <MenuItem
                            onClick={
                                userPermissions.canUpdate ? goToUpdate : stopPropagationEventHandler
                            }>
                            <Tooltip
                                title={
                                    !userPermissions.canUpdate
                                        ? 'You do not have permission to edit this data product'
                                        : ''
                                }>
                                <div
                                    className={clsx(internalClasses.textIcon, {
                                        [internalClasses.disabledTextIcon]:
                                            !userPermissions.canUpdate,
                                    })}>
                                    <EditOutlined />
                                    <span className={internalClasses.textLabel}>Edit</span>
                                </div>
                            </Tooltip>
                        </MenuItem>
                        <MenuItem onClick={openCloneDialog} className={internalClasses.textIcon}>
                            <div className={internalClasses.textIcon}>
                                <ContentCopyOutlined />
                                <span className={internalClasses.textLabel}>Clone</span>
                            </div>
                        </MenuItem>
                        <MenuItem
                            onClick={
                                userPermissions.canDelete
                                    ? openDeleteDialog
                                    : stopPropagationEventHandler
                            }>
                            <Tooltip
                                title={
                                    !userPermissions.canDelete
                                        ? 'You do not have permission to delete this data product'
                                        : ''
                                }>
                                <div
                                    className={clsx(internalClasses.textIcon, {
                                        [internalClasses.disabledTextIcon]:
                                            !userPermissions.canDelete,
                                        [internalClasses.deleteTextIcon]: userPermissions.canDelete,
                                    })}>
                                    <DeleteOutline />
                                    <span className={internalClasses.textLabel}>Delete</span>
                                </div>
                            </Tooltip>
                        </MenuItem>
                    </Menu>
                </>
            )}
        />
    );
};

function stopPropagationEventHandler(event: MouseEvent): void {
    event.stopPropagation();
}
