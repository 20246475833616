/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { ReactElement } from 'react';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import { FilterCriteria, queryFilters } from './queryFilters';
import { Select } from '../../../components/select/Select';
import { SelectChangeEvent } from '@mui/material/Select';

export interface FiltersRowModel {
    id: string;
    filterBy: keyof FilterCriteria;
    filterValue: FilterCriteria[keyof FilterCriteria];
    valid: boolean;
}

interface FiltersRowProps {
    model: FiltersRowModel;
    setModel: (setFn: (newState: FiltersRowModel) => FiltersRowModel) => void;
    availableFilterBy: Array<keyof FilterCriteria>;
    deleteRow: ReactElement<HTMLElement>;
}

export const FiltersRow: React.FunctionComponent<FiltersRowProps> = ({
    model,
    setModel,
    availableFilterBy,
    deleteRow,
}) => {
    const onFilterByChange: (event: SelectChangeEvent<unknown>, child: React.ReactNode) => void = (
        event
    ) => {
        const filterBy = event.target.value as keyof FilterCriteria;
        setModel((prevState) => ({
            id: prevState.id,
            filterBy,
            filterValue: null,
            valid: true,
        }));
    };

    const onFilterValueChange = (newValue: FilterCriteria[keyof FilterCriteria]): void => {
        setModel((prevState) => ({
            ...prevState,
            filterValue: newValue,
        }));
    };

    const onValidityChange = (valid: boolean) => {
        setModel((prevState) => ({
            ...prevState,
            valid,
        }));
    };

    const getFilterComponent = (): ReactElement<HTMLElement> | undefined => {
        const filter = queryFilters().find((filter) => filter.filterBy === model.filterBy);
        if (filter) {
            return filter.component(model.filterValue, onFilterValueChange, onValidityChange);
        }
    };

    return (
        <Grid container spacing={1} alignItems={'center'}>
            <Grid item lg={3} sm={3} xl={3} xs={3}>
                <FormControl variant="outlined" margin="dense" fullWidth>
                    <Select value={model.filterBy} onChange={onFilterByChange}>
                        {availableFilterBy.map((filterBy) => (
                            <MenuItem key={filterBy} value={filterBy}>
                                {
                                    queryFilters().find((filter) => filter.filterBy === filterBy)
                                        ?.label
                                }
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            {getFilterComponent()}
            <Grid item xs={1}>
                {deleteRow}
            </Grid>
        </Grid>
    );
};
