/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';
import { DataProductState, SchemaDataProduct } from '../../../../api/dataProduct/dataProductApi';
import { OverviewBasicInfo } from './OverviewBasicInfo';
import { OverviewDatasets } from './dataset/OverviewDatasets';
import { useSubsectionClasses } from './useSubsectionClasses';

interface DataProductOverviewProps {
    dataProduct: SchemaDataProduct;
}

export const DataProductOverview: React.FunctionComponent<DataProductOverviewProps> = ({
    dataProduct,
}) => {
    const subsectionClasses = useSubsectionClasses();
    const isPublished = dataProduct.status !== DataProductState.DRAFT;

    return (
        <>
            <OverviewBasicInfo
                description={dataProduct.description}
                catalogName={dataProduct.catalogName}
                summary={dataProduct.summary}
                isPublished={isPublished}
                dataProductId={dataProduct.id}
                classes={subsectionClasses}
            />
            <OverviewDatasets dataProduct={dataProduct} classes={subsectionClasses} />
        </>
    );
};
