/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { createUseStyles } from 'react-jss';
import { useDialogOpenState } from '../../../components/dialog/useDialogOpenState';
import { SimpleDialog } from '../../../components/dialog/SimpleDialog';
import {
    Catalog,
    cloneDataProduct,
    DataProductNameValidation,
    getSupportedCatalogs$,
} from '../../../api/dataProduct/dataProductApi';
import { useTextInputValueChange } from '../../../utils/useTextInputValueChange';
import { DataDomain, getDomains$ } from '../../../api/dataProduct/dataDomain/dataDomainApi';
import { DataProductNameInput, isNameProblematic } from '../publish/DataProductNameInput';
import { DataFetchingState, isDataSuccess, useFetchingState } from '../domain/useFetchingState';
import { createDataProductDetailsPath } from '../routing/dataProductRoutingUtils';

interface CloneDataProductProps {
    dataProductId: string;
    dataProductName: string;
    needMaterialization: boolean;
    opener: (open: () => void) => ReactElement<HTMLElement>;
}

export const CloneDataProduct = ({
    dataProductId,
    dataProductName,
    needMaterialization,
    opener,
}: CloneDataProductProps) => {
    const { isOpen, close, open } = useDialogOpenState();
    const history = useHistory();
    const handleClone = useCallback(
        (cloneName: string, catalogName: string, domainId: string) =>
            cloneDataProduct(dataProductId, cloneName, catalogName, domainId).then(({ id }) =>
                history.push(createDataProductDetailsPath(id))
            ),
        [dataProductId]
    );
    return (
        <>
            {opener(open)}
            {isOpen && (
                <CloneDialog
                    onConfirm={handleClone}
                    needMaterialization={needMaterialization}
                    isOpen={isOpen}
                    close={close}
                    dataProductName={dataProductName}
                />
            )}
        </>
    );
};

interface CloneDialogProps {
    dataProductName: string;
    isOpen: boolean;
    needMaterialization: boolean;
    close: () => void;
    onConfirm: (cloneName: string, catalogName: string, domainId: string) => Promise<void>;
}

const CloneDialog = ({
    isOpen,
    close,
    onConfirm,
    dataProductName,
    needMaterialization,
}: CloneDialogProps) => {
    const [fetchingNameValidationInfo, setFetchingNameValidationInfo] =
        useState<DataFetchingState<DataProductNameValidation | undefined>>();
    const { observer: catalogsObserver, data: fetchedCatalogs } = useFetchingState<Catalog[]>();
    const { observer: domainsObserver, data: fetchedDomains } = useFetchingState<DataDomain[]>();
    const [domainId, setDomainId] = useState('');

    const supportedCatalogNames = useMemo(() => {
        if (!fetchedCatalogs) {
            return [];
        }
        const supportedCatalogs = needMaterialization
            ? fetchedCatalogs.filter(({ isMaterializedViewEnabled }) => isMaterializedViewEnabled)
            : fetchedCatalogs;
        return supportedCatalogs.map(({ catalogName }) => catalogName);
    }, [fetchedCatalogs, needMaterialization]);
    const [catalogName, setCatalogName] = useState('');

    const [cloneName, setCloneName] = useState(`${dataProductName} copy`);
    const handleConfirm = useCallback(
        () => onConfirm(cloneName, catalogName, domainId),
        [onConfirm, cloneName, catalogName, domainId]
    );
    const hasProblemsWithName = useMemo(() => {
        return isNameProblematic(
            cloneName,
            fetchingNameValidationInfo && isDataSuccess(fetchingNameValidationInfo)
                ? fetchingNameValidationInfo.data
                : undefined
        );
    }, [cloneName, fetchingNameValidationInfo]);

    useEffect(() => {
        const subscription = getSupportedCatalogs$().subscribe(catalogsObserver);
        return () => subscription.unsubscribe();
    }, []);

    useEffect(() => {
        const subscription = getDomains$().subscribe(domainsObserver);
        return () => subscription.unsubscribe();
    }, []);

    return (
        <SimpleDialog<CloneDialogContentProps>
            isOpen={isOpen}
            close={close}
            title="Clone"
            confirmButtonLabel="Create clone"
            isConfirmationButtonDisabled={
                !catalogName || !cloneName || !domainId || hasProblemsWithName
            }
            onConfirm={handleConfirm}
            contentProps={{
                cloneName,
                catalogName,
                domainId,
                supportedCatalogNames,
                supportedDomains: fetchedDomains || [],
                onCloneNameChange: (newName) => setCloneName(newName),
                onCatalogNameChange: (newName) => setCatalogName(newName),
                onDomainIdChange: (newId: string) => setDomainId(newId),
                onValidationChange: (newValidationInfo) =>
                    setFetchingNameValidationInfo(newValidationInfo),
            }}
            Content={CloneDialogContent}
        />
    );
};

const useCloneDialogContentClasses = createUseStyles({
    hint: {
        marginBottom: '1rem',
    },
    domainSelect: {
        marginTop: '1rem',
    },
});

interface CloneDialogContentProps {
    cloneName: string;
    catalogName: string;
    domainId: string;
    supportedCatalogNames: string[];
    supportedDomains: DataDomain[];
    onCloneNameChange: (newCloneName: string) => void;
    onCatalogNameChange: (newCatalogName: string) => void;
    onDomainIdChange: (newDomainId: string) => void;
    onValidationChange: (
        fetchingNameValidationInfo: DataFetchingState<DataProductNameValidation | undefined>
    ) => void;
    error?: ReactNode;
}

const CloneDialogContent = ({
    cloneName,
    catalogName,
    domainId,
    supportedCatalogNames,
    supportedDomains,
    onCloneNameChange,
    onCatalogNameChange,
    onDomainIdChange,
    onValidationChange,
    error,
}: CloneDialogContentProps) => {
    const internalClasses = useCloneDialogContentClasses();
    const handleCatalogNameChange = useTextInputValueChange(onCatalogNameChange, [
        onCatalogNameChange,
    ]);
    const handleDomainIdChange = useTextInputValueChange(onDomainIdChange, [onDomainIdChange]);
    return (
        <>
            <div className={internalClasses.hint}>Type the name of the new data product.</div>
            {error}
            <DataProductNameInput
                value={cloneName}
                onChange={onCloneNameChange}
                canBeChanged
                onValidationChange={onValidationChange}
                showTooltip={false}
            />
            <TextField
                fullWidth
                label="Catalog"
                required
                value={catalogName}
                select
                onChange={handleCatalogNameChange}>
                {supportedCatalogNames.map((option) => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </TextField>
            <TextField
                className={internalClasses.domainSelect}
                fullWidth
                label="Domain"
                required
                value={domainId}
                select
                onChange={handleDomainIdChange}>
                {supportedDomains.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                        {name}
                    </MenuItem>
                ))}
            </TextField>
        </>
    );
};
