/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React, { useCallback, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, {
    AutocompleteProps,
    AutocompleteRenderGroupParams,
    createFilterOptions,
} from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { EmptyOrValue } from '../../../../utils/value';
import { useQueryClient } from '../../useQueryClient';
import { ChooserErrorIcon } from '../../grants/ChooserErrorIcon';
import { addPrivilegesStyles } from '../add-privileges-styles';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { VirtualizedListBoxComponent } from '../../grants/VirtualizedListBoxComponent';
import Typography from '@mui/material/Typography';
import ListSubheader from '@mui/material/ListSubheader';
import isString from 'lodash/isString';

interface TableOrViewInfo {
    label: string;
    kind: 'tables' | 'views';
    value: string | null;
}

interface TableSelectorProps {
    disabled: boolean;
    catalogName: string | null;
    schemaName: string | null;
    value: EmptyOrValue<string | null>;
    handleChange: (value: EmptyOrValue<string | null>) => void;
}
const tableFilter = createFilterOptions<TableOrViewInfo>();
const tableFilterOptions: AutocompleteProps<
    TableOrViewInfo,
    false,
    false,
    true
>['filterOptions'] = (options, params) => {
    const filtered = tableFilter(options, params);
    const { inputValue } = params;
    const isExisting = options.some((option: TableOrViewInfo) => inputValue === option.label);
    if (inputValue && inputValue !== ' ' && !isExisting) {
        filtered.push({
            value: inputValue,
            label: `${inputValue} (custom table)`,
            kind: 'tables',
        });
    }
    return filtered;
};
export const TableSelector: React.FunctionComponent<TableSelectorProps> = ({
    disabled,
    catalogName,
    schemaName,
    value,
    handleChange,
}) => {
    const classes = addPrivilegesStyles();
    const [selectedTableName, setSelectedTableName] = useState<TableOrViewInfo | string | null>(
        null
    );
    const allChecked = !value.empty && value.value === null;

    const [tables, setTables] = useState<TableOrViewInfo[]>([]);
    const { busy, error, execute, reset } = useQueryClient((data) => {
        const tablesFound = data.map<TableOrViewInfo>(([tableName, tableType]) => ({
            label: tableName as string,
            kind: tableType === 'BASE TABLE' ? 'tables' : 'views',
            value: tableName as string,
        }));
        setTables(tablesFound);
    });

    useEffect(() => {
        if (!disabled && catalogName) {
            execute(
                `SELECT table_name, table_type FROM "${catalogName}".information_schema.tables WHERE table_schema = '${schemaName}' ORDER by table_type, table_name`
            );
            return () => {
                setTables([]);
                reset();
            };
        }
    }, [disabled, catalogName, schemaName]);

    const resetSelection = useCallback(() => {
        onChange(null, null);
        setTables([]);
    }, []);

    useEffect(() => {
        resetSelection();
        return () => resetSelection();
    }, [catalogName, schemaName]);

    const optionLabel = useCallback((option: TableOrViewInfo | string) => {
        if (isString(option)) {
            return option;
        } else if (option.value) {
            return option.value;
        } else {
            return option.label;
        }
    }, []);

    const optionSelected = useCallback(
        (option: TableOrViewInfo | string, value: TableOrViewInfo | string) => {
            const valueDetails = isString(value) ? value : value.value;
            const optionDetails = isString(option) ? option : option.value;
            return valueDetails === optionDetails;
        },
        []
    );

    const renderOption = useCallback(
        (props: React.HTMLAttributes<HTMLLIElement>, option: TableOrViewInfo) => (
            <li {...props}>
                <Box display="flex" width="100%">
                    <Box className={classes.optionLabel}>{option.label}</Box>
                </Box>
            </li>
        ),
        []
    );

    const onChange = useCallback(
        (event: React.SyntheticEvent | null, value: TableOrViewInfo | string | null) => {
            setSelectedTableName(value);
            if (!value) {
                handleChange({
                    empty: true,
                    value: null,
                });
            } else if (isString(value)) {
                handleChange({
                    empty: !value,
                    value: value,
                });
            } else {
                handleChange({
                    empty: !value,
                    value: value.value,
                });
            }
        },
        []
    );

    const renderPopupIcon = useCallback(() => {
        if (busy) {
            return <CircularProgress size={20} />;
        } else if (error) {
            return (
                <div>
                    <ChooserErrorIcon title="Loading tables and views failed" />
                </div>
            );
        } else {
            return <ArrowDropDownOutlinedIcon />;
        }
    }, [busy, error]);

    const groupBy = useCallback((option: TableOrViewInfo) => option.kind, []);

    const renderGroup = useCallback((params: AutocompleteRenderGroupParams) => {
        return [
            <ListSubheader key={params.key} component="div">
                {params.group}
            </ListSubheader>,
            params.children,
        ];
    }, []);

    const handleCheckStar = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            handleChange({
                empty: false,
                value: null,
            });
            setSelectedTableName(null);
        } else {
            handleChange({
                empty: true,
                value: null,
            });
        }
    }, []);

    return (
        <Grid pl={1} maxWidth="fit-content">
            <Typography variant="h5">Which table or view would you like to select?</Typography>
            <Typography className={classes.questionInfo}>
                Use the dropdown to select a specific table or view from selected schemas. To select
                all currently defined tables and views from the selected schemas, as well as any
                tables or views defined in the future in the selected schemas, check the{' '}
                <b>All tables and views</b> box instead.
            </Typography>
            <Box mb={1.5}>
                <FormControlLabel
                    control={
                        <Checkbox
                            disabled={disabled}
                            checked={allChecked}
                            onChange={handleCheckStar}
                            id="select-star"
                        />
                    }
                    label={
                        <span>
                            All tables and views{' '}
                            <span className={classes.cautionText}>(use with caution)</span>
                        </span>
                    }
                />
            </Box>
            <Box width="100%" mb={3}>
                <Autocomplete
                    freeSolo
                    loading={busy}
                    disabled={disabled || allChecked}
                    openOnFocus
                    options={tables}
                    getOptionLabel={optionLabel}
                    isOptionEqualToValue={optionSelected}
                    ListboxComponent={VirtualizedListBoxComponent}
                    renderOption={renderOption}
                    filterOptions={tableFilterOptions}
                    groupBy={groupBy}
                    renderGroup={renderGroup}
                    value={selectedTableName}
                    onChange={onChange}
                    popupIcon={renderPopupIcon()}
                    renderInput={(params) => <TextField {...params} label="Table or view" />}
                    selectOnFocus
                    clearOnBlur
                    blurOnSelect
                    handleHomeEndKeys
                />
            </Box>
        </Grid>
    );
};
