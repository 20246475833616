/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useState } from 'react';
import { QueryTable } from '../../../../api/queryApi';
import clsx from 'clsx';
import { paletteSwitch } from '../../../../themes/palette';
import { Ellipsis } from '../../../../components/ellipsis/Ellipsis';
import { abbreviateNumber } from '../../../../utils/abbreviateNumber';
import { createUseStyles } from 'react-jss';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import { SortableColumn, SortingState } from '../../../../components/table/SortableColumn';
import orderBy from 'lodash/orderBy';
import { NoData } from '../../../../components/error/NoData';
import { formatBytes } from '../../../../utils/formatBytes';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';

interface QueryTablesSectionProps {
    tables: QueryTable[];
}

const useStyles = createUseStyles((theme: Theme) => ({
    container: {
        padding: '8px 8px 16px 0px',
    },
    tableContainer: {
        maxHeight: 275,
        border: `1px solid ${paletteSwitch(theme).nebulaNavy50}`,
        letterSpacing: '0.5px',
    },
    noRowBorder: {
        '&:last-child th, &:last-child td': {
            borderBottom: 0,
        },
    },
    tableRow: {
        '& td,th': {
            padding: '12px',
        },
        '& td:first-child,th:first-child': {
            paddingLeft: '16px',
        },
    },
    separator: {
        marginLeft: '4px',
        marginRight: '4px',
        color: paletteSwitch(theme).black54,
    },
}));

export const QueryTablesSection: React.FunctionComponent<QueryTablesSectionProps> = ({
    tables,
}) => {
    const classes = useStyles();
    const [sorting, setSorting] = useState<SortingState<keyof QueryTable>>({
        sortOrder: 'desc',
        sortBy: 'output',
    });

    return (
        <div className={classes.container}>
            <Typography variant={'h5'}>Tables</Typography>

            {tables.length === 0 ? (
                <NoData height={130} icon={'table'} />
            ) : (
                <TableContainer className={classes.tableContainer}>
                    <Table stickyHeader style={{ tableLayout: 'fixed' }}>
                        <TableHead>
                            <TableRow className={classes.tableRow}>
                                <SortableColumn<keyof QueryTable>
                                    label="Catalog"
                                    sortingKey="catalogName"
                                    sortingState={sorting}
                                    setSortingState={setSorting}
                                    component="td"
                                />
                                <SortableColumn<keyof QueryTable>
                                    label="Schema"
                                    sortingKey="schemaName"
                                    sortingState={sorting}
                                    setSortingState={setSorting}
                                    component="td"
                                />
                                <SortableColumn<keyof QueryTable>
                                    label="Table"
                                    sortingKey="tableName"
                                    sortingState={sorting}
                                    setSortingState={setSorting}
                                    component="td"
                                />
                                <SortableColumn<keyof QueryTable>
                                    label="Rows"
                                    sortingKey="physicalRows"
                                    sortingState={sorting}
                                    setSortingState={setSorting}
                                    component="td"
                                />
                                <SortableColumn<keyof QueryTable>
                                    label="Bytes"
                                    sortingKey="physicalBytes"
                                    sortingState={sorting}
                                    setSortingState={setSorting}
                                    component="td"
                                />
                                <SortableColumn<keyof QueryTable>
                                    label="Read / write"
                                    sortingKey="output"
                                    sortingState={sorting}
                                    setSortingState={setSorting}
                                    component="td"
                                />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orderBy(tables, [sorting.sortBy], [sorting.sortOrder]).map((table) => (
                                <TableRow
                                    key={`${table.catalogName}.${table.schemaName}.${table.tableName}`}
                                    hover
                                    className={clsx(classes.noRowBorder, classes.tableRow)}>
                                    <TableCell>
                                        <Ellipsis>{table.catalogName}</Ellipsis>
                                    </TableCell>
                                    <TableCell>
                                        <Ellipsis>{table.schemaName}</Ellipsis>
                                    </TableCell>
                                    <TableCell>
                                        <Ellipsis>{table.tableName}</Ellipsis>
                                    </TableCell>
                                    <TableCell>{abbreviateNumber(table.physicalRows)}</TableCell>
                                    <TableCell>
                                        {table.physicalBytes === 0 && table.physicalRows > 0
                                            ? '-'
                                            : formatBytes(table.physicalBytes)}
                                    </TableCell>
                                    <TableCell>{table.output ? 'write' : 'read'}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </div>
    );
};
