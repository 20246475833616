/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import { createUseStyles } from 'react-jss';
import { paletteSwitch } from '../../themes/palette';
import { ConfigContext } from '../../app/ConfigContextProvider';
import { FilteredLabel } from './FilteredLabel';
import { Theme } from '@mui/material/styles';

const useStyles = createUseStyles((theme: Theme) => ({
    you: {
        color: `${paletteSwitch(theme).black38}`,
        marginLeft: '0.25rem',
    },
}));

interface UserEmailCellProps {
    user: string;
    filter: string;
}

export const UserEmailCell = ({ user, filter }: UserEmailCellProps) => {
    const classes = useStyles();

    const currentUserDetails = useContext(ConfigContext);

    return (
        <Box display={'flex'}>
            <FilteredLabel label={user} filter={filter} />
            {user === currentUserDetails?.user && <div className={classes.you}>(you)</div>}
        </Box>
    );
};
