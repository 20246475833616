/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { ChooserErrorIcon } from '../../grants/ChooserErrorIcon';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { EmptyOrValue } from '../../../../utils/value';
import Popper from '@mui/material/Popper';
import Paper, { PaperProps } from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import { addPrivilegesStyles } from '../add-privileges-styles';
import { PopperUnstyledProps } from '@mui/base/PopperUnstyled';
import Button from '@mui/material/Button';
import { listDataProducts$ } from '../../../../api/dataProduct/dataProductApi';

interface DataProductInfo {
    label: string;
    value: string;
    description?: string | null;
}

interface DataProductSelectorProps {
    disabled: boolean;
    domainId?: string;
    value: EmptyOrValue<string[] | null>;
    handleChange: (value: EmptyOrValue<string[] | null>) => void;
}

export const DataProductSelector: React.FunctionComponent<DataProductSelectorProps> = ({
    value,
    domainId,
    disabled,
    handleChange,
}) => {
    const classes = addPrivilegesStyles();
    const [applicableDataProducts, setDataProducts] = useState<DataProductInfo[]>([]);
    const [busy, setBusy] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [selectedDataProducts, setSelectedDataProducts] = useState<DataProductInfo[]>([]);

    useEffect(() => {
        const selectedLength = value.value?.filter((currentVal) =>
            selectedDataProducts.some((role) => currentVal === role.value)
        ).length;
        if (!selectedLength) {
            if (value.value) {
                const rolesFound: DataProductInfo[] = [];
                applicableDataProducts.forEach((applicableRole) => {
                    value.value?.forEach((val) => {
                        if (applicableRole.value === val) {
                            rolesFound.push(applicableRole);
                        }
                    });
                });
                setSelectedDataProducts(rolesFound ? rolesFound : []);
            } else {
                setSelectedDataProducts([]);
            }
        }
    }, [value]);

    useEffect(() => {
        setBusy(true);
        setError(null);
        if (domainId) {
            const subscription = listDataProducts$({
                dataDomainIds: [domainId],
            }).subscribe({
                next: (products) => {
                    setDataProducts(
                        products.map((it) => ({
                            label: it.name,
                            value: it.name,
                            description: it.description,
                        }))
                    );
                    setBusy(false);
                },
                error: (error) => {
                    setBusy(false);
                    setError(error);
                },
            });

            return () => {
                subscription.unsubscribe();
            };
        } else {
            setDataProducts([]);
            setBusy(false);
        }
    }, [disabled, domainId]);

    useEffect(() => {
        return () => handleChange({ empty: true, value: null });
    }, []);

    const allDataProductsChecked = !value.empty && value.value === null;

    const onChange = useCallback(
        (event: React.SyntheticEvent, value: DataProductInfo[]) => {
            setSelectedDataProducts(value);
            setValues(value);
        },
        [selectedDataProducts]
    );

    const setValues = useCallback((value: DataProductInfo[]) => {
        if (!value.length) {
            handleChange({
                empty: true,
                value: null,
            });
        } else {
            handleChange({
                empty: !value,
                value: value.map((it) => it.value),
            });
        }
    }, []);

    const checkAllChange = useCallback(() => {
        setValues(applicableDataProducts);
        setSelectedDataProducts(applicableDataProducts);
    }, [applicableDataProducts]);

    const handleCheckStar = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            handleChange({
                empty: false,
                value: null,
            });
        } else {
            handleChange({
                empty: true,
                value: null,
            });
        }
        setSelectedDataProducts([]);
    }, []);

    const renderOption = useCallback(
        (props: React.HTMLAttributes<HTMLLIElement>, option: DataProductInfo, { selected }) => {
            return (
                <li {...props}>
                    <Checkbox style={{ marginRight: '8px' }} checked={selected} />
                    <div>
                        <div className={classes.optionLabel}>{option.label}</div>
                        {option.description && (
                            <div className={classes.optionDescription}>{option.description}</div>
                        )}
                    </div>
                </li>
            );
        },
        []
    );

    const renderPopupIcon = useCallback(() => {
        if (busy) {
            return <CircularProgress size={20} />;
        } else if (error) {
            return (
                <div>
                    <ChooserErrorIcon title="Loading data products failed" />
                </div>
            );
        } else {
            return <ArrowDropDownOutlinedIcon />;
        }
    }, [busy, error]);

    const CustomPaper = useCallback(
        (props: PaperProps) => <Paper style={{ borderRadius: '4px 4px 0 0' }} {...props} />,
        []
    );

    const dataProductsSelectorLabel = useMemo(() => {
        if (domainId && !busy && applicableDataProducts.length === 0) {
            return 'No data products available';
        } else {
            return 'Data products';
        }
    }, [domainId, busy, applicableDataProducts]);

    const SelectAllButton = useCallback(
        (param: PopperUnstyledProps) => (
            <Popper {...param}>
                <Box {...param} />
                <Paper className={classes.popperComponentPaper}>
                    <Box justifyContent="flex-end" display="flex">
                        <Button
                            variant="outlined"
                            size="small"
                            onMouseDown={(e) => {
                                e.preventDefault();
                            }}
                            onClick={checkAllChange}>
                            Select all data products
                        </Button>
                    </Box>
                </Paper>
            </Popper>
        ),
        [checkAllChange]
    );

    return (
        <Grid pl={1} maxWidth="fit-content">
            <Typography variant="h5">Which data products would you like to select?</Typography>
            <Typography className={classes.questionInfo}>
                Use the dropdown to select the data products in the selected domains. To select all
                currently defined data products from the selected domains, as well as data products
                defined in the future in the selected domains, check the <b>All data products</b>{' '}
                box instead.
            </Typography>
            <Box mb={1.5}>
                <FormControlLabel
                    control={
                        <Checkbox
                            disabled={disabled}
                            checked={allDataProductsChecked}
                            onChange={handleCheckStar}
                            id="select-star"
                        />
                    }
                    label={
                        <span>
                            All data products{' '}
                            <span className={classes.cautionText}>(use with caution)</span>
                        </span>
                    }
                />
            </Box>
            <Box mb={2}>
                <Autocomplete
                    value={selectedDataProducts}
                    multiple
                    limitTags={2}
                    clearText="Clear selected data products"
                    id="multiple-limit-tags"
                    options={applicableDataProducts}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    disableCloseOnSelect
                    disabled={disabled || allDataProductsChecked || !applicableDataProducts.length}
                    onChange={onChange}
                    renderOption={renderOption}
                    popupIcon={renderPopupIcon()}
                    ChipProps={{
                        className: classes.chip,
                        deleteIcon: <CloseIcon className={classes.chipCloseIcon} />,
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label={dataProductsSelectorLabel} />
                    )}
                    PopperComponent={SelectAllButton}
                    PaperComponent={CustomPaper}
                />
            </Box>
        </Grid>
    );
};
