/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { FunctionComponent, ReactNode, useContext, useEffect, useState } from 'react';
import { Spinner } from '../../../components/spinner/Spinner';
import { ErrorIndicator } from '../../../components/error/ErrorIndicator';
import { UserEntityPermissions } from '../../../api/dataProduct/user/userPermissionApi';
import { getUserDataDomainPermissions$ } from '../../../api/dataProduct/dataDomain/dataDomainApi';

const DataDomainPermissionContext = React.createContext<UserEntityPermissions>({
    canUpdate: false,
    canPublish: false,
    canDelete: false,
});

export const useDataDomainPermissionContext = (): UserEntityPermissions =>
    useContext(DataDomainPermissionContext);

interface DataDomainPermissionContextProviderProps {
    children: ReactNode;
    dataDomainId: string;
}

type DataProductPermissionContextProviderState = UserEntityPermissions | 'loading' | 'error';

export const DataDomainPermissionContextProvider: FunctionComponent<
    DataDomainPermissionContextProviderProps
> = ({ children, dataDomainId }: DataDomainPermissionContextProviderProps) => {
    const [userEntityPermissions, setUserEntityPermissions] =
        useState<DataProductPermissionContextProviderState>('loading');

    useEffect(() => {
        setUserEntityPermissions('loading');
        const subscription = getUserDataDomainPermissions$(dataDomainId).subscribe(
            setUserEntityPermissions,
            () => setUserEntityPermissions('error')
        );
        return () => subscription.unsubscribe();
    }, [dataDomainId]);

    if (userEntityPermissions === 'loading') {
        return <Spinner position="relative" delay={500} />;
    } else if (userEntityPermissions === 'error') {
        return (
            <ErrorIndicator text="We are having problem fetching permissions, try again later" />
        );
    } else {
        return (
            <DataDomainPermissionContext.Provider value={userEntityPermissions}>
                {children}
            </DataDomainPermissionContext.Provider>
        );
    }
};
