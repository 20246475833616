/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import Chart from 'chart.js';
import orderBy from 'lodash/orderBy';
import { parseISO } from 'date-fns';
import { alpha } from '@mui/material/styles';
import { defaultChartPalette } from '../themes/palette';

type ChartData = Chart.ChartData | 'loading' | 'error';

interface LineChartOptions {
    fill?: boolean | string;
    color?: string;
}

export const prepareLineChartData = <T extends Record<string, unknown>>(
    label: string,
    inputData: T[] | 'loading' | 'error',
    x: keyof T,
    y: keyof T | ((point: T) => number),
    options?: LineChartOptions
): ChartData => {
    if (!Array.isArray(inputData)) {
        return inputData;
    }
    const { color = defaultChartPalette[0], fill = false } = options || {};

    const data = orderBy(
        inputData.map((point) => ({
            x: parseISO(point[x] as unknown as string),
            y: typeof y === 'function' ? y(point) : (point[y] as unknown as number),
        })),
        ['x']
    );

    return {
        datasets: [
            {
                data,
                label,
                fill,
                borderColor: color,
                pointBorderColor: color,
                pointHoverBackgroundColor: color,
                backgroundColor: alpha(color, 0.1),
            },
        ],
    };
};

export const mergeLines = (lines: (ChartData | false)[]): ChartData => {
    for (const line of lines) {
        if (typeof line === 'string') {
            return line;
        }
    }

    const datasets = (lines as Chart.ChartData[])
        .filter((line) => line !== false)
        .flatMap((line) => line.datasets || []);

    return { datasets };
};
