/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { createTheme } from '@mui/material/styles';
import clone from 'lodash/clone';

const originalTheme = createTheme();

export const shadows = (() => {
    const shadowsArray = clone(originalTheme.shadows);

    shadowsArray[1] =
        '0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)';
    shadowsArray[2] = '0px 1px 5px #00000033';
    shadowsArray[3] =
        '0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)'; //button
    return shadowsArray;
})();
