/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import {
    getDataProductIds$,
    listDataProductsByIds$,
    ListDataProductsSearchOptions,
    SchemaDataProduct,
} from '../../api/dataProduct/dataProductApi';
import {
    usePaginatedRecordsManager,
    UsePaginatedRecordsManagerResult,
} from './pagination/paginatedRecordsManager';
import { useEffect } from 'react';
import { dataProductEvent$ } from '../../api/dataProduct/dataProductEvent';
import { map, tap } from 'rxjs/operators';

function extractDataProductId({ id }: SchemaDataProduct): string {
    return id;
}

export const useDataProductListManager: () => UsePaginatedRecordsManagerResult<
    ListDataProductsSearchOptions,
    SchemaDataProduct
> = () => {
    const paginatedRecordsManager = usePaginatedRecordsManager(
        {},
        getDataProductIds$,
        listDataProductsByIds$,
        extractDataProductId
    );

    useEffect(() => {
        const subscription = dataProductEvent$
            .pipe(
                map(({ payload: { dataProductId } }) => dataProductId),
                tap((dataProductId) => paginatedRecordsManager.refetchRecord(dataProductId))
            )
            .subscribe();
        return () => subscription.unsubscribe();
    }, []);

    return paginatedRecordsManager;
};
