/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';
import { DetailsCellHeader, DetailsCellValue } from './DetailsCell';
import clsx from 'clsx';

interface DetailsItemProps {
    header: string;
    value: React.ReactElement | string | number | undefined;
    tooltip?: React.ReactNode;
    valueTooltip?: React.ReactNode;
    classes?: {
        item?: string;
        header?: string;
        value?: string;
    };
}

export const DetailsItem: React.FunctionComponent<DetailsItemProps> = ({
    header,
    value,
    tooltip,
    valueTooltip,
    classes: classesFromProps = {},
}) => {
    return (
        <div className={clsx(classesFromProps.item)}>
            <DetailsCellHeader
                text={header}
                tooltip={tooltip}
                className={classesFromProps.header}
            />
            <DetailsCellValue
                value={value}
                className={classesFromProps.value}
                valueTooltip={valueTooltip}
            />
        </div>
    );
};
