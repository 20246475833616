/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { createUseStyles } from 'react-jss';
import { Theme } from '@mui/material/styles';
import { paletteSwitch } from '../../themes/palette';

interface NoItemsBannerProps {
    title: string;
    canCreateNewItem?: boolean;
    newItemButtonId?: string;
    onCreateNewItem: () => void;
    newItemButtonLabel: string;
}

const useStyles = createUseStyles((theme: Theme) => ({
    noItemsBanner: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: paletteSwitch(theme).nebulaNavy50,
        marginTop: '2.625rem',
        marginRight: '1.5rem',
        padding: '2rem',
    },
    noItemsBannerTitle: {
        textAlign: 'center',
        marginTop: theme.spacing(1),
        fontFamily: 'montserrat, sans-serif',
        fontSize: '1.25rem',
    },
    noItemsBannerButton: {
        marginTop: theme.spacing(2),
    },
}));

export const NoItemsBox: React.FunctionComponent<NoItemsBannerProps> = ({
    title,
    canCreateNewItem = true,
    newItemButtonId,
    onCreateNewItem,
    newItemButtonLabel,
}) => {
    const classes = useStyles();

    return (
        <Box className={classes.noItemsBanner}>
            <Typography className={classes.noItemsBannerTitle}>{title}</Typography>
            {canCreateNewItem && (
                <Button
                    id={newItemButtonId}
                    className={classes.noItemsBannerButton}
                    variant="contained"
                    color="primary"
                    onClick={onCreateNewItem}>
                    {newItemButtonLabel}
                </Button>
            )}
        </Box>
    );
};
