/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { AutocompleteChangeReason } from '@mui/material/useAutocomplete';
import Box from '@mui/material/Box';
import { useSubjects } from './useSubjects';
import { AttributeSubject } from '../../../api/biac/biacApi';
import CircularProgress from '@mui/material/CircularProgress';
import { ChooserErrorIcon } from './ChooserErrorIcon';
import capitalize from 'lodash/capitalize';
import { EmptyOrValue } from '../../../utils/value';
import { addPrivilegesStyles } from '../add-privileges/add-privileges-styles';

interface SubjectInfo {
    label: string;
    subjectName: string | null;
    description?: string;
}

const all: SubjectInfo = {
    label: '*',
    subjectName: null,
    description: 'All users',
};

interface SubjectChooserProps {
    currentRoleName: string;
    disabled: boolean;
    required?: boolean;
    excludedSubjects?: string[];
    label: string;
    subjectType: Exclude<AttributeSubject, 'role'>;
    allowStar?: boolean;
    value: EmptyOrValue<string | null>;
    handleChange: (value: EmptyOrValue<string | null>) => void;
}

export const SubjectChooser: React.FunctionComponent<SubjectChooserProps> = ({
    currentRoleName,
    disabled,
    required = !disabled,
    excludedSubjects,
    label,
    subjectType,
    allowStar,
    value,
    handleChange,
}) => {
    const { subjects, busy, error } = useSubjects(currentRoleName, subjectType);
    const classes = addPrivilegesStyles();
    const [subjectName, setSubjectName] = useState<SubjectInfo | string | null>(
        !value.empty && !value.value ? all : value.value
    );
    const [subjectNameInput, setSubjectNameInput] = useState<string>(
        !value.empty && !value.value ? all.label : value.value ?? ''
    );

    const options = useMemo(
        () => [
            ...(allowStar ? [all] : []),
            ...subjects
                .filter((subject) =>
                    excludedSubjects ? !excludedSubjects.includes(subject) : true
                )
                .map<SubjectInfo>((subject) => ({
                    label: subject,
                    subjectName: subject,
                })),
        ],
        [subjects, allowStar, excludedSubjects]
    );

    useEffect(() => {
        if (value.empty !== !!subjectNameInput || value.value !== subjectNameInput) {
            if (value.empty) {
                setSubjectName(null);
            }
            setSubjectNameInput(!value.empty && !value.value ? all.label : value.value ?? '');
        }
    }, [value]);

    useEffect(() => {
        return () => handleChange({ empty: true, value: null });
    }, []);

    const optionLabel = useCallback(
        (option: SubjectInfo | string) => (typeof option === 'string' ? option : option.label),
        []
    );

    const optionSelected = useCallback(
        (option: SubjectInfo, value: SubjectInfo | string) =>
            typeof value === 'string'
                ? option.subjectName === value
                : option.subjectName === value.subjectName,
        []
    );

    const renderOption = useCallback(
        (props: React.HTMLAttributes<HTMLLIElement>, option: SubjectInfo) => (
            <li {...props}>
                <Box display="flex" width="100%">
                    <Box>{option.label}</Box>
                    <Box ml="auto" className={classes.cautionText}>
                        {option.description}
                    </Box>
                </Box>
            </li>
        ),
        []
    );

    const onChange = useCallback(
        (_, value: SubjectInfo | string | null, reason: AutocompleteChangeReason) => {
            setSubjectName(value);
            if (!value) {
                handleChange({
                    empty: true,
                    value: null,
                });
            } else if (value === all) {
                handleChange({
                    empty: false,
                    value: all.subjectName,
                });
            } else if (typeof value !== 'string') {
                if (reason === 'selectOption') {
                    handleChange({
                        empty: !value.subjectName,
                        value: value.subjectName,
                    });
                }
            } else {
                handleChange({
                    empty: !value,
                    value: value.length ? value : null,
                });
            }
        },
        []
    );

    const onInputChange = useCallback((_, value: string) => {
        setSubjectNameInput(value);
        if (value === all.label) {
            setSubjectName(all);
            handleChange({
                empty: false,
                value: all.subjectName,
            });
        } else {
            handleChange({
                empty: !value,
                value: value.length ? value : null,
            });
        }
    }, []);

    return (
        <Autocomplete<SubjectInfo, false, false, true>
            freeSolo
            loading={busy}
            disabled={disabled}
            openOnFocus
            options={options}
            getOptionLabel={optionLabel}
            isOptionEqualToValue={optionSelected}
            renderOption={renderOption}
            value={subjectName}
            onChange={onChange}
            inputValue={subjectNameInput}
            onInputChange={onInputChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    required={required}
                    fullWidth
                    autoComplete="false"
                    margin="dense"
                    label={label}
                    variant="outlined"
                    error={subjectNameInput ? subjectNameInput.includes(' ') : false}
                    helperText={
                        subjectNameInput && subjectNameInput.includes(' ')
                            ? `${capitalize(subjectType)}name cannot contain a space`
                            : null
                    }
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {!disabled && busy ? (
                                    <CircularProgress color="secondary" size={20} />
                                ) : null}
                                {!disabled && error ? (
                                    <ChooserErrorIcon
                                        title={`Loading ${subjectType}s failed`}
                                        error={error}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
};
