/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useMemo } from 'react';
import { EmptyOrValue } from '../../../../utils/value';
import { TagDetails } from '../../../../components/tag/TagDetails';
import { TagValue } from '../../../../api/dataProduct/dataProductApi';
import { TagTextField } from '../../../../components/tag/TagTextField';

interface CustomItemSelectorProps {
    label: string;
    newTagOptionPostfix: string;
    disabled: boolean;
    suggestions: string[];
    value: EmptyOrValue<string[] | null>;
    handleChange: React.Dispatch<React.SetStateAction<EmptyOrValue<string[] | null>>>;
    textFieldEndAdornment?: React.ReactNode;
    inputValidationRequired?: boolean;
}

export const CustomItemSelector: React.FunctionComponent<CustomItemSelectorProps> = ({
    value,
    handleChange,
    disabled,
    label,
    newTagOptionPostfix,
    suggestions,
    textFieldEndAdornment,
    inputValidationRequired,
}) => {
    const onAdd = useCallback(
        (toAdd: string) => {
            handleChange((prevState) => {
                const alreadyExists = prevState?.value?.find((it) => it === toAdd);
                if (alreadyExists) {
                    return prevState;
                }
                return {
                    empty: false,
                    value: prevState?.value ? [...prevState?.value, toAdd] : [toAdd],
                };
            });
        },
        [handleChange]
    );

    const onRemove = useCallback(
        (toRemove: string) => {
            handleChange((prevState) => {
                const newState = prevState?.value?.filter((value) => value !== toRemove) ?? [];
                return {
                    empty: newState.length === 0,
                    value: newState,
                };
            });
        },
        [handleChange]
    );

    const tagDetailsInput: TagValue[] = useMemo(() => {
        return value.value?.map((it) => ({ value: it })) ?? [];
    }, [value]);

    return (
        <>
            <TagTextField
                allTagNames={suggestions}
                onChange={onAdd}
                disabled={disabled}
                placeholder={''}
                label={label}
                newTagOptionPostfix={newTagOptionPostfix}
                endAdornment={textFieldEndAdornment}
                isTextValidationRequired={inputValidationRequired}
            />
            <TagDetails tags={tagDetailsInput} removeTag={onRemove} disabled={disabled} />
        </>
    );
};
