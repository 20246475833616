/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import Chart, { ChartData, ChartPoint } from 'chart.js';
import { LegendProvider } from './legendProvider';

export const datasetLegendProvider: LegendProvider = (
    data: Chart.ChartData | 'loading' | 'error' | undefined,
    hiddenLegendItems: string[],
    toggleLegendItemVisibility: (label: string) => () => void,
    legendValueFormatter: (
        value: number | null | undefined | number[] | ChartPoint
    ) => string | null
) => {
    if (!data || typeof data === 'string') {
        return {
            filterData: (_) => _,
            getLegendItems: () => [],
        };
    }

    const getLegendItems = () => {
        return (
            data.datasets?.flatMap((dataset) => {
                if (!dataset.label || !dataset.borderColor) {
                    return [];
                }

                return [
                    {
                        id: dataset.label,
                        label: dataset.label,
                        color: dataset.borderColor?.toString(),
                        enabled: {
                            value: !hiddenLegendItems.includes(dataset.label),
                            setValue: toggleLegendItemVisibility(dataset.label),
                        },
                        value:
                            typeof Array.isArray(dataset.data) && dataset.data
                                ? legendValueFormatter(dataset.data[dataset.data.length - 1])
                                : '-',
                    },
                ];
            }) ?? []
        );
    };

    const filterData = (data: ChartData): ChartData => {
        return {
            datasets: data.datasets?.map((dataset) => ({
                ...dataset,
                hidden: hiddenLegendItems.includes(dataset?.label ?? ''),
            })),
        };
    };

    return {
        getLegendItems,
        filterData,
    };
};
