/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

export const getListResponseCount = (
    dataLength: number | undefined,
    isError: boolean,
    isLoading: boolean
): string | number => {
    if (dataLength === undefined || isLoading || isError) {
        return '-';
    }
    return dataLength;
};
