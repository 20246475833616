/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { DependencyList, useCallback } from 'react';
import * as React from 'react';

export const useTextInputValueChange = (callback: Callback, deps: DependencyList): Result =>
    useCallback<Result>(({ target: { value } }) => callback(value), deps);

type Callback = (value: string) => void;
type Result = React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
