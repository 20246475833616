/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, {
    Dispatch,
    ReactNode,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react';
import clsx from 'clsx';
import Edit from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { createUseStyles } from 'react-jss';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { TypographyVariant } from '@mui/material/styles';
import { ErrorIndicator } from '../../../components/error/ErrorIndicator';
import { useDataProductPermissionContext } from '../permission/DataProductPermissionContext';
import { useEditContext } from './EditContext';

const useClasses = createUseStyles({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    buttonContainer: {
        marginTop: '0.25rem',
        display: 'flex',
        justifyContent: 'end',
    },
    submitButton: {
        marginLeft: '1rem',
    },
});

interface EditableSectionProps<FormData> {
    header: ReactNode;
    headerVariant?: Exclude<TypographyVariant, 'p'>;
    createForm: () => FormData;
    children: (
        isEditModeOn: boolean,
        editedFormData: FormData,
        dispatchEditedFormData: Dispatch<SetStateAction<FormData>>
    ) => ReactNode;
    isFormChanged: (originalForm: FormData, userForm: FormData) => boolean;
    classes?: {
        header?: string;
        editButton?: string;
        formButtonContainer?: string;
    };
    onSave: (formData: FormData) => Promise<unknown>;
}

export const EditableSection = <FormData,>({
    header,
    headerVariant = 'h5',
    children,
    createForm,
    isFormChanged,
    onSave,
    classes,
}: EditableSectionProps<FormData>) => {
    const internalClasses = useClasses();
    const editContext = useEditContext();
    const { canUpdate } = useDataProductPermissionContext();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [isEditModeOn, setIsEditModeOn] = useState(false);
    const [editedFormData, setEditedFormData] = useState<FormData>(createForm());
    const [originalFormData, setOriginalFormData] = useState<FormData>(createForm());
    const startEdition = useCallback(() => setIsEditModeOn(true), []);
    const handleSave = useCallback(
        () =>
            onSave(editedFormData)
                .then(() => setIsEditModeOn(false))
                .catch((error) => {
                    setErrorMessage(error?.message || 'Changes were not saved.');
                    return Promise.reject(error);
                }),
        [editedFormData, onSave]
    );
    const handleCancel = useCallback(() => setIsEditModeOn(false), []);
    useEffect(() => {
        setErrorMessage(undefined);
        editContext.setIsEditing(isEditModeOn);
        if (isEditModeOn) {
            setEditedFormData(createForm());
            setOriginalFormData(createForm());
        }
    }, [isEditModeOn]);

    return (
        <>
            <Typography
                variant={headerVariant}
                className={clsx(internalClasses.header, classes?.header)}>
                {header}
                {canUpdate && (
                    <IconButton
                        className={classes?.editButton}
                        size="small"
                        color={'primary'}
                        onClick={startEdition}
                        disabled={editContext.isEditing}>
                        <Edit />
                    </IconButton>
                )}
            </Typography>
            <ErrorIndicator text={errorMessage} />
            {children(isEditModeOn, editedFormData, setEditedFormData)}
            {isEditModeOn && (
                <div
                    className={clsx(internalClasses.buttonContainer, classes?.formButtonContainer)}>
                    <Button color="primary" variant="outlined" onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button
                        disabled={!isFormChanged(originalFormData, editedFormData)}
                        className={internalClasses.submitButton}
                        color="primary"
                        variant="contained"
                        onClick={handleSave}>
                        Save
                    </Button>
                </div>
            )}
        </>
    );
};
