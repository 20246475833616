/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useEffect, useState } from 'react';
import Autocomplete, { AutocompleteProps, createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { ChooserErrorIcon } from '../../grants/ChooserErrorIcon';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { EmptyOrValue } from '../../../../utils/value';
import Popper from '@mui/material/Popper';
import Paper, { PaperProps } from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import { addPrivilegesStyles } from '../add-privileges-styles';
import { PopperUnstyledProps } from '@mui/base/PopperUnstyled';
import Button from '@mui/material/Button';
import { useQueryClient } from '../../useQueryClient';
import sortBy from 'lodash/sortBy';
import { VirtualizedListBoxComponent } from '../../grants/VirtualizedListBoxComponent';
import union from 'lodash/union';

interface ColumnsSelectorProps {
    disabled: boolean;
    catalogName: string | null;
    schemaName: string | null;
    tableName: string | null;
    value: EmptyOrValue<string[] | null>;
    handleChange: (value: EmptyOrValue<string[] | null>) => void;
}
const filter = createFilterOptions<string>();
const newColumnOptionPostfix = ' (new column)';
const filterOptions: AutocompleteProps<string, false, false, true>['filterOptions'] = (
    options,
    params
) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    const isExisting = options.some((option: string) => inputValue === option);
    if (inputValue !== '' && !isExisting) {
        filtered.push(`${inputValue}${newColumnOptionPostfix}`);
    }

    return filtered;
};
const removeNewOptionText = (option: string): string => {
    return option.replace(newColumnOptionPostfix, '');
};
export const ColumnsSelector: React.FunctionComponent<ColumnsSelectorProps> = ({
    disabled,
    catalogName,
    schemaName,
    tableName,
    value,
    handleChange,
}) => {
    const classes = addPrivilegesStyles();
    const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
    const allColumnsChecked = !value.empty && value.value === null;
    const [availableColumns, setAvailableColumns] = useState<string[]>([]);
    const { busy, error, execute, reset } = useQueryClient((data) => {
        const columns = sortBy(data.map<string>(([name]) => name as string));
        setAvailableColumns(columns);
    });

    useEffect(() => {
        if (!disabled && catalogName && schemaName && tableName) {
            execute(`SHOW COLUMNS FROM "${catalogName}"."${schemaName}"."${tableName}"`);
            return () => {
                setAvailableColumns([]);
                reset();
            };
        }
    }, [disabled, catalogName, schemaName, tableName]);

    useEffect(() => {
        setSelectedColumns([]);
        handleChange({ empty: true, value: null });
    }, [catalogName, schemaName, tableName]);

    useEffect(() => {
        return () => handleChange({ empty: true, value: null });
    }, []);

    const renderOption = useCallback(
        (props: React.HTMLAttributes<HTMLLIElement>, option: string, { selected }) => {
            return (
                <li {...props} style={{ height: '4rem' }}>
                    <Checkbox style={{ marginRight: '8px' }} checked={selected} />
                    <div>
                        <div className={classes.optionLabel}>{option}</div>
                    </div>
                </li>
            );
        },
        []
    );

    const optionLabel = useCallback((option: string) => option, []);

    const optionSelected = useCallback(
        (option: string, value: string) => removeNewOptionText(option) === value,
        []
    );

    const onChange = useCallback((event: React.SyntheticEvent, value: string[]) => {
        const selectedValues = value.map((val) => removeNewOptionText(val));
        setSelectedColumns(selectedValues);
        setValues(selectedValues);
    }, []);
    const setValues = (value: string[]) => {
        if (!value.length) {
            handleChange({
                empty: true,
                value: null,
            });
        } else {
            handleChange({
                empty: !value,
                value: value,
            });
        }
    };
    const renderPopupIcon = useCallback(() => {
        if (busy && !availableColumns.length) {
            return <CircularProgress size={20} />;
        } else if (error) {
            return (
                <div>
                    <ChooserErrorIcon title="Loading columns failed" />
                </div>
            );
        } else {
            return <ArrowDropDownOutlinedIcon />;
        }
    }, [busy, error]);

    const CustomPaper = useCallback(
        (props: PaperProps) => <Paper style={{ borderRadius: '4px 4px 0 0' }} {...props} />,
        []
    );

    const checkAllChange = useCallback(() => {
        const uniqueColumns = union(availableColumns, selectedColumns);
        setValues(uniqueColumns);
        setSelectedColumns(uniqueColumns);
    }, [availableColumns, selectedColumns]);

    const handleCheckStar = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            handleChange({
                empty: false,
                value: null,
            });
            setSelectedColumns([]);
        } else {
            handleChange({
                empty: true,
                value: null,
            });
        }
    }, []);

    const SelectAllButton = useCallback(
        (param: PopperUnstyledProps) => (
            <Popper {...param}>
                <Box {...param} />
                <Paper className={classes.popperComponentPaper}>
                    {availableColumns.length > 0 ? (
                        <Box justifyContent="flex-end" display="flex">
                            <Button
                                variant="outlined"
                                size="small"
                                onMouseDown={(e) => {
                                    e.preventDefault();
                                }}
                                onClick={checkAllChange}>
                                Select all columns
                            </Button>
                        </Box>
                    ) : (
                        <div className={classes.cautionText}>
                            No columns present in the selected table create new columns
                        </div>
                    )}
                </Paper>
            </Popper>
        ),
        [checkAllChange]
    );

    return (
        <Grid pl={1} maxWidth="fit-content">
            <Typography variant="h5">Which columns would you like to select?</Typography>
            <Typography className={classes.questionInfo}>
                Use the dropdown to select the columns in the selected tables or views. To select
                all currently defined columns from the selected tables and views, as well as columns
                defined in the future in the selected tables and views, check the <b>All columns</b>{' '}
                box instead.
            </Typography>
            <Box mb={1.5}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={allColumnsChecked}
                            onChange={handleCheckStar}
                            disabled={disabled}
                            id="select-star"
                        />
                    }
                    label={
                        <span>
                            All columns{' '}
                            <span className={classes.cautionText}>(use with caution)</span>
                        </span>
                    }
                />
            </Box>
            <Box mb={2}>
                <Autocomplete
                    value={selectedColumns}
                    freeSolo
                    multiple
                    limitTags={2}
                    clearText="Clear selected columns"
                    id="multiple-limit-tags"
                    options={availableColumns}
                    onChange={onChange}
                    getOptionLabel={optionLabel}
                    isOptionEqualToValue={optionSelected}
                    disableCloseOnSelect
                    disabled={allColumnsChecked || disabled}
                    renderOption={renderOption}
                    popupIcon={renderPopupIcon()}
                    ListboxComponent={VirtualizedListBoxComponent}
                    ChipProps={{
                        className: classes.chip,
                        deleteIcon: <CloseIcon className={classes.chipCloseIcon} />,
                    }}
                    filterOptions={filterOptions}
                    renderInput={(params) => <TextField {...params} label="Columns" />}
                    PopperComponent={SelectAllButton}
                    PaperComponent={CustomPaper}
                    selectOnFocus
                    clearOnBlur
                    blurOnSelect
                    handleHomeEndKeys
                />
            </Box>
        </Grid>
    );
};
