/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { Spinner } from '../spinner/Spinner';

interface DataLoaderProps<T> {
    items: T | undefined;
    children: (items: T) => React.ReactElement;
    loading?: boolean;
    error?: string | null;
    classes?: { alert?: string };
}

export const DataLoader = <T,>({
    children,
    items,
    loading,
    error,
    classes,
}: DataLoaderProps<T>) => {
    if (error) {
        return (
            <Box flex={1} mt={3} className={classes?.alert}>
                <Alert severity="error" style={{ wordBreak: 'break-word' }}>
                    {error}
                </Alert>
            </Box>
        );
    } else if (items === undefined || loading) {
        return <Spinner position="relative" />;
    }

    return children(items);
};
