/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
export const baseUrl = '/ui/api/insights';
export const settingsBaseUrl = '/ui/api/settings';
export const trinoBaseUrl = '/ui/api/query';
export const accessControlUrl = '/ui/api/accesscontrol';
