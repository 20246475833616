/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { ReactElement, useEffect } from 'react';
import { fetchReport, ReportSlice } from './reportSlice';
import { FilterCriteria } from '../history/queryFilters';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../app/store';

interface LazyChartProps<
    K extends keyof ReportSlice['reports'],
    T extends ReportSlice['reports'][K]
> {
    name: K;
    load: (filters: FilterCriteria | null) => Promise<T>;
    children: (data: T | 'loading' | 'error' | undefined) => ReactElement;
}

export const LazyReport = <
    K extends keyof ReportSlice['reports'],
    T extends ReportSlice['reports'][K]
>({
    children,
    load,
    name,
}: LazyChartProps<K, T>): JSX.Element => {
    const dispatch: AppDispatch = useDispatch();
    const data = useSelector(({ report }: RootState) => report.reports[name]) as T;

    useEffect(() => {
        if (data === undefined) {
            dispatch(fetchReport<K, T>(name, load));
        }
    }, [data]);

    return children(data);
};
