/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import Button from '@mui/material/Button';
import {
    DataProductState,
    getDataProduct$,
    SchemaDataProduct,
} from '../../../api/dataProduct/dataProductApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { dataProductEvent$ } from '../../../api/dataProduct/dataProductEvent';
import { filter, switchMap } from 'rxjs/operators';
import { PartialObserver } from 'rxjs';
import { useDataProductDashboardRedirection } from '../routing/dataProductRoutingUtils';
import { Spinner } from '../../../components/spinner/Spinner';
import Typography from '@mui/material/Typography';
import { ErrorBox } from '../../../components/error/ErrorBox';
import { EditContextProvider } from '../edit/EditContext';
import TabContext from '@mui/lab/TabContext';
import Grid from '@mui/material/Grid';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { DiscussionContextProvider } from '../discussion/DiscussionContext';
import { useDataProductPermissionContext } from '../permission/DataProductPermissionContext';
import { paletteSwitch } from '../../../themes/palette';
import { DiscussionTab } from './discussion/DiscussionTab';
import { PublishDataProduct } from './PublishDataProduct';
import { StatusMessage } from './StatusMessage';
import { BookmarkDataProduct, BookMarkSimpleWrapper } from '../components/Bookmark';
import { InteractiveRating } from '../rating/InteractiveRating';
import { PreserveTabPanel } from './PreserveTabPanel';
import { SampleQueriesView } from '../sampleQueries/SampleQueriesView';
import { DataProductDiscussion } from '../discussion/DataProductDiscussion';
import { DetailsSummarySection } from './DetailsSummarySection';
import { DataProductTabs } from './DataProductTabs';
import { DataProductOverview } from './overview/DataProductOverview';
import Box from '@mui/material/Box';
import { PageContent } from '../../../layout/PageContent';
import { useTabStyles } from '../../../themes/useTabStyles';
import { DataProductActionMenu } from './actionMenu/DataProductActionMenu';
import { DefaultTab } from '../../../components/tab/DefaultTab';
import { Theme } from '@mui/material/styles';

interface DataProductDetailsProps {
    dataProductId: string;
}

const useStyles = createUseStyles((theme: Theme) => ({
    contentGridRoot: {
        padding: '0',
        marginTop: '-1rem',
        height: '100%',
    },
    header: {
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
        marginTop: '0.75rem',
    },
    headerText: {
        marginLeft: '1.375rem',
    },
    tabPanel: {
        padding: 0,
    },
    publish: {
        display: 'inline-block',
        marginRight: '1rem',
    },
    icon: {
        marginRight: '16px',
    },
    userDataManipulators: {
        fontSize: '1rem',
        display: 'flex',
        cursor: 'pointer',
        marginTop: '-1.5rem',
        marginBottom: '2rem',
        alignItems: 'center',
    },
    bookmark: {
        marginRight: '1.8125rem',
    },
    leftSection: {
        paddingRight: '1rem',
        borderRight: `1px solid ${paletteSwitch(theme).nebulaNavy100}`,
    },
    buttonGroup: {
        marginTop: '0.688rem',
        marginRight: '1rem',
    },
    rightSection: {
        paddingLeft: '24px',
        lineHeight: '1.5rem',
    },
    button: {
        marginRight: '36px',
    },
    textButton: {
        padding: '0px 8px',
    },
    editButton: {
        marginRight: '1rem',
    },
}));

export const DataProductDetails: React.FunctionComponent<DataProductDetailsProps> = ({
    dataProductId,
}) => {
    const [dataProduct, setDataProduct] = useState<SchemaDataProduct | undefined>();
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);

    const canBePublished = dataProduct?.status !== DataProductState.PUBLISHED;
    const userPermissions = useDataProductPermissionContext();

    const goToDashboard = useDataProductDashboardRedirection();

    const classes = useStyles();
    const tabClasses = useTabStyles();
    const [selectedTab, setSelectedTab] = useState<DataProductTabs>(DataProductTabs.OVERVIEW_TAB);

    const fetchObserver = useMemo<PartialObserver<SchemaDataProduct>>(
        () => ({
            next: (data) => {
                setDataProduct(data);
                setLoading(false);
            },
            error: ({ message }) => {
                setError(message);
                setLoading(false);
            },
        }),
        []
    );

    useEffect(() => {
        const subscription = getDataProduct$(dataProductId).subscribe(fetchObserver);
        return () => subscription.unsubscribe();
    }, [dataProductId]);

    useEffect(() => {
        const subscription = dataProductEvent$
            .pipe(
                filter(
                    (dataProductEvent) => dataProductEvent.payload.dataProductId === dataProductId
                ),
                switchMap(() => getDataProduct$(dataProductId))
            )
            .subscribe(fetchObserver);
        return () => subscription.unsubscribe();
    }, [dataProductId]);

    return (
        <PageContent
            title={
                <Box display={'flex'}>
                    <Box mr={'auto'}>Data product details</Box>
                    <Button
                        variant="text"
                        color="primary"
                        onClick={goToDashboard}
                        className={classes.button}
                        classes={{ root: classes.textButton }}>
                        <FontAwesomeIcon icon={faChevronLeft} className={classes.icon} />
                        Back
                    </Button>
                </Box>
            }>
            {loading && <Spinner size={150} position="absolute" />}
            {error && <ErrorBox text={error} />}
            {dataProduct && (
                <EditContextProvider>
                    <Grid container className={classes.contentGridRoot}>
                        <TabContext value={selectedTab}>
                            <DiscussionContextProvider dataProductId={dataProduct.id}>
                                <Grid item lg={9} md={6} sm={12} className={classes.leftSection}>
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="space-between">
                                        <div className={classes.header}>
                                            <TabList
                                                className={tabClasses.tabs}
                                                indicatorColor="primary"
                                                textColor="primary"
                                                value={selectedTab}
                                                onChange={(_, newValue): void =>
                                                    setSelectedTab(newValue)
                                                }>
                                                <DefaultTab
                                                    value={DataProductTabs.OVERVIEW_TAB}
                                                    label="Overview"
                                                />
                                                <DefaultTab
                                                    label="Usage examples"
                                                    value={DataProductTabs.SAMPLE_QUERIES_TAB}
                                                />
                                                <DiscussionTab
                                                    className={tabClasses.tab}
                                                    classes={{ selected: tabClasses.selected }}
                                                    value={DataProductTabs.DISCUSSION_TAB}
                                                />
                                            </TabList>
                                        </div>
                                        <div className={classes.buttonGroup}>
                                            {canBePublished && dataProduct?.id && (
                                                <PublishDataProduct
                                                    className={classes.publish}
                                                    dataProductId={dataProduct.id}
                                                    name={dataProduct.name}
                                                    hasNoDatasets={
                                                        dataProduct.views.length === 0 &&
                                                        dataProduct.materializedViews.length === 0
                                                    }
                                                    canUserPublish={userPermissions?.canPublish}
                                                />
                                            )}
                                            <DataProductActionMenu
                                                dataProductId={dataProductId}
                                                dataProductName={dataProduct.name}
                                                needMaterialization={
                                                    dataProduct.materializedViews.length > 0
                                                }
                                            />
                                        </div>
                                    </Grid>

                                    <StatusMessage
                                        status={dataProduct.status}
                                        hasDatasetsDeleted={
                                            dataProduct.views.some(
                                                (view) => view.markedForDeletion
                                            ) ||
                                            dataProduct.materializedViews.some(
                                                (view) => view.markedForDeletion
                                            )
                                        }
                                    />

                                    <Typography variant="h3">{dataProduct.name}</Typography>

                                    <div className={classes.userDataManipulators}>
                                        <BookmarkDataProduct
                                            className={classes.bookmark}
                                            isBookmarked={dataProduct.userData.isBookmarked}
                                            dataProductId={dataProduct.id}
                                            Wrapper={BookMarkSimpleWrapper}
                                            bookmarkCount={dataProduct.bookmarkCount}
                                        />
                                        <InteractiveRating
                                            entityName={dataProduct.name}
                                            entityId={dataProduct.id}
                                            userRate={dataProduct.userData.rating || 0}
                                            voterCount={dataProduct.ratingsCount}
                                            ratingsAverage={dataProduct.ratingsAverage}
                                        />
                                    </div>

                                    <TabPanel
                                        value={DataProductTabs.OVERVIEW_TAB}
                                        className={classes.tabPanel}>
                                        <DataProductOverview dataProduct={dataProduct} />
                                    </TabPanel>

                                    <PreserveTabPanel
                                        value={DataProductTabs.SAMPLE_QUERIES_TAB}
                                        className={classes.tabPanel}>
                                        <SampleQueriesView
                                            dataProductId={dataProduct.id}
                                            dataProductStatus={dataProduct.status}
                                        />
                                    </PreserveTabPanel>
                                    <PreserveTabPanel
                                        value={DataProductTabs.DISCUSSION_TAB}
                                        className={classes.tabPanel}>
                                        <DataProductDiscussion dataProductId={dataProduct.id} />
                                    </PreserveTabPanel>
                                </Grid>
                            </DiscussionContextProvider>
                        </TabContext>
                        <Grid item lg={3} md={6} sm={12} className={classes.rightSection}>
                            <DetailsSummarySection dataProduct={dataProduct} />
                        </Grid>
                    </Grid>
                </EditContextProvider>
            )}
        </PageContent>
    );
};
