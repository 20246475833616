/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { emitDataProductEvent } from './dataProductEvent';
import { fromPromise } from 'rxjs/internal-compatibility';
import { Observable } from 'rxjs';
import { UserEntityPermissions } from './user/userPermissionApi';
import { SortOrder } from '../../components/table/SortableColumn';
import axios from 'axios';

export interface SimpleDataProduct {
    id: string;
    name: string;
}

interface DataProductUserData {
    isBookmarked: boolean;
    rating?: number;
}

export interface Dataset extends View {
    type: DatasetType;
    refreshInterval?: string;
    incrementalColumn?: string;
}

export interface DataProductForm {
    id?: string;
    name: string;
    catalogName: string;
    schemaName: string;
    summary: string;
    description: string;
    createdBy?: string;
    dataDomainId: string;
    datasets: Dataset[];
    owners?: DataProductOwner[];
    relevantLinks?: DataProductLink[];
    status?: DataProductState;
}

interface AccessMetadata {
    lastQueriedAt?: string;
    lastQueriedBy?: string;
}

export interface SchemaDataProduct {
    id: string;
    userData: DataProductUserData;
    name: string;
    catalogName: string;
    schemaName: string;
    summary: string;
    description: string;
    createdBy?: string;
    dataDomainId: string;
    status?: DataProductState;
    views: View[];
    materializedViews: MaterializedView[];
    createdAt?: string;
    updatedAt?: string;
    updatedBy?: string;
    lastQueriedAt?: string;
    owners?: DataProductOwner[];
    relevantLinks?: DataProductLink[];
    accessMetadata?: AccessMetadata;
    ratingsAverage?: number;
    ratingsCount: number;
    bookmarkCount: number;
}

export enum DataProductState {
    PUBLISHED = 'PUBLISHED',
    PENDING_CHANGES = 'PENDING_CHANGES',
    DRAFT = 'DRAFT',
}

export enum DatasetType {
    MATERIALIZED_VIEW = 'MATERIALIZED_VIEW',
    VIEW = 'VIEW',
}

export interface MaterializedView extends View {
    definitionProperties?: {
        refresh_interval?: string;
        incremental_column?: string;
    };
}

export interface MaterializedViewRefreshMetadata {
    refreshInterval: string;
    incrementalColumn?: string;
    lastImport?: MaterializedViewImportMetadata;
    estimatedNextRefreshTime?: string;
}

export interface MaterializedViewImportMetadata {
    status: string;
    finishTime: string;
    error?: string;
}

export interface View {
    name: string;
    description: string;
    createdBy?: string;
    definitionQuery: string;
    columns: Column[];
    status?: DataProductState;
    markedForDeletion?: boolean;
    createdAt?: string;
    updatedAt?: string;
}

export interface TagValue {
    value: string;
}

export interface Tag extends TagValue {
    id: string;
}

export interface DataProductOwner {
    email: string;
    name: string;
}

export interface DataProductLink {
    label: string;
    url: string;
}

export interface Column {
    name: string;
    type: string;
    description: string;
}

export interface Catalog {
    catalogName: string;
    connectorName: string;
    isMaterializedViewEnabled: boolean;
}

export interface DataProductNameValidation {
    nameValidationErrorMessage?: string;
    generatedSchemaName: string;
    schemaValidationErrorMessage?: string;
}

export interface DataProductSepTaskState {
    status: DataProductSepTaskStatus;
    errors: DataProductSepTaskError[];
    workflowType: DataProductSepTaskWorkflowType;
}

export enum DataProductSepTaskStatus {
    SCHEDULED = 'SCHEDULED',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    ERROR = 'ERROR',
}

export enum DataProductSepTaskWorkflowType {
    PUBLISH = 'PUBLISH',
}

export interface DataProductSepTaskError {
    entityType: DataProductEntityType;
    entityName: string;
    message: string;
}

export enum DataProductEntityType {
    SCHEMA = 'SCHEMA',
    VIEW = 'VIEW',
}

export interface SampleQueryDto {
    name: string;
    description?: string;
    query: string;
}

export const baseUrl = '/ui/api/v1/dataProduct';

export type DataProductSortKey =
    | 'NAME'
    | 'CREATED_AT'
    | 'CREATED_BY'
    | 'UPDATED_AT'
    | 'LAST_QUERIED_AT'
    | 'CATALOG_NAME'
    | 'SCHEMA_NAME'
    | 'STATUS'
    | 'RATINGS_AVERAGE'
    | 'BOOKMARK';

export interface ListDataProductsSearchOptions {
    searchString?: string;
    sortKey?: DataProductSortKey;
    sortDirection?: SortOrder;
    limit?: number;
    dataDomainIds?: string[];
    tagIds?: string[];
}

export function listDataProducts(
    searchOptions: ListDataProductsSearchOptions
): Promise<SchemaDataProduct[]> {
    return axios
        .get<SchemaDataProduct[]>(`${baseUrl}/products`, {
            params: searchOptions,
            paramsSerializer: (params) => {
                return `searchOptions=${encodeURIComponent(JSON.stringify(params))}`;
            },
        })
        .then((response) => response.data);
}

export const listDataProducts$ = (
    searchOptions: ListDataProductsSearchOptions
): Observable<SchemaDataProduct[]> => fromPromise(listDataProducts(searchOptions));

function listDataProductsByIds(ids: string[]): Promise<SchemaDataProduct[]> {
    return axios
        .get<SchemaDataProduct[]>(`${baseUrl}/products`, {
            params: ids,
            paramsSerializer: (params) => {
                return `ids=${encodeURIComponent(JSON.stringify(params))}`;
            },
        })
        .then((response) => response.data);
}

export const listDataProductsByIds$ = (ids: string[]): Observable<SchemaDataProduct[]> =>
    fromPromise(listDataProductsByIds(ids));

export function getDataProduct(dataProductId: string): Promise<SchemaDataProduct> {
    return axios
        .get<SchemaDataProduct>(`${baseUrl}/products/${dataProductId}`)
        .then((response) => response.data);
}

export const getDataProduct$ = (dataProductId: string): Observable<SchemaDataProduct> =>
    fromPromise(getDataProduct(dataProductId));

export function getMaterializedViewMetadata(
    dataProductId: string,
    viewName: string
): Promise<MaterializedViewRefreshMetadata> {
    return axios
        .get<MaterializedViewRefreshMetadata>(
            `${baseUrl}/products/${dataProductId}/materializedViews/${viewName}/refreshMetadata`
        )
        .then((response) => response.data);
}

export const getMaterializedViewMetadata$ = (
    dataProductId: string,
    viewName: string
): Observable<MaterializedViewRefreshMetadata> =>
    fromPromise(getMaterializedViewMetadata(dataProductId, viewName));

export interface CreateDataProductPayload {
    name: string;
    catalogName: string;
    summary: string;
    description: string;
    dataDomainId: string;
    views: View[];
    materializedViews: MaterializedView[];
    owners?: DataProductOwner[];
    relevantLinks?: DataProductLink[];
}

export function createDataProduct(
    dataProduct: CreateDataProductPayload
): Promise<SchemaDataProduct> {
    return axios
        .post<SchemaDataProduct>(`${baseUrl}/products`, dataProduct)
        .then(({ data }) => data);
}

export type UpdateDataProductPayload = CreateDataProductPayload;

export function updateDataProduct(
    dataProductId: string,
    payload: UpdateDataProductPayload
): Promise<SchemaDataProduct> {
    return axios
        .put<SchemaDataProduct>(`${baseUrl}/products/${dataProductId}`, payload)
        .then(({ data }) => data);
}

export const patchDataProduct = (dataProductId: string, payload: Partial<SchemaDataProduct>) =>
    axios.patch(`${baseUrl}/products/${dataProductId}`, payload).then(({ data }) => {
        emitDataProductEvent({
            type: 'patched',
            payload: { dataProductId, ...payload },
        });
        return data;
    });

export async function deleteDataProduct(dataProductId: string): Promise<string> {
    const response = await axios.post(`${baseUrl}/products/${dataProductId}/workflows/delete`);

    return response.headers.location;
}

export async function refreshDataProductView(
    dataProductId: string,
    viewName: string
): Promise<string> {
    const response = await axios.post(
        `${baseUrl}/products/${dataProductId}/workflows/materializedViews/${viewName}/refresh`
    );
    return response.headers.location;
}

export function saveTagsForDataProduct(dataProductId: string, tags: TagValue[]): Promise<Tag[]> {
    return axios.put<Tag[]>(`${baseUrl}/tags/products/${dataProductId}`, tags).then((response) => {
        const tags = response.data;
        emitDataProductEvent({
            type: 'tagsAssigned',
            payload: { dataProductId, tags },
        });
        return tags;
    });
}

export function getAllTags$(): Observable<Tag[]> {
    return fromPromise(axios.get<Tag[]>(`${baseUrl}/tags`).then((response) => response.data));
}

export function getTags$(schemaDataProductId: string): Observable<Tag[]> {
    return fromPromise(
        axios
            .get<Tag[]>(`${baseUrl}/tags/products/${schemaDataProductId}`)
            .then((response) => response.data)
    );
}

export async function publishDataProduct(dataProductId: string): Promise<string> {
    const response = await axios.post(`${baseUrl}/products/${dataProductId}/workflows/publish`);

    return response.headers.location;
}

export function getWorkflowStatus(statusUrl: string): Promise<DataProductSepTaskState> {
    return axios.get<DataProductSepTaskState>(statusUrl).then(({ data }) => data);
}

async function getSupportedCatalogs(): Promise<Catalog[]> {
    return axios.get<Catalog[]>(`${baseUrl}/catalogs`).then((response) => response.data);
}

export function getSupportedCatalogs$(): Observable<Catalog[]> {
    return fromPromise(getSupportedCatalogs());
}

export function validateDataProductName(
    name: string,
    dataProductId: string | undefined
): Observable<DataProductNameValidation> {
    const promise = axios
        .post<DataProductNameValidation>(`${baseUrl}/products/nameValidation`, {
            nameToValidate: name,
            dataProductId,
        })
        .then((response) => response.data);
    return fromPromise(promise);
}

export async function reassignDomain(dataProductIds: string[], newDomainId: string): Promise<void> {
    return axios.post(`${baseUrl}/products/reassignDomain`, {
        dataProductIds,
        newDomainId,
    });
}

export async function manageBookmark(
    dataProductId: string,
    shouldBookmark: boolean
): Promise<void> {
    return (
        shouldBookmark
            ? axios.put(`${baseUrl}/bookmarks/${dataProductId}`, {
                  dataProductId,
              })
            : axios.delete(`${baseUrl}/bookmarks/${dataProductId}`)
    ).then(() =>
        emitDataProductEvent({
            type: 'bookmarkChanged',
            payload: {
                dataProductId,
                isBookmarked: shouldBookmark,
            },
        })
    );
}

export async function getSampleQueries(dataProductId: string): Promise<SampleQueryDto[]> {
    return axios
        .get<SampleQueryDto[]>(`${baseUrl}/products/${dataProductId}/sampleQueries`)
        .then((response) => response.data);
}

export async function saveSampleQueries(
    dataProductId: string,
    queries: SampleQueryDto[]
): Promise<void> {
    return axios.put(`${baseUrl}/products/${dataProductId}/sampleQueries`, queries);
}

export async function rateDataProduct(dataProductId: string, rating: number): Promise<void> {
    return axios
        .put(`${baseUrl}/userRatings/${dataProductId}`, {
            rating,
        })
        .then(() =>
            emitDataProductEvent({
                type: 'ratingChanged',
                payload: {
                    dataProductId,
                    rating,
                },
            })
        );
}

export async function deleteDataProductRating(dataProductId: string): Promise<void> {
    return axios.delete(`${baseUrl}/userRatings/${dataProductId}`).then(() =>
        emitDataProductEvent({
            type: 'ratingChanged',
            payload: {
                dataProductId,
                rating: null,
            },
        })
    );
}

export function getUserDataProductPermissions(
    dataProductId: string
): Promise<UserEntityPermissions> {
    return axios
        .get<UserEntityPermissions>(`${baseUrl}/userPermissions/products/${dataProductId}`)
        .then((response) => response.data);
}

export const getUserDataProductPermissions$ = (
    dataProductId: string
): Observable<UserEntityPermissions> => fromPromise(getUserDataProductPermissions(dataProductId));

export interface DataProductStatistics {
    dataProductId: string;
    sevenDayQueryCount: number;
    thirtyDayQueryCount: number;
    sevenDayUserCount: number;
    thirtyDayUserCount: number;
    updatedAt: number;
}

const getDataProductStatistics = (dataProductId: string): Promise<DataProductStatistics | null> =>
    axios.get(`${baseUrl}/products/${dataProductId}/statistics`).then(({ data }) => data);

export const getDataProductStatistics$ = (
    dataProductId: string
): Observable<DataProductStatistics | null> => fromPromise(getDataProductStatistics(dataProductId));

const getDataProductIds = (searchOptions: ListDataProductsSearchOptions): Promise<string[]> =>
    axios
        .get(`${baseUrl}/products/ids`, {
            params: searchOptions,
            paramsSerializer: (params) => {
                return `searchOptions=${encodeURIComponent(JSON.stringify(params))}`;
            },
        })
        .then(({ data }) => data);

export const getDataProductIds$ = (
    searchOptions: ListDataProductsSearchOptions
): Observable<string[]> => fromPromise(getDataProductIds(searchOptions));

export const cloneDataProduct = (
    dataProductId: string,
    newName: string,
    catalogName: string,
    dataDomainId: string
): Promise<SchemaDataProduct> =>
    axios
        .post(`${baseUrl}/products/${dataProductId}/clone`, {
            newName,
            catalogName,
            dataDomainId,
        })
        .then(({ data }) => data);

export const cloneDataset = (dataProductId: string, view: View, type: DatasetType) => {
    const isMaterialized = type === DatasetType.MATERIALIZED_VIEW;
    const url = `${baseUrl}/products/${dataProductId}/${
        isMaterialized ? 'materializedViews' : 'views'
    }`;
    return axios.post(url, view).then(({ data }) => {
        emitDataProductEvent({
            type: 'datasetCloned',
            payload: { dataProductId, view },
        });
        return data;
    });
};
