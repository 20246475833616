/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useEffect, useState } from 'react';
import { isValid } from 'date-fns';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextField from '@mui/material/TextField';

interface TimePicker24hProps {
    value: Date;
    onValueChange: (newValue: Date, valid: boolean) => void;
    defaultTime: Date;
    timeDisabled: boolean;
}

export const TimePicker24h: React.FunctionComponent<TimePicker24hProps> = ({
    defaultTime,
    onValueChange,
    timeDisabled,
    value,
}) => {
    const [time, setTime] = useState<Date | null>(value);

    const timeValid = (date: Date | null): date is Date => {
        return date !== null && isValid(date);
    };

    useEffect(() => {
        if (!timeDisabled) {
            const newTime = time || defaultTime;
            onValueChange(newTime, timeValid(newTime));
        }
    }, [timeDisabled]);

    const handleTimeChange = (date: Date | null) => {
        if (!timeValid(date)) {
            onValueChange(defaultTime, false);
        } else {
            onValueChange(date, true);
        }
        setTime(date);
    };

    return (
        <TimePicker<Date | null>
            mask="__:__"
            value={timeDisabled ? null : time}
            onChange={(date) => handleTimeChange(date)}
            disabled={timeDisabled}
            ampm={false}
            ampmInClock={false}
            renderInput={(props) => (
                <TextField {...props} variant={'outlined'} fullWidth margin="dense" />
            )}
        />
    );
};
