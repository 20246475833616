/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React, { ReactElement } from 'react';
import { DateTimePicker } from '../../../components/date/DateTimePicker';
import { format } from 'date-fns';
import { localeDateFormat, localeTimeFormat } from '../../../components/date/dateFormats';
import { FilterCriteria } from '../../../api/biac/audit/auditLogApi';
import { TextFilter } from '../../query/history/TextFilter';

export type FilterBy = keyof FilterCriteria;
type FilterValue = FilterCriteria[FilterBy];
type SetFilterValue = (newValue: FilterValue) => void;

export interface Filter {
    label: string;
    component: (
        value: FilterValue,
        setValue: SetFilterValue,
        onValidityChange: (valid: boolean) => void
    ) => ReactElement<HTMLElement>;
    formatValue: (value: FilterValue) => string;
}

const startDateFilter = {
    label: 'After date',
    component: (
        value: FilterValue,
        setValue: SetFilterValue,
        onValidityChange: (valid: boolean) => void
    ): ReactElement<HTMLElement> => (
        <DateTimePicker
            key="start_date"
            placeholder="Pick start date"
            value={value as Date}
            setValue={setValue}
            defaultTime="day-start"
            onValidityChange={onValidityChange}
        />
    ),
    formatValue: (value: FilterValue): string =>
        format(value as Date, `${localeDateFormat} ${localeTimeFormat}`),
};

const endDateFilter = {
    label: 'Before date',
    component: (
        value: FilterValue,
        setValue: SetFilterValue,
        onValidityChange: (valid: boolean) => void
    ): ReactElement<HTMLElement> => (
        <DateTimePicker
            key="end_date"
            placeholder="Pick end date"
            value={value as Date}
            setValue={setValue}
            defaultTime="day-end"
            onValidityChange={onValidityChange}
        />
    ),
    formatValue: (value: FilterValue): string =>
        format(value as Date, `${localeDateFormat} ${localeTimeFormat}`),
};

const queryIdFilter = {
    label: 'Query ID',
    component: (value: FilterValue, setValue: SetFilterValue): ReactElement<HTMLElement> => (
        <TextFilter
            key="query_id"
            value={value as string}
            setValue={setValue}
            placeholder="Search by query ID"
        />
    ),
    formatValue: (value: FilterValue): string => value as string,
};

export const changeLogFilters = new Map<FilterBy, Filter>([
    ['startDate', startDateFilter],
    ['endDate', endDateFilter],
    ['queryId', queryIdFilter],
]);

export const accessLogFilters = new Map<FilterBy, Filter>([
    ['startDate', startDateFilter],
    ['endDate', endDateFilter],
    ['queryId', queryIdFilter],
]);
