/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

export const saveFile = (fileName: string, data: Blob): void => {
    const link = document.createElement('a');
    const file = new Blob([data]);
    link.href = URL.createObjectURL(file);
    link.setAttribute('download', fileName);
    link.click();
};
