/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { IOBaseChart } from './IOBaseChart';
import React from 'react';

type Base = { inputBytes: number };

interface InputDataBaseChartProps<T extends Base> {
    inputData: T[] | 'loading' | 'error' | undefined;
    labelKey: keyof T;
    labelFormatter?: (inputLabel: string) => string;
}

export const InputDataBaseChart = <T extends Base>({
    inputData,
    labelKey,
    labelFormatter,
}: InputDataBaseChartProps<T>): JSX.Element => {
    return (
        <IOBaseChart
            data={inputData}
            labelKey={labelKey}
            valueKey={'inputBytes'}
            tooltipText="Data read"
            labelFormatter={labelFormatter}
        />
    );
};
