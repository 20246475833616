/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { FunctionComponent, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { createUseStyles } from 'react-jss';
import { ContentErrorProps, SimpleDialog } from './SimpleDialog';

interface DeleteDialogProps {
    onConfirm: () => Promise<void>;
    isOpen: boolean;
    close: () => void;
    entityType: string;
    entityHumanName: string;
    hideTextConfirmationInput?: boolean;
}

export const DeleteDialog: FunctionComponent<DeleteDialogProps> = ({
    isOpen,
    close,
    onConfirm,
    entityType,
    entityHumanName,
    hideTextConfirmationInput = false,
}: DeleteDialogProps) => {
    const [isConfirmTextProper, setIsConfirmTextProper] = useState<boolean>(false);
    useEffect(() => {
        if (isOpen) {
            setIsConfirmTextProper(false);
        }
    }, [isOpen]);
    return (
        <SimpleDialog<DeleteDialogContentProps>
            isOpen={isOpen}
            close={close}
            title={`Delete ${entityType}?`}
            confirmButtonLabel="Yes, delete"
            cancelButtonLabel="No, do not delete"
            onConfirm={onConfirm}
            isConfirmationButtonDisabled={hideTextConfirmationInput ? false : !isConfirmTextProper}
            fullWidth={true}
            contentProps={{
                entityType,
                entityHumanName,
                setIsConfirmTextProper,
                hideTextConfirmationInput,
            }}
            Content={DeleteDialogContent}
        />
    );
};

const useStyles = createUseStyles({
    input: {
        margin: '3rem 0 1rem 0',
    },
});

interface DeleteDialogContentProps {
    entityType: string;
    entityHumanName: string;
    setIsConfirmTextProper: (isProper: boolean) => void;
    hideTextConfirmationInput?: boolean;
}

const DeleteDialogContent = ({
    entityHumanName,
    entityType,
    setIsConfirmTextProper,
    error,
    hideTextConfirmationInput,
}: DeleteDialogContentProps & ContentErrorProps) => {
    const styles = useStyles();
    return (
        <>
            <div>
                Are you sure you want to delete the {entityType} <b>{entityHumanName}</b>?
            </div>
            <div>
                <b>This action cannot be undone.</b>
            </div>

            {error}
            {!hideTextConfirmationInput ? (
                <TextField
                    className={styles.input}
                    fullWidth
                    required
                    variant="outlined"
                    label="Type DELETE to confirm"
                    size="small"
                    onChange={(e) => {
                        setIsConfirmTextProper(e.target.value === 'DELETE');
                    }}
                />
            ) : null}
        </>
    );
};
