/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React, { useCallback, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import { PrivilegesList } from './role-details/PrivilegesList';
import { useTabStyles } from '../../themes/useTabStyles';
import Tabs from '@mui/material/Tabs';
import { ColumnMasksList } from './role-details/ColumnMasksList';
import { RowFiltersList } from './role-details/RowFiltersList';
import { DefaultTab } from '../../components/tab/DefaultTab';

interface RoleDetailsProps {
    currentRoleName: string;
    roleId: number;
    roleName: string;
    canAddPrivileges: boolean;

    hidePrivilegesSidePanel(): void;
}

type TabState = 'privileges' | 'column_masks' | 'row_filters';

export const RoleDetails: React.FunctionComponent<RoleDetailsProps> = ({
    currentRoleName,
    roleId,
    roleName,
    canAddPrivileges,
    hidePrivilegesSidePanel,
}) => {
    const history = useHistory();
    const goToAddPrivileges = useCallback(() => {
        history.push(`/roles/${roleId}`);
    }, [history, roleId]);

    const [currentTab, setCurrentTab] = useState<TabState>('privileges');
    const tabClasses = useTabStyles();
    return (
        <>
            <Grid
                container
                wrap="nowrap"
                direction="column"
                rowSpacing={2}
                style={{ width: '480px', margin: '0.5rem 1rem', padding: '0 0.625rem' }}>
                <Grid item>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <span style={{ fontSize: '1.125rem', fontWeight: 600 }}>
                            Details: {roleName}
                        </span>
                        <IconButton
                            size="small"
                            onClick={hidePrivilegesSidePanel}
                            style={{ padding: '6px' }}>
                            <FontAwesomeIcon icon={faTimes} style={{ width: '1rem' }} />
                        </IconButton>
                    </Box>
                </Grid>
                {canAddPrivileges && (
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={goToAddPrivileges}>
                            Add privileges
                        </Button>
                    </Grid>
                )}
                <Grid item></Grid>
                <Tabs
                    onChange={(e, value) => setCurrentTab(value)}
                    value={currentTab}
                    className={tabClasses.tabs}
                    indicatorColor="primary"
                    textColor="primary">
                    <DefaultTab value="privileges" label="Privileges" />
                    <DefaultTab value="column_masks" label="Column masks" />
                    <DefaultTab value="row_filters" label="Row filters" />
                </Tabs>
                {currentTab === 'privileges' && (
                    <PrivilegesList currentRoleName={currentRoleName} roleId={roleId} />
                )}
                {currentTab === 'column_masks' && (
                    <ColumnMasksList currentRoleName={currentRoleName} roleId={roleId} />
                )}
                {currentTab === 'row_filters' && (
                    <RowFiltersList currentRoleName={currentRoleName} roleId={roleId} />
                )}

                <Grid item>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={hidePrivilegesSidePanel}
                        style={{ marginTop: '0.5rem' }}>
                        Close
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};
