/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { ReactNode } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { DataProductWizard } from '../DataProductWizard';
import { RouteComponentProps } from 'react-router';
import { DataProductsEnabledBiac } from '../../../components/biac/DataProductsEnabledBiac';
import { useRoleHeader } from '../../biac/CurrentRoleContext';
import { DataProductDetails } from '../details/DataProductDetails';
import { DataProductDashboard } from '../DataProductDashboard';
import {
    dataProductManagementPath,
    dataProductDetailsPath,
    dataProductModuleRootPath,
    dataProductCreatePath,
    dataProductUpdatePath,
    domainManagementPath,
} from './dataProductRoutingUtils';
import { UserGlobalPermissionContextProvider } from '../permission/UserGlobalPermissionContext';
import { DataProductPermissionContextProvider } from '../permission/DataProductPermissionContext';
import { DomainList } from '../domain/DomainList';

const renderDataProductDetails = ({
    match,
}: RouteComponentProps<{ dataProductId?: string }>): ReactNode => {
    if (!match.params.dataProductId) {
        return null;
    }

    return (
        <DataProductPermissionContextProvider dataProductId={match.params.dataProductId}>
            <DataProductDetails dataProductId={match.params.dataProductId} />
        </DataProductPermissionContextProvider>
    );
};

const renderDataProductEdit = ({
    match,
}: RouteComponentProps<{ dataProductId?: string }>): JSX.Element => {
    return <DataProductWizard dataProductId={match.params.dataProductId} />;
};

const renderDataProductCreate = ({
    match,
}: RouteComponentProps<{ dataProductId?: string }>): JSX.Element => {
    return <DataProductWizard dataProductId={match.params.dataProductId} />;
};

export const DataProductRouting: React.FunctionComponent = () => {
    const roleHeader = useRoleHeader();

    return (
        <DataProductsEnabledBiac key={roleHeader}>
            <UserGlobalPermissionContextProvider>
                <Switch>
                    <Route exact={true} path={dataProductModuleRootPath}>
                        <Redirect to={dataProductManagementPath} />
                    </Route>
                    <Route
                        exact={true}
                        path={dataProductManagementPath}
                        component={DataProductDashboard}
                    />
                    <Route path={dataProductUpdatePath} render={renderDataProductEdit} />
                    <Route path={dataProductCreatePath} render={renderDataProductCreate} />
                    <Route path={dataProductDetailsPath} render={renderDataProductDetails} />
                    <Route exact={true} path={domainManagementPath}>
                        <DomainList />
                    </Route>
                </Switch>
            </UserGlobalPermissionContextProvider>
        </DataProductsEnabledBiac>
    );
};
