/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import './main/web/styles.css';
import React from 'react';
import { render } from 'react-dom';
import { App } from './main/web/app/App';

import './index.css';

const rootEl = document.getElementById('root');

render(<App />, rootEl);
