/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { configureStore, combineReducers, ThunkAction, Action } from '@reduxjs/toolkit';
import queryHistorySlice from '../features/query/history/queryHistorySlice';
import reportSlice from '../features/query/report/reportSlice';
import clusterHistorySlice from '../features/cluster/clusterHistorySlice';
import usageMetricsSlice from '../features/usage/usageMetricsSlice';

const rootReducer = combineReducers({
    queryHistory: queryHistorySlice,
    clusterHistory: clusterHistorySlice,
    usageMetrics: usageMetricsSlice,
    report: reportSlice,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: false,
        }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
export type AppDispatch = typeof store.dispatch;
