/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface ChartTooltipContainerProps {
    children: React.ReactNode;
    style?: React.CSSProperties;
}

export const TooltipContext = React.createContext<{
    containerId: string;
} | null>(null);

export const ChartTooltipContainer: React.FunctionComponent<ChartTooltipContainerProps> = ({
    children,
    style = {},
}: ChartTooltipContainerProps) => {
    const [tooltipContainerId] = useState(uuidv4());

    return (
        <div style={style}>
            <div id={tooltipContainerId} />
            <TooltipContext.Provider value={{ containerId: tooltipContainerId }}>
                {children}
            </TooltipContext.Provider>
        </div>
    );
};
