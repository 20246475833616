/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import { createUseStyles } from 'react-jss';
import { paletteSwitch } from '../../../themes/palette';
import { Theme } from '@mui/material/styles';

export const addPrivilegesStyles = createUseStyles((theme: Theme) => ({
    questionInfo: {
        fontSize: '0.875rem',
        lineHeight: '24px',
        letterSpacing: '0.14px',
        alignItems: 'center',
        marginBottom: '0.875rem',
    },
    chip: {
        color: paletteSwitch(theme).nebulaNavy700,
        backgroundColor: paletteSwitch(theme).nebulaNavy50,
        padding: '4px 8px 4px 8px',
        maxWidth: '40%',
        margin: '2px',
    },
    chipCloseIcon: {
        color: `${paletteSwitch(theme).nebulaNavy700}!important`,
        fontSize: '1rem!important',
    },
    autoCompletePaper: {
        borderRadius: '4px 4px 0 0!important',
    },
    popperComponentPaper: {
        padding: '1rem!important',
        borderRadius: '0 0 4px 4px!important',
    },
    optionLabel: {
        textOverflow: 'ellipsis',
        maxWidth: '400px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    toolTipOptionDescription: {
        fontSize: '0.875rem',
        color: paletteSwitch(theme).black54,
    },
    optionDescription: {
        fontSize: '0.875rem',
        color: paletteSwitch(theme).black54,
        textOverflow: 'ellipsis',
        maxWidth: '400px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    maxWidthChip: {
        maxWidth: '80%!important',
    },
    cautionText: {
        color: paletteSwitch(theme).black54,
    },
}));
