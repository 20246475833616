/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';
import { createUseStyles } from 'react-jss';
import { SchemaDataProduct } from '../../../api/dataProduct/dataProductApi';
import { formatDate } from '../../../utils/formatDateInterval';

interface MetadataProps {
    dataProduct: SchemaDataProduct;
}

const useStyles = createUseStyles({
    label: {
        fontSize: '1rem',
        paddingRight: '1rem',
    },
    value: {
        wordBreak: 'break-word',
        fontSize: '1rem',
    },
});

export const Metadata: React.FunctionComponent<MetadataProps> = ({ dataProduct }) => {
    const classes = useStyles();

    return (
        <table>
            <tbody>
                <tr>
                    <td className={classes.label}>Created on:</td>
                    <td className={classes.value}>
                        {formatDate(dataProduct.createdAt, undefined, fallbackDisplayValue)}
                    </td>
                </tr>
                <tr>
                    <td className={classes.label}>Created by:</td>
                    <td className={classes.value}>{dataProduct.createdBy}</td>
                </tr>
                <tr>
                    <td className={classes.label}>Last updated on:</td>
                    <td className={classes.value}>
                        {formatDate(dataProduct.updatedAt, undefined, fallbackDisplayValue)}
                    </td>
                </tr>
                <tr>
                    <td className={classes.label}>Last updated by:</td>
                    <td className={classes.value}>
                        {dataProduct.updatedBy || fallbackDisplayValue}
                    </td>
                </tr>
                {dataProduct.accessMetadata &&
                !dataProduct.accessMetadata?.lastQueriedAt &&
                !dataProduct.accessMetadata?.lastQueriedBy ? (
                    <tr>
                        <td colSpan={2} className={classes.value}>
                            Not queried yet
                        </td>
                    </tr>
                ) : (
                    <>
                        <tr>
                            <td className={classes.label}>Last queried:</td>
                            <td className={classes.value}>
                                {formatDate(
                                    dataProduct.accessMetadata?.lastQueriedAt,
                                    undefined,
                                    fallbackDisplayValue
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.label}>Last queried by:</td>
                            <td className={classes.value}>
                                {dataProduct.accessMetadata?.lastQueriedBy || fallbackDisplayValue}
                            </td>
                        </tr>
                    </>
                )}
            </tbody>
        </table>
    );
};

const fallbackDisplayValue = 'N/A';
