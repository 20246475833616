/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React, { useCallback, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import sortBy from 'lodash/sortBy';
import { EmptyOrValue } from '../../../../utils/value';
import { useQueryClient } from '../../useQueryClient';
import { ChooserErrorIcon } from '../../grants/ChooserErrorIcon';
import { addPrivilegesStyles } from '../add-privileges-styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { EntityCategory } from '../../../../api/biac/biacApi';

interface CatalogInfo {
    label: string;
    catalogName: string | null;
    description: string | null;
}

interface CatalogSelectorProps {
    autocomplete: boolean;
    disabled: boolean;
    shouldDisplaySelectAllCheckbox: boolean;
    value: EmptyOrValue<string | null>;
    handleChange: (value: EmptyOrValue<string | null>) => void;
    entityCategory: EntityCategory;
}

export const CatalogSelector: React.FunctionComponent<CatalogSelectorProps> = ({
    autocomplete,
    disabled,
    value,
    handleChange,
    entityCategory,
    shouldDisplaySelectAllCheckbox,
}) => {
    const classes = addPrivilegesStyles();
    const [selectedCatalogName, setSelectedCatalogName] = useState<CatalogInfo | null>(null);
    const allCatalogsChecked = !value.empty && value.value === null;
    const [catalogs, setCatalogs] = useState<CatalogInfo[]>([]);
    const { busy, error, execute, reset } = useQueryClient((data) => {
        const catalogs = sortBy(
            data.map<CatalogInfo>(([catalogName]) => ({
                label: catalogName as string,
                catalogName: catalogName as string,
                description: null,
            })),
            ({ label }) => label
        );
        setCatalogs(catalogs);
    });

    useEffect(() => {
        setSelectedCatalogName(null);
    }, [entityCategory, disabled]);

    useEffect(() => {
        if (!disabled && autocomplete) {
            execute('SHOW CATALOGS');
            return () => {
                setCatalogs([]);
                reset();
            };
        }
    }, [disabled]);

    useEffect(() => {
        return () => handleChange({ empty: true, value: null });
    }, []);

    const optionLabel = useCallback((option: CatalogInfo) => option.label, []);

    const optionSelected = useCallback(
        (option: CatalogInfo, value: CatalogInfo) => option.catalogName === value.catalogName,
        []
    );

    const renderOption = useCallback(
        (props: React.HTMLAttributes<HTMLLIElement>, option: CatalogInfo) => (
            <li {...props}>
                <Box display="flex" width="100%">
                    <Box>{option.label}</Box>
                    <Box className={classes.cautionText} ml="auto">
                        {option.description}
                    </Box>
                </Box>
            </li>
        ),
        []
    );

    const onChange = useCallback((event: React.SyntheticEvent, value: CatalogInfo | null) => {
        setSelectedCatalogName(value);
        if (!value) {
            handleChange({
                empty: true,
                value: null,
            });
        } else {
            handleChange({
                empty: !value,
                value: value.catalogName,
            });
        }
    }, []);

    const renderPopupIcon = useCallback(() => {
        if (busy) {
            return <CircularProgress size={20} />;
        } else if (error) {
            return (
                <div>
                    <ChooserErrorIcon title="Loading catalogs failed" />
                </div>
            );
        } else {
            return <ArrowDropDownOutlinedIcon />;
        }
    }, [busy, error]);

    const handleCheckStar = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            handleChange({
                empty: false,
                value: null,
            });
            setSelectedCatalogName(null);
        } else {
            handleChange({
                empty: true,
                value: null,
            });
        }
    }, []);

    return (
        <Grid pl={1}>
            <Typography variant="h5">Which catalog would you like to select?</Typography>
            <Typography className={classes.questionInfo}>
                Use the dropdown to select a specific catalog.{' '}
                {shouldDisplaySelectAllCheckbox && (
                    <>
                        To select all currently defined catalogs, as well as any catalog defined in
                        future, check the <b>All catalogs</b> box instead.
                    </>
                )}
            </Typography>
            {shouldDisplaySelectAllCheckbox && (
                <Box mb={1.5}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={allCatalogsChecked}
                                onChange={handleCheckStar}
                                id="select-star"
                            />
                        }
                        label={
                            <span>
                                All catalogs{' '}
                                <span className={classes.cautionText}>(use with caution)</span>
                            </span>
                        }
                    />
                </Box>
            )}
            <Box mb={3}>
                <Autocomplete
                    loading={busy}
                    disabled={disabled || allCatalogsChecked || !catalogs.length}
                    openOnFocus
                    options={catalogs}
                    getOptionLabel={optionLabel}
                    isOptionEqualToValue={optionSelected}
                    renderOption={renderOption}
                    value={selectedCatalogName}
                    onChange={onChange}
                    popupIcon={renderPopupIcon()}
                    renderInput={(params) => <TextField {...params} label="Catalog" />}
                />
            </Box>
        </Grid>
    );
};
