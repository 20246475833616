/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { createUseStyles } from 'react-jss';
import { Tooltip } from '../tooltip/Tooltip';

const useStyles = createUseStyles({
    truncated: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    longText: { maxHeight: '18.75rem', overflow: 'auto' },
    shortText: { maxHeight: 'fit-content' },
});

interface EllipsisProps {
    children: React.ReactNode;
    tooltip?: string;
    tooltipDisableInteractive?: boolean;
    className?: string;
}

export const Ellipsis: React.FunctionComponent<EllipsisProps> = ({
    children,
    tooltip,
    tooltipDisableInteractive = true,
    className,
}) => {
    const classes = useStyles();
    const [showTooltip, setShowTooltip] = useState(false);

    const onMouseEnter = useCallback(({ currentTarget }) => {
        const offsetWidth = currentTarget.offsetWidth;
        const scrollWidth = currentTarget.scrollWidth;
        if (offsetWidth < scrollWidth) {
            setShowTooltip(true);
        } else {
            setShowTooltip(false);
        }
    }, []);

    const content = (
        <div className={clsx(classes.truncated, className)} onMouseEnter={onMouseEnter}>
            {children}
        </div>
    );

    if (showTooltip) {
        return (
            <Tooltip title={tooltip || children} disableInteractive={tooltipDisableInteractive}>
                {content}
            </Tooltip>
        );
    } else {
        return content;
    }
};
