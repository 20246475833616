/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import Chart, { ChartData, ChartPoint } from 'chart.js';
import head from 'lodash/head';
import { LegendProvider } from './legendProvider';

export const singleDatasetLegendProvider: LegendProvider = (
    data: Chart.ChartData | 'loading' | 'error' | undefined,
    hiddenLegendItems: string[],
    toggleLegendItemVisibility: (label: string) => () => void,
    legendValueFormatter: (
        value: number | null | undefined | number[] | ChartPoint
    ) => string | null
) => {
    if (!data || typeof data === 'string') {
        return {
            filterData: (_) => _,
            getLegendItems: () => [],
        };
    }

    const getLegendItems = () => {
        return (
            data.labels?.map((label, index) => {
                return {
                    id: label.toString(),
                    label: label.toString(),
                    color: (head(data.datasets)?.backgroundColor as Array<string>)[index],
                    enabled: {
                        value: !hiddenLegendItems.includes(label.toString()),
                        setValue: toggleLegendItemVisibility(label.toString()),
                    },
                    value: legendValueFormatter(
                        (head(data.datasets)?.data as Array<number>)[index]
                    ),
                };
            }) ?? []
        );
    };

    const filterData = (data: ChartData): ChartData => {
        const removeIndexes =
            data.labels?.flatMap((label, index) => {
                if (hiddenLegendItems.includes(label.toString())) {
                    return [index];
                }
                return [];
            }) ?? [];

        return {
            labels: data.labels?.filter((_, i) => !removeIndexes.includes(i)),
            datasets: data.datasets?.map((dataset) => ({
                data: (dataset.data as Array<number>).filter((_, i) => !removeIndexes.includes(i)),
                backgroundColor: (dataset.backgroundColor as Array<string>).filter(
                    (_, i) => !removeIndexes.includes(i)
                ),
            })),
        };
    };

    return {
        getLegendItems,
        filterData,
    };
};
