/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import TableCell from '@mui/material/TableCell';
import React from 'react';
import clsx from 'clsx';
import { Ellipsis } from '../ellipsis/Ellipsis';
import { useTableStyles } from './useTableStyles';

interface TableBodyCellProps {
    widthInPercentage?: number;
    minWidthInPercentage?: number;
    dataValue?: string;
    children?: React.ReactNode;
    className?: string;
    onClick?: () => void;
}
const MIN_SCREEN_WIDTH = 600;
export const TableBodyCell = ({
    widthInPercentage,
    minWidthInPercentage,
    dataValue,
    children = <></>,
    className,
    onClick,
}: TableBodyCellProps) => {
    const tableClasses = useTableStyles();

    return (
        <TableCell
            data-value={dataValue}
            onClick={onClick}
            className={clsx(tableClasses.cell, className)}
            classes={{
                root: tableClasses.cellRoot,
            }}
            style={{
                maxWidth: `calc((100vw - ${MIN_SCREEN_WIDTH}px)*${widthInPercentage}/100)`,
                minWidth: `calc((100vw - ${MIN_SCREEN_WIDTH}px)*${minWidthInPercentage}/100)`,
            }}>
            {typeof children == 'string' ? <Ellipsis>{children}</Ellipsis> : children}
        </TableCell>
    );
};
