/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { createUseStyles } from 'react-jss';
import { faPeopleArrows } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { paletteSwitch } from '../../themes/palette';
import { Theme } from '@mui/material/styles';

const useStyles = createUseStyles((theme: Theme) => ({
    root: {
        fontSize: '1rem',
        fontWeight: 400,
        padding: '2rem 1.5rem 1.5rem 1.5rem',
        marginBottom: '1rem',
        maxWidth: 'none',
    },
    header: {
        fontSize: '1.25rem',
        marginBottom: '2rem',
        letterSpacing: 1,
    },
    headerRoleName: {
        fontWeight: 600,
    },
    description: {
        lineHeight: '1.5rem',
    },
    actionDescription: {
        fontFamily: 'montserrat, sans-serif',
        lineHeight: '1.6875rem',
        color: paletteSwitch(theme).nebulaNavy,
        fontWeight: 600,
    },
    switchRoleIcon: {
        marginRight: '0.5rem',
    },
}));

interface DefaultRoleNotificationProps {
    currentRoleName: string;
    hasDefaultRole: boolean;
    hide: boolean;
}

export const DefaultRoleNotification: React.FunctionComponent<DefaultRoleNotificationProps> = ({
    currentRoleName,
    hasDefaultRole,
    hide,
    children,
}) => {
    const classes = useStyles();
    const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
    const [state, setState] = useState<'hidden' | 'delayed' | 'visible'>('hidden');

    const handleClose = useCallback(() => {
        setState('hidden');
    }, []);

    useEffect(() => {
        switch (state) {
            case 'delayed':
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                }
                timeoutRef.current = setTimeout(() => {
                    setState('visible');
                }, 1_000);
                return () => {
                    if (timeoutRef.current) {
                        clearTimeout(timeoutRef.current);
                    }
                };
            case 'visible':
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                }
                timeoutRef.current = setTimeout(() => {
                    setState('hidden');
                }, 30_000);
                return () => {
                    if (timeoutRef.current) {
                        clearTimeout(timeoutRef.current);
                    }
                };
        }
    }, [state]);

    useEffect(() => {
        if (hasDefaultRole) {
            setState('delayed');
        }
    }, [hasDefaultRole]);

    useEffect(() => {
        if (hide) {
            setState('hidden');
        }
    }, [hide]);

    return (
        <Tooltip
            open={state === 'visible'}
            onClose={handleClose}
            onOpen={handleClose}
            title={
                <>
                    <div className={classes.header}>
                        Your current role is 
                        <span className={classes.headerRoleName}>{currentRoleName}</span>
                    </div>
                    <div className={classes.description}>
                        To change your current role use an option
                    </div>
                    <div className={classes.actionDescription}>
                        <FontAwesomeIcon icon={faPeopleArrows} className={classes.switchRoleIcon} />
                        Switch role
                    </div>
                </>
            }
            arrow
            placement="bottom"
            disableHoverListener
            disableFocusListener
            disableTouchListener
            TransitionComponent={Zoom}
            TransitionProps={{
                timeout: state === 'visible' ? 700 : 0, // do not show animation on leave
            }}
            classes={{ tooltip: classes.root }}>
            <div>{children}</div>
        </Tooltip>
    );
};
