/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { Expression, getExpressions } from '../../../../../api/biac/biacApi';
import { Persisted } from '../../../../../api/biac/common';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { palette } from '../../../../../themes/palette';
import { v4 as uuidv4 } from 'uuid';
import IconButton from '@mui/material/IconButton';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { CREATE_NEW_ID, initialFields, MaskOrFilterData } from './addition-privileges-constants';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import { useQueryClient } from '../../../useQueryClient';
import CircularProgress from '@mui/material/CircularProgress';
import { ChooserErrorIcon } from '../../../grants/ChooserErrorIcon';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { ColumnOrTableAutoComplete } from './ColumnOrTableAutoComplete';
import { EmptyOrValue } from '../../../../../utils/value';
import { VirtualizedListBoxComponent } from '../../../grants/VirtualizedListBoxComponent';
import { Tooltip } from '../../../../../components/tooltip/Tooltip';
import { addPrivilegesStyles } from '../../add-privileges-styles';

interface RowFilterPrivilegeProps {
    currentRoleName: string;
    catalogName: string | null;
    schemaName: string | null;
    tableName: EmptyOrValue<string | null>;
    handleChange: (newState: MaskOrFilterData[]) => void;
}

const isAllSelected = (value: EmptyOrValue<unknown>) => {
    return !value.empty && value.value === null;
};

export const RowFilterPrivilege: React.FunctionComponent<RowFilterPrivilegeProps> = ({
    currentRoleName,
    catalogName,
    schemaName,
    tableName,
    handleChange,
}) => {
    const [existingRowFilterExpressions, setExistingRowFilterExpressions] = useState<
        Persisted<Expression>[]
    >([]);

    const [selectedRowFilters, setSelectedRowFilters] = useState<MaskOrFilterData[]>([]);

    const [availableTables, setAvailableTables] = useState<string[]>([]);

    const { busy, error, execute, reset } = useQueryClient((data) => {
        const tablesFound = data.map<string>(([tableName]) => tableName as string);
        setAvailableTables(tablesFound);
    });

    const fetchExpressions = useCallback(() => {
        getExpressions(currentRoleName, 'ROW_FILTER')
            .then((expressions) => {
                setExistingRowFilterExpressions(expressions);
            })
            .catch(() => {
                setExistingRowFilterExpressions([]);
            });
    }, []);

    const classes = addPrivilegesStyles();

    useEffect(() => {
        fetchExpressions();
    }, [tableName]);

    useEffect(() => {
        setSelectedRowFilters([]);
        if (catalogName && schemaName && isAllSelected(tableName)) {
            execute(
                `SELECT table_name, table_type FROM "${catalogName}".information_schema.tables WHERE table_schema = '${schemaName}' ORDER by table_type, table_name`
            );
            return () => {
                setAvailableTables([]);
                reset();
            };
        } else {
            setAvailableTables(tableName.value ? [tableName.value] : []);
        }
    }, [tableName]);

    const handleSelectedTables = useCallback(
        (event: React.SyntheticEvent, value: string | null, selectedRowFilterId: string) => {
            setSelectedRowFilters((currentRowFilters) =>
                currentRowFilters.map((currentRowFilter) =>
                    currentRowFilter.id === selectedRowFilterId
                        ? {
                              ...currentRowFilter,
                              selectedValue: value ? value : '',
                          }
                        : currentRowFilter
                )
            );
        },
        []
    );

    const handleRowFilterChange = useCallback(
        (
            event: React.SyntheticEvent,
            value: Persisted<Expression> | null,
            selectedRowFilterId: string
        ) => {
            const selectedRowFilter = value ? value.id : null;
            const columnMask = existingRowFilterExpressions?.find(
                (columnMaskExpression) => columnMaskExpression.id === selectedRowFilter
            );
            setSelectedRowFilters((currentRowFilters) => {
                return currentRowFilters.map((currentRowFilter) => {
                    if (currentRowFilter.id === selectedRowFilterId) {
                        if (selectedRowFilter != null && selectedRowFilter != CREATE_NEW_ID) {
                            return {
                                ...currentRowFilter,
                                oldExpression: {
                                    name:
                                        columnMask && columnMask.object
                                            ? columnMask.object.name
                                            : '',
                                    sqlExpression:
                                        columnMask && columnMask.object
                                            ? columnMask.object.expression
                                            : '',
                                },
                                expressionId: selectedRowFilter,
                                newExpression: null,
                            };
                        } else if (selectedRowFilter === null) {
                            return {
                                ...currentRowFilter,
                                expressionId: null,
                                oldExpression: null,
                                newExpression: null,
                            };
                        } else {
                            return {
                                ...currentRowFilter,
                                oldExpression: null,
                                expressionId: selectedRowFilter,
                            };
                        }
                    } else {
                        return currentRowFilter;
                    }
                });
            });
        },
        [existingRowFilterExpressions]
    );

    const validateFilterName = useCallback(
        (filterName: string | undefined, names: (string | undefined)[]): string | null => {
            if (filterName) {
                if (filterName.startsWith(' ') || filterName.endsWith(' ')) {
                    return 'Filter name cannot start or end with space';
                } else if (
                    existingRowFilterExpressions.some(
                        (rowFilterExpression) =>
                            rowFilterExpression.object.name.toLowerCase() ===
                            filterName.toLowerCase()
                    ) ||
                    names.filter((name) => name === filterName).length > 1
                ) {
                    return 'Filter name already exists';
                } else {
                    return null;
                }
            }
            return null;
        },
        [existingRowFilterExpressions]
    );

    const handleRowFilterNewName = useCallback(
        (
            event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
            selectedRowFilterId: string
        ) => {
            const currentValue = event.target.value;
            setSelectedRowFilters((currentRowFilters) => {
                const filterNames = currentRowFilters
                    .map((filterName) =>
                        filterName.id === selectedRowFilterId
                            ? currentValue
                            : filterName.newExpression?.name.toLowerCase()
                    )
                    .filter(Boolean);

                return currentRowFilters.map((currentRowFilter) => {
                    if (currentRowFilter.id === selectedRowFilterId) {
                        return {
                            ...currentRowFilter,
                            newExpression: {
                                name: currentValue,
                                expression: currentRowFilter.newExpression?.expression
                                    ? currentRowFilter.newExpression.expression
                                    : '',
                            },
                            errorText: validateFilterName(currentValue, filterNames),
                        };
                    } else {
                        return {
                            ...currentRowFilter,
                            errorText: validateFilterName(
                                currentRowFilter.newExpression?.name,
                                filterNames
                            ),
                        };
                    }
                });
            });
        },
        [validateFilterName]
    );

    const handleRowFilterNewExpression = useCallback(
        (
            event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
            selectedRowFilterId: string
        ) => {
            setSelectedRowFilters((currentRowFilters) =>
                currentRowFilters.map((currentRowFilter) =>
                    currentRowFilter.id === selectedRowFilterId
                        ? {
                              ...currentRowFilter,
                              newExpression: {
                                  name: currentRowFilter.newExpression?.name
                                      ? currentRowFilter.newExpression.name
                                      : '',
                                  expression: event.target.value,
                              },
                          }
                        : currentRowFilter
                )
            );
        },
        []
    );

    const deleteSelectedRowFilter = useCallback((deletingColumnMask: MaskOrFilterData) => {
        setSelectedRowFilters((currentRowFilters) =>
            currentRowFilters.filter(
                (currentRowFilter) => currentRowFilter.id != deletingColumnMask.id
            )
        );
    }, []);

    const renderOption = useCallback(
        (props: React.HTMLAttributes<HTMLLIElement>, option: Persisted<Expression>) => {
            return (
                <li {...props} key={option.id}>
                    {option.id === CREATE_NEW_ID ? (
                        <div style={{ color: palette.nebulaNavy300 }}>{option.object.name}</div>
                    ) : (
                        <div>
                            <div className={classes.optionLabel}>{option.object.name}</div>
                            {/*
              {option.object.description && (
                <div className={classes.optionDescription}>
                  {option.object.description}
                </div>
              )}
*/}
                        </div>
                    )}
                </li>
            );
        },
        []
    );

    useEffect(() => {
        handleChange(selectedRowFilters);
    }, [selectedRowFilters]);

    const renderPopupIcon = useCallback(() => {
        if (busy) {
            return <CircularProgress size={20} />;
        } else if (error) {
            return (
                <div>
                    <ChooserErrorIcon title="Loading tables and views failed" />
                </div>
            );
        } else {
            return <ArrowDropDownOutlinedIcon />;
        }
    }, [busy, error]);

    return (
        <>
            <Typography variant="subtitle2" mb={1}>
                Row Filtering
            </Typography>
            <Typography variant="body2" width="34rem">
                Operator selection determines filter behavior. The “equals” operator filters in rows
                matching the entered value, “not equals” filters out rows matching the entered
                value.
            </Typography>
            {selectedRowFilters.map((selectedRowFilter) => (
                <Box mt={3} mb={1} display="flex" key={selectedRowFilter.id}>
                    <Box pr={1}>
                        <ColumnOrTableAutoComplete
                            handleChange={handleSelectedTables}
                            options={availableTables}
                            selectedData={selectedRowFilter}
                            icon={renderPopupIcon()}
                            label={'Select table'}
                        />
                    </Box>
                    {selectedRowFilter.selectedValue && (
                        <>
                            <Box pr={1}>
                                <Autocomplete
                                    renderInput={(params) => (
                                        <TextField {...params} label="Select filter" required />
                                    )}
                                    style={{ width: '16rem' }}
                                    getOptionLabel={(option) => option.object.name}
                                    renderOption={renderOption}
                                    options={existingRowFilterExpressions}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    onChange={(event, value) =>
                                        handleRowFilterChange(event, value, selectedRowFilter.id)
                                    }
                                    ListboxComponent={VirtualizedListBoxComponent}
                                />
                            </Box>
                            {selectedRowFilter.oldExpression && (
                                <Tooltip
                                    title={selectedRowFilter.oldExpression.sqlExpression}
                                    placement="right">
                                    <Box pr={1}>
                                        <TextField
                                            value={selectedRowFilter.oldExpression.sqlExpression}
                                            label="SQL Expression"
                                            disabled
                                            style={{ width: '20rem' }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <LockOutlinedIcon color="disabled" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Box>
                                </Tooltip>
                            )}
                            {selectedRowFilter.expressionId === CREATE_NEW_ID && (
                                <>
                                    <Box pr={1}>
                                        <TextField
                                            label="Enter name for new row filter"
                                            required
                                            style={{ width: '16rem' }}
                                            value={
                                                selectedRowFilter.newExpression
                                                    ? selectedRowFilter.newExpression.name
                                                    : ''
                                            }
                                            onChange={(e) =>
                                                handleRowFilterNewName(e, selectedRowFilter.id)
                                            }
                                            inputProps={{ maxLength: 40 }}
                                            error={!!selectedRowFilter.errorText}
                                            helperText={selectedRowFilter.errorText}></TextField>
                                    </Box>
                                    <Box pr={1}>
                                        <TextField
                                            label="Enter custom SQL"
                                            required
                                            style={{ width: '16rem' }}
                                            value={
                                                selectedRowFilter.newExpression
                                                    ? selectedRowFilter.newExpression.expression
                                                    : ''
                                            }
                                            onChange={(e) =>
                                                handleRowFilterNewExpression(
                                                    e,
                                                    selectedRowFilter.id
                                                )
                                            }></TextField>
                                    </Box>
                                </>
                            )}
                        </>
                    )}
                    <Box>
                        <IconButton onClick={() => deleteSelectedRowFilter(selectedRowFilter)}>
                            <ClearRoundedIcon color="error" />
                        </IconButton>
                    </Box>
                </Box>
            ))}
            <Box mt={1}>
                <Button
                    variant="text"
                    onClick={() => {
                        setSelectedRowFilters((rowFilters) => [
                            ...rowFilters,
                            {
                                id: uuidv4(),
                                ...initialFields,
                            } as MaskOrFilterData,
                        ]);
                    }}>
                    <AddIcon /> &nbsp;Add row filter
                </Button>
            </Box>
        </>
    );
};
