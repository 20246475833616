/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import Box from '@mui/material/Box';
import { ChartLegendItem, ChartLegendItemProps } from './ChartLegendItem';
import React from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';

const useStyles = createUseStyles({
    legendItem: {
        margin: '4px 0 4px 6%',
    },
    legend: {
        paddingTop: '12px',
        flexGrow: 1,
        minWidth: 0,
    },
});

interface ColumnarChartLegendProps {
    items: (ChartLegendItemProps & { id: string })[];
    bulletSize?: string;
    className?: string;
    labelFormatter?: (inputLabel: string) => string;
}

export const ColumnarChartLegend: React.FunctionComponent<ColumnarChartLegendProps> = ({
    items,
    bulletSize,
    className = '',
    labelFormatter = (input: string) => input,
}) => {
    const classes = useStyles();

    if (!items || items.length === 0) {
        return null;
    }

    return (
        <Box display="flex" flexDirection="column" className={clsx(className, classes.legend)}>
            {items.map(({ id, color, value, label, enabled }) => (
                <ChartLegendItem
                    key={id}
                    color={color}
                    label={labelFormatter(label)}
                    value={value}
                    enabled={enabled}
                    className={classes.legendItem}
                    bulletSize={bulletSize}
                />
            ))}
        </Box>
    );
};
