/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import React, { useEffect, useState } from 'react';
import { useTabStyles } from '../../themes/useTabStyles';
import { uiFeatureEnabled } from '../../app/ConfigContextProvider';
import { UiFeatures } from '../../api/configApi';
import { License } from '../../features/license/License';
import { CustomizeLogin } from './customizeLogin/CustomizeLogin';
import { Spinner } from '../../components/spinner/Spinner';
import { DefaultTab } from '../../components/tab/DefaultTab';

enum SettingsTab {
    LICENSE = 'license',
    CUSTOMIZELOGIN = 'customize-login',
}

export const availableTabs = [
    {
        label: 'License',
        id: SettingsTab.LICENSE,
        isVisible: () => uiFeatureEnabled(UiFeatures.SETTINGS_LICENSE),
        Content: () => <License />,
    },
    {
        label: 'Customize login',
        id: SettingsTab.CUSTOMIZELOGIN,
        isVisible: () => uiFeatureEnabled(UiFeatures.SETTINGS_LOGIN),
        Content: () => <CustomizeLogin />,
    },
];

export const SettingsTabs: React.FunctionComponent = () => {
    const tabClasses = useTabStyles();
    const [activeTab, setActiveTab] = useState<SettingsTab>(SettingsTab.LICENSE);
    const visibleTabs = availableTabs.filter((tab) => tab.isVisible());
    const selectedTab = visibleTabs.find((tab) => tab.id === activeTab);

    useEffect(() => {
        //Display first available tab if the selected tab for current role is not available for another role
        if (visibleTabs.length > 0 && !selectedTab) {
            setActiveTab(visibleTabs[0].id);
        }
    }, [selectedTab, visibleTabs]);

    if (!selectedTab) {
        return <Spinner position={'relative'} />;
    }

    return (
        <>
            <Box className={tabClasses.tabs}>
                <Tabs
                    value={activeTab}
                    onChange={(_, newValue): void => setActiveTab(newValue)}
                    indicatorColor="primary"
                    textColor="primary">
                    {visibleTabs.map((visibleTab) => (
                        <DefaultTab
                            key={visibleTab.id}
                            value={visibleTab.id}
                            label={visibleTab.label}
                        />
                    ))}
                </Tabs>
            </Box>
            {selectedTab.isVisible() && <selectedTab.Content />}
        </>
    );
};
