/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import {
    CreateDataProductPayload,
    DataProductForm,
    DatasetType,
    MaterializedView,
    UpdateDataProductPayload,
    View,
} from '../../../api/dataProduct/dataProductApi';

export const defaultRefreshIntervalMinutes = 60;
export function mapToCreateDataProductPayload({
    name,
    catalogName,
    summary,
    description,
    dataDomainId,
    owners,
    relevantLinks,
    datasets,
}: DataProductForm): CreateDataProductPayload {
    const materializedViews: MaterializedView[] = datasets
        .filter((dataset) => dataset.type === DatasetType.MATERIALIZED_VIEW)
        .map((dataset) => {
            return {
                ...dataset,
                definitionProperties: {
                    refresh_interval: dataset.refreshInterval
                        ? dataset.refreshInterval + 'm'
                        : `${defaultRefreshIntervalMinutes}m`,
                    incremental_column: dataset.incrementalColumn
                        ? dataset.incrementalColumn
                        : undefined,
                },
            };
        });
    // put everything else as views
    const views: View[] = datasets.filter(
        (dataset) => dataset.type !== DatasetType.MATERIALIZED_VIEW
    );
    return {
        name,
        catalogName,
        summary,
        description,
        dataDomainId,
        views,
        materializedViews,
        owners,
        relevantLinks,
    };
}

export const mapToUpdateDataProductPayload: (form: DataProductForm) => UpdateDataProductPayload =
    mapToCreateDataProductPayload;
