/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { ReactElement } from 'react';
import { UiFeatures } from '../../../api/configApi';
import { uiFeatureEnabled } from '../../../app/ConfigContextProvider';
import { Redirect, Route } from 'react-router-dom';

interface UIFeatureRouteProps {
    feature: UiFeatures;
    children: ReactElement;
    exact?: boolean;
    path: string | string[];
}

export const UIFeatureRoute: React.FunctionComponent<UIFeatureRouteProps> = ({
    feature,
    exact,
    path,
    children,
}) => {
    const isFeaturePresent = uiFeatureEnabled(feature);
    const pathName = uiFeatureEnabled(UiFeatures.OVERVIEW) ? '/' : '/roles'; // reroute if user doesn't have overview feature access
    return (
        <Route
            exact={exact}
            path={path}
            render={({ location }) => {
                if (!isFeaturePresent) {
                    return (
                        <Redirect
                            to={{
                                pathname: pathName,
                                state: { from: location },
                            }}
                        />
                    );
                }
                return children;
            }}
        />
    );
};
