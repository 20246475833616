/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useMemo } from 'react';
import Button from '@mui/material/Button';
import { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

interface LoadingButtonProps extends ButtonProps {
    loading?: boolean;
}

export const LoadingButton: React.FunctionComponent<LoadingButtonProps> = ({
    loading,
    disabled,
    startIcon,
    children,
    ...props
}) => {
    const startingIcon = useMemo(() => {
        return loading ? <CircularProgress color="secondary" size={20} /> : startIcon;
    }, [loading]);

    return (
        <Button {...props} disabled={disabled || loading} startIcon={startingIcon}>
            {children}
        </Button>
    );
};
