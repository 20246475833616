/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { ChangeEvent, useContext } from 'react';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import { dropDownItems, mainRadioButtons } from './add-privilege-constants';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import capitalize from 'lodash/capitalize';
import InputLabel from '@mui/material/InputLabel';
import { Select } from '../../../components/select/Select';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { ConfigContext } from '../../../app/ConfigContextProvider';

interface RadioAndDropdownGroupProps {
    radioSelection: string;
    handleRadioSelection(event: ChangeEvent<{ value: string }>): void;
    otherPrivileges: string;
    handleOtherPrivileges(event: SelectChangeEvent<unknown>): void;
}

export const RadioAndDropdownGroup: React.FunctionComponent<RadioAndDropdownGroupProps> = ({
    radioSelection,
    handleRadioSelection,
    otherPrivileges,
    handleOtherPrivileges,
}) => {
    const clusterInfo = useContext(ConfigContext);
    const mainRadioButtonEntries = clusterInfo?.dataProductEnabled
        ? mainRadioButtons
        : mainRadioButtons.filter((it) => it !== 'data_products');

    return (
        <>
            <Typography variant="h5" mb={1}>
                What would you like to modify privileges for?
            </Typography>
            <FormControl>
                <RadioGroup
                    row
                    aria-labelledby="radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={handleRadioSelection}
                    value={radioSelection}>
                    {mainRadioButtonEntries.map((entity) => {
                        return (
                            <FormControlLabel
                                key={entity}
                                value={entity}
                                control={<Radio />}
                                label={capitalize(entity.replace(/_/g, ' '))}
                            />
                        );
                    })}
                    {radioSelection === 'other' && (
                        <FormControl>
                            <InputLabel id="select-label">Specify</InputLabel>
                            <Select
                                labelId="select-label"
                                label="Specify"
                                value={otherPrivileges}
                                onChange={handleOtherPrivileges}
                                style={{ minWidth: '15rem' }}
                                autoWidth
                                displayEmpty
                                disabled={radioSelection != 'other'}>
                                {dropDownItems.map((entityCategory) => {
                                    return (
                                        <MenuItem key={entityCategory} value={entityCategory}>
                                            {capitalize(entityCategory.replace(/_/g, ' '))}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    )}
                </RadioGroup>
            </FormControl>
        </>
    );
};
