/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { maximumFreeTextLength as maxDescriptionLength } from '../util/formUtils';

export function mapDomainDescription(description: string): string {
    return description.substr(0, maxDescriptionLength);
}

export function createDescriptionHelperText(description = ''): string {
    return `${maxDescriptionLength - description.length} character remaining`;
}
