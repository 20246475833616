/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { createUseStyles } from 'react-jss';
import TextField from '@mui/material/TextField';
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { DataProductOwner } from '../../../api/dataProduct/dataProductApi';
import { paletteSwitch } from '../../../themes/palette';
import { useDialogOpenState } from '../../../components/dialog/useDialogOpenState';
import { SimpleDialog } from '../../../components/dialog/SimpleDialog';
import { SimpleTable } from '../components/SimpleTable';
import { OwnerAction } from './publishFormReducer';
import { maximumTitleLength } from '../util/formUtils';
import Typography from '@mui/material/Typography';
import { useTextInputValueChange } from '../../../utils/useTextInputValueChange';
import { Theme } from '@mui/material/styles';

interface DataProductOwnersProps {
    owners?: DataProductOwner[];
    ownerDispatch: Dispatch<OwnerAction>;
}

const useStyles = createUseStyles((theme: Theme) => ({
    helperText: {
        fontSize: '1rem',
        color: paletteSwitch(theme).black,
    },
    tableNameColumn: {
        width: '30%',
    },
}));

export const DataProductOwners: React.FunctionComponent<DataProductOwnersProps> = ({
    owners,
    ownerDispatch,
}) => {
    const classes = useStyles();
    return (
        <>
            <Typography variant={'h5'}>Assign data product owner</Typography>
            {(!owners || owners.length === 0) && (
                <div className={classes.helperText}>
                    You must assign at least one data product owner.
                </div>
            )}
            <Grid container>
                <Grid item xs={12}>
                    {owners && (
                        <SimpleTable<DataProductOwner>
                            rows={owners}
                            columns={['name', 'email']}
                            idGenerator={({ email }) => email}
                            deleteRow={({ email }) => ownerDispatch({ type: 'deleteOwner', email })}
                            classes={{
                                columns: {
                                    name: classes.tableNameColumn,
                                },
                            }}
                        />
                    )}
                </Grid>
            </Grid>
            <AddOwner
                onAdd={(owner) => {
                    ownerDispatch({ type: 'addOwner', owner });
                    return Promise.resolve();
                }}
            />
        </>
    );
};

const useAddOwnerClasses = createUseStyles({
    addButton: {
        marginBottom: '2rem',
    },
    addIcon: {
        marginRight: '0.625rem',
    },
});

interface AddOwnerProps {
    onAdd: (owner: DataProductOwner) => Promise<void>;
}

export const AddOwner = ({ onAdd }: AddOwnerProps) => {
    const internalClasses = useAddOwnerClasses();
    const { isOpen, close, open } = useDialogOpenState();
    return (
        <>
            <Button
                variant="text"
                color="primary"
                className={internalClasses.addButton}
                onClick={open}>
                <FontAwesomeIcon icon={faPlus} className={internalClasses.addIcon} />
                Add owner
            </Button>
            {isOpen && <AddOwnerDialog onAdd={onAdd} isOpen={isOpen} close={close} />}
        </>
    );
};

interface AddOwnerDialogProps {
    isOpen: boolean;
    close: () => void;
    onAdd: (owner: DataProductOwner) => Promise<void>;
}

const AddOwnerDialog = ({ isOpen, close, onAdd }: AddOwnerDialogProps) => {
    const [currentOwner, setCurrentOwner] = useState<DataProductOwner>({
        name: '',
        email: '',
    });

    const handleConfirm = useCallback(() => onAdd(currentOwner), [currentOwner, onAdd]);

    const isConfirmationButtonDisabled =
        !isNameValid(currentOwner.name) || !isEmailValid(currentOwner.email);

    return (
        <SimpleDialog<AddOwnerFormProps>
            isOpen={isOpen}
            close={close}
            title="Assign data product owner"
            confirmButtonLabel="Add owner"
            onConfirm={handleConfirm}
            isConfirmationButtonDisabled={isConfirmationButtonDisabled}
            fullWidth={true}
            contentProps={{
                name: currentOwner.name,
                email: currentOwner.email,
                dispatchOwner: setCurrentOwner,
            }}
            Content={AddOwnerForm}
        />
    );
};

interface AddOwnerFormProps extends DataProductOwner {
    dispatchOwner: Dispatch<SetStateAction<DataProductOwner>>;
}

const AddOwnerForm = ({ name, email, dispatchOwner }: AddOwnerFormProps) => {
    const handleNameChange = useTextInputValueChange(
        (newName) =>
            dispatchOwner((prevState) => ({
                ...prevState,
                name: newName,
            })),
        [dispatchOwner]
    );
    const handleEmailChange = useTextInputValueChange(
        (newEmail) =>
            dispatchOwner((prevState) => ({
                ...prevState,
                email: newEmail,
            })),
        [dispatchOwner]
    );
    return (
        <>
            <TextField
                value={name}
                onChange={handleNameChange}
                autoFocus
                margin="dense"
                id="name"
                fullWidth
                variant="outlined"
                label="Name"
                required
                inputProps={{ maxLength: maximumTitleLength }}
            />
            <TextField
                value={email}
                onChange={handleEmailChange}
                margin="dense"
                id="email"
                type="email"
                fullWidth
                variant="outlined"
                label="Email"
                required
            />
        </>
    );
};

function isNameValid(name: string) {
    return name.trim().length > 0;
}
function isEmailValid(email: string) {
    return email.trim().length > 0;
}
