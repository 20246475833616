/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';
import { mergeLines, prepareLineChartData } from '../../../utils/lineChart';
import { defaultChartPalette } from '../../../themes/palette';
import { ChartWrapper } from '../../../components/chart/ChartWrapper';
import { HighlightAreaProps, TimeLineChart } from '../../../components/chart/TimeLineChart';
import { formatDateInterval } from '../../../utils/formatDateInterval';

interface ClusterSizeChartProps {
    size:
        | Array<{
              sampleIntervalStart: string;
              sampleIntervalEnd: string;
              cpus: number;
              nodes: number;
          }>
        | 'loading'
        | 'error';
    height: number;
    highlightArea?: HighlightAreaProps;
}

export const ClusterSizeChart: React.FunctionComponent<ClusterSizeChartProps> = ({
    size,
    height,
    highlightArea,
}) => {
    const cpusLabel = 'CPUs';
    const cpus = prepareLineChartData(cpusLabel, size, 'sampleIntervalStart', ({ cpus }) => cpus, {
        fill: '-1',
        color: defaultChartPalette[0],
    });

    const workers = prepareLineChartData(
        'Running workers',
        size,
        'sampleIntervalStart',
        ({ nodes }) => nodes,
        { fill: 'origin', color: defaultChartPalette[2] }
    );

    const data = mergeLines([workers, cpus]);

    return (
        <ChartWrapper
            data={data}
            title="Cluster size"
            height={height}
            legend={{
                type: 'dataset',
                variant: 'inline',
                valueFormatter: () => null,
                hiddenItems: [cpusLabel],
            }}>
            {(chartData): React.ReactNode => (
                <TimeLineChart
                    data={chartData}
                    height={height}
                    yTicksLimit={3}
                    xTicksLimit={5}
                    xTicksMaxRotation={0}
                    yMax={2}
                    tooltipDateFormat={formatDateInterval(
                        size,
                        ({ sampleIntervalStart }) => sampleIntervalStart,
                        ({ sampleIntervalEnd }) => sampleIntervalEnd
                    )}
                    labelWidth={50}
                    highlightArea={highlightArea}
                />
            )}
        </ChartWrapper>
    );
};
