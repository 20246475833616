/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import { palette } from '../../../themes/palette';

export const defaultScale = 5;
export const defaultColor = palette.warning;
export const defaultStrokeColor = palette.hilite;
export const unratedColor = palette.nebulaNavy100;
export const unratedStrokeColor = palette.customBlue;

export function mapScoreToPartialScores(score: number, scale: number = defaultScale): number[] {
    if (score > scale || score < 0) {
        console.warn(
            `Improper score, can not properly calculate score: ${score} for scale: ${scale}`
        );
    }
    return new Array(scale).fill(0).map((_, index) => Math.min(1, Math.max(score - index, 0)));
}

export function generateSingleScorePartColor(
    scoreRepresentation: number,
    hoveredScore: number | undefined,
    customColor: string
): string {
    return hoveredScore
        ? scoreRepresentation <= hoveredScore
            ? defaultColor
            : customColor
        : customColor;
}

export function generateSingleScorePartValue(
    scoreRepresentation: number,
    hoveredScore: number | undefined,
    partialScore: number
): number {
    return hoveredScore ? (scoreRepresentation <= hoveredScore ? 1 : 0) : partialScore;
}
