/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { FunctionComponent } from 'react';
import Link from '@mui/material/Link';
import markDownIcon from '../../images/markdown-icon.svg';
import { useDocUrl } from '../../app/ConfigContextProvider';

export const MarkDownIconWithDocsLink: FunctionComponent = () => {
    const docUrl = useDocUrl() + '/data-products/markdown.html';
    return (
        <Link href={docUrl} target={'_blank'}>
            <img src={markDownIcon} alt={'Markdown Icon'} width={24} />
        </Link>
    );
};
