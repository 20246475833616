/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { Loadable } from '../../utils/loadable';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
    getAccessibleRoles,
    getGroupGrantsForRole,
    getRoleGrantsForRole,
    getUserGrantsForRole,
    grantRoleToSubject as grantRoleToSubjectRequest,
    SYSTEM_ROLE_ID,
} from '../../api/biac/biacRolesApi';
import sortBy from 'lodash/sortBy';
import { AttributeSubject } from '../../api/biac/biacApi';

export interface RoleGrant {
    grantId: number;
    subjectKind: AttributeSubject;
    subject: string;
    subjectId: number | null; // available only for roles
    grantOption: boolean;
    canManage: boolean;
}

interface RoleGrants {
    grants: Loadable<RoleGrant[]> | null;
    error: string | null;
    setError(error: string | null): void;
    grantRoleToSubject(
        roleId: number,
        subjectKind: AttributeSubject,
        subject: string,
        adminOption: boolean
    ): Promise<void>;
    reload(): Promise<void>;
}

export const useRoleGrants = (currentRoleName: string, roleId: number): RoleGrants => {
    const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

    const [grants, setGrants] = useState<RoleGrants['grants']>(null);
    const [error, setError] = useState<RoleGrants['error']>(null);

    const showDelayedLoading = useCallback(() => {
        if (timeoutRef.current) {
            return;
        }

        timeoutRef.current = setTimeout(() => {
            setGrants('loading');
            timeoutRef.current = undefined;
        }, 200);
    }, []);

    const clearLoading = useCallback(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = undefined;
        }
    }, []);

    const reload = useCallback(
        (foreground?: boolean) => {
            setError(null);
            return Promise.all([
                getUserGrantsForRole(currentRoleName, roleId),
                getGroupGrantsForRole(currentRoleName, roleId),
                getRoleGrantsForRole(currentRoleName, roleId),
                getAccessibleRoles(currentRoleName),
            ])
                .then(([userGrants, groupGrants, roleGrants, roles]) => {
                    const grants: RoleGrant[] = [
                        ...userGrants.map<RoleGrant>(
                            ({ roleGrant: { id, object: roleGrant }, canManage }) => ({
                                grantId: id,
                                subjectKind: 'user',
                                subject: roleGrant.subjectUser as string,
                                subjectId: null,
                                grantOption: roleGrant.grantOption,
                                canManage,
                            })
                        ),
                        ...groupGrants.map<RoleGrant>(
                            ({ roleGrant: { id, object: roleGrant }, canManage }) => ({
                                grantId: id,
                                subjectKind: 'group',
                                subject: roleGrant.subjectGroup as string,
                                subjectId: null,
                                grantOption: roleGrant.grantOption,
                                canManage,
                            })
                        ),
                        ...roleGrants
                            // TODO: filter out this in a backend
                            .filter(
                                ({ roleGrant: { object: roleGrant } }) =>
                                    roleGrant.subjectRoleId !== SYSTEM_ROLE_ID
                            )
                            .map<RoleGrant>(
                                ({ roleGrant: { id, object: roleGrant }, canManage }) => {
                                    const role = roles.find(
                                        ({ role: { id } }) =>
                                            id === (roleGrant.subjectRoleId as number)
                                    );
                                    return {
                                        grantId: id,
                                        subjectKind: 'role',
                                        subject: role?.role.object.name ?? 'unknown',
                                        subjectId: role?.role.id ?? NaN,
                                        grantOption: roleGrant.grantOption,
                                        canManage,
                                    };
                                }
                            ),
                    ];
                    setGrants(sortBy(grants, ({ subject, subjectKind }) => [subjectKind, subject]));
                })
                .catch((e) => {
                    if (foreground) {
                        setGrants('error');
                    }
                    setError(e.message);
                });
        },
        [roleId, currentRoleName]
    );

    const grantRoleToSubject = useCallback(
        (
            roleId: number,
            subjectKind: AttributeSubject,
            subject: string,
            adminOption: boolean
        ): Promise<void> =>
            grantRoleToSubjectRequest(currentRoleName, {
                roleId,
                subject: {
                    attributes: [{ key: subjectKind, value: subject }],
                },
                adminOption,
            }).then(() => {
                //
            }),
        [currentRoleName]
    );

    useEffect(() => {
        showDelayedLoading();
        reload(true).then(() => clearLoading());
    }, [reload]);

    return {
        grants,
        error,
        setError,
        grantRoleToSubject,
        reload,
    };
};
