/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import { TrinoResponseColumns, TrinoResponseData } from '@starburstdata/query-editor';
import { TrinoClient, optionalCallbacks } from '@starburstdata/query-editor';
import noop from 'lodash/noop';
import { DependencyList, useMemo, useRef } from 'react';
import { statementSubmissionUrl } from '../../ide/QueryEditor';

interface DataProductTrinoClientCallbacks {
    onReceiveColumnMetadata: (columns: TrinoResponseColumns) => void;
    onReceiveData?: (data: TrinoResponseData) => void;
    onError: (error: string) => void;
    onBusy: (busy: boolean) => void;
}
/**
 * Specialized Trino client for data products that only fetches the first page of
 * query results (i.e., does not follow nextUri until the query finishes, only
 * until at least 10 rows have been fetched)
 *
 * @param callbacks Callbacks for receiving column metadata, first page of data and errors
 * @returns TrinoClient object
 */
export const useDataProductTrinoClient = (
    {
        onReceiveColumnMetadata,
        onReceiveData = noop,
        onError,
        onBusy,
    }: DataProductTrinoClientCallbacks,
    deps?: DependencyList
): {
    execute: (queryText: string, source?: string) => void;
    cancel: () => Promise<void>;
} => {
    const loadedData = useRef<TrinoResponseData>([]);
    const fetchMetadataOnly = onReceiveData === noop;

    return useMemo(() => {
        const trinoClient = new TrinoClient();
        const callbacks = optionalCallbacks({
            onColumns: (columns: TrinoResponseColumns) => {
                if (columns) {
                    onReceiveColumnMetadata([...columns]);
                    if (fetchMetadataOnly) {
                        trinoClient.cancel();
                    }
                }
            },
            onData: (data: TrinoResponseData) => {
                if (!fetchMetadataOnly && data) {
                    loadedData.current = [...loadedData.current, ...data];
                }
            },
            onFinish: () => {
                onBusy(false);
                onReceiveData(loadedData.current);
                loadedData.current = [];
            },
            onError: (error) => {
                onBusy(false);
                onError(error);
                loadedData.current = [];
            },
        });

        return {
            execute: (queryText: string, source?: string) => {
                onBusy(true);
                loadedData.current = [];

                trinoClient.execute(queryText, callbacks, {
                    submissionUrl: statementSubmissionUrl,
                    source: source ?? 'dataproduct',
                    limit: ROW_LIMIT,
                });
            },
            cancel: () => trinoClient.cancel(),
        };
    }, deps);
};

export const createPreviewQuery = (
    catalogName: string,
    schemaName: string,
    viewName: string
): string => {
    return `SELECT * FROM \"${catalogName}\".\"${schemaName}\".\"${viewName}\" LIMIT ${ROW_LIMIT}`;
};

export const ROW_LIMIT = 10;
