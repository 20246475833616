/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import ada from '../../images/Ada.jpg';
import bear from '../../images/Bear.jpg';
import max from '../../images/Max.jpg';
import sam from '../../images/Sam.jpg';
import { paletteSwitch } from '../../themes/palette';
import { createUseStyles } from 'react-jss';
import { Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import React, { useCallback } from 'react';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import shuffle from 'lodash/shuffle';
import Grid from '@mui/material/Grid';

const useLocalStyles = createUseStyles((theme: Theme) => ({
    mainGrid: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'row',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
        overflow: 'auto',
    },
    errorMsg: {
        fontFamily: 'montserrat, sans-serif',
        fontSize: '32px',
        fontWeight: 600,
        lineHeight: '48px',
        letterSpacing: '1px',
        color: paletteSwitch(theme).nebulaNavy,
        paddingBottom: '1.5rem',
    },
    leftPane: {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        display: 'flex',
        width: '40%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    rightPane: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        width: '60%',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    rightPanelContent: {
        fontFamily: 'montserrat, sans-serif',
        alignItems: 'center',
        fontSize: '24px',
        lineHeight: '36px',
        textAlign: 'center',
        marginBottom: '29px',
        color: paletteSwitch(theme).nebulaNavy,
    },
    attribution: {
        position: 'absolute',
        bottom: 0,
        marginLeft: '40%',
        padding: '0 0 1rem 1rem',
    },
}));

interface ErrorScreenProps {
    errorCode: number | string;
    errorMsg?: string;
    errorDescription?: string;
    contentMaxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

const errorImages = [
    {
        image: ada,
        name: 'Ada',
        age: 9,
    },
    {
        image: bear,
        name: 'Bear',
        age: 6,
    },
    {
        image: max,
        name: 'Max',
        age: 5,
    },
    {
        image: sam,
        name: 'Sam',
        age: 7,
    },
];

export const ErrorScreen: React.FunctionComponent<ErrorScreenProps> = ({
    errorCode,
    errorMsg,
    errorDescription,
    contentMaxWidth = 'sm',
}) => {
    const localClasses = useLocalStyles();
    const history = useHistory();
    const goToOverview = useCallback(() => {
        history.push('/');
    }, [history]);
    const errorImage = shuffle(errorImages)[0];

    return (
        <Box className={localClasses.mainGrid}>
            <Box
                className={localClasses.leftPane}
                style={{ backgroundImage: `url(${errorImage.image})` }}></Box>
            <Box className={localClasses.rightPane}>
                <Container maxWidth={contentMaxWidth}>
                    <Box className={localClasses.rightPanelContent}>
                        <Typography fontSize="128px">{errorCode}</Typography>
                        <Typography className={localClasses.errorMsg}>{errorMsg}</Typography>
                        <Typography mb={2}>
                            <b>Sorry about that!&nbsp;</b>
                            {errorDescription}
                        </Typography>
                        <Button variant={'contained'} onClick={goToOverview}>
                            Go to the Overview
                        </Button>
                    </Box>
                </Container>
            </Box>
            <Grid className={localClasses.attribution}>
                Error message by Starburst kid {errorImage.name}, age {errorImage.age}.
            </Grid>
        </Box>
    );
};
