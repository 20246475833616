/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useRef } from 'react';
import { Subject } from 'rxjs';

interface AlignedChartsProps {
    children: React.ReactElement;
}

interface AlignedChartsState {
    activeIndex: number;
    activeChartId: string;
}

interface AlignedChartsContextState {
    onChartHover: Subject<AlignedChartsState | null>;
}

export const AlignedChartsContext = React.createContext<AlignedChartsContextState | null>(null);

export const AlignedCharts: React.FunctionComponent<AlignedChartsProps> = ({ children }) => {
    const onChartHover = useRef<Subject<AlignedChartsState | null>>(new Subject());

    return (
        <AlignedChartsContext.Provider value={{ onChartHover: onChartHover.current }}>
            {children}
        </AlignedChartsContext.Provider>
    );
};
