/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';

import {
    baseUrl,
    reassignDomain as reassignProductDomain,
    SimpleDataProduct,
} from '../dataProductApi';
import { emitDataDomainEvent } from './dataDomainEvent';
import { UserEntityPermissions } from '../user/userPermissionApi';
import axios from 'axios';

const dataDomainBaseUrl = `${baseUrl}/domains`;

export interface CreateDataDomainPayload {
    name: string;
    description: string;
    schemaLocation?: string;
    assignedDataProducts: SimpleDataProduct[];
}

export interface DataDomain {
    id: string;
    name: string;
    description?: string;
    schemaLocation?: string;
    createdBy?: string;
    createdAt?: string;
    updatedAt?: string;
    assignedDataProducts: SimpleDataProduct[];
}

export async function getDomains(): Promise<DataDomain[]> {
    return axios.get<DataDomain[]>(dataDomainBaseUrl).then((response) => response.data);
}

export function getDomains$(): Observable<DataDomain[]> {
    return fromPromise(getDomains());
}

export function getDomain$(dataDomainId: string): Observable<DataDomain> {
    return fromPromise(
        axios
            .get<DataDomain>(`${dataDomainBaseUrl}/${dataDomainId}`)
            .then((response) => response.data)
    );
}

export async function createDataDomain(domain: CreateDataDomainPayload): Promise<void> {
    return axios.post(dataDomainBaseUrl, domain).then(() =>
        emitDataDomainEvent({
            type: 'dataDomainCreated',
            payload: domain,
        })
    );
}

export async function deleteDataDomain(domainId: string): Promise<void> {
    return axios.delete(`${dataDomainBaseUrl}/${domainId}`).then(() =>
        emitDataDomainEvent({
            type: 'dataDomainDeleted',
            payload: {
                id: domainId,
            },
        })
    );
}

export async function updateDataDomain(domain: DataDomain): Promise<void> {
    return axios.put(`${dataDomainBaseUrl}/${domain.id}`, domain).then(() =>
        emitDataDomainEvent({
            type: 'dataDomainChanged',
            payload: domain,
        })
    );
}

export async function reassignDomain(dataProductIds: string[], newDomainId: string): Promise<void> {
    return reassignProductDomain(dataProductIds, newDomainId).then(() =>
        emitDataDomainEvent({
            type: 'dataDomainAssigned',
            payload: {
                id: newDomainId,
                productIds: dataProductIds,
            },
        })
    );
}

export function getUserDataDomainPermissions(dataDomainId: string): Promise<UserEntityPermissions> {
    return axios
        .get<UserEntityPermissions>(`${baseUrl}/userPermissions/domains/${dataDomainId}`)
        .then((response) => response.data);
}

export const getUserDataDomainPermissions$ = (
    dataDomainId: string
): Observable<UserEntityPermissions> => fromPromise(getUserDataDomainPermissions(dataDomainId));
