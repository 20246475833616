/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import {
    defaultColor,
    defaultScale,
    defaultStrokeColor,
    generateSingleScorePartColor,
    generateSingleScorePartValue,
    mapScoreToPartialScores,
} from './RatingService';
import { SingleScorePart } from './SingleScorePart';

const useStyles = createUseStyles({
    scoreContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    scorePart: {
        cursor: 'pointer',
        fontSize: '3rem',
        marginRight: '0.5rem',
        '&:hover svg': {
            fontSize: '3.375rem',
        },
    },
});

export interface RatingProps {
    score: number;
    onChange: (newScore: number) => void;
    onHoverScore: (hoveredScore: number | undefined) => void;
    scale?: number;
    color?: string;
    strokeColor?: string;
    error?: ReactNode;
}

export const Rating: FunctionComponent<RatingProps> = ({
    score,
    onChange,
    onHoverScore,
    error,
    scale = defaultScale,
    color = defaultColor,
    strokeColor = defaultStrokeColor,
}: RatingProps) => {
    const [scaleScores, setScaleScores] = useState<number[]>([]);
    const styles = useStyles();
    useEffect(() => {
        setScaleScores(mapScoreToPartialScores(score, scale));
    }, [score, scale]);

    const [hoveredScore, setHoveredScore] = useState<number>();
    useEffect(() => {
        onHoverScore(hoveredScore);
    }, [hoveredScore]);

    return (
        <>
            <div className={styles.scoreContainer}>
                {scaleScores.map((partialScore, index) => (
                    <SingleScorePart
                        className={styles.scorePart}
                        key={index}
                        color={generateSingleScorePartColor(index + 1, hoveredScore, color)}
                        strokeColor={strokeColor}
                        score={generateSingleScorePartValue(index + 1, hoveredScore, partialScore)}
                        onClick={() => onChange(index + 1)}
                        onMouseEnter={() => setHoveredScore(index + 1)}
                        onMouseLeave={() => setHoveredScore(undefined)}
                    />
                ))}
            </div>
            {error}
        </>
    );
};
