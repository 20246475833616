/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import axios, { AxiosError } from 'axios';
import { baseUrl } from './api';
import {
    addDays,
    addMonths,
    addYears,
    endOfDay,
    endOfMonth,
    endOfYear,
    formatISO,
    min,
    startOfDay,
    startOfMonth,
    startOfYear,
} from 'date-fns';
import { UsageMetricsCriteria } from '../features/usage/usageMetricsSlice';

export const minValidDate = new Date(2010, 0, 1);

export type AggregateUsageMetrics = {
    dateRangeStart: string;
    dateRangeEnd: string;
    cpuTimeSeconds: number;
    wallTimeSeconds: number;
    averageDailyUsage: number;
    clusterRestarts: number;
    minCores: number;
    maxCores: number;
    cpuUtilization: number;
};

type SignedClusterUsage = {
    nodeEnvironment: string;
    instanceId: string;
    startTime: number;
    nodeVersion: string;
    time: Date;
    cumulativeCpuTime: string;
    cumulativeAvailableCpuTime: string;
    cores: number;
    activeNodes: number;
    signature: string;
};

export type UsageMetricsPreviewResponse = Array<SignedClusterUsage>;

export class UsageMetricsErrorResponse {
    private readonly errorMessage: string | undefined;

    constructor(private error: AxiosError<string | undefined>) {
        const response = this.error.response?.data;
        if (response) {
            const lines = response.split(/\n/);
            const match = lines[0].match(/^.*InsightsConfigurationException:?\s*(.*)$/);
            if (match) {
                this.errorMessage = match[1];
            }
        }
    }

    public isConfigurationError(): boolean {
        return this.errorMessage !== undefined;
    }

    public getConfigurationErrorMessage(): string {
        return this.errorMessage ?? '';
    }
}

export type CumulativeCpuUsageResponse = Array<{
    intervalStart: string;
    intervalEnd: string;
    cpuUsage: number;
}>;

export interface UsageMetricsSearchCriteria {
    startDate: Date;
    endDate: Date;
}

const criteriaForDayOf = (date: Date): UsageMetricsSearchCriteria => ({
    startDate: startOfDay(date),
    endDate: min([endOfDay(date), new Date()]),
});

const criteriaForMonthOf = (date: Date): UsageMetricsSearchCriteria => {
    return {
        startDate: startOfMonth(date),
        endDate: min([endOfMonth(date), new Date()]),
    };
};

const criteriaForYearOf = (date: Date): UsageMetricsSearchCriteria => ({
    startDate: startOfYear(date),
    endDate: min([endOfYear(date), new Date()]),
});

export const filtersToSearchCriteria = ({
    endDay,
    option,
    startDay,
}: UsageMetricsCriteria): UsageMetricsSearchCriteria | null => {
    if (option === 'this_day') {
        return criteriaForDayOf(new Date());
    } else if (option === 'this_month') {
        return criteriaForMonthOf(new Date());
    } else if (option === 'last_month') {
        const monthAgo = addMonths(new Date(), -1);
        return criteriaForMonthOf(monthAgo);
    } else if (option === 'this_year') {
        return criteriaForYearOf(new Date());
    } else if (option === 'last_year') {
        const yearAgo = addYears(new Date(), -1);
        return criteriaForYearOf(yearAgo);
    } else if (option === 'all') {
        return {
            startDate: minValidDate,
            endDate: new Date(),
        };
    } else if (startDay && endDay) {
        return {
            startDate: startOfDay(startDay),
            endDate: startOfDay(addDays(endDay, 1)),
        };
    }
    return null;
};

export const getAggregateUsageMetrics = (
    filters: UsageMetricsSearchCriteria
): Promise<AggregateUsageMetrics> => {
    const params = {
        filter: {
            startTime: filters?.startDate ? formatISO(filters.startDate) : undefined,
            endTime: filters?.endDate ? formatISO(filters.endDate) : undefined,
        },
    };

    return axios.get(`${baseUrl}/usage/summary`, { params }).then((response) => response.data);
};

export const getUsageMetricsPreview = (
    filters: UsageMetricsSearchCriteria
): Promise<UsageMetricsPreviewResponse> => {
    const params = {
        filter: {
            startTime: filters?.startDate ? formatISO(filters.startDate) : undefined,
            endTime: filters?.endDate ? formatISO(filters.endDate) : undefined,
        },
    };

    return axios.get(`${baseUrl}/usage/preview`, { params }).then((response) => response.data);
};

export const getUsageMetricsTimeline = (
    filters: UsageMetricsSearchCriteria
): Promise<CumulativeCpuUsageResponse> => {
    const params = {
        filter: {
            startTime: filters?.startDate ? formatISO(filters.startDate) : undefined,
            endTime: filters?.endDate ? formatISO(filters.endDate) : undefined,
        },
    };

    return axios.get(`${baseUrl}/usage/timeline`, { params }).then((response) => response.data);
};

const createUsageUrl = (filters: UsageMetricsSearchCriteria): string => {
    const filter = {
        startTime: filters?.startDate ? formatISO(filters.startDate) : undefined,
        endTime: filters?.endDate ? formatISO(filters.endDate) : undefined,
    };
    return (
        baseUrl +
        '/usage/details?' +
        new URLSearchParams({ filter: JSON.stringify(filter) }).toString()
    );
};

export const downloadData = (filters: UsageMetricsSearchCriteria): Promise<Blob> => {
    return axios.get(createUsageUrl(filters)).then((res) => res.data);
};

export const sendUsageMetrics = (filters: UsageMetricsSearchCriteria): Promise<void> => {
    const filter = {
        startTime: filters?.startDate ? formatISO(filters.startDate) : undefined,
        endTime: filters?.endDate ? formatISO(filters.endDate) : undefined,
    };

    return axios.post(`${baseUrl}/usage/send`, filter).then((response) => response.data);
};
