/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { QueryFailureReason } from '../api/queryApi';

const failureInfoToString = (t: QueryFailureReason): string => {
    return t.message ? t.type + ': ' + t.message : t.type;
};

const countSharedStackFrames = (stack: string[], parentStack: string[]): number => {
    let n = 0;
    const minStackLength = Math.min(stack.length, parentStack.length);
    while (
        n < minStackLength &&
        stack[stack.length - 1 - n] === parentStack[parentStack.length - 1 - n]
    ) {
        n++;
    }
    return n;
};

const formatStackTraceHelper = (
    info: QueryFailureReason,
    parentStack: string[],
    prefix: string,
    linePrefix: string
): string => {
    let s = linePrefix + prefix + failureInfoToString(info) + '\n';

    if (info.stack) {
        let sharedStackFrames = 0;
        if (parentStack !== null) {
            sharedStackFrames = countSharedStackFrames(info.stack, parentStack);
        }

        for (let i = 0; i < info.stack.length - sharedStackFrames; i++) {
            s += linePrefix + '\tat ' + info.stack[i] + '\n';
        }
        if (sharedStackFrames !== 0) {
            s += `${linePrefix}\t... ${sharedStackFrames} more\n`;
        }
    }

    if (info.suppressed) {
        for (let i = 0; i < info.suppressed.length; i++) {
            s += formatStackTraceHelper(
                info.suppressed[i],
                info.stack,
                'Suppressed: ',
                linePrefix + '\t'
            );
        }
    }

    if (info.cause) {
        s += formatStackTraceHelper(info.cause, info.stack, 'Caused by: ', linePrefix);
    }

    return s;
};

export const formatStackTrace = (info: QueryFailureReason): string => {
    return formatStackTraceHelper(info, [], '', '');
};
