/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useContext, useRef } from 'react';
import ChartComponent, { ChartComponentProps } from 'react-chartjs-2';
import Chart, {
    ChartData,
    ChartDataSets,
    ChartOptions,
    ChartTooltipLabelColor,
    Point,
    TimeUnit,
} from 'chart.js';
import * as ChartAnnotation from 'chartjs-plugin-annotation';
import { format, parse, roundToNearestMinutes } from 'date-fns';
import nth from 'lodash/nth';
import { chartTooltip } from './tooltip/ChartTooltip';
import { TooltipContext } from './tooltip/ChartTooltipContainer';
import { convertRem } from '../../utils/fontSize';
import { abbreviateWholeNumbers } from '../../utils/abbreviateNumber';
import { clockSystem } from '../date/dateFormats';
import { AlignedChart } from './AlignedChart';
import { registerLineWithLineChartType } from './lineWithLine';
import { registerBelowPositioner } from './belowPositioner';
import { getTimeAxisFormatter } from '../../utils/timeAxis';
import { useTheme } from '@mui/material/styles';
import { paletteSwitch } from '../../themes/palette';
import { useThemeMode } from '../../app/UIThemeContextProvider';

registerLineWithLineChartType();
registerBelowPositioner();

export interface HighlightAreaProps {
    start: Date;
    end: Date | null;
}

interface TimeLineChartProps {
    height: number;
    width?: number;
    data: ChartData;
    tooltipDateFormat?: { '12h': string; '24h': string } | ((inputDate: Date) => string);
    fixedUnit?: TimeUnit;
    percentageYAxis?: boolean;
    tooltipValueFormatter?: (value: number) => string | number;
    xTicksLimit?: number;
    yTicksLimit?: number;
    xTicksMaxRotation?: number;
    yMax?: number;
    drawXZeroLine?: boolean;
    labelWidth?: number;
    yLabelFormatter?: ((label: number) => string) | 'duration';
    highlightArea?: HighlightAreaProps;
}

const defaultPointHoverColor = 'rgba(220,220,220,1)';

export const TimeLineChart: React.FunctionComponent<TimeLineChartProps> = ({
    height,
    width,
    data,
    tooltipDateFormat,
    fixedUnit,
    percentageYAxis = false,
    tooltipValueFormatter = (val: number) => val.toLocaleString(),
    yTicksLimit = 7,
    xTicksLimit = 15,
    xTicksMaxRotation = 50,
    yMax,
    drawXZeroLine = true,
    labelWidth,
    yLabelFormatter: inputYLabelFormatter = abbreviateWholeNumbers,
    highlightArea,
}) => {
    const chartRef = useRef<ChartComponent<ChartComponentProps> | null>(null);
    const { containerId: tooltipContainerId } = useContext(TooltipContext) || {};
    const theme = useTheme();
    const themeMode = useThemeMode();
    const formatDateInTooltip = (inputDate: string): string => {
        if (!tooltipDateFormat) {
            return inputDate;
        }

        const defaultFormat = 'MMM d, yyyy, h:mm:ss a';
        let date;
        try {
            date = parse(inputDate, defaultFormat, new Date());
            if (typeof tooltipDateFormat === 'object') {
                return format(
                    roundToNearestMinutes(date),
                    tooltipDateFormat[clockSystem === 12 ? '12h' : '24h']
                );
            } else {
                return tooltipDateFormat(date);
            }
        } catch (e) {
            console.error('Falling back to default date format...', e);
            return inputDate;
        }
    };

    const [stepSize, yLabelFormatter] =
        inputYLabelFormatter === 'duration'
            ? getTimeAxisFormatter(data)
            : [undefined, inputYLabelFormatter];

    const datasets: ChartDataSets[] | undefined = data.datasets?.map(
        (dataset): ChartDataSets => ({
            fill: true,
            lineTension: 0.1,
            borderWidth: 2.4,
            pointBackgroundColor: '#fff',
            pointBorderWidth: 0,
            pointHoverRadius: 0,
            pointHoverBorderColor: defaultPointHoverColor,
            pointHoverBorderWidth: 0,
            pointRadius: 0,
            pointHitRadius: 0,
            ...dataset,
        })
    );

    const getRangeInMillis = (): number => {
        if (!data.datasets) {
            return 0;
        }

        const points = data.datasets[0].data as Array<Point>;
        if (points.length < 2) {
            return 0;
        }

        return points[points.length - 1].x - points[0].x;
    };

    const getRangeInHours = (): number => {
        return getRangeInMillis() / 60 / 60 / 1000;
    };

    const getRangeInMinutes = (): number => {
        return getRangeInMillis() / 60 / 1000;
    };

    const highlightColor = 'rgba(0, 0, 0, 0.04)';

    // todo: remove @types/chartjs-plugin-annotation after upgrading to chart.js 3
    // annotation plugin types are not aligned with the runtime library
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const annotations: any =
        highlightArea !== undefined
            ? [
                  {
                      type: 'box',
                      xScaleID: 'x-axis-0',
                      xMin: highlightArea.start,
                      xMax: highlightArea.end,
                      backgroundColor: highlightColor,
                      borderColor: highlightColor,
                      // https://github.com/chartjs/chartjs-plugin-annotation/issues/127
                      // borderWidth: 0,
                  },
              ]
            : [];

    return (
        <div style={{ height, width }}>
            <AlignedChart chartRef={chartRef}>
                {(onHover) => (
                    <ChartComponent
                        type={'LineWithLine' as any} // eslint-disable-line @typescript-eslint/no-explicit-any
                        ref={chartRef}
                        data={{
                            labels: data.labels,
                            datasets,
                        }}
                        options={
                            {
                                maintainAspectRatio: false,
                                animation: {
                                    duration: 0,
                                },
                                scales: {
                                    xScaleID: 'x-axis-0',
                                    xAxes: [
                                        {
                                            type: 'time',
                                            time: {
                                                unit: fixedUnit,
                                                minUnit: 'second',
                                                displayFormats: {
                                                    hour: (function () {
                                                        if (getRangeInHours() <= 24) {
                                                            return clockSystem === 12
                                                                ? 'ha'
                                                                : 'H:mm';
                                                        }

                                                        return clockSystem === 12
                                                            ? 'MMM D, ha'
                                                            : 'MMM D, H:mm';
                                                    })(),
                                                    minute: clockSystem === 12 ? 'h:mm a' : 'H:mm',
                                                    second: (function () {
                                                        if (getRangeInMinutes() < xTicksLimit) {
                                                            return clockSystem === 12
                                                                ? 'h:mm:ss a'
                                                                : 'H:mm:ss';
                                                        }

                                                        return clockSystem === 12
                                                            ? 'h:mm a'
                                                            : 'H:mm';
                                                    })(),
                                                },
                                            },
                                            ticks: {
                                                maxTicksLimit: xTicksLimit,
                                                fontFamily: 'barlow, Roboto Condensed, sans-serif',
                                                fontSize: convertRem(0.75),
                                                maxRotation: xTicksMaxRotation,
                                                fontColor: paletteSwitch(theme).black54,
                                            },
                                            gridLines: {
                                                display: false,
                                            },
                                        },
                                    ],
                                    yAxes: [
                                        {
                                            ticks: {
                                                suggestedMin: 0,
                                                suggestedMax: yMax,
                                                min: percentageYAxis ? 0 : undefined,
                                                max: percentageYAxis ? 1 : undefined,
                                                callback: function (
                                                    value: number
                                                ): string | number | undefined {
                                                    if (percentageYAxis) {
                                                        return value * 100 + '%';
                                                    }
                                                    return yLabelFormatter(value);
                                                },
                                                fontFamily: 'barlow, Roboto Condensed, sans-serif',
                                                fontSize: convertRem(0.75),
                                                maxTicksLimit: yTicksLimit,
                                                fontColor: paletteSwitch(theme).black54,
                                                padding: 10,
                                                stepSize,
                                            },
                                            gridLines: {
                                                drawBorder: drawXZeroLine,
                                                zeroLineWidth: 2,
                                                color: paletteSwitch(theme).black12,
                                                zeroLineColor: paletteSwitch(theme).black20,
                                            },
                                            afterFit: function (scaleInstance) {
                                                if (labelWidth) {
                                                    scaleInstance.width = labelWidth;
                                                }
                                            },
                                        },
                                    ],
                                },
                                plugins: [ChartAnnotation],
                                annotation: {
                                    annotations,
                                },
                                legend: {
                                    display: false,
                                },
                                hover: {
                                    intersect: false,
                                    mode: 'index',
                                },
                                onHover,
                                tooltips: {
                                    enabled: false,
                                    mode: 'index',
                                    position: 'below',
                                    intersect: false,
                                    custom: chartTooltip(
                                        chartRef,
                                        themeMode,
                                        tooltipContainerId,
                                        (value) => tooltipValueFormatter(value as number).toString()
                                    ),
                                    callbacks: {
                                        title: (tooltipItem) => {
                                            const header = tooltipItem[0]?.xLabel;
                                            if (typeof header === 'string') {
                                                return formatDateInTooltip(header);
                                            }

                                            return header?.toString() ?? '';
                                        },
                                        labelColor: function (
                                            tooltipItem: Chart.ChartTooltipItem,
                                            chart: Chart
                                        ): ChartTooltipLabelColor {
                                            const color = nth(
                                                chart.data.datasets,
                                                tooltipItem.datasetIndex
                                            )?.borderColor as string;

                                            return {
                                                borderColor: color,
                                                backgroundColor: color,
                                            };
                                        },
                                    },
                                },
                            } as ChartOptions
                        }
                    />
                )}
            </AlignedChart>
        </div>
    );
};
