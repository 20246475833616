/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';
import { StageStats } from '../../../../../../api/queryApi';
import { formatRows, parseAndFormatDataSize } from '../utils';
import { createUseStyles } from 'react-jss';
import { Theme } from '@mui/material/styles';
import { paletteSwitch } from '../../../../../../themes/palette';

type RemoteSourceProps = StageStats;

const useStyles = createUseStyles((theme: Theme) => ({
    planNode: {
        color: paletteSwitch(theme).nightBlue,
        paddingLeft: '0.5rem',
    },
}));

export const RemoteSource: React.FunctionComponent<RemoteSourceProps> = ({
    outputDataSize,
    outputPositions,
}: RemoteSourceProps) => {
    const classes = useStyles();
    return (
        <div className={classes.planNode}>
            <div>{parseAndFormatDataSize(outputDataSize)}</div>
            <div>/</div>
            <div>{formatRows(outputPositions)}</div>
        </div>
    );
};
