/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { createUseStyles } from 'react-jss';
import { paletteSwitch } from '../../themes/palette';
import { Theme } from '@mui/material/styles';

export const useContextMenuStyles = createUseStyles((theme: Theme) => ({
    menuItemEnabledDangerous: {
        color: paletteSwitch(theme).error,
        '&:hover': {
            color: paletteSwitch(theme).error,
        },
    },
    icon: {
        marginRight: '0.75rem',
    },
    menuItemEnabled: {
        color: paletteSwitch(theme).nebulaNavy,
    },
}));
