/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { ChartOptions } from 'chart.js';
import ChartComponent, { ChartComponentProps } from 'react-chartjs-2';
import { v4 as uuidv4 } from 'uuid';
import { AlignedChartsContext } from './AlignedCharts';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { closeTooltip } from './tooltip/closeTooltip';

interface AlignedChartProps {
    children: (onHover: ChartOptions['onHover']) => React.ReactElement;
    chartRef: React.MutableRefObject<ChartComponent<ChartComponentProps> | null>;
}

export const AlignedChart: React.FunctionComponent<AlignedChartProps> = ({
    chartRef,
    children,
}) => {
    const id = useRef(uuidv4());
    const context = useContext(AlignedChartsContext);

    useEffect(() => {
        const subscription = context?.onChartHover
            .pipe(
                distinctUntilChanged(
                    (prev, curr) =>
                        prev?.activeIndex === curr?.activeIndex &&
                        prev?.activeChartId === curr?.activeChartId
                ),
                debounceTime(15) // skip some events for performance reasons
            )
            .subscribe((state) => {
                if (state?.activeChartId !== id.current) {
                    if (state?.activeIndex !== undefined) {
                        openTooltip(state?.activeIndex);
                    } else {
                        closeTooltip(chartRef);
                    }
                }
            });

        return () => subscription?.unsubscribe();
    }, []);

    // todo: replace with native mechanism introduced in Chart.js 3
    // https://github.com/chartjs/Chart.js/issues/2850
    const openTooltip = (index: number) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const chart: any = chartRef?.current?.chartInstance;
        if (chart.tooltip._active === undefined) {
            chart.tooltip._active = [];
        }
        const requestedElem = [];

        for (let i = 0; i < chart.data.datasets.length; i++) {
            if (!chart.data.datasets[i].hidden) {
                requestedElem.push(chart.getDatasetMeta(i).data[index]);
            }
        }

        chart.tooltip._active = requestedElem;
        chart.tooltip.update(true);
        chart.draw();
    };

    const onHover = useCallback(
        (event: MouseEvent, activeElements: Array<{ _index: number }>): void => {
            context?.onChartHover.next(
                activeElements.length > 0
                    ? {
                          activeIndex: activeElements[0]._index,
                          activeChartId: id.current,
                      }
                    : null
            );
        },
        []
    );

    return children(onHover);
};
