/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { RegisterOptions, ValidationValueMessage } from 'react-hook-form';

export const isRequired = (
    rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
): boolean => {
    if (!rules || !rules.required) {
        return false;
    }

    if (typeof rules.required == 'boolean') {
        return rules.required as boolean;
    }

    if (typeof rules.required == 'string') {
        return true;
    }

    return (rules.required as ValidationValueMessage<boolean>).value;
};

export const isNonEmpty = (str: string): boolean => str.trim().length > 0;

export function getCharacterCountdownText(text = '', maxCharacters: number): string {
    return `${maxCharacters - text.length} characters remaining`;
}
