/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React, { useCallback, useState } from 'react';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { openWorksheet, Worksheet, WorksheetPermissionRow } from '../../../api/savedQueriesApi';
import { useTableStyles } from '../../../components/table/useTableStyles';
import { useHistory } from 'react-router-dom';
import { useTableSorting } from '../../../components/table/useTableSorting';
import { useTablePagination } from '../../../components/table/TablePagination';
import isEmpty from 'lodash/isEmpty';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import Grid from '@mui/material/Grid';
import { LoadingButton } from '../../../components/LoadingButton';
import CodeIcon from '@mui/icons-material/Code';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { TableActionMenuButton } from '../../../components/table/TableActionMenu';
import { TableBodyCell } from '../../../components/table/TableBodyCell';
import { FilteredLabel } from '../../../components/table/FilteredLabel';
import { persistActiveTabId } from '@starburstdata/query-editor';
import { SortableColumn } from '../../../components/table/SortableColumn';
import { TableHeaderCell } from '../../../components/table/TableHeaderCell';
import { SavedQueryPreviewDialog } from './SavedQueryPreviewDialog';
import { LoadingBackdrop } from '../../../components/LoadingBackdrop';
import { ErrorDialog } from '../../../components/error/ErrorDialog';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { formatDateTimeToMediumDateStyle } from '../../../utils/formatDateInterval';
import { UserEmailCell } from '../../../components/table/UserEmailCell';
import { TableButtonTooltip } from '../../../components/table/TableButtonTooltip';
import Button from '@mui/material/Button';
import Share from '@mui/icons-material/Share';
import { isWorksheetOwner } from './WorksheetUtils';
import { ShareQueryDialog } from './ShareQueryDialog';

interface SavedQueryTableProps {
    worksheets: Worksheet[];
    filter: string;
    showSharedWithColumn: boolean;
    showShareButton: boolean;
    reloadWorksheets: () => void;
}
const useLocalStyles = createUseStyles({
    tableContainer: {
        maxHeight: 'calc(100vh - 19rem)',
    },
});

export const SavedQueryTable: React.FunctionComponent<SavedQueryTableProps> = ({
    worksheets,
    filter,
    showSharedWithColumn,
    showShareButton,
    reloadWorksheets,
}) => {
    const tableClasses = useTableStyles();
    const localStyles = useLocalStyles();
    const history = useHistory();
    const [backdropOpened, setBackdropOpened] = useState(false);
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [queryToPreview, setQueryToPreview] = useState<Worksheet | null>(null);
    const [queryToShare, setQueryToShare] = useState<Worksheet | null>(null);

    const getValueToCompare = useCallback((worksheet: Worksheet, columnKey: keyof Worksheet) => {
        switch (columnKey) {
            case 'name':
            case 'lastUpdateTime':
            case 'owner':
                return worksheet[columnKey];
            case 'createTime':
                return worksheet[columnKey];
        }
    }, []);

    const [sortedWorksheets, sortParams, setSortParams] = useTableSorting<Worksheet>(
        worksheets,
        {
            sortBy: 'lastUpdateTime',
            sortOrder: 'desc',
        },
        getValueToCompare
    );

    const { TablePagination, paginatedItems: paginatedWorksheets } = useTablePagination(
        sortedWorksheets,
        'Queries per page'
    );

    const open = useCallback(
        async (worksheetId: string) => {
            try {
                await openWorksheet(worksheetId);
                persistActiveTabId(worksheetId);
                history.push('/ide');
            } catch (e) {
                setOpenErrorDialog(true);
            }
        },
        [openWorksheet, history, persistActiveTabId]
    );

    const openViaButton = useCallback(
        async (worksheet: Worksheet) => {
            try {
                setBackdropOpened(true);
                await open(worksheet.id);
            } finally {
                setBackdropOpened(false);
            }
        },
        [open]
    );

    const previewWorksheet = useCallback((worksheet: Worksheet) => {
        setQueryToPreview(worksheet);
    }, []);

    const openViaLink = useCallback(
        async (worksheet: Worksheet) => {
            try {
                setBackdropOpened(true);
                await open(worksheet.id);
            } finally {
                setBackdropOpened(false);
            }
        },
        [open]
    );

    const isQueryEmpty = useCallback(
        ({ queryText }: Worksheet): boolean => isEmpty(queryText?.trim()),
        []
    );

    const formatWorksheetPermissions = useCallback((permissions: WorksheetPermissionRow[]) => {
        if (permissions.length === 0) {
            return '-';
        }
        return permissions.map(({ subjectName }) => subjectName).join(', ');
    }, []);

    const WorksheetPermissionColumn = ({
        permissions,
    }: {
        permissions: WorksheetPermissionRow[];
    }) => {
        const permissionText = formatWorksheetPermissions(permissions);
        return (
            <TableBodyCell widthInPercentage={15} dataValue={permissionText}>
                <FilteredLabel label={permissionText} filter={filter} />
            </TableBodyCell>
        );
    };

    return (
        <>
            <LoadingBackdrop opened={backdropOpened} />
            <ErrorDialog
                text={'Unable to open the query. Try again later.'}
                openErrorDialog={openErrorDialog}
                setOpenErrorDialog={setOpenErrorDialog}
            />
            {queryToPreview && (
                <SavedQueryPreviewDialog
                    queryText={queryToPreview.queryText}
                    worksheetId={queryToPreview.id}
                    openDialog={!!queryToPreview}
                    onClose={() => {
                        setQueryToPreview(null);
                    }}
                />
            )}
            {queryToShare && (
                <ShareQueryDialog
                    worksheet={queryToShare}
                    onClose={(shouldReloadWorksheets) => {
                        setQueryToShare(null);
                        if (shouldReloadWorksheets) {
                            reloadWorksheets();
                        }
                    }}
                />
            )}

            <TableContainer className={clsx(tableClasses.table, localStyles.tableContainer)}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableHeaderCell />
                            <SortableColumn<keyof Worksheet>
                                label="Name"
                                sortingKey="name"
                                sortingState={sortParams}
                                setSortingState={setSortParams}
                                widthInPercentage={30}
                                component="th"
                            />
                            <SortableColumn<keyof Worksheet>
                                label="Owned by"
                                sortingKey="owner"
                                sortingState={sortParams}
                                setSortingState={setSortParams}
                                widthInPercentage={20}
                                component="th"
                            />
                            {showSharedWithColumn && (
                                <TableHeaderCell widthInPercentage={20}>
                                    Shared with role(s)
                                </TableHeaderCell>
                            )}
                            <SortableColumn<keyof Worksheet>
                                label="Last updated"
                                sortingKey="lastUpdateTime"
                                sortingState={sortParams}
                                setSortingState={setSortParams}
                                widthInPercentage={20}
                                component="th"
                            />
                            {!showSharedWithColumn && (
                                <SortableColumn<keyof Worksheet>
                                    label="Created at"
                                    sortingKey="createTime"
                                    sortingState={sortParams}
                                    setSortingState={setSortParams}
                                    widthInPercentage={20}
                                    component="th"
                                />
                            )}
                            <TableHeaderCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedWorksheets.map((worksheet) => (
                            <TableRow data-id={worksheet.id} key={worksheet.id}>
                                <TableBodyCell>
                                    <TableActionMenuButton row={{ row: worksheet }} />
                                </TableBodyCell>
                                <TableBodyCell widthInPercentage={20} dataValue={worksheet.name}>
                                    <div
                                        className={tableClasses.link}
                                        onClick={() => openViaLink(worksheet)}>
                                        <FilteredLabel label={worksheet.name} filter={filter} />
                                    </div>
                                </TableBodyCell>
                                <TableBodyCell widthInPercentage={15} dataValue={worksheet.owner}>
                                    <UserEmailCell user={worksheet.owner} filter={filter} />
                                </TableBodyCell>
                                {showSharedWithColumn && (
                                    <WorksheetPermissionColumn
                                        permissions={worksheet.permissions}
                                    />
                                )}
                                <TableBodyCell widthInPercentage={15}>
                                    {formatDateTimeToMediumDateStyle(worksheet.lastUpdateTime)}
                                </TableBodyCell>
                                {!showSharedWithColumn && (
                                    <TableBodyCell widthInPercentage={15}>
                                        {formatDateTimeToMediumDateStyle(worksheet.createTime)}
                                    </TableBodyCell>
                                )}
                                <TableBodyCell>
                                    <Grid
                                        data-id="query-quick-actions"
                                        container
                                        spacing={2}
                                        wrap="nowrap">
                                        <Grid item>
                                            <LoadingButton
                                                color="primary"
                                                startIcon={<CodeIcon />}
                                                onClick={() => openViaButton(worksheet)}>
                                                Open
                                            </LoadingButton>
                                        </Grid>
                                        <Grid item>
                                            <LoadingButton
                                                color="primary"
                                                startIcon={<VisibilityIcon />}
                                                disabled={isQueryEmpty(worksheet)}
                                                onClick={() => previewWorksheet(worksheet)}>
                                                Preview
                                            </LoadingButton>
                                        </Grid>
                                        {showShareButton && (
                                            <Grid item>
                                                <TableButtonTooltip
                                                    title={
                                                        !isWorksheetOwner(worksheet)
                                                            ? 'You are not owner of this query'
                                                            : ''
                                                    }>
                                                    <Button
                                                        color="primary"
                                                        startIcon={<Share />}
                                                        disabled={!isWorksheetOwner(worksheet)}
                                                        onClick={() => setQueryToShare(worksheet)}>
                                                        Share
                                                    </Button>
                                                </TableButtonTooltip>
                                            </Grid>
                                        )}
                                    </Grid>
                                </TableBodyCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TablePagination />
                </Table>
            </TableContainer>
        </>
    );
};
