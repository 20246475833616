/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Theme } from '@mui/material/styles';
import { paletteSwitch } from '../../themes/palette';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = createUseStyles((theme: Theme) => ({
    headerText: {
        fontFamily: theme.typography.fontFamily,
        fontSize: '1.25rem',
        fontWeight: 500,
        textAlign: 'center',
        letterSpacing: '0.5px',
        padding: '1rem 2rem',
    },
    message: {
        fontFamily: theme.typography.fontFamily,
        fontSize: '0.875rem',
        padding: '1rem 1.5rem',
        letterSpacing: '0.5px',
        lineHeight: 1.4,
        borderTop: `1px solid ${paletteSwitch(theme).black12}`,
        borderBottom: `1px solid ${paletteSwitch(theme).black12}`,
        maxWidth: '25rem',
    },
    buttonsWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '0.875rem',
    },
    deleteButton: {
        marginLeft: '0.875rem',
        color: paletteSwitch(theme).main,
        backgroundColor: paletteSwitch(theme).error,
        '&:hover': {
            backgroundColor: paletteSwitch(theme).errorDark,
        },
    },
}));

interface DeleteRoleDialogProps {
    roleName: string;
    deleteRole(): Promise<void>;
    closeDialog(): void;
    reload(): Promise<void>;
}

export const DeleteRoleDialog: React.FunctionComponent<DeleteRoleDialogProps> = ({
    roleName,
    deleteRole,
    closeDialog,
    reload,
}) => {
    const classes = useStyles();
    const [busy, setBusy] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);

    const handleDelete = async () => {
        setBusy(true);
        setError(undefined);

        // minimum wait time for avoiding spinner flash
        const minimumWait = new Promise<void>((resolve) => setTimeout(resolve, 700));

        try {
            await deleteRole();
        } catch (e) {
            setBusy(false);
            setError(e.message);
            return;
        }

        await Promise.all([reload(), minimumWait]);
        closeDialog();
    };

    return (
        <Dialog open={true}>
            <div className={classes.headerText}>Delete role?</div>
            <Box className={classes.message}>
                {error && (
                    <Alert variant="standard" severity="error" style={{ marginBottom: '1rem' }}>
                        {error}
                    </Alert>
                )}
                Are you sure you want to delete the <strong>{roleName}</strong> role? The following
                action cannot be undone.
            </Box>
            <div className={classes.buttonsWrapper}>
                <Button variant="outlined" color="primary" onClick={closeDialog} disabled={busy}>
                    No, do not delete
                </Button>
                <Button
                    variant="contained"
                    onClick={handleDelete}
                    disabled={busy}
                    className={classes.deleteButton}>
                    {busy && (
                        <CircularProgress
                            color="secondary"
                            thickness={4}
                            size={20}
                            style={{
                                marginRight: '8px',
                            }}
                        />
                    )}
                    Yes, delete
                </Button>
            </div>
        </Dialog>
    );
};
