/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React, { useMemo } from 'react';
import clsx from 'clsx';
import { createUseStyles } from 'react-jss';
import { Ellipsis } from '../ellipsis/Ellipsis';
import escapeRegExp from 'lodash/escapeRegExp';

export const useStyles = createUseStyles({
    highlighted: {
        backgroundColor: '#ffff00',
    },
});

interface FilteredLabelProps {
    label: string;
    filter: string;
    className?: string;
}

export const FilteredLabel: React.FunctionComponent<FilteredLabelProps> = ({
    label,
    filter,
    className,
}) => {
    const classes = useStyles();

    const parts = useMemo(() => {
        if (!filter || !label) {
            return [label];
        }

        return label
            .split(new RegExp(`(${escapeRegExp(filter)})`, 'i'))
            .filter((part) => part !== '');
    }, [label, filter]);

    return (
        <Ellipsis className={className} tooltip={label}>
            <>
                {parts.map((part, index) => (
                    <span
                        key={index}
                        className={clsx({
                            [classes.highlighted]: part.toLowerCase() === filter.toLowerCase(),
                        })}>
                        {part}
                    </span>
                ))}
            </>
        </Ellipsis>
    );
};
