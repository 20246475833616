/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';
import FormControl from '@mui/material/FormControl';
import { Select } from '../../../components/select/Select';
import Chip from '@mui/material/Chip';
import { QueryState } from '../../../api/queriesApi';
import MenuItem from '@mui/material/MenuItem';
import { StatusIcon } from '../../../components/icon/StatusIcon';
import capitalize from 'lodash/capitalize';
import { createUseStyles } from 'react-jss';
import Checkbox from '@mui/material/Checkbox';
import { SelectChangeEvent } from '@mui/material/Select';
import Box from '@mui/material/Box';
import { paletteSwitch } from '../../../themes/palette';
import { Theme } from '@mui/material/styles';

const useStyles = createUseStyles((theme: Theme) => ({
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: '-0.375rem 0 -0.5rem -0.25rem',
    },
    chip: {
        margin: '0.125rem',
        border: `1px solid ${paletteSwitch(theme).black20}`,
        backgroundColor: 'transparent',
        fontSize: '0.875rem',
    },
    iconStyle: {
        width: '1.25rem !important',
        fontSize: '1.25rem',
        marginRight: '0.688rem',
        verticalAlign: 'middle',
    },
    selectMenuItem: {
        padding: '0.313rem 0 0.313rem 1.25rem',
        width: '100%',
    },
    checkbox: {
        paddingRight: '1rem',
    },
    queryState: {
        verticalAlign: 'middle',
    },
}));
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 224,
            width: 250,
        },
    },
};

interface QueryStateSelectorProps {
    value: QueryState[];
    setValue: (newValue: QueryState[]) => void;
}

export const QueryStateSelector: React.FunctionComponent<QueryStateSelectorProps> = ({
    value,
    setValue,
}) => {
    const classes = useStyles();

    const handleChange = (event: SelectChangeEvent<unknown>) => {
        setValue(event.target.value as QueryState[]);
    };

    const getStateWithIcon = (status: QueryState) => {
        return (
            <>
                <StatusIcon state={status} className={classes.iconStyle} />
                <span className={classes.queryState}>{capitalize(status)}</span>
            </>
        );
    };

    return (
        <FormControl key="status" variant="outlined" margin="dense" fullWidth>
            <Select
                value={value ?? []}
                onChange={handleChange}
                multiple
                renderValue={(selected) => (
                    <Box className={classes.chips}>
                        {(selected as QueryState[]).map((selectedValue) => (
                            <Chip
                                key={selectedValue}
                                label={getStateWithIcon(selectedValue)}
                                className={classes.chip}
                            />
                        ))}
                    </Box>
                )}
                MenuProps={MenuProps}>
                {(['RUNNING', 'FINISHED', 'QUEUED', 'FAILED'] as QueryState[]).map((state) => (
                    <MenuItem key={state} value={state} className={classes.selectMenuItem}>
                        <span className={classes.checkbox}>
                            <Checkbox checked={value && value.includes(state)} />
                        </span>
                        {getStateWithIcon(state)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
