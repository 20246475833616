/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { FunctionComponent, MouseEventHandler, ReactNode } from 'react';

interface PreventDefaultClickProps {
    children: ReactNode;
    className?: string;
}

export const PreventDefaultClick: FunctionComponent<PreventDefaultClickProps> = ({
    children,
    className,
}: PreventDefaultClickProps) => {
    return (
        <div className={className} onClick={clickHandler}>
            {children}
        </div>
    );
};

const clickHandler: MouseEventHandler = (e) => e.preventDefault();
