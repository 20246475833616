/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { FunctionComponent, ReactNode, useContext, useEffect } from 'react';
import { merge, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { CommentData, listDataProductComments$ } from '../../../api/dataProduct/comment/commentApi';
import { commentEvent$ } from '../../../api/dataProduct/comment/commentEvent';
import { useFetchingState, UseFetchingStateResult } from '../domain/useFetchingState';

const discussionContextDefaultValue: UseFetchingStateResult<CommentData[]> = {
    errorMessage: 'No context provided',
    data: undefined,
    lastSuccessData: undefined,
    isError: true,
    isFetching: false,
    setData: () => console.warn('No context provided'),
    setError: () => console.warn('No context provided'),
    setFetching: () => console.warn('No context provided'),
    setState: () => console.warn('No context provided'),
    state: {
        type: 'error',
        message: 'No context provided',
    },
    observer: {
        next: () => console.warn('No context provided'),
    },
};

const DiscussionContext = React.createContext<UseFetchingStateResult<CommentData[]>>(
    discussionContextDefaultValue
);

interface DiscussionContextProviderProps {
    dataProductId: string;
    children: ReactNode;
}
export const DiscussionContextProvider: FunctionComponent<DiscussionContextProviderProps> = ({
    dataProductId,
    children,
}: DiscussionContextProviderProps) => {
    const state = useFetchingState<CommentData[]>();
    useEffect(() => {
        const subscription = merge(new Observable((observer) => observer.next()), commentEvent$)
            .pipe(
                tap(() => state.setFetching()),
                switchMap(() => listDataProductComments$(dataProductId))
            )
            .subscribe(state.setData);
        return () => subscription.unsubscribe();
    }, [dataProductId]);

    return <DiscussionContext.Provider value={state}>{children}</DiscussionContext.Provider>;
};

export const useDiscussionContext: () => UseFetchingStateResult<CommentData[]> = () =>
    useContext(DiscussionContext);
