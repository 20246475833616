/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useMemo } from 'react';
import { ContentErrorProps } from '../../../../components/dialog/SimpleDialog';
import TextField from '@mui/material/TextField';

import { TextFieldWithHint } from '../../../dataproduct/components/TextFieldWithHint';
import FormControl from '@mui/material/FormControl';
import { getCharacterCountdownText } from '../../../../utils/formUtils';
import { getEntityDisplayName, RowExpressionType } from '../MasksAndFilters';
import { Expression } from '../../../../api/biac/biacApi';
import { useTextInputValueChange } from '../../../../utils/useTextInputValueChange';

export interface ExpressionDialogContentProps {
    rowExpression: Expression;
    rowExpressionType: RowExpressionType;
    setRowExpression: (rowExpression1: Expression) => void;
    expressionNameError?: string;
}
export const ExpressionForm: React.FunctionComponent<
    ExpressionDialogContentProps & ContentErrorProps
> = (params) => {
    const entityDisplayName = useMemo(
        () => getEntityDisplayName(params.rowExpressionType),
        [params.rowExpressionType]
    );

    const handleNameChange = useTextInputValueChange(
        (value) =>
            params.setRowExpression({
                ...params.rowExpression,
                name: value,
            }),
        [params.rowExpression, params.setRowExpression]
    );

    const handleExpressionChange = useTextInputValueChange(
        (value) =>
            params.setRowExpression({
                ...params.rowExpression,
                expression: value,
            }),
        [params.rowExpression, params.setRowExpression]
    );

    const handleDescriptionChange = useCallback(
        (value: string) =>
            params.setRowExpression({
                ...params.rowExpression,
                description: value,
            }),
        [params.rowExpression, params.setRowExpression]
    );

    const expressionNameError = useMemo(
        () =>
            params.rowExpression.name !== params.rowExpression.name.trim()
                ? `${getEntityDisplayName(
                      params.rowExpressionType
                  )} name cannot start or end with a space`
                : undefined,
        [params.rowExpression.name]
    );

    return (
        <FormControl fullWidth>
            {params.error}
            <TextField
                value={params.rowExpression.name}
                variant="outlined"
                label={`${entityDisplayName} name`}
                size="small"
                fullWidth
                required
                onChange={handleNameChange}
                inputProps={{ maxLength: 30 }}
                margin="normal"
                error={!!expressionNameError}
                helperText={expressionNameError}
                autoFocus
            />
            <TextField
                value={params.rowExpression.expression}
                variant="outlined"
                label="Expression"
                size="small"
                fullWidth
                required
                onChange={handleExpressionChange}
                margin="normal"
                multiline
                rows={4}
            />
            <TextFieldWithHint
                key={params.rowExpression.expression}
                fullWidth={true}
                value={params.rowExpression.description}
                label={'Description'}
                multiline
                rows={5}
                maxLength={200}
                onValueChange={handleDescriptionChange}
                helperText={getCharacterCountdownText(params.rowExpression.description, 200)}
                margin="normal"
            />
        </FormControl>
    );
};
