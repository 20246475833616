/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { CSSProperties, FunctionComponent, ReactElement } from 'react';
import { graphlib } from 'dagre';
import { palette } from '../../themes/palette';
import { Rect } from './Rect';

interface Size {
    height: number;
    width: number;
}

export interface GraphNodeBasic extends Size {
    id: string;
    groupId?: string;
}

export interface GraphNode<T = Record<string, unknown>> extends GraphNodeBasic {
    data: T;
    Component: FunctionComponent<T>;
}

interface GroupNodeProps {
    classes?: Record<string, string>;
    layout: graphlib.Graph;
    nodes: GraphNode[];
    onNodeHover: (node: GraphNode) => void;
    onNodeHoverLost: (node: GraphNode) => void;
}

export const GroupNode = ({
    classes = {},
    layout,
    nodes,
    onNodeHover,
    onNodeHoverLost = () => void 0,
}: GroupNodeProps): ReactElement => {
    return (
        <g>
            {nodes.map((node) => {
                const layoutInfo = layout.node(node.id);
                const x = -layoutInfo.width / 2 + layoutInfo.x;
                const y = -layoutInfo.height / 2 + layoutInfo.y;
                const width = layoutInfo.width;
                const height = layoutInfo.height;
                const strokeWidth = 2;
                const rx = 5;
                const stroke = palette.purple;
                const fill = 'white';
                return (
                    <Rect
                        key={node.id}
                        className={classes[node.id]}
                        x={x}
                        y={y}
                        width={width}
                        height={height}
                        strokeWidth={strokeWidth}
                        rx={rx}
                        stroke={stroke}
                        fill={fill}>
                        <div
                            style={planNodeContainerStyles}
                            onMouseEnter={() => onNodeHover(node)}
                            onMouseLeave={() => onNodeHoverLost(node)}>
                            <node.Component {...node.data} />
                        </div>
                    </Rect>
                );
            })}
        </g>
    );
};

const planNodeContainerStyles: CSSProperties = {
    height: '100%',
    padding: '5px',
};
