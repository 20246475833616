/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { FiltersRowModel } from './FilterRow';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { RoleContext } from '../CurrentRoleContext';
import {
    AuditLogPaginationParam,
    FilterCriteria,
    Paginated,
} from '../../../api/biac/audit/auditLogApi';
import {
    isDataError,
    isDataFetching,
    useFetchingState,
} from '../../dataproduct/domain/useFetchingState';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { fromPromise } from 'rxjs/internal-compatibility';

export interface AuditLogs<T> {
    isFetchingFirstRecords: boolean;
    isFetchingPage: boolean;
    isError: boolean;
    records: T[];
    errorMessage: string | undefined;
    hasMoreRecords: boolean;
    fetchMore: () => void;
    fetchCsv: () => Promise<Blob>;
}

const pageSize = 100;

export function useFetchAuditLogs<T>(
    appliedFilters: FiltersRowModel[],
    fetchingFunction: (
        roleHeader: string,
        filter: Partial<FilterCriteria> | null,
        pagination: AuditLogPaginationParam
    ) => Promise<Paginated<T>>,
    fetchingCsvFunction: (
        roleHeader: string,
        filter: Partial<FilterCriteria> | null
    ) => Promise<Blob>
): AuditLogs<T> {
    const roleContext = useContext(RoleContext);
    if (roleContext === 'disabled') {
        throw Error('Illegal state - RoleContext is required');
    }

    const criteria = useMemo<Partial<FilterCriteria>>(
        () =>
            Object.fromEntries(
                appliedFilters
                    .filter(({ filterBy, filterValue }) =>
                        filterBy && Array.isArray(filterValue)
                            ? filterValue.length > 0
                            : !!filterValue
                    )
                    .map(({ filterBy, filterValue }) => [filterBy, filterValue])
            ),
        [appliedFilters]
    );

    const [fetchedRecords, setFetchedRecords] = useState<T[]>([]);
    const currentLogsApi = useFetchingState<T[]>();

    const [hasMoreRecords, setHasMoreRecords] = useState(true);
    const tokenValueToLoadNext = useRef<string>();
    const fetchMore$ = useMemo(() => new BehaviorSubject<'fetch more'>('fetch more'), []);

    const fetchMore = useCallback(() => {
        if (hasMoreRecords) {
            fetchMore$.next('fetch more');
        }
    }, [hasMoreRecords]);

    useEffect(() => {
        tokenValueToLoadNext.current = undefined;
        setFetchedRecords([]);
        setHasMoreRecords(true);
        const subscription = fetchMore$
            .pipe(
                tap(() => currentLogsApi.setFetching()),
                exhaustMap(() =>
                    fromPromise(
                        fetchingFunction(roleContext.roleHeader, criteria, {
                            size: pageSize,
                            token: tokenValueToLoadNext.current,
                        })
                    ).pipe(
                        tap(({ nextPageToken, result }) => {
                            tokenValueToLoadNext.current = nextPageToken;
                            setHasMoreRecords(!!nextPageToken);
                            currentLogsApi.setData(result);
                        }),
                        map(({ result }) => result),
                        catchError(({ message }) => {
                            currentLogsApi.setError(message);
                            return of([]);
                        })
                    )
                )
            )
            .subscribe((pageRecords) => {
                setFetchedRecords((prevState) => [...prevState, ...pageRecords]);
            });
        return () => subscription.unsubscribe();
    }, [roleContext.roleHeader, criteria]);

    const isFetchingPage = isDataFetching(currentLogsApi.state);

    const fetchCsv = useCallback(
        () => fetchingCsvFunction(roleContext.roleHeader, criteria),
        [roleContext.roleHeader, criteria]
    );

    return {
        isFetchingFirstRecords: fetchedRecords.length === 0 && isFetchingPage,
        isFetchingPage,
        isError: isDataError(currentLogsApi.state),
        records: fetchedRecords,
        errorMessage: currentLogsApi.errorMessage,
        hasMoreRecords,
        fetchMore,
        fetchCsv,
    };
}
