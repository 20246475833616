/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import { getStages, PlanNodeInfo, StageNodeInfo } from './graphUtils';
import { SingleQueryPlanDetails, StageStats } from '../../../../../api/queryApi';
import { StageStatistics, StageStatisticsProps } from './stage/StageStatistics';
import { PlanNode } from './plan-node/PlanNode';
import { GraphNode } from '../../../../../components/graph/GroupNode';
import { RemoteSource } from './remote-source/RemoteSource';
import { GraphGroup } from '../../../../../components/graph/GroupCluster';
import { getColorByState } from '../../stages/stagesUtil';
import { alpha } from '@mui/material/styles';
import { GraphEdge } from '../../../../../components/graph/GroupEdgePath';

function createGroupId(stageId: string): string {
    return `group-${stageId}`;
}

function createStageNodeId(stageId: string): string {
    return `stage-${stageId}-root`;
}

function createOperatorNodeId(operatorId: string): string {
    return `operator-${operatorId}`;
}

function createEdgeId(from: string, to: string): string {
    return `edge-${from}-${to}`;
}

function createStageRootGraphNode(
    id: string,
    groupId: string,
    stage: StageNodeInfo
): GraphNode<StageStatisticsProps> {
    return {
        id,
        groupId,
        Component: StageStatistics,
        data: {
            stage,
        },
        height: 245,
        width: 300,
    };
}

function createOperatorGraphNode(
    id: string,
    groupId: string,
    data: PlanNodeInfo
): GraphNode<PlanNodeInfo> {
    return {
        id,
        groupId,
        Component: PlanNode,
        data,
        height: 85,
        width: 200,
    };
}

function createRemoteSourceGraphNode(
    id: string,
    groupId: string,
    data: StageStats
): GraphNode<StageStats> {
    return {
        id,
        groupId,
        width: 100,
        height: 80,
        Component: RemoteSource,
        data,
    };
}

function createGroupGraphNode(id: string, stage: StageNodeInfo): GraphGroup {
    const stroke = getColorByState(stage.state, stage.stageStats.fullyBlocked);
    const fill = alpha(stroke, 0.5);
    return {
        id,
        Component: () => null,
        data: {},
        fill,
        stroke,
    };
}

interface QueryLivePlanGraph {
    nodes: GraphNode[];
    edges: GraphEdge[];
    groups: GraphGroup[];
}

export function calculateGraph(query: SingleQueryPlanDetails): QueryLivePlanGraph {
    const stages = getStages(query);
    const nodes: GraphNode[] = [];
    const groups: GraphGroup[] = [];
    const edges: GraphEdge[] = [];

    stages.forEach((stage) => {
        const groupId = createGroupId(stage.id);
        groups.push(createGroupGraphNode(groupId, stage) as GraphGroup<unknown> as GraphGroup);
        const stageRootNodeId = createStageNodeId(stage.id);
        nodes.push(
            createStageRootGraphNode(
                stageRootNodeId,
                groupId,
                stage
            ) as GraphNode<unknown> as GraphNode
        );
        edges.push({
            id: createEdgeId('node-' + stage.root, stageRootNodeId),
            from: createOperatorNodeId(stage.root),
            to: stageRootNodeId,
            hidden: true,
        });

        stage.nodes.forEach((stageNode) => {
            const nodeId = createOperatorNodeId(stageNode.id);

            stageNode.sources.forEach((source: string) => {
                edges.push({
                    id: createEdgeId('node-' + source, nodeId),
                    from: createOperatorNodeId(source),
                    to: nodeId,
                });
            });

            if (stageNode.remoteSources.length > 0) {
                stageNode.remoteSources.forEach((sourceId: string) => {
                    const source = stages.get(sourceId);
                    if (source) {
                        nodes.push(
                            createRemoteSourceGraphNode(
                                nodeId,
                                groupId,
                                source.stageStats
                            ) as GraphNode<unknown> as GraphNode
                        );
                        edges.push({
                            id: createEdgeId('node-' + sourceId, nodeId),
                            from: createStageNodeId(sourceId),
                            to: nodeId,
                        });
                    }
                });
            } else {
                nodes.push(
                    createOperatorGraphNode(
                        nodeId,
                        groupId,
                        stageNode
                    ) as GraphNode<unknown> as GraphNode
                );
            }
        });
    });

    return {
        nodes,
        groups,
        edges,
    };
}
