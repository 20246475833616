/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import Box from '@mui/material/Box';
import { Toggle } from '../../../components/toggle/Toggle';
import { FilterChips } from './FilterChips';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import Card from '@mui/material/Card';
import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { FilterRow, FiltersRowModel } from './FilterRow';
import { FilterBy, Filter } from './auditLogFilters';
import { v4 as uuidv4 } from 'uuid';
import IconButton from '@mui/material/IconButton';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { addDays, startOfHour } from 'date-fns';
import { createUseStyles } from 'react-jss';

const newFilter = (
    filterBy: FilterBy,
    filterValue: FiltersRowModel['filterValue'] = null
): FiltersRowModel => ({
    id: uuidv4(),
    filterBy,
    filterValue,
    valid: true,
});

export const newDefaultFilter = () => newFilter('startDate', startOfHour(addDays(new Date(), -1)));

const useStyles = createUseStyles({
    card: {
        marginBottom: '16px',
    },
    filtersRow: {
        minHeight: '32px',
    },
});

interface AuditLogFilterProps {
    appliedFilters: FiltersRowModel[];
    setAppliedFilters: (appliedFilters: FiltersRowModel[]) => void;
    allowedFilters: Map<FilterBy, Filter>;
}

export const AuditLogFilter: React.FunctionComponent<AuditLogFilterProps> = ({
    appliedFilters,
    setAppliedFilters,
    allowedFilters,
}) => {
    const classes = useStyles();
    const [filters, setFilters] = useState<FiltersRowModel[]>(appliedFilters);

    const onlyAllowedFilters = useMemo(
        () => filters.filter((it) => allowedFilters.get(it.filterBy)),
        [filters, allowedFilters]
    );

    const applyFilters = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        setAppliedFilters(filters.filter(({ filterValue, valid }) => valid && filterValue));
    };

    const setFiltersElement =
        (id: string) =>
        (setFn: (prevState: FiltersRowModel) => FiltersRowModel): void => {
            setFilters((prevState) =>
                prevState.map((filterRow) => (filterRow.id !== id ? filterRow : setFn(filterRow)))
            );
        };

    const availableFilters = (ignoreFilter?: FiltersRowModel): FilterBy[] =>
        Array.from(allowedFilters.keys()).filter(
            (auditLogFilter) =>
                !filters
                    .filter((filter) => !ignoreFilter || filter.id !== ignoreFilter.id)
                    .map((filterRow) => filterRow.filterBy)
                    .includes(auditLogFilter)
        );

    const resetFilters = (): void => setFilters([newDefaultFilter()]);

    const addNewFilter = (): void => {
        const availableFilterBy = availableFilters();
        if (availableFilterBy.length) {
            setFilters((prevState) => [...prevState, newFilter(availableFilterBy[0], null)]);
        }
    };

    const canRemoveFilter = onlyAllowedFilters.length > 1;

    const deleteFilter = (id: string) => (): void => {
        if (canRemoveFilter) {
            setFilters((prevState) => prevState.filter((filter) => filter.id !== id));
        } else {
            resetFilters();
        }
    };

    const deleteFilterAndApplyChange = (filterId: string): void => {
        const filtersToPreserve = filters.filter((filter) => filter.id !== filterId);
        setFilters(filtersToPreserve);
        setAppliedFilters(
            filtersToPreserve.filter(({ filterValue, valid }) => valid && filterValue)
        );
    };

    const createDeleteRowComponent = useCallback(
        (id: string): ReactElement<HTMLElement> => (
            <div style={{ marginTop: '8px' }}>
                <IconButton
                    onClick={deleteFilter(id)}
                    disabled={!canRemoveFilter}
                    title="Delete filter"
                    color="primary"
                    style={{ padding: '8px' }}
                    size="large">
                    <RemoveCircleOutlineIcon />
                </IconButton>
            </div>
        ),
        [deleteFilter, canRemoveFilter]
    );

    return (
        <Card className={classes.card}>
            <Box m={1} ml={2}>
                <Toggle
                    id="searchToggle"
                    label={(expanded) => (expanded ? 'Hide filters' : 'Show filters')}
                    summary={(expanded): JSX.Element | null =>
                        expanded ? null : (
                            <FilterChips
                                appliedFilters={appliedFilters}
                                removeFilter={deleteFilterAndApplyChange}
                                allowedFilters={allowedFilters}
                            />
                        )
                    }
                    className={classes.filtersRow}>
                    <form noValidate autoComplete="off" onSubmit={applyFilters}>
                        <Box mt={2}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    {onlyAllowedFilters.map((filter) => (
                                        <FilterRow
                                            key={filter.id}
                                            model={filter}
                                            setModel={setFiltersElement(filter.id)}
                                            availableFilters={availableFilters(filter)}
                                            DeleteRowComponent={createDeleteRowComponent(filter.id)}
                                            allowedFilters={allowedFilters}
                                        />
                                    ))}
                                </Grid>

                                <Grid item xs={12}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={addNewFilter}
                                        disabled={availableFilters().length === 0}
                                        style={{ marginTop: '4px' }}>
                                        <FontAwesomeIcon
                                            icon={faPlus}
                                            style={{ marginRight: '8px' }}
                                        />
                                        And
                                    </Button>
                                </Grid>

                                <Grid item xs={12}>
                                    <Box display="flex" justifyContent={'center'} mb={1}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={!onlyAllowedFilters.every((f) => f.valid)}>
                                            Apply filters
                                        </Button>

                                        <Button
                                            style={{ marginLeft: '0.5rem' }}
                                            variant="outlined"
                                            color="primary"
                                            onClick={resetFilters}>
                                            Reset filters
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </form>
                </Toggle>
            </Box>
        </Card>
    );
};
