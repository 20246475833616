/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';
import { createUseStyles } from 'react-jss';
import Button from '@mui/material/Button';
import { killQuery } from '../../../api/queryApi';
import Box from '@mui/material/Box';

const useStyles = createUseStyles({
    root: {
        marginLeft: '1rem',
    },
});

interface QueryKillProps {
    queryId: string;
    displayKillQuery: boolean;
}

export const QueryKill: React.FunctionComponent<QueryKillProps> = ({
    queryId,
    displayKillQuery,
}) => {
    const classes = useStyles();
    if (!displayKillQuery) {
        return <></>;
    }
    return (
        <Box className={classes.root}>
            <Button variant="contained" color="primary" onClick={() => killQuery(queryId)}>
                Kill query
            </Button>
        </Box>
    );
};
