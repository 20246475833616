/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { fromEvent, merge, Observable } from 'rxjs';
import { map, mergeMap, pairwise, takeUntil, throttleTime } from 'rxjs/operators';

interface Move {
    movementX: number;
    movementY: number;
}

export function createDragObservable$(element: Element): Observable<Move> {
    const move$ = fromEvent<MouseEvent>(element, 'mousemove');
    const down$ = fromEvent<MouseEvent>(element, 'mousedown');
    const up$ = fromEvent<MouseEvent>(element, 'mouseup');
    const leave$ = fromEvent<MouseEvent>(element, 'mouseleave');
    const abortMoveListening$ = merge(up$, leave$);

    return down$.pipe(
        mergeMap((down) =>
            move$.pipe(
                map((e) => {
                    e.preventDefault();
                    return {
                        movementX: e.clientX - down.clientX,
                        movementY: e.clientY - down.clientY,
                    };
                }),
                takeUntil(abortMoveListening$),
                throttleTime(30),
                pairwise(),
                map(([a, b]) => ({
                    movementX: b.movementX - a.movementX,
                    movementY: b.movementY - a.movementY,
                }))
            )
        )
    );
}
