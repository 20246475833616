/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';
import { ChartWrapper } from '../../../components/chart/ChartWrapper';
import { TimeLineChart } from '../../../components/chart/TimeLineChart';
import { CpuLoadHistory } from '../../../api/overviewApi';
import { mergeLines, prepareLineChartData } from '../../../utils/lineChart';
import { defaultChartPalette } from '../../../themes/palette';
import { ChartPoint } from 'chart.js';
import { percentageValueFormatter } from '../../../utils/percentageValueFormatter';

interface CpuUsageChartProps {
    cpuUsage: CpuLoadHistory | 'loading' | 'error';
    height: number;
}

export const CpuUsageChart: React.FunctionComponent<CpuUsageChartProps> = ({
    cpuUsage,
    height,
}) => {
    const processCpuUsage = prepareLineChartData(
        'JVM process',
        cpuUsage,
        'time',
        ({ metric: { processCpuUsage } }) => processCpuUsage,
        { fill: 'origin', color: defaultChartPalette[3] }
    );
    const systemCpuUsage = prepareLineChartData(
        'Operating system',
        cpuUsage,
        'time',
        ({ metric: { systemCpuUsage } }) => systemCpuUsage,
        {
            fill: '+1',
            color: defaultChartPalette[1],
        }
    );
    const data = mergeLines([systemCpuUsage, processCpuUsage]);

    const legendValueFormatter = (
        value: number | null | undefined | number[] | ChartPoint
    ): string => {
        if (!value) {
            return '-';
        }

        return percentageValueFormatter((value as ChartPoint).y as number);
    };

    return (
        <ChartWrapper
            data={data}
            title={'CPU usage'}
            height={height}
            legend={{
                type: 'dataset',
                variant: 'inline',
                valueFormatter: legendValueFormatter,
            }}>
            {(chartData): React.ReactNode => (
                <TimeLineChart
                    data={chartData}
                    height={height}
                    fixedUnit={'minute'}
                    percentageYAxis={true}
                    tooltipValueFormatter={percentageValueFormatter}
                    yTicksLimit={3}
                    xTicksLimit={5}
                    xTicksMaxRotation={0}
                    tooltipDateFormat={{
                        '12h': 'h:mm aaa',
                        '24h': 'H:mm',
                    }}
                    labelWidth={50}
                />
            )}
        </ChartWrapper>
    );
};
