/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';
import { LicenseFeature, LicenseInfo } from '../../api/licenseApi';
import { DetailsItem } from '../../components/details/DetailsItem';
import sortBy from 'lodash/sortBy';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExternalLink, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { palette, paletteSwitch } from '../../themes/palette';
import { createUseStyles } from 'react-jss';
import Divider from '@mui/material/Divider';
import { getFormattedExpiryDate } from './licenseUtil';
import { Theme } from '@mui/material/styles';

interface LicenseInformationProps {
    licenseInfo: LicenseInfo;
}

interface LicenseFeatureProps {
    licenseFeature: LicenseFeature;
}

const useStyles = createUseStyles((theme: Theme) => ({
    licenseInfoItems: {
        display: 'inline',
        width: '100%',
    },
    fontStyle: {
        margin: '0 10px 0 0',
        width: '20px !important',
    },
    item: {
        marginBottom: '1rem',
        display: 'inline-block',
        width: `${100 / 3}%`,
    },
    itemValue: {
        fontSize: '1rem',
    },
    featureItemStyle: {
        display: 'flex',
        marginBottom: '1rem',
        alignItems: 'center',
        fontSize: '1rem',
    },
    featuresUl: {
        columns: 3,
        paddingInlineStart: 0,
        fontSize: 0,
    },
    featuresUlText: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    docsLink: {
        marginBottom: '2rem',
        fontSize: '1rem',
    },
    keyStyle: {
        display: 'flex',
        fontSize: '0.75rem',
        fontWeight: 400,
        marginBottom: '1rem',
        color: paletteSwitch(theme).black54,
        textTransform: 'uppercase',
    },
    divider: {
        margin: '0.5rem 0 1.5rem',
        borderColor: paletteSwitch(theme).nebulaNavy100,
    },
    '@media (max-width: 1600px)': {
        featuresUl: {
            columns: 2,
        },
        item: {
            width: '50%',
        },
    },
    '@media (max-width: 1100px)': {
        featuresUl: {
            columns: 1,
        },
        item: {
            width: '50%',
        },
    },
}));

const LicenseBasicInfo: React.FunctionComponent<LicenseInformationProps> = ({ licenseInfo }) => {
    const basicInfoClasses = useStyles();
    const itemClasses = {
        item: basicInfoClasses.item,
        value: basicInfoClasses.itemValue,
    };
    return (
        <>
            <div className={basicInfoClasses.licenseInfoItems}>
                <DetailsItem header={'Owner'} value={licenseInfo.owner} classes={itemClasses} />
                <DetailsItem
                    header={'License type'}
                    value={licenseInfo.tier}
                    classes={itemClasses}
                />
                <DetailsItem
                    header={'Expiry'}
                    value={getFormattedExpiryDate(licenseInfo.expiry)}
                    classes={itemClasses}
                />
            </div>
        </>
    );
};

const FeaturesItem: React.FunctionComponent<LicenseFeatureProps> = ({ licenseFeature }) => {
    const featureClasses = useStyles();
    return (
        <li className={featureClasses.featureItemStyle} key={licenseFeature.code}>
            <FontAwesomeIcon
                color={licenseFeature.enabled ? palette.teal : palette.error}
                icon={licenseFeature.enabled ? faCheck : faTimes}
                className={featureClasses.fontStyle}
            />
            <div className={featureClasses.featuresUlText}>{licenseFeature.displayName}</div>
        </li>
    );
};

const DocsLink: React.FunctionComponent<LicenseInformationProps> = ({ licenseInfo }) => {
    const docsClasses = useStyles();
    return (
        <div className={docsClasses.docsLink}>
            <span>
                To find out more information on all the features included in this version,
                <Link href={licenseInfo.docUrl} color="secondary" target={'_blank'}>
                    &nbsp;read more in the documentation&nbsp;
                    <FontAwesomeIcon
                        color={'secondary'}
                        icon={faExternalLink}
                        className={docsClasses.fontStyle}
                    />
                </Link>
            </span>
        </div>
    );
};

export const LicenseInformation: React.FunctionComponent<LicenseInformationProps> = ({
    licenseInfo,
}) => {
    const classes = useStyles();

    const extractFeatures = (licenseInfo: LicenseInfo) => {
        return sortBy(licenseInfo.features, ['displayName']).map((feature) => (
            <FeaturesItem licenseFeature={feature} key={feature.code} />
        ));
    };

    return (
        <>
            <LicenseBasicInfo licenseInfo={licenseInfo} />
            <Divider className={classes.divider} />
            <div className={classes.keyStyle}>Features</div>
            <DocsLink licenseInfo={licenseInfo} />
            <ul className={classes.featuresUl}>{extractFeatures(licenseInfo)}</ul>
        </>
    );
};
