/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';
import { createUseStyles } from 'react-jss';
import { paletteSwitch } from '../../../themes/palette';
import { Column } from '../../../api/dataProduct/dataProductApi';
import { ViewSchema } from '../components/ViewSchema';
import { ColumnNameDescription } from './publishFormReducer';
import { Theme } from '@mui/material/styles';

interface DataProductDatasetProps {
    datasetName: string;
    columns: Column[];
    updateColumnDescription: (column: ColumnNameDescription) => void;
}

const useStyles = createUseStyles((theme: Theme) => ({
    header: {
        fontSize: '1.125rem',
        paddingBottom: '1.5rem',
    },
    helperText: {
        fontSize: '1rem',
        color: paletteSwitch(theme).black54,
        paddingBottom: '1.5rem',
    },
}));

export const DataProductDataset: React.FunctionComponent<DataProductDatasetProps> = ({
    datasetName,
    columns,
    updateColumnDescription,
}) => {
    const classes = useStyles();
    return (
        <div>
            <div className={classes.header}>
                Provide column description for Dataset detail: {datasetName}
            </div>
            <div className={classes.helperText}>
                You can fill in Column description below to provide better context. This step is
                optional.
            </div>
            <ViewSchema columns={columns} updateColumnDescription={updateColumnDescription} />
        </div>
    );
};
