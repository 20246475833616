/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    InputDataBySource,
    InputDataByTable,
    InputDataByUser,
    LeastActiveQueries,
    OutputDataBySource,
    OutputDataByTable,
    OutputDataByUser,
    QueriesBySource,
    QueriesByTable,
    QueriesByTime,
    QueriesByUser,
    CpuTimeByUser,
    CpuTimeBySource,
} from '../../../api/queryReportApi';
import { AppThunk } from '../../../app/store';
import { FilterCriteria } from '../history/queryFilters';

type Report<T> = T | 'loading' | 'error';

export interface ReportSlice {
    updateDate: Date | null;
    reports: {
        queriesByUser?: Report<QueriesByUser>;
        usersByCpuTime?: Report<CpuTimeByUser>;
        queriesByTime?: Report<QueriesByTime>;
        inputDataByUser?: Report<InputDataByUser>;
        outputDataByUser?: Report<OutputDataByUser>;
        queriesByTable?: Report<QueriesByTable>;
        queriesBySource?: Report<QueriesBySource>;
        leastActiveQueries?: Report<LeastActiveQueries>;
        inputDataBySource?: Report<InputDataBySource>;
        outputDataBySource?: Report<OutputDataBySource>;
        inputDataByTable?: Report<InputDataByTable>;
        outputDataByTable?: Report<OutputDataByTable>;
        cpuTimeBySource?: Report<CpuTimeBySource>;
    };
}

const initialState: ReportSlice = {
    updateDate: null,
    reports: {},
};

const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        clearReports: (state): void => {
            state.updateDate = null;
            state.reports = {};
        },
        setUpdateDate: (state, action: PayloadAction<Date | null>): void => {
            state.updateDate = action.payload;
        },
        setReport: <T>(
            { reports }: ReportSlice,
            {
                payload,
            }: PayloadAction<{
                data: Report<T>;
                reportName: keyof ReportSlice['reports'];
            }>
        ): void => {
            (reports[payload.reportName] as Report<T>) = payload.data;
        },
    },
});

export const { clearReports } = reportSlice.actions;
const { setReport, setUpdateDate } = reportSlice.actions;

export const fetchReport =
    <K extends keyof ReportSlice['reports'], T extends ReportSlice['reports'][K]>(
        reportName: K,
        fetchFn: (filters: FilterCriteria | null) => Promise<T>
    ): AppThunk =>
    async (dispatch, getState): Promise<void> => {
        dispatch(setReport({ data: 'loading', reportName }));
        try {
            const result = await fetchFn(getState().queryHistory.filters);
            dispatch(setReport({ data: result, reportName }));

            // set updateDate when the first report is fetched
            if (!getState().report.updateDate) {
                dispatch(setUpdateDate(new Date()));
            }
        } catch {
            dispatch(setReport({ data: 'error', reportName }));
        }
    };

export default reportSlice.reducer;
