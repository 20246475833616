/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';
import { createUseStyles } from 'react-jss';

interface ExternalLogoProps {
    imgPath: string;
}

const useStyles = createUseStyles({
    logoImg: {
        position: 'relative',
        maxWidth: '18.75rem',
        maxHeight: '9.375rem',
    },
});

export const ExternalLogo: React.FunctionComponent<ExternalLogoProps> = ({ imgPath }) => {
    const classes = useStyles();

    return <img className={classes.logoImg} src={imgPath} alt="External Logo" />;
};
