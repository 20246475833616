/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';

export const dataProductModuleRootPath = '/dataproduct';
export const dataProductManagementPath = `${dataProductModuleRootPath}/product`;
export const dataProductCreatePath = `${dataProductManagementPath}/create/:dataProductId?`;
export const dataProductDetailsPath = `${dataProductManagementPath}/display/:dataProductId`;
export const dataProductUpdatePath = `${dataProductManagementPath}/update/:dataProductId`;

export const domainManagementPath = `${dataProductModuleRootPath}/domain`;

export interface DataProductWizardAddDatasetEvent {
    type: 'addDataset';
    name: string;
    query: string;
    navigateToNewDataset: boolean;
}

export type DataProductWizardInputEvent = DataProductWizardAddDatasetEvent | undefined;

export function useDataProductDashboardRedirection(): () => void {
    const history = useHistory();
    return useCallback(() => {
        history.push(dataProductManagementPath);
    }, []);
}

function createDataProductCreatePath(dataProductId = ''): string {
    return dataProductCreatePath.replace(':dataProductId?', dataProductId);
}

export function useDataProductCreateRedirection(
    dataProductId = '',
    state?: DataProductWizardInputEvent
): () => void {
    const history = useHistory();
    return useCallback(() => {
        history.push(createDataProductCreatePath(dataProductId), state);
    }, [dataProductId, state]);
}

export function createDataProductDetailsPath(dataProductId: string): string {
    return dataProductDetailsPath.replace(':dataProductId', dataProductId);
}

export function useDataProductDetailsRedirection(dataProductId: string): () => void {
    const history = useHistory();
    return useCallback(() => {
        history.push(createDataProductDetailsPath(dataProductId));
    }, [dataProductId]);
}

function createDataProductUpdatePath(dataProductId: string): string {
    return dataProductUpdatePath.replace(':dataProductId', dataProductId);
}

export function useDataProductUpdateRedirection(
    dataProductId: string,
    state?: DataProductWizardInputEvent
): () => void {
    const history = useHistory();
    return useCallback(() => {
        history.push(createDataProductUpdatePath(dataProductId), state);
    }, [dataProductId, state]);
}
