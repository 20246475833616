/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React, { useEffect } from 'react';
import { FormState, useForm } from 'react-hook-form';
import Alert from '@mui/material/Alert';
import { UpdatedWorksheet, Worksheet } from '../../../api/savedQueriesApi';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { LoadingButton } from '../../../components/LoadingButton';
import { InputField } from '../../../components/InputField';

interface SavedQueryEditFormProps {
    worksheet: UpdatedWorksheet;
    errorMessage: string;
    onSubmit: (worksheet: UpdatedWorksheet) => Promise<void>;
    onClose: () => void;
}

export const SavedQueryEditForm: React.FunctionComponent<SavedQueryEditFormProps> = ({
    worksheet,
    errorMessage,
    onSubmit,
    onClose,
}) => {
    const { handleSubmit, formState, control, reset } = useForm<Worksheet>({
        defaultValues: worksheet,
    });

    useEffect(() => {
        reset(worksheet);
    }, [worksheet, reset]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
                {errorMessage && (
                    <Grid item xs={12}>
                        <Alert severity="error">{errorMessage}</Alert>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <InputField
                        variant="outlined"
                        control={control}
                        name="name"
                        label="Name"
                        fullWidth={true}
                        autoFocus={true}
                        rules={{
                            required: 'You must specify the name',
                        }}
                    />
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        variant="outlined"
                        style={{ marginRight: '1rem' }}
                        onClick={() => onClose()}>
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        loading={formState.isValidating || formState.isSubmitting}
                        disabled={!isDirty(formState)}>
                        Save changes
                    </LoadingButton>
                </Grid>
            </Grid>
        </form>
    );
};
export const isDirty = (formState: FormState<any>) => !!Object.keys(formState.dirtyFields).length;
