/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { prism, a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { useThemeMode } from '../../app/UIThemeContextProvider';

interface SqlHighlightProps {
    sql: string;
    lines?: number;
    ellipsis?: ReactElement<HTMLElement>;
    webkitClamp?: boolean;
    className?: string;
    fontSize?: string;
    condensedSql?: boolean;
}

const useStyles = createUseStyles({
    code: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        whiteSpace: 'pre-wrap!important' as any,
        fontFamily: 'Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace',
        fontSize: '0.75rem',
        overflow: 'hidden',
        display: 'block',
        '& span': {
            backgroundColor: 'transparent!important',
        },
    },
});

const lineHeightDiff = '0.5rem';

export const SqlHighlight: React.FunctionComponent<SqlHighlightProps> = ({
    sql,
    lines = 2,
    ellipsis = null,
    webkitClamp = false,
    className,
    fontSize = '0.75rem',
    condensedSql = true,
}) => {
    const classes = useStyles();
    const themeMode = useThemeMode();
    const [ellipsisDetected, setEllipsisDetected] = useState(false);
    const componentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const scrollHeight = componentRef?.current?.scrollHeight;
        const offsetHeight = componentRef?.current?.offsetHeight;

        if (scrollHeight && offsetHeight) {
            setEllipsisDetected(scrollHeight > offsetHeight);
        }
    }, []);

    const standardizeSQL = (sql: string): string => {
        // replaces all whitespaces with single space
        return sql
            .replace(/--.*\n/g, ' ') // drop -- sql comments
            .replace(/\/\*[\s\S]*\*\//g, ' ') // drop /* */ SQL comments
            .replace(/(\r\n|\n|\r)/gm, ' ') // replace all whitespaces with a space
            .replace(/\s\s+/g, ' ') // replace all whitespace with single space
            .trim();
    };

    const commonStyles = useMemo(
        () => ({
            fontSize,
            lineHeight: `calc(${lineHeightDiff} + ${fontSize})`,
        }),
        [fontSize]
    );

    return (
        <>
            <div
                ref={componentRef}
                style={{
                    maxHeight: `calc(${lines} * (${fontSize} + ${lineHeightDiff}))`,
                    overflow: 'hidden',
                }}>
                <SyntaxHighlighter
                    customStyle={{
                        margin: '0',
                        marginBottom: '0',
                        background: 'transparent',
                        padding: 0,
                    }}
                    codeTagProps={{
                        className: clsx(classes.code, className),
                        style: webkitClamp
                            ? ({
                                  ...commonStyles,
                                  WebkitLineClamp: `${lines}`,
                                  textOverflow: 'ellipsis',
                                  display: '-webkit-box',
                                  WebkitBoxOrient: 'vertical',
                              } as React.CSSProperties)
                            : commonStyles,
                    }}
                    language="sql"
                    style={themeMode === 'light' ? prism : a11yDark}>
                    {condensedSql ? standardizeSQL(sql) : sql}
                </SyntaxHighlighter>
            </div>
            {ellipsisDetected ? ellipsis : null}
        </>
    );
};
