/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { ReactElement, useState } from 'react';
import { FullScreenDialog } from '../../../../components/dialog/FullScreenDialog';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faUndo } from '@fortawesome/pro-regular-svg-icons';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { prism, a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { createUseStyles } from 'react-jss';
import { formatDialect, trino } from 'sql-formatter';
import { palette } from '../../../../themes/palette';
import { useThemeMode } from '../../../../app/UIThemeContextProvider';

interface QueryFullTextProps {
    text: string;
    children: (open: () => void) => ReactElement<HTMLElement>;
    lineHighlight?: number;
}

const useStyles = createUseStyles({
    dialogIcon: {
        height: '1.5rem!important',
        width: '1.5rem!important',
    },
    code: {
        whiteSpace: 'pre',
        '& .token': {
            backgroundColor: 'transparent!important',
        },
    },
    fallbackText: {
        fontFamily: 'Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace',
        padding: '1.5rem 0 0 1.5rem',
    },
});

export const QueryFullTextDialog: React.FunctionComponent<QueryFullTextProps> = ({
    text,
    children,
    lineHighlight,
}) => {
    const classes = useStyles();
    const themeMode = useThemeMode();
    const [prettyPrint, setPrettyPrint] = useState(false);
    const syntaxHighlightingEnabled = text.length < 25000;
    const queryText =
        prettyPrint && syntaxHighlightingEnabled
            ? formatDialect(text, {
                  dialect: trino,
                  linesBetweenQueries: 2,
                  tabWidth: 4,
              })
            : text;

    const tooltipText = (): string => {
        if (!syntaxHighlightingEnabled) {
            return 'Pretty print not available';
        }

        return prettyPrint ? 'Undo pretty print' : 'Pretty print';
    };

    return (
        <FullScreenDialog
            header="Query text"
            textToCopy={queryText}
            onBeforeOpen={(): void => setPrettyPrint(false)}
            actions={
                <Tooltip title={tooltipText()}>
                    <span>
                        <IconButton
                            edge="end"
                            color="inherit"
                            disabled={!syntaxHighlightingEnabled}
                            style={{ marginRight: '0.25rem' }}
                            onClick={(): void => setPrettyPrint(!prettyPrint)}
                            size="large">
                            <FontAwesomeIcon
                                className={classes.dialogIcon}
                                icon={prettyPrint ? faUndo : faCode}
                            />
                        </IconButton>
                    </span>
                </Tooltip>
            }
            button={children}>
            <Box pb={2} className={classes.code}>
                {syntaxHighlightingEnabled ? (
                    <SyntaxHighlighter
                        showLineNumbers={true}
                        customStyle={{
                            background: 'transparent',
                            display: 'flex',
                            flexDirection: 'column',
                            overflow: 'none',
                        }}
                        lineNumberStyle={{ marginRight: '2rem' }}
                        language="sql"
                        style={themeMode === 'light' ? prism : a11yDark}
                        wrapLines={true}
                        lineProps={(lineNumber: number): React.HTMLProps<HTMLElement> => ({
                            style:
                                !prettyPrint && lineNumber === lineHighlight
                                    ? {
                                          backgroundColor: palette.errorLight,
                                          borderRadius: '2px',
                                          display: 'block',
                                          minWidth: 'max-content',
                                      }
                                    : undefined,
                        })}>
                        {queryText}
                    </SyntaxHighlighter>
                ) : (
                    <div className={classes.fallbackText}>{text}</div>
                )}
            </Box>
        </FullScreenDialog>
    );
};
