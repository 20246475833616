/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { FunctionComponent } from 'react';
import Button from '@mui/material/Button';
import { createUseStyles } from 'react-jss';
import { paletteSwitch } from '../../../themes/palette';
import Box from '@mui/material/Box';
import { TextFieldWithMarkdownPreview } from '../../../components/markDown/TextFieldWithMarkdownPreview';
import { useTextInputValueChange } from '../../../utils/useTextInputValueChange';
import { Theme } from '@mui/material/styles';
import clsx from 'clsx';

const useStyles = createUseStyles((theme: Theme) => ({
    buttonContainer: {
        marginTop: '1.5rem',
        marginLeft: 'auto',
        float: 'right',
    },
    cancelButton: {
        marginRight: '1rem',
    },
    commentContentBox: {
        border: `1px solid ${paletteSwitch(theme).nebulaNavy50}`,
        backgroundColor: paletteSwitch(theme).nebulaNavy25,
    },
}));

interface CommentContentEditorProps {
    content: string;
    onContentChange: (newContent: string) => void;
    onConfirm: () => void;
    onCancel: () => void;
    className?: string;
}

export const CommentContentEditor: FunctionComponent<CommentContentEditorProps> = ({
    content,
    onContentChange,
    onConfirm,
    onCancel,
    className,
}: CommentContentEditorProps) => {
    const styles = useStyles();
    const handleContentChange = useTextInputValueChange(
        (value: string) => onContentChange(value),
        [onContentChange]
    );
    return (
        <Box className={clsx(className, styles.commentContentBox)} borderRadius="4px">
            <TextFieldWithMarkdownPreview
                value={content}
                placeholder="Write a comment"
                onChange={handleContentChange}
                minRows={4}
                previewContentMinHeight={82}
            />
            <Box display="flex" m={1} justifyContent="flex-end" alignItems="flex-end">
                <Button
                    className={styles.cancelButton}
                    color="primary"
                    variant="outlined"
                    onClick={onCancel}>
                    Cancel
                </Button>
                <Button
                    disabled={content.length === 0}
                    color="primary"
                    variant="contained"
                    onClick={onConfirm}>
                    Comment
                </Button>
            </Box>
        </Box>
    );
};
