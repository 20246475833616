/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { ClusterInfo, getClusterInfo, UiFeatures } from '../api/configApi';
import { Loadable } from '../utils/loadable';
import { useRoleHeader } from '../features/biac/CurrentRoleContext';
import { Spinner } from '../components/spinner/Spinner';
import { ErrorBox } from '../components/error/ErrorBox';
import axios from 'axios';
import { handleRequest } from '../api/biac/common';

interface ConfigContextProviderProps {
    children: ReactElement;
}

const defaultDocsUrl = 'https://docs.starburst.io/latest';
type ConfigContextValue = ClusterInfo | undefined;
export const ConfigContext = React.createContext<ConfigContextValue>(undefined);

export const ConfigContextProvider: React.FunctionComponent<ConfigContextProviderProps> = ({
    children,
}) => {
    const [clusterInfo, setClusterInfo] = useState<Loadable<ClusterInfo>>('loading');
    const roleHeader = useRoleHeader();
    useEffect(() => {
        setAxiosInterceptor();
        fetchClusterInfo();
    }, [roleHeader]);

    const setAxiosInterceptor = () => {
        axios.interceptors.request.use(handleRequest);
    };
    const fetchClusterInfo = useCallback(() => {
        getClusterInfo()
            .then((info) => setClusterInfo(info))
            .catch(() => setClusterInfo('error'));
    }, [clusterInfo]);

    if (clusterInfo === 'loading') {
        return <Spinner position={'absolute'} />;
    } else if (clusterInfo === 'error') {
        return <ErrorBox text={'Could not load the page. Unexpected error occurred'} />;
    }
    return <ConfigContext.Provider value={clusterInfo}>{children}</ConfigContext.Provider>;
};
export const useConfigContext = (): ConfigContextValue => useContext(ConfigContext);
export const useAllowedUiFeatures = () => {
    return useConfigContext()?.allowedUiFeatures;
};
export const uiFeatureEnabled = (uiFeature: UiFeatures): boolean | undefined =>
    useContext(ConfigContext)?.allowedUiFeatures.some((info) => {
        return info.feature === uiFeature;
    });
export const useDocUrl = (): string => useConfigContext()?.docsUrl ?? defaultDocsUrl;
export const useClientDocUrl = (): string => `${useDocUrl()}/client.html`;
