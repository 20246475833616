/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import Grid from '@mui/material/Grid';
import { createUseStyles } from 'react-jss';
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import {
    DataProductForm,
    DatasetType,
    getDataProduct,
    getTags$,
} from '../../api/dataProduct/dataProductApi';
import { paletteSwitch } from '../../themes/palette';
import { DataProductBasicInfo } from './publish/DataProductBasicInfo';
import { DataProductDefinition } from './publish/DataProductDefinition';
import { DataProductDetails } from './publish/DataProductDetails';
import { DataProductPublishSummary } from './publish/DataProductPublishSummary';
import { DataProductWizardStep } from './publish/DataProductWizardStep';
import { publishFormReducer } from './publish/publishFormReducer';
import { DataDomain, getDomains } from '../../api/dataProduct/dataDomain/dataDomainApi';
import { PageContent } from '../../layout/PageContent';
import { useLocation } from 'react-router-dom';
import { DataProductWizardInputEvent } from './routing/dataProductRoutingUtils';
import { Theme } from '@mui/material/styles';

interface DataProductWizardProps {
    dataProductId?: string;
}

const useStyles = createUseStyles((theme: Theme) => ({
    button: {
        marginBottom: '1rem',
    },
    body: {
        marginTop: '-1rem',
        height: '100%',
    },
    leftSection: {
        paddingRight: '1.5rem',
        paddingBottom: '1.5rem',
        borderRight: `1px solid ${paletteSwitch(theme).nebulaNavy100}`,
    },
    rightSection: {
        paddingTop: '3rem',
        paddingLeft: '1.5rem',
    },
}));

const DATA_SETS_STEP = 2;

export const DataProductWizard: React.FunctionComponent<DataProductWizardProps> = ({
    dataProductId,
}) => {
    const classes = useStyles();
    const location = useLocation();
    const event = location.state as DataProductWizardInputEvent;

    const [wizardStep, setWizardStep] = useState<number>(1);
    const [selectedDatasetIndex, setSelectedDatasetIndex] = useState<number>(0);
    const [formData, publishFormDispatch] = useReducer(publishFormReducer, {
        dataProduct: initialDataProductFormForCreation,
        tags: [],
    });

    useEffect(() => {
        const onNewDatasetEventAction = async (datasetIndexToSelect: number) => {
            if (event?.type === 'addDataset') {
                await publishFormDispatch({
                    type: 'addDataset',
                    dataset: {
                        name: event.name,
                        definitionQuery: event.query,
                    },
                });
                if (event.navigateToNewDataset) {
                    setWizardStep(DATA_SETS_STEP);
                    setSelectedDatasetIndex(datasetIndexToSelect);
                }
            }
        };

        if (!dataProductId) {
            onNewDatasetEventAction(0);
            return;
        }
        getDataProduct(dataProductId).then(async (data) => {
            await publishFormDispatch({
                type: 'setDataProductForm',
                dataProduct: data,
            });
            await onNewDatasetEventAction(data.materializedViews.length + data.views.length);
        });
        const subscription = getTags$(dataProductId).subscribe({
            next: (tags) =>
                publishFormDispatch({
                    type: 'setTags',
                    tags: tags.map(({ value }) => ({ value })),
                }),
            error: (e) => setError(e.message),
        });

        return () => subscription.unsubscribe();
    }, [dataProductId]);

    const [allDomains, setAllDomains] = useState<DataDomain[]>([]);
    useEffect(() => {
        getDomains()
            .then(setAllDomains)
            .catch((e) => setError(e.message));
    }, []);

    const dataDomainId = formData.dataProduct.dataDomainId;
    const selectedDomain = useMemo(() => {
        return allDomains.find((it) => it.id === dataDomainId);
    }, [allDomains, dataDomainId]);

    const [error, setError] = useState<string | undefined>(undefined);

    return (
        <PageContent title={`${!dataProductId ? 'Create' : 'Update'} data product`}>
            <Grid container className={classes.body}>
                <Grid item className={classes.leftSection} lg={9} md={6} sm={12}>
                    <DataProductWizardStep value={wizardStep} index={1}>
                        <DataProductBasicInfo
                            dataProductForm={formData.dataProduct}
                            tags={formData.tags}
                            allDomains={allDomains}
                            error={error}
                            publishFormDispatch={publishFormDispatch}
                            setWizardStep={setWizardStep}
                            setError={setError}
                        />
                    </DataProductWizardStep>
                    <DataProductWizardStep value={wizardStep} index={DATA_SETS_STEP}>
                        <DataProductDefinition
                            dataProductForm={formData.dataProduct}
                            tags={formData.tags}
                            selectedDatasetIndex={selectedDatasetIndex}
                            error={error}
                            publishFormDispatch={publishFormDispatch}
                            setWizardStep={setWizardStep}
                            setSelectedDatasetIndex={setSelectedDatasetIndex}
                            setError={setError}
                        />
                    </DataProductWizardStep>
                    <DataProductWizardStep value={wizardStep} index={3}>
                        <DataProductDetails
                            dataProductForm={formData.dataProduct}
                            publishFormDispatch={publishFormDispatch}
                            tags={formData.tags}
                            error={error}
                            setWizardStep={setWizardStep}
                            setError={setError}
                        />
                    </DataProductWizardStep>
                </Grid>
                <Grid item className={classes.rightSection} lg={3} md={6} sm={12}>
                    <DataProductPublishSummary
                        dataProductForm={formData.dataProduct}
                        tags={formData.tags}
                        domain={selectedDomain}
                        wizardStep={wizardStep}
                        publishFormDispatch={publishFormDispatch}
                        setSelectedDatasetIndex={setSelectedDatasetIndex}
                    />
                </Grid>
            </Grid>
        </PageContent>
    );
};

const initialDataProductFormForCreation: DataProductForm = {
    name: '',
    catalogName: '',
    schemaName: '',
    summary: '',
    description: '',
    dataDomainId: '',
    owners: [],
    datasets: [
        {
            type: DatasetType.VIEW,
            name: '',
            description: '',
            definitionQuery: '',
            columns: [],
        },
    ],
};
