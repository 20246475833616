/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { ExpressionForm } from './ExpressionForm';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Button from '@mui/material/Button';
import { createUseStyles } from 'react-jss';
import { paletteSwitch } from '../../../../themes/palette';
import { Role } from '../../../../api/biac/biacRolesApi';
import { getExpressionRoles, updateExpressionRow } from './rowExpressionApi';
import { Persisted } from '../../../../api/biac/common';
import { Expression } from '../../../../api/biac/biacApi';
import { getEntityDisplayName, RowExpressionType } from '../MasksAndFilters';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { Theme } from '@mui/material/styles';

interface EditExpressionProps {
    rowExpression: Persisted<Expression>;
    rowExpressionType: RowExpressionType;
    close: (shouldRefresh?: boolean) => void;
    deleteExpression: (mask: Persisted<Expression>) => void;
}

const useStyles = createUseStyles((theme: Theme) => ({
    deleteButton: {
        color: paletteSwitch(theme).error,
        fontSize: '0.8125rem',
        marginRight: 'auto',
        '& svg': {
            marginRight: '0.25rem',
        },
    },
    buttonsContainer: {
        padding: '1rem 0',
        display: 'flex',
        justifyContent: 'end',
    },
    roleContainer: {
        display: 'flex',
        borderBottom: `1px solid ${paletteSwitch(theme).nebulaNavy100}`,
    },
    roleItem: {
        fontFamily: 'Barlow',
        fontSize: '14px',
        lineHeight: '24px',
    },
    roleDescription: {
        color: paletteSwitch(theme).black54,
    },
    roleListBox: {
        maxHeight: 'calc(100vh - 38rem)',
        overflowY: 'auto',
        borderTop: `1px solid ${paletteSwitch(theme).nebulaNavy100}`,
    },
}));

export const EditExpression: React.FunctionComponent<EditExpressionProps> = (params) => {
    const classes = useStyles();
    const [rowExpression, setRowExpression] = useState<Expression>({
        ...params.rowExpression.object,
    });
    const [roles, setRoles] = useState<Persisted<Role>[]>();
    const [error, setError] = useState<string>();
    const abortController = useRef<AbortController>();

    useEffect(() => {
        setError(undefined);
    }, [rowExpression]);

    const loadRoles = useCallback(async () => {
        if (abortController.current) {
            abortController.current.abort();
        }
        abortController.current = new AbortController();
        try {
            const roles = await getExpressionRoles(
                params.rowExpression,
                params.rowExpressionType,
                abortController.current
            );
            setRoles(roles);
        } catch (e) {
            if (e.message !== 'cancelled') {
                setError(e.message);
            }
        }
    }, [params.rowExpression, params.rowExpressionType]);

    useEffect(() => {
        setRowExpression({ ...params.rowExpression.object });
        loadRoles();
    }, [params.rowExpression]);

    const isUpdatedEnabled = useMemo(() => {
        return (
            rowExpression.name.trim() == rowExpression.name &&
            rowExpression.expression.trim() !== '' &&
            !(
                (params.rowExpression.object.name ?? '') == rowExpression.name &&
                (params.rowExpression.object.description ?? '') ==
                    (rowExpression.description ?? '') &&
                (params.rowExpression.object.expression ?? '') == rowExpression.expression
            )
        );
    }, [params.rowExpression, rowExpression]);

    const handleSaveChangesClicked = useCallback(async () => {
        try {
            await updateExpressionRow(
                {
                    id: params.rowExpression.id,
                    object: rowExpression,
                },
                params.rowExpressionType
            );
            params.close(true);
        } catch (e) {
            setError(e.message);
        }
    }, [rowExpression, params.rowExpression, params.rowExpressionType]);

    return (
        <>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <span style={{ fontSize: '1.125rem', fontWeight: 600 }}>
                    Edit: {params.rowExpression.object.name}
                </span>
                <IconButton size="small" onClick={() => params.close()} style={{ padding: '6px' }}>
                    <FontAwesomeIcon icon={faTimes} style={{ width: '1rem' }} />
                </IconButton>
            </Box>
            <ExpressionForm
                rowExpression={rowExpression}
                setRowExpression={setRowExpression}
                rowExpressionType={params.rowExpressionType}
                error={
                    error ? (
                        <Alert
                            variant="standard"
                            severity="error"
                            style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                            {error}
                        </Alert>
                    ) : undefined
                }
            />
            {roles && roles.length > 0 && (
                <Typography variant="subtitle2" pb={1}>
                    Applies to roles:
                </Typography>
            )}
            <Box className={classes.roleListBox}>
                {roles &&
                    roles.map((role, index) => (
                        <Box className={classes.roleContainer} p={2} key={index}>
                            <Box key={index} flex={1} className={classes.roleItem}>
                                <div>{role.object.name}</div>
                                <div className={classes.roleDescription}>
                                    {role.object.description}
                                </div>
                            </Box>
                        </Box>
                    ))}
            </Box>
            <div className={classes.buttonsContainer}>
                {roles && roles.length == 0 && (
                    <Button
                        className={classes.deleteButton}
                        size="small"
                        onClick={() => params.deleteExpression(params.rowExpression)}>
                        <DeleteOutline />
                        Delete{getEntityDisplayName(params.rowExpressionType).toLowerCase()}
                    </Button>
                )}

                <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => params.close()}
                    style={{ marginRight: '1rem' }}>
                    Cancel
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleSaveChangesClicked}
                    disabled={!isUpdatedEnabled}>
                    Save changes
                </Button>
            </div>
        </>
    );
};
