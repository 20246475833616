/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import Chart, { ChartData } from 'chart.js';
import { getTopValues } from '../../utils/topValues';
import { ReactElement, useMemo } from 'react';
import { defaultChartPalette } from '../../themes/palette';

interface TopEntitiesChartProps<T extends Record<string, unknown>> {
    data: T[] | 'loading' | 'error' | undefined;
    labelKey: keyof T;
    valueKey: keyof T;
    limit: number;
    children: (data: ChartData | 'loading' | 'error' | undefined) => ReactElement;
    otherLabel?: string;
}

export const defaultOtherLabel = 'Other';

export const TopEntitiesChart = <T extends Record<string, unknown>>({
    children,
    data,
    labelKey,
    limit,
    valueKey,
    otherLabel = defaultOtherLabel,
}: TopEntitiesChartProps<T>): JSX.Element => {
    const prepareChartData = (allEntities: T[]): Chart.ChartData => {
        const chartUsers = getTopValues<T>(limit, labelKey, valueKey, otherLabel)(allEntities);

        return {
            labels: chartUsers.map((u) => (u[labelKey] as unknown as string) || '<unknown>'),
            datasets: [
                {
                    data: chartUsers.map((u) => u[valueKey] as unknown as number),
                    backgroundColor: defaultChartPalette,
                },
            ],
        };
    };

    const chartData = useMemo(() => {
        if (!Array.isArray(data)) {
            return data;
        }

        return prepareChartData(data);
        // eslint-disable-next-line
  }, [data]);

    return children(chartData);
};
