/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { createUseStyles } from 'react-jss';
import { paletteSwitch } from '../../../../themes/palette';
import { Theme } from '@mui/material/styles';

export const useSectionStyles = createUseStyles((theme: Theme) => ({
    header: {
        fontFamily: 'montserrat, sans-serif',
        fontWeight: 600,
        fontSize: '1.125rem',
        padding: '27px 8px 8px 0px',
        lineHeight: '1.6875rem',
        color: paletteSwitch(theme).nebulaNavy,
        letterSpacing: '0.15px',
    },
    subHeader: {
        fontFamily: 'barlow, Roboto Condensed, sans-serif',
        fontWeight: 600,
        fontSize: '0.875rem',
        padding: '8px 0px 8px 0px',
        lineHeight: '1.125rem',
        color: paletteSwitch(theme).black,
        letterSpacing: '0.15px',
    },
    subHeaderValue: {
        fontWeight: 400,
        fontSize: '1rem',
    },
    itemValue: {
        fontSize: '1.125rem',
        fontWeight: 600,
    },
}));
