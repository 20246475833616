/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';
import { createUseStyles } from 'react-jss';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import PlayIcon from '@mui/icons-material/PlayArrow';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import HourglassIcon from '@mui/icons-material/HourglassFull';
import { SingleQueryDetails } from '../../../../api/queryApi';
import { parseISO } from 'date-fns';
import Box from '@mui/material/Box';
import capitalize from 'lodash/capitalize';
import { palette, paletteSwitch } from '../../../../themes/palette';
import { Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { QueryTimelineItem } from './QueryTimelineItem';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { QueryState } from '../../../../api/queriesApi';
import { formatDurationReadable } from '../../../../utils/formatDuration';
import CalendarTodayOutlined from '@mui/icons-material/CalendarTodayOutlined';

const useStyles = createUseStyles((theme: Theme) => ({
    header: {
        fontSize: '1.125rem',
        paddingBottom: '16px',
        fontFamily: 'montserrat, sans-serif',
        fontWeight: 600,
        color: paletteSwitch(theme).nebulaNavy,
    },
    root: {
        height: '100%',
    },
    timeline: {
        padding: '16px 16px 6px 0',
    },
    horizontalSeparator: {
        height: '2px',
        width: '70px',
        backgroundColor: 'rgba(225,225,225)',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    joinPoint: {
        width: '8px',
        height: '8px',
        backgroundColor: 'rgba(225,225,225)',
        borderRadius: '8px',
        position: 'relative',
        top: -3,
        left: 31,
    },
}));

interface QueryTimelineProps {
    query: Readonly<SingleQueryDetails>;
}

export const QueryTimeline: React.FunctionComponent<QueryTimelineProps> = ({ query }) => {
    const classes = useStyles();
    const theme = useTheme();
    const fullWidth = useMediaQuery(theme.breakpoints.down('md'));

    const formatDatetime = (datetime: string): string => {
        return datetime && `${parseISO(datetime).toLocaleString()}`;
    };

    const formatDistance = (millis: number): string => {
        if (millis < 1000) {
            return `${millis} millisecond${millis !== 1 ? 's' : ''}`;
        } else {
            return formatDurationReadable(millis);
        }
    };

    const horizontalSeparator = (
        <Box className={classes.horizontalSeparator}>
            <div className={classes.joinPoint} />
        </Box>
    );

    const getFinalIconColor = (): string => {
        switch (query.state) {
            case 'FINISHED':
                return palette.green;
            case 'FAILED':
                return palette.error;
        }
        return palette.black12;
    };

    const isStateActive = (stateName?: QueryState): boolean => {
        if (query.state === 'FINISHED' || query.state === 'FAILED') {
            return true;
        }
        switch (stateName) {
            case 'WAITING_FOR_RESOURCES':
                return (
                    query.waitingForResourcesTime > 0 ||
                    query.planningTime > 0 ||
                    query.executionTime > 0
                );
            case 'PLANNING':
                return query.planningTime > 0 || query.executionTime > 0;
        }
        return query.executionTime > 0;
    };

    return (
        <Box display="flex" flexDirection="column" className={fullWidth ? undefined : classes.root}>
            <div className={classes.header}>Query execution</div>
            <Box className={classes.timeline}>
                <QueryTimelineItem
                    name="Submitted"
                    time={formatDatetime(query.submissionTime)}
                    icon={<CalendarTodayOutlined />}
                    color="#919BBD"
                    variant="left"
                />

                {horizontalSeparator}
                <Box display="flex">
                    <Box flexBasis={'0px'} flexGrow={1}>
                        <QueryTimelineItem
                            name="Analyzing"
                            time={formatDistance(query.analysisTime)}
                            icon={<FindInPageIcon />}
                            tooltip="Time spent reading metadata from the data sources and analyzing the query for semantic errors"
                            color="#6876A3"
                            timeColor={query.analysisTime > 2000 ? palette.error : undefined}
                            variant="left"
                            lines="both"
                            fullWidth={true}
                            fullHeight={true}
                        />
                    </Box>
                    <Box flexBasis={'0px'} flexGrow={1}>
                        <QueryTimelineItem
                            name="Queuing"
                            time={formatDistance(query.queuedTime)}
                            icon={<AccessTimeIcon />}
                            tooltip="Time spent waiting for other queries to be processed"
                            color="#6876A3"
                            timeColor={query.queuedTime > 1000 ? palette.error : undefined}
                            variant="right"
                            lines="both"
                            fullWidth={true}
                        />

                        <QueryTimelineItem
                            name="Waiting for resources"
                            time={
                                isStateActive('WAITING_FOR_RESOURCES')
                                    ? formatDistance(query.waitingForResourcesTime)
                                    : null
                            }
                            icon={<HourglassIcon />}
                            tooltip="Time spent waiting for resources to process the query, value can include analysis time"
                            color={
                                isStateActive('WAITING_FOR_RESOURCES') ? '#6876A3' : palette.black12
                            }
                            timeColor={
                                query.waitingForResourcesTime > 2000 ? palette.error : undefined
                            }
                            variant="right"
                            lines="both"
                            fullWidth={true}
                        />
                    </Box>
                </Box>
                {horizontalSeparator}

                <QueryTimelineItem
                    name="Planning"
                    time={isStateActive('PLANNING') ? formatDistance(query.planningTime) : null}
                    icon={<LowPriorityIcon />}
                    tooltip="Time spent creating and optimizing the query plan"
                    color={isStateActive('PLANNING') ? '#495A92' : palette.black12}
                    variant="left"
                    lines="top"
                />

                <QueryTimelineItem
                    name="Executing"
                    time={isStateActive() ? formatDistance(query.executionTime) : null}
                    icon={<PlayIcon />}
                    color={isStateActive() ? '#293F81' : palette.black12}
                    variant="right"
                    lines="top"
                />

                <QueryTimelineItem
                    name={
                        query.state === 'FAILED' || query.state === 'FINISHED'
                            ? capitalize(query.state)
                            : null
                    }
                    time={query.completionTime ? formatDatetime(query.completionTime) : null}
                    icon={query.state === 'FAILED' ? <CloseIcon /> : <DoneIcon />}
                    color={getFinalIconColor()}
                    lines="top"
                    variant="left"
                    hidePaper={!query.completionTime}
                />
            </Box>
        </Box>
    );
};
