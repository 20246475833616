/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useEffect, useMemo } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import { createUseStyles } from 'react-jss';
import debounce from 'lodash/debounce';
import noop from 'lodash/noop';
import { TextFieldWithHint } from '../components/TextFieldWithHint';
import { DataFetchingState, useFetchingState } from '../domain/useFetchingState';
import {
    DataProductNameValidation,
    validateDataProductName,
} from '../../../api/dataProduct/dataProductApi';
import { maximumTitleLength } from '../util/formUtils';
import { isNonEmpty } from '../../../utils/formUtils';

interface DataProductNameInputProps {
    dataProductId?: string;
    canBeChanged: boolean;
    value: string;
    onChange: (newValue: string) => void;
    showTooltip: boolean;
    onValidationChange: (
        fetchingNameValidationInfo: DataFetchingState<DataProductNameValidation | undefined>
    ) => void;
}

export const PENDING_SCHEMA_NAME = '<pending>';
const tooltipText = `The name here will define the schema name you use to refer to your data product in queries. Limited to ${maximumTitleLength} characters.`;

const useInputAdornmentClasses = createUseStyles({
    positionStart: {
        paddingRight: '0.25rem',
    },
});

export const DataProductNameInput = ({
    dataProductId,
    canBeChanged,
    value,
    onChange,
    onValidationChange,
    showTooltip,
}: DataProductNameInputProps) => {
    const inputAdornmentClasses = useInputAdornmentClasses();
    const {
        setData: setNameValidationInfo,
        setError: setNameValidationInfoError,
        setFetching: setFetchingNameValidationInfo,
        isFetching: isFetchingNameValidationInfo,
        data: nameValidationInfo,
        state: fetchingNameValidationInfo,
    } = useFetchingState<DataProductNameValidation | undefined>();

    useEffect(() => {
        onValidationChange(fetchingNameValidationInfo);
    }, [nameValidationInfo]);

    const debounceValidateDataProductName = useCallback(
        debounce((name: string, dataProductId: string | undefined) => {
            if (!name) {
                setNameValidationInfo({
                    generatedSchemaName: '',
                });
                return noop;
            }
            const subscription = validateDataProductName(name, dataProductId).subscribe({
                next: (data) => {
                    setNameValidationInfo(data);
                },
                error: (error) => {
                    setNameValidationInfoError(error.message);
                },
            });
            return () => subscription.unsubscribe();
        }, 300),
        []
    );

    useEffect(() => {
        setFetchingNameValidationInfo();
        return debounceValidateDataProductName(value, dataProductId);
    }, [dataProductId, value]);

    const isEmptyValue = useMemo(() => {
        return !isNonEmpty(value);
    }, [value]);

    const getHelperText = () => {
        if (isEmptyValue) {
            return value.length ? 'Can not be blank' : undefined;
        }
        if (nameValidationInfo?.nameValidationErrorMessage) {
            return nameValidationInfo?.nameValidationErrorMessage;
        }
        if (nameValidationInfo?.schemaValidationErrorMessage) {
            return nameValidationInfo?.schemaValidationErrorMessage;
        }
        return `Schema name: ${nameValidationInfo?.generatedSchemaName || PENDING_SCHEMA_NAME}`;
    };

    const hasProblemsWithName = useMemo(() => {
        return isNameProblematic(value, nameValidationInfo);
    }, [value, nameValidationInfo]);

    return (
        <TextFieldWithHint
            fullWidth={true}
            value={value}
            label="Data product title"
            required={true}
            disabled={!canBeChanged}
            onValueChange={onChange}
            tooltipText={showTooltip ? tooltipText : undefined}
            error={hasProblemsWithName}
            helperText={getHelperText()}
            maxLength={maximumTitleLength}
            endAdornment={
                isFetchingNameValidationInfo ? (
                    <InputAdornment position="start" classes={inputAdornmentClasses}>
                        <CircularProgress color="secondary" size={15} />
                    </InputAdornment>
                ) : undefined
            }
        />
    );
};

export function isNameProblematic(
    name: string,
    nameValidationInfo: DataProductNameValidation | undefined
): boolean {
    return (
        (name && !isNonEmpty(name)) ||
        Boolean(nameValidationInfo?.nameValidationErrorMessage) ||
        Boolean(nameValidationInfo?.schemaValidationErrorMessage)
    );
}
