/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { configWithRoleHeader, errorMappingAxios } from './common';

export const getUdfs = (currentRoleName: string): Promise<string[]> => {
    return errorMappingAxios
        .get('/ui/api/biac/entities/udfs', configWithRoleHeader(currentRoleName))
        .then(({ data }) => data);
};
