/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React, { useCallback } from 'react';
import Typography from '@mui/material/Typography';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { createUseStyles } from 'react-jss';
import { QuerySharingFormState } from './ShareQueryDialog';
import { Control, useFieldArray } from 'react-hook-form';
import { AccessMode } from '../../../api/savedQueriesApi';
import { Theme } from '@mui/material/styles';
import { paletteSwitch } from '../../../themes/palette';
import { QuerySharingModesSelect } from './QuerySharingModesSelect';
import Grid from '@mui/material/Grid';

const useLocalStyles = createUseStyles((theme: Theme) => ({
    flex: {
        display: 'flex',
    },
    dialogRolesWithAccessTitle: {
        fontFamily: theme.typography.fontFamily,
        fontStyle: 'normal',
        fontSize: '1.125rem',
        letterSpacing: '0.15px',
        fontWeight: 600,
        lineHeight: '27px',

        marginBottom: '1.875rem',
        color: `${paletteSwitch(theme).nebulaNavy}`,
        maxWidth: '30rem',
    },
    rolesList: {
        paddingTop: '0.18rem!important',
        overflow: 'auto',
        width: '100%',
        maxHeight: 400,
        paddingRight: '0.625',
    },
    roleIcon: {
        height: '2.5rem',
        width: '2.25rem',
        color: `${paletteSwitch(theme).nebulaNavy700}`,
        lineHeight: '0 !important',
    },
    roleName: {
        marginLeft: '0.68rem',
        fontFamily: theme.typography.fontFamily,
        fontSize: '1rem',
        lineHeight: '24px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    rolesItem: {
        '&:first-child': {
            paddingTop: '0',
        },
    },
}));

interface RolesWithAccessProps {
    control: Control<QuerySharingFormState>;
}

export const EditQueryAccessMode: React.FunctionComponent<RolesWithAccessProps> = ({ control }) => {
    const localClasses = useLocalStyles();

    const { fields, update } = useFieldArray({ name: 'permissions', control });

    const handleModeChange = useCallback(
        (index: number) => (mode: AccessMode) => {
            update(index, { ...fields[index], access: mode });
        },
        [fields, update]
    );

    return (
        <>
            {!!fields.length && (
                <Grid item xs={12}>
                    <Typography variant="h6" className={localClasses.dialogRolesWithAccessTitle}>
                        Roles with access
                    </Typography>
                </Grid>
            )}
            <Grid container item className={localClasses.rolesList} rowSpacing={1.5}>
                {fields.map(({ subjectName, access }, index) => (
                    <Grid
                        className={localClasses.rolesItem}
                        key={subjectName}
                        container
                        item
                        xs={12}
                        columnSpacing={1}>
                        <Grid item container xs={7}>
                            <Grid item className={localClasses.flex} alignItems="center">
                                <AssignmentIndIcon className={localClasses.roleIcon} />
                            </Grid>
                            <Grid item className={localClasses.flex} alignItems="center">
                                <Typography typography="body1" className={localClasses.roleName}>
                                    {subjectName}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={5}>
                            <QuerySharingModesSelect
                                mode={access}
                                onModeChange={handleModeChange(index)}
                                canRemove={true}
                            />
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </>
    );
};
