/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';
import TabPanel, { TabPanelProps } from '@mui/lab/TabPanel';
import TabContext, { useTabContext } from '@mui/lab/TabContext';

export const PreserveTabPanel: React.FunctionComponent<TabPanelProps> = (props) => {
    const topTabContext = useTabContext();
    return (
        <TabContext value={props.value}>
            <TabPanel
                {...props}
                style={topTabContext?.value !== props.value ? { display: 'none' } : props.style}
            />
        </TabContext>
    );
};
