/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useEffect, useState } from 'react';
import { ClusterHistoryRefresh } from './ClusterHistoryRefresh';
import { ClusterHistoryCriteria, ClusterHistoryFilters } from './ClusterHistoryFilters';
import { ClusterHistoryReports } from './ClusterHistoryReports';
import { ClusterHistoryResponse, getClusterHistoryMetrics } from '../../api/clusterApi';
import { MetricsCollectionEnabledBiac } from '../../components/biac/MetricsCollectionEnabledBiac';
import { PersistenceEnabledBiac } from '../../components/biac/PersistenceEnabledBiac';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { setFilters } from './clusterHistorySlice';
import { PageContent } from '../../layout/PageContent';
import Grid from '@mui/material/Grid';

export const ClusterHistory: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const filters = useSelector(({ clusterHistory }: RootState) => clusterHistory.filters);

    const [reports, setReports] = useState<ClusterHistoryResponse | 'loading' | 'error'>('loading');
    const [lastUpdateDate, setLastUpdateDate] = useState<Date | null>(null);

    const fetchReports = (criteria: ClusterHistoryCriteria = filters) => {
        setReports('loading');
        getClusterHistoryMetrics(criteria)
            .then((metrics) => {
                setReports(metrics);
                setLastUpdateDate(new Date());
            })
            .catch(() => setReports('error'));
    };

    useEffect(() => {
        fetchReports();
    }, []);

    const applyFilters = (filters: ClusterHistoryCriteria): void => {
        dispatch(setFilters(filters));
        fetchReports(filters);
    };

    return (
        <PersistenceEnabledBiac>
            <MetricsCollectionEnabledBiac>
                <PageContent title={'Cluster history'}>
                    <Grid container>
                        <Grid item xs={12}>
                            <ClusterHistoryFilters filters={filters} setFilters={applyFilters} />
                        </Grid>
                        <Grid item xs={12}>
                            <ClusterHistoryReports reports={reports} />
                        </Grid>
                        <Grid container justifyContent="flex-end">
                            <ClusterHistoryRefresh
                                refreshReports={() => fetchReports(filters)}
                                lastUpdateDate={lastUpdateDate}
                            />
                        </Grid>
                    </Grid>
                </PageContent>
            </MetricsCollectionEnabledBiac>
        </PersistenceEnabledBiac>
    );
};
