/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash';
import Menu from '@mui/material/Menu';
import { createUseStyles } from 'react-jss';
import { paletteSwitch } from '../../themes/palette';
import clsx from 'clsx';
import { Theme } from '@mui/material/styles';

const useStyles = createUseStyles((theme: Theme) => ({
    menu: {
        '-webkit-font-smoothing': 'auto',
    },
    menuItem: {
        fontSize: `0.875rem`,
        paddingTop: '6px',
        paddingBottom: '6px',
        paddingRight: '24px',
        minWidth: '7rem',
    },
    deleteMenuItem: {
        color: paletteSwitch(theme).error,
    },
    menuItemIcon: {
        marginRight: '0.75rem',
        color: paletteSwitch(theme).black54,
    },
    deleteMenuItemIcon: {
        marginRight: '0.75rem',
        color: paletteSwitch(theme).error,
    },
}));

interface RoleContextMenuProps {
    anchorEl: HTMLElement | null;
    deleteRole(): void;
    onClose(): void;
}

export const RoleContextMenu: React.FunctionComponent<RoleContextMenuProps> = ({
    anchorEl,
    deleteRole,
    onClose,
}) => {
    const classes = useStyles();
    return (
        <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            className={classes.menu}>
            <MenuItem
                className={clsx(classes.menuItem, classes.deleteMenuItem)}
                onClick={deleteRole}>
                <FontAwesomeIcon className={classes.deleteMenuItemIcon} icon={faTrash} />
                Delete role
            </MenuItem>
        </Menu>
    );
};
