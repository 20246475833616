/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';
import { ReportWrapper } from '../../../components/chart/ReportWrapper';
import { getLeastActiveQueries, LeastActiveQueries } from '../../../api/queryReportApi';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { paletteSwitch } from '../../../themes/palette';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import Link from '@mui/material/Link';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink } from 'react-router-dom';
import { Tooltip } from '../../../components/tooltip/Tooltip';
import { SqlHighlight } from '../../../components/sql-highlight/SqlHighlight';
import { LazyReport } from './LazyReport';
import { navigateBackInHistoryParam } from '../details/QueryDetails';
import { formatBytes } from '../../../utils/formatBytes';
import { Theme } from '@mui/material/styles';

const useStyles = createUseStyles((theme: Theme) => ({
    box: {
        padding: '12px 4px 18px 12px',
    },
    container: {
        fontSize: '0.875rem',
    },
    header: {
        marginBottom: '6px',
        fontWeight: 600,
    },
    defaultRow: {
        borderBottom: `1px solid ${paletteSwitch(theme).black12}`,
        padding: '6px 0',
    },
    firstRow: {
        paddingTop: 0,
        fontWeight: 600,
        borderBottom: `2px solid ${paletteSwitch(theme).black12}`,
    },
    truncated: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingRight: '16px',
    },
    code: {
        fontSize: '0.6875rem',
    },
    nowrap: {
        whiteSpace: 'nowrap',
    },
    scrollbar: {
        height: 'initial',
    },
}));

export const LeastActiveQueriesChart: React.FunctionComponent = () => {
    const classes = useStyles();
    const height = 185;

    const row = (
        id: string | { value: string; children: React.ReactNode },
        user: string,
        bytes: string,
        active: string,
        className = ''
    ) => (
        <Grid
            key={typeof id === 'string' ? id : id.value}
            container
            className={clsx(classes.defaultRow, className)}>
            <Grid item xs={5} className={classes.truncated}>
                {typeof id === 'string' ? id : id.children}
            </Grid>
            <Grid item xs={4} className={classes.truncated}>
                {user}
            </Grid>
            <Grid item xs={1} className={classes.nowrap}>
                {bytes}
            </Grid>
            <Grid item xs={2} style={{ textAlign: 'right' }}>
                {active}
            </Grid>
        </Grid>
    );

    const queryLink = (id: string, query: string) => (
        <Tooltip
            title={<SqlHighlight sql={query} webkitClamp={true} className={classes.code} />}
            disableInteractive={true}>
            <Link
                component={RouterLink}
                to={{
                    pathname: `/query/${id}`,
                    state: { [navigateBackInHistoryParam]: true },
                }}>
                {id}
            </Link>
        </Tooltip>
    );

    const activeRatio = (utilizationRatio: number) => {
        if (utilizationRatio < 0.01) {
            return '<1%';
        }

        return `${(utilizationRatio * 100).toFixed(0)}%`;
    };

    return (
        <LazyReport name={'leastActiveQueries'} load={getLeastActiveQueries}>
            {(leastActiveQueries) => (
                <ReportWrapper<LeastActiveQueries>
                    data={leastActiveQueries}
                    title={<div className={classes.header}>Least active queries</div>}
                    height={height}
                    isEmpty={(data) => !data.length}
                    className={classes.box}>
                    {(data) => (
                        <PerfectScrollbar
                            className={classes.scrollbar}
                            component="div"
                            options={{ wheelPropagation: false }}>
                            <Box ml={1} mr={2} className={classes.container} style={{ height }}>
                                {row('Query ID', 'User', 'I/O', 'Active', classes.firstRow)}
                                {data.map(
                                    ({
                                        queryId,
                                        user,
                                        inputOutputBytes,
                                        utilizationRatio,
                                        query,
                                    }) =>
                                        row(
                                            { value: queryId, children: queryLink(queryId, query) },
                                            user,
                                            formatBytes(inputOutputBytes),
                                            activeRatio(utilizationRatio)
                                        )
                                )}
                            </Box>
                        </PerfectScrollbar>
                    )}
                </ReportWrapper>
            )}
        </LazyReport>
    );
};
