/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import { format, formatDistanceToNow } from 'date-fns';

export const getLicenseFile = (data: BlobPart): void => {
    const link = document.createElement('a');
    const file = new Blob([data]);
    link.href = URL.createObjectURL(file);
    link.setAttribute('download', 'starburstdata.license');
    link.click();
};

export const getFormattedExpiryDate = (expiry: string): string => {
    return expiry
        ? `${format(new Date(expiry), 'PP')} (${formatDistanceToNow(new Date(expiry))})`
        : '-';
};
