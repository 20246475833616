/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { ReactNode, useContext, useMemo, useState } from 'react';

interface EditContextValue {
    isEditing: boolean;
    setIsEditing: (isEditing: boolean) => void;
}
const EditContext = React.createContext<EditContextValue>({
    isEditing: false,
    setIsEditing: () => console.warn('No context defined'),
});

interface EditContextProviderProps {
    children: ReactNode;
}

export const EditContextProvider = ({ children }: EditContextProviderProps) => {
    const [isEditing, setIsEditing] = useState(false);
    const editContextValue = useMemo<EditContextValue>(
        () => ({
            isEditing,
            setIsEditing,
        }),
        [isEditing]
    );
    return <EditContext.Provider value={editContextValue}>{children}</EditContext.Provider>;
};

export const useEditContext = () => useContext(EditContext);
