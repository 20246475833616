/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';
import { createUseStyles } from 'react-jss';
import { DataProductState } from '../../../api/dataProduct/dataProductApi';
import Alert from '@mui/material/Alert';

interface StatusMessageProps {
    status: DataProductState | undefined;
    hasDatasetsDeleted: boolean;
}

const useStyles = createUseStyles({
    alert: {
        marginTop: '1.5rem',
    },
});

export const StatusMessage: React.FunctionComponent<StatusMessageProps> = ({
    status,
    hasDatasetsDeleted,
}) => {
    const classes = useStyles();
    return (
        <div className={classes.alert}>
            {status === DataProductState.DRAFT && (
                <Alert severity="info">
                    This data product is in draft form. Finish editing and publish.
                </Alert>
            )}
            {status === DataProductState.PENDING_CHANGES && hasDatasetsDeleted ? (
                <Alert severity="error">
                    You have Datasets that are marked for deletion. These Datasets will be deleted
                    on publish.
                </Alert>
            ) : (
                status === DataProductState.PENDING_CHANGES && (
                    <Alert severity="warning">
                        You have pending changes that have not been published.
                    </Alert>
                )
            )}
        </div>
    );
};
