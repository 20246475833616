/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React, { SetStateAction, useEffect, useMemo, useState } from 'react';
import orderBy from 'lodash/orderBy';
import { SortingState } from './SortableColumn';
export type TableRowData = {
    [key: string]: any;
};

const getSortedItems = <T extends TableRowData, S extends string | number | symbol = keyof T>(
    items: T[],
    getValueToCompare: (item: T, columnKey: S) => unknown,
    { sortBy, sortOrder }: SortingState<S>
): T[] => {
    return orderBy(
        items,
        [
            (item: T) => {
                const value = getValueToCompare(item, sortBy);
                if (value === undefined) {
                    throw Error(`Sorting key: "${sortBy.toString()}" is not registered in table.`);
                }
                return value;
            },
        ],
        [sortOrder]
    );
};

export const useTableSorting = <T, S extends string | number | symbol = keyof T>(
    items: T[],
    defaultSorting: SortingState<S>,
    getValueToCompare: (item: T, columnKey: S) => unknown
) => {
    const [sortParams, setSortParams] = useState<SortingState<S>>(defaultSorting);
    const [sortedItems, setSortedItems] = useState<T[]>(items);

    useEffect(() => {
        const newItems = getSortedItems(items, getValueToCompare, sortParams);
        setSortedItems(newItems);
    }, [sortParams, items, getValueToCompare]);

    const result: [T[], SortingState<S>, React.Dispatch<SetStateAction<SortingState<S>>>] = useMemo(
        () => [sortedItems, sortParams, setSortParams],
        [sortedItems, sortParams, setSortParams]
    );

    return result;
};
