/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import { Action, EntityCategory } from '../../../api/biac/biacApi';

export const mainRadioButtons = [
    'tables',
    'roles',
    'users',
    'queries',
    'data_products',
    'user_interface',
    'other',
];

export const dropDownItems = [
    'functions',
    'procedures',
    'system_session_properties',
    'catalog_session_properties',
    'schema_properties',
    'table_properties',
];

export const entityActions: ReadonlyMap<EntityCategory, Action[]> = new Map<
    EntityCategory,
    Action[]
>([
    [
        'tables',
        ['ALTER', 'CREATE', 'DELETE', 'DROP', 'INSERT', 'REFRESH', 'SELECT', 'SHOW', 'UPDATE'],
    ],
    ['roles', ['ALTER', 'CREATE', 'DROP', 'SHOW']],
    ['users', ['IMPERSONATE']],
    ['queries', ['EXECUTE', 'KILL', 'SHOW']],
    ['data_products', ['SHOW', 'CREATE', 'DROP', 'ALTER', 'PUBLISH']],
    ['user_interface', ['SHOW']],
    ['functions', ['EXECUTE']],
    ['procedures', ['EXECUTE']],
    ['system_session_properties', ['SET']],
    ['catalog_session_properties', ['SET']],
    ['schema_properties', ['SET']],
    ['table_properties', ['SET']],
]);

export const catalogInputVisible: ReadonlyArray<EntityCategory> = [
    'tables',
    'schema_properties',
    'table_properties',
    'catalog_session_properties',
    'procedures',
];

export const schemaInputVisible: ReadonlyArray<EntityCategory> = [
    'tables',
    'schema_properties',
    'table_properties',
    'procedures',
];

export const tableInputVisible: ReadonlyArray<EntityCategory> = ['tables', 'table_properties'];

export const columnInputVisible: ReadonlyArray<EntityCategory> = ['tables'];

export const validActionsForFilters: string[] = ['SELECT'];
