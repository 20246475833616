/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import TableCell from '@mui/material/TableCell';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { palette } from '../../../themes/palette';
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons';
import { createUseStyles } from 'react-jss';
import { QueriesTableRowData } from './QueriesTable';

interface TableLoadingProps {
    rowHeight: number;
    columnIndex: number;
}

const useStyles = createUseStyles({
    root: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        cursor: 'initial',
        justifyContent: 'center',
        borderBottom: 'none',
    },
    loading: {
        fontSize: '1.25rem',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
    },
});

export const isLoadingRow = (rowData: QueriesTableRowData): boolean => {
    return rowData === 'loading-row';
};

export const TableLoading: React.FunctionComponent<TableLoadingProps> = ({
    rowHeight,
    columnIndex,
}) => {
    const classes = useStyles();

    if (columnIndex !== 0) {
        return null;
    }

    return (
        <TableCell
            component="div"
            className={classes.root}
            variant="body"
            style={{ height: rowHeight }}>
            <FontAwesomeIcon
                className={classes.loading}
                color={palette.teal}
                icon={faSpinner}
                pulse
            />
        </TableCell>
    );
};

export const useTableLoadingRowClassName = (): string => {
    const classes = useStyles();
    return classes.row;
};
