/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React, { useCallback, useEffect, useMemo } from 'react';
import { Control, Controller, UseFormSetValue, useWatch } from 'react-hook-form';
import { QuerySharingFormState } from './ShareQueryDialog';
import { fetchApplicableRoles, RoleBasic } from '../../biac/CurrentRoleContext';
import { AccessMode } from '../../../api/savedQueriesApi';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { QuerySharingModesSelect } from './QuerySharingModesSelect';
import CircularProgress from '@mui/material/CircularProgress';
import { ChooserErrorIcon } from '../../biac/grants/ChooserErrorIcon';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { useFetchingState } from '../../dataproduct/domain/useFetchingState';
import { fromPromise } from 'rxjs/internal-compatibility';
import { SYSTEM_ROLE_ID } from '../../../api/biac/biacRolesApi';
import { createUseStyles } from 'react-jss';
import { Theme } from '@mui/material/styles';
import { paletteSwitch } from '../../../themes/palette';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';

interface AddingNewRolesWithAccessProps {
    control: Control<QuerySharingFormState>;
    setValue: UseFormSetValue<QuerySharingFormState>;
}

const useApplicableRoles = () => {
    const state = useFetchingState<RoleBasic[]>();
    const reload = useCallback(() => {
        state.setFetching();
        return fromPromise(fetchApplicableRoles()).subscribe(state.setData, ({ message }) =>
            state.setError(message)
        );
    }, []);
    useEffect(() => {
        const subscription = reload();
        return () => subscription.unsubscribe();
    }, [reload]);
    return { ...state, reload };
};

const useStyles = createUseStyles((theme: Theme) => ({
    textFieldRoot: {
        width: '100%',
        '& .MuiFormLabel-root': {
            marginTop: '0.15rem',
            fontFamily: theme.typography.fontFamily,
            typography: 'primary',
            fontWeight: 300,
            fontSize: '1rem',
            lineHeight: '24px',
            letterSpacing: '0.144px',
        },
        '& .MuiOutlinedInput-root': {
            minHeight: '3rem',
        },
        '& .MuiSelect-select': {
            padding: '0 0.8rem',
            fontFamily: theme.typography.fontFamily,
            typography: 'primary',
            fontWeight: 400,
            fontSize: '1rem',
            lineHeight: '24px',
            letterSpacing: '0.144px',
            color: `${paletteSwitch(theme).black}`,
        },
    },
    chip: {
        color: paletteSwitch(theme).nebulaNavy700,
        backgroundColor: paletteSwitch(theme).nebulaNavy50,
        padding: '0.25rem 0.5rem 0.25rem 0.5rem',
        margin: '0.125rem',
    },
    chipCloseIcon: {
        color: `${paletteSwitch(theme).nebulaNavy700}!important`,
        fontSize: '1rem!important',
    },
    optionLabel: {
        textOverflow: 'ellipsis',
        maxWidth: '25rem',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
}));

export const ShareQueryWithRole: React.FunctionComponent<AddingNewRolesWithAccessProps> = ({
    control,
    setValue,
}) => {
    const classes = useStyles();
    const { isFetching, data: roles, errorMessage } = useApplicableRoles();

    const newRolesMode = useWatch({ control, name: 'newRolesMode' });
    const handleNewRolesMode = useCallback(
        (newRoleMode: AccessMode) => {
            setValue('newRolesMode', newRoleMode, { shouldDirty: true });
        },
        [setValue]
    );

    const handleOnChange = useCallback(
        (newRoleIds: string[]) => {
            setValue('newAliases', newRoleIds, { shouldDirty: true });
        },
        [setValue]
    );
    const permissions = useWatch({ control, name: 'permissions' });
    const rolesForSelect = useMemo(
        () =>
            roles
                ? roles
                      .filter((it) => it.id !== SYSTEM_ROLE_ID)
                      .filter(
                          (it) =>
                              !permissions.find(
                                  (alreadyShared) => alreadyShared.subjectName === it.name
                              )
                      )
                      .map((it) => it.name)
                : [],
        [roles, permissions]
    );

    const renderPopupIcon = useCallback(() => {
        if (isFetching) {
            return <CircularProgress size={20} />;
        } else if (errorMessage) {
            return (
                <div>
                    <ChooserErrorIcon title="Loading roles failed" />
                </div>
            );
        } else {
            return <ArrowDropDownOutlinedIcon />;
        }
    }, [isFetching, errorMessage]);

    const renderOption = useCallback(
        (props: React.HTMLAttributes<HTMLLIElement>, option: string, { selected }) => {
            return (
                <li {...props}>
                    <Checkbox style={{ marginRight: '8px' }} checked={selected} />
                    <div className={classes.optionLabel}>{option}</div>
                </li>
            );
        },
        []
    );

    return (
        <Grid paddingRight={'0.625rem'} item xs={12}>
            <Grid container item xs={12} spacing={1}>
                <Grid item xs={7}>
                    <Controller<QuerySharingFormState>
                        name={'newAliases'}
                        control={control}
                        render={({ field: { value = [] } }) => (
                            <Autocomplete
                                value={value as string[]}
                                multiple
                                clearText="Clear selected roles"
                                options={rolesForSelect}
                                onChange={(event, value) => handleOnChange(value)}
                                disableCloseOnSelect
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Share with role"
                                        classes={{ root: classes.textFieldRoot }}
                                    />
                                )}
                                renderOption={renderOption}
                                popupIcon={renderPopupIcon()}
                                ChipProps={{
                                    className: classes.chip,
                                    deleteIcon: <CloseIcon className={classes.chipCloseIcon} />,
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={5}>
                    <QuerySharingModesSelect
                        mode={newRolesMode}
                        onModeChange={handleNewRolesMode}
                        canRemove={false}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
