/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import Box from '@mui/material/Box';
import { ChartLegendItem, ChartLegendItemProps } from './ChartLegendItem';
import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    legendItem: {
        margin: '0 0.75rem 0 0.75rem',
    },
});

interface InlineChartLegendProps {
    items: (ChartLegendItemProps & { id: string })[];
    bulletSize?: string;
    width?: number;
    labelFormatter?: (inputLabel: string) => string;
}

export const InlineChartLegend: React.FunctionComponent<InlineChartLegendProps> = ({
    items,
    bulletSize,
    width,
    labelFormatter = (input: string) => input,
}) => {
    const classes = useStyles();

    return (
        <Box display="flex" style={{ maxWidth: width }}>
            {items.map(({ id, color, value, label, enabled }) => (
                <ChartLegendItem
                    key={id}
                    className={classes.legendItem}
                    color={color}
                    label={labelFormatter(label)}
                    value={value}
                    enabled={enabled}
                    bulletSize={bulletSize}
                />
            ))}
        </Box>
    );
};
