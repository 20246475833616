/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useContext, useRef } from 'react';
import ChartComponent, { Bar, ChartComponentProps } from 'react-chartjs-2';
import Chart from 'chart.js';
import { chartTooltip } from './tooltip/ChartTooltip';
import { TooltipContext } from './tooltip/ChartTooltipContainer';
import { convertRem } from '../../utils/fontSize';
import { alpha, useTheme } from '@mui/material/styles';
import { getTimeAxisFormatter } from '../../utils/timeAxis';
import { abbreviateWholeNumbers } from '../../utils/abbreviateNumber';
import { closeTooltipOnLeave } from './tooltip/closeTooltip';
import { paletteSwitch } from '../../themes/palette';
import { useThemeMode } from '../../app/UIThemeContextProvider';

interface BarChartProps {
    data: Chart.ChartData;
    tooltipText: string;
    height: number;
    yLabelFormatter?: 'duration' | ((label: number) => string);
    tooltipValueFormatter?: (value: number) => string;
}

export const BarChart: React.FunctionComponent<BarChartProps> = ({
    data: inputData,
    tooltipText,
    height,
    yLabelFormatter: inputXLabelFormatter = abbreviateWholeNumbers,
    tooltipValueFormatter: inputTooltipValueFormatter = (value: number): string =>
        value?.toLocaleString(),
}) => {
    const chartRef = useRef<ChartComponent<ChartComponentProps> | null>(null);
    const { containerId: tooltipContainerId } = useContext(TooltipContext) || {};
    const theme = useTheme();
    const themeMode = useThemeMode();
    const datasets = inputData.datasets?.map((dataset) => ({
        ...dataset,
        maxBarThickness: 30,
        minBarLength: 5,
        backgroundColor: (dataset.backgroundColor as string[]).map((color) => alpha(color, 0.7)),
        borderColor: dataset.backgroundColor,
        borderWidth: 2.4,
    }));

    const tooltipValueFormatter = (input: string | number | undefined) => {
        if (typeof input === 'string') {
            return input;
        } else if (input === undefined) {
            return '-';
        }

        return inputTooltipValueFormatter(input);
    };

    const [stepSize, xLabelFormatter] =
        inputXLabelFormatter === 'duration'
            ? getTimeAxisFormatter(inputData)
            : [undefined, inputXLabelFormatter];

    return (
        <div style={{ height }}>
            <Bar
                ref={chartRef}
                data={{ datasets, labels: inputData.labels }}
                options={{
                    maintainAspectRatio: false,
                    legend: {
                        display: false,
                    },
                    layout: {
                        padding: {
                            right: 10,
                        },
                    },
                    tooltips: {
                        enabled: false,
                        custom: chartTooltip(
                            chartRef,
                            themeMode,
                            tooltipContainerId,
                            tooltipValueFormatter
                        ),
                        callbacks: {
                            title(): string {
                                return tooltipText;
                            },
                            label: function (tooltipItem): string {
                                return `${tooltipItem.xLabel}: ${tooltipItem.yLabel}`;
                            },
                        },
                    },
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                    callback: xLabelFormatter,
                                    fontFamily: 'barlow, Roboto Condensed, sans-serif',
                                    fontSize: convertRem(0.75),
                                    padding: 5,
                                    stepSize,
                                    maxTicksLimit: 11,
                                    fontColor: paletteSwitch(theme).black54,
                                },
                                gridLines: {
                                    drawBorder: false,
                                    zeroLineWidth: 2,
                                    zeroLineColor: paletteSwitch(theme).black20,
                                    color: paletteSwitch(theme).black12,
                                },
                            },
                        ],
                        xAxes: [
                            {
                                gridLines: {
                                    display: false,
                                },
                                ticks: {
                                    display: false,
                                },
                            },
                        ],
                    },
                    onHover: closeTooltipOnLeave(chartRef),
                    plugins: {
                        datalabels: {
                            display: false,
                        },
                    },
                }}
            />
        </div>
    );
};
