/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';
import { GroupedReports } from '../../../../components/chart/GroupedReports';
import { InputDataBaseChart } from './InputDataBaseChart';
import { OutputDataBaseChart } from './OutputDataBaseChart';
import { LazyReport } from '../LazyReport';
import { getInputDataBySource, getOutputDataBySource } from '../../../../api/queryReportApi';

type Tab = 'input' | 'output';

export const TopSourcesIOCharts: React.FunctionComponent = () => {
    return (
        <GroupedReports<Tab>
            title={'Top sources'}
            tabs={[
                { tab: 'input', label: 'By data read' },
                { tab: 'output', label: 'By data written' },
            ]}>
            {(tab) => (
                <>
                    {tab === 'input' && (
                        <LazyReport name={'inputDataBySource'} load={getInputDataBySource}>
                            {(inputData) => (
                                <InputDataBaseChart inputData={inputData} labelKey={'source'} />
                            )}
                        </LazyReport>
                    )}
                    {tab === 'output' && (
                        <LazyReport name={'outputDataBySource'} load={getOutputDataBySource}>
                            {(outputData) => (
                                <OutputDataBaseChart outputData={outputData} labelKey={'source'} />
                            )}
                        </LazyReport>
                    )}
                </>
            )}
        </GroupedReports>
    );
};
