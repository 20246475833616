/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';
import { defaultOtherLabel } from '../../../components/chart/TopEntitiesChart';

interface TablesData {
    table: {
        catalogName: string;
        schemaName: string;
        tableName: string;
    };
}

type JoinedNames<T extends TablesData> = Omit<T, 'table'> & { name: string };

interface TopTablesChartProps<T extends TablesData> {
    data: T[] | 'loading' | 'error' | undefined;
    children: (
        data: JoinedNames<T>[] | 'loading' | 'error' | undefined,
        labelFormatter: (name: string) => string
    ) => React.ReactElement;
}

export const TopTablesChart = <T extends TablesData>({
    data,
    children,
}: TopTablesChartProps<T>): JSX.Element => {
    // \u200b is invisible and it makes the separator much less likely to occur in the real life cases
    // moreover it is a space, so it can wrap long names
    const separatorChar = '.\u200b';

    const fullTableNames: JoinedNames<T>[] | 'loading' | 'error' | undefined =
        typeof data === 'string' || data === undefined
            ? data
            : data.map((q) => ({
                  ...q,
                  name:
                      q.table.catalogName === defaultOtherLabel
                          ? q.table.catalogName
                          : `${q.table.catalogName}${separatorChar}${q.table.schemaName}${separatorChar}${q.table.tableName}`,
              }));

    const shortTableName = (fullName: string): string => {
        return fullName.split(separatorChar)[2] ?? fullName;
    };

    return children(fullTableNames, shortTableName);
};
