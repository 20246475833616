/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import {
    DataDomain,
    CreateDataDomainPayload,
} from '../../../../api/dataProduct/dataDomain/dataDomainApi';

export type DataDomainFormData = CreateDataDomainPayload & {
    schemaLocation: string;
};

export function mapToDataDomainFormData({
    name,
    description = '',
    schemaLocation = '',
    assignedDataProducts,
}: DataDomain): DataDomainFormData {
    return { name, description, schemaLocation, assignedDataProducts };
}

export function mapToDataDomain(form: DataDomainFormData, originalDomain: DataDomain): DataDomain {
    return { ...originalDomain, ...form };
}

export function isFormChanged(form: DataDomainFormData, originalDomain: DataDomain): boolean {
    return (
        originalDomain.name !== form.name ||
        (originalDomain.description ?? '') !== form.description ||
        (originalDomain.schemaLocation ?? '') !== form.schemaLocation
    );
}
