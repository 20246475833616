/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import ListSubheader from '@mui/material/ListSubheader';
import React, { DependencyList, forwardRef, useContext, useEffect, useRef } from 'react';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import { createUseStyles } from 'react-jss';

const LISTBOX_PADDING_PX = 8;
const ITEM_SIZE = 36;

export const useStyles = createUseStyles({
    listbox: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
});

const renderRow = ({ data, index, style }: ListChildComponentProps) => {
    return React.cloneElement(data[index], {
        style: {
            ...style,
            top: (style.top as number) + LISTBOX_PADDING_PX,
        },
    });
};

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

const useResetCache = (data: DependencyList) => {
    const ref = useRef<VariableSizeList>(null);
    useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
};

const getItemSize = (child: React.ReactNode) => {
    if (React.isValidElement(child)) {
        if (child.type === ListSubheader) {
            return 48;
        }
        if (child.props && Array.isArray(child.props.children) && child.props.children.length > 1) {
            return 57;
        }
    }
    return ITEM_SIZE;
};

const VirtualizedListBox = forwardRef<HTMLDivElement>(({ children, ...other }, ref) => {
    const itemData = React.Children.toArray(children);
    const itemCount = itemData.length;
    const getChildSize = (child: React.ReactNode) => getItemSize(child);
    const getHeight = () =>
        itemCount > 8 ? 8 * ITEM_SIZE : itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    const gridRef = useResetCache([itemCount]);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING_PX}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}>
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

export const VirtualizedListBoxComponent = VirtualizedListBox as React.ComponentType<
    React.HTMLAttributes<HTMLElement>
>;
