/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';
import { mergeLines, prepareLineChartData } from '../../../utils/lineChart';
import { defaultChartPalette } from '../../../themes/palette';
import { ChartWrapper } from '../../../components/chart/ChartWrapper';
import { HighlightAreaProps, TimeLineChart } from '../../../components/chart/TimeLineChart';
import { formatDateInterval } from '../../../utils/formatDateInterval';

interface ClusterCpuUsageChartProps {
    cpuUsage:
        | Array<{
              sampleIntervalStart: string;
              sampleIntervalEnd: string;
              processCpuLoad: number;
              systemCpuLoad: number;
          }>
        | 'loading'
        | 'error';
    height: number;
    highlightArea?: HighlightAreaProps;
}

export const ClusterCpuUsageChart: React.FunctionComponent<ClusterCpuUsageChartProps> = ({
    cpuUsage,
    height,
    highlightArea,
}) => {
    const processCpuUsage = prepareLineChartData(
        'JVM process',
        cpuUsage,
        'sampleIntervalStart',
        ({ processCpuLoad }) => processCpuLoad,
        { fill: 'origin', color: defaultChartPalette[5] }
    );
    const systemCpuUsage = prepareLineChartData(
        'Operating system',
        cpuUsage,
        'sampleIntervalStart',
        ({ systemCpuLoad }) => systemCpuLoad,
        {
            fill: '+1',
            color: defaultChartPalette[1],
        }
    );
    const data = mergeLines([systemCpuUsage, processCpuUsage]);

    const percentageValueFormatter = (value: number): string => `${(value * 100).toFixed(1)}%`;

    return (
        <ChartWrapper
            data={data}
            title={'CPU usage'}
            height={height}
            legend={{
                type: 'dataset',
                variant: 'inline',
                valueFormatter: () => null,
            }}>
            {(chartData): React.ReactNode => (
                <TimeLineChart
                    data={chartData}
                    height={height}
                    percentageYAxis={true}
                    tooltipValueFormatter={percentageValueFormatter}
                    yTicksLimit={6}
                    xTicksLimit={5}
                    xTicksMaxRotation={0}
                    tooltipDateFormat={formatDateInterval(
                        cpuUsage,
                        ({ sampleIntervalStart }) => sampleIntervalStart,
                        ({ sampleIntervalEnd }) => sampleIntervalEnd
                    )}
                    labelWidth={50}
                    highlightArea={highlightArea}
                />
            )}
        </ChartWrapper>
    );
};
