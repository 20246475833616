/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import React, { ChangeEvent, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { Role } from '../../api/biac/biacRolesApi';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

const useStyles = createUseStyles((theme: Theme) => ({
    headerText: {
        fontFamily: theme.typography.fontFamily,
        fontSize: '1.25rem',
        fontWeight: 500,
        textAlign: 'center',
        letterSpacing: '0.5px',
        padding: '1.25rem 2rem',
    },
    form: {
        padding: '0 1.5rem',
        width: '30rem',
    },
    roleNameTextField: {
        paddingBottom: '0.5rem',
    },
    buttonsWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '1.5rem',
    },
    addButton: {
        marginLeft: '1rem',
    },
}));

interface CreateRoleDialogProps {
    createRole(role: Role): Promise<void>;
    closeDialog(): void;
    reload(): Promise<void>;
}

export const CreateRoleDialog: React.FunctionComponent<CreateRoleDialogProps> = ({
    createRole,
    closeDialog,
    reload,
}) => {
    const classes = useStyles();
    const [busy, setBusy] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);

    const [roleName, setRoleName] = useState<string>('');
    const [isDirtyRoleName, setDirtyRoleName] = useState<boolean>(false);
    const [isValidRoleName, setValidRoleName] = useState<boolean>(false);

    const [roleDescription, setRoleDescription] = useState<string>('');
    const [isValidRoleDescription, setValidRoleDescription] = useState<boolean>(true);

    const onRoleNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const value = event.target.value;
        setRoleName(value);
        setDirtyRoleName(true);
        setValidRoleName(value.length > 0 && value.length <= 64 && /^[a-z\d_]+$/.test(value));
    };

    const onRoleDescriptionChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const value = event.target.value;
        setRoleDescription(value);
        setValidRoleDescription(value.length < 512);
    };

    const handleCreate = async () => {
        setDirtyRoleName(true);
        if (isValidRoleName && isValidRoleDescription) {
            setBusy(true);
            setError(undefined);

            // minimum wait time for avoiding spinner flash
            const minimumWait = new Promise<void>((resolve) => setTimeout(resolve, 700));

            try {
                await createRole({ name: roleName, description: roleDescription });
            } catch (e) {
                setBusy(false);
                setError(e.message);
                return;
            }

            await Promise.all([reload(), minimumWait]);
            closeDialog();
        }
    };

    return (
        <Dialog open={true}>
            <div className={classes.headerText}>
                <Typography variant="h5" style={{ margin: 0 }}>
                    Add a new role
                </Typography>
            </div>
            <div className={classes.form}>
                {error && (
                    <Alert variant="standard" severity="error" style={{ marginBottom: '1rem' }}>
                        {error}
                    </Alert>
                )}
                <TextField
                    label="Role name"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={roleName}
                    onChange={onRoleNameChange}
                    required
                    error={isDirtyRoleName && !isValidRoleName}
                    helperText={
                        isDirtyRoleName && !isValidRoleName
                            ? 'The role name must be up to 64 characters, may contain only lowercase latin characters (a-z), numbers (0-9) and underscores (_)'
                            : undefined
                    }
                    autoFocus
                    disabled={busy}
                    onInvalid={(e) => {
                        e.preventDefault();
                    }}
                    className={classes.roleNameTextField}
                />
                <TextField
                    label="Role description"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    multiline
                    rows={5}
                    value={roleDescription}
                    onChange={onRoleDescriptionChange}
                    error={!isValidRoleDescription}
                    helperText={
                        !isValidRoleDescription
                            ? 'The description must be at most 512 characters long'
                            : undefined
                    }
                    disabled={busy}
                />
            </div>
            <div className={classes.buttonsWrapper}>
                <Button variant="outlined" color="primary" onClick={closeDialog} disabled={busy}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={busy}
                    onClick={handleCreate}
                    className={classes.addButton}>
                    {busy && (
                        <CircularProgress
                            color="secondary"
                            thickness={4}
                            size={20}
                            style={{
                                marginRight: '8px',
                            }}
                        />
                    )}
                    Add role
                </Button>
            </div>
        </Dialog>
    );
};
