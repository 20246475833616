/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { Expression } from '../../../../../api/biac/biacApi';

export interface OldExpression {
    name: string;
    sqlExpression: string;
}

export interface MaskOrFilterData {
    id: string;
    selectedValue: string;
    expressionId: number | null;
    oldExpression: OldExpression | null;
    newExpression: Expression | null;
    errorText: string | null;
}

export const initialFields = {
    selectedValue: '',
    expressionId: null,
    oldExpression: null,
    newExpression: null,
};

export const CREATE_NEW_ID = 99999999999;

export const getNewExpressionObject = (name: string) => {
    return {
        id: CREATE_NEW_ID,
        object: { name: name, expression: '' },
    };
};
