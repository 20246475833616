/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type UsageMetricsPredefinedFilter =
    | 'this_day'
    | 'this_month'
    | 'last_month'
    | 'this_year'
    | 'last_year'
    | 'all'
    | 'custom';

export interface UsageMetricsCriteria {
    option: UsageMetricsPredefinedFilter;
    startDay: Date | null;
    endDay: Date | null;
}

interface UsageMetricsSlice {
    filters: UsageMetricsCriteria;
}

const initialState: UsageMetricsSlice = {
    filters: {
        option: 'this_month',
        startDay: null,
        endDay: null,
    },
};

const usageMetricsSlice = createSlice({
    name: 'usage-metrics',
    initialState,
    reducers: {
        setOption: (state, action: PayloadAction<UsageMetricsPredefinedFilter>): void => {
            state.filters.option = action.payload;
        },
        setFilters: (state, action: PayloadAction<UsageMetricsCriteria>): void => {
            state.filters = action.payload;
        },
    },
});

export const { setOption, setFilters } = usageMetricsSlice.actions;
export default usageMetricsSlice.reducer;
