/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';
import TableCell from '@mui/material/TableCell';
import { QueryDetails } from '../../../api/queriesApi';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { paletteSwitch } from '../../../themes/palette';
import { Theme } from '@mui/material/styles';

interface TableDataProps {
    cellData: QueryDetails[keyof QueryDetails];
    rowHeight: number;
    align: 'left' | 'center';
    renderer: (cellData: QueryDetails[keyof QueryDetails]) => string | JSX.Element;
    className?: string;
}

const useStyles = createUseStyles((theme: Theme) => ({
    root: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        cursor: 'initial',
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
    },
    tableRowHover: {
        '&:hover': {
            backgroundColor: paletteSwitch(theme).nebulaNavy15,
        },
    },
}));

export const TableData: React.FunctionComponent<TableDataProps> = ({
    cellData,
    align,
    renderer,
    rowHeight,
    className,
}) => {
    const classes = useStyles();
    return (
        <TableCell
            component="div"
            className={clsx(classes.root, className)}
            variant="body"
            style={{ height: rowHeight, justifyContent: align }}>
            {cellData !== undefined ? renderer(cellData) : null}
        </TableCell>
    );
};

export const useTableDataRowClassName = (): ((index: number) => string) => {
    const classes = useStyles();

    return (index: number): string =>
        clsx(classes.flexContainer, {
            [classes.tableRowHover]: index >= 0,
        });
};
