/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import { createUseStyles } from 'react-jss';
import React from 'react';
import Typography from '@mui/material/Typography';

const useStyles = createUseStyles({
    title: {
        margin: '1rem 0 1.5rem 0',
        fontSize: '1.25rem',
        lineHeight: '30px',
    },
    info: {
        maxWidth: '35vw',
        letterSpacing: '0.5px',
        lineHeight: '24px',
    },
    subTitle: {
        fontSize: '1.125rem',
        margin: '1.5rem 0',
    },
});

interface SettingsTypographyProps {
    text: string;
}

export const SettingsTitle: React.FunctionComponent<SettingsTypographyProps> = ({ text }) => {
    const classes = useStyles();
    return (
        <Typography variant={'h5'} className={classes.title}>
            {text}
        </Typography>
    );
};

export const SettingsSubTitle: React.FunctionComponent<SettingsTypographyProps> = ({ text }) => {
    const classes = useStyles();
    return (
        <Typography variant={'h5'} className={classes.subTitle}>
            {text}
        </Typography>
    );
};
