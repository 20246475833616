/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { Observable, Subject } from 'rxjs';
import { SchemaDataProduct, Tag, View } from './dataProductApi';
const subject = new Subject<DataProductEvent>();

export const dataProductEvent$: Observable<DataProductEvent> = subject;

interface BookmarkChangedEvent {
    type: 'bookmarkChanged';
    payload: {
        dataProductId: string;
        isBookmarked: boolean;
    };
}

interface RatingChangedEvent {
    type: 'ratingChanged';
    payload: {
        dataProductId: string;
        rating: number | null;
    };
}

interface ProductPublishedEvent {
    type: 'published';
    payload: {
        dataProductId: string;
    };
}

interface ProductDeletedEvent {
    type: 'deleted';
    payload: {
        dataProductId: string;
    };
}

interface ProductPatchedEvent {
    type: 'patched';
    payload: Partial<SchemaDataProduct> & {
        dataProductId: string;
    };
}

export interface TagsAssignedEvent {
    type: 'tagsAssigned';
    payload: {
        dataProductId: string;
        tags: Tag[];
    };
}

export interface DatasetClonedEvent {
    type: 'datasetCloned';
    payload: {
        dataProductId: string;
        view: View;
    };
}

type DataProductEvent =
    | BookmarkChangedEvent
    | RatingChangedEvent
    | ProductDeletedEvent
    | ProductPublishedEvent
    | ProductPatchedEvent
    | TagsAssignedEvent
    | DatasetClonedEvent;

export const emitDataProductEvent = (event: DataProductEvent): void => {
    subject.next(event);
};

export function isTagsAssignedEvent(
    dataProductEvent: DataProductEvent
): dataProductEvent is TagsAssignedEvent {
    return dataProductEvent.type === 'tagsAssigned';
}
