/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import Grid from '@mui/material/Grid';
import { createUseStyles } from 'react-jss';
import noop from 'lodash/noop';
import React from 'react';
import { Column } from '../../../api/dataProduct/dataProductApi';
import { ColumnNameDescription } from '../publish/publishFormReducer';
import { ViewColumn } from './ViewColumn';

export type Mode = 'create' | 'edit' | 'display';

interface ViewSchemaProps {
    columns: Column[];
    mode?: Mode;
    updateColumnDescription?: (column: ColumnNameDescription) => void;
}

const useStyles = createUseStyles({
    root: {
        paddingBottom: '1.5rem',
    },
    title: {
        fontSize: '0.875rem',
        fontWeight: 500,
    },
});

export const ViewSchema: React.FunctionComponent<ViewSchemaProps> = ({
    columns,
    mode = 'edit',
    updateColumnDescription = noop,
}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid
                container
                direction="row"
                alignItems="center"
                spacing={1}
                className={classes.title}>
                <Grid item lg={3} md={3} sm={12}>
                    <div>Column name</div>
                </Grid>
                <Grid item lg={3} md={3} sm={12}>
                    <div>Data type</div>
                </Grid>
                <Grid item lg={6} md={6} sm={12}>
                    <div>Column description (optional)</div>
                </Grid>
            </Grid>
            {columns.map((col, index) => (
                <ViewColumn
                    key={index}
                    column={col}
                    mode={mode}
                    updateColumnDescription={updateColumnDescription}
                />
            ))}
        </div>
    );
};
