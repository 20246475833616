/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useEffect } from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { EmptyOrValue } from '../../../../utils/value';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import { addPrivilegesStyles } from '../add-privileges-styles';
import { CustomItemSelector } from './CustomItemSelector';

interface ProcedureSelectorProps {
    disabled: boolean;
    schemaName: EmptyOrValue<string | null>;
    value: EmptyOrValue<string[] | null>;
    handleChange: React.Dispatch<React.SetStateAction<EmptyOrValue<string[] | null>>>;
}

export const ProcedureSelector: React.FunctionComponent<ProcedureSelectorProps> = ({
    value,
    handleChange,
    disabled,
    schemaName,
}) => {
    const classes = addPrivilegesStyles();

    const allChecked = !value.empty && value.value === null;

    const resetSelection = useCallback(() => {
        handleChange({ empty: true, value: null });
    }, [handleChange]);

    useEffect(() => {
        return () => resetSelection();
    }, []);

    useEffect(() => {
        if (schemaName.empty || schemaName.value === null) {
            resetSelection();
        }
    }, [schemaName]);

    const handleCheckStar = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            handleChange({
                empty: false,
                value: null,
            });
        } else {
            handleChange({
                empty: true,
                value: null,
            });
        }
    }, []);

    return (
        <Grid pl={1} maxWidth="fit-content">
            <Typography variant="h5">Which procedures would you like to select?</Typography>
            <Typography className={classes.questionInfo}>
                Use the dropdown to select the procedures. To select all currently defined
                procedures, as well as procedures defined in the future, check the{' '}
                <b>All procedures</b> box instead.
            </Typography>
            <Box mb={1.5}>
                <FormControlLabel
                    control={
                        <Checkbox
                            disabled={disabled}
                            checked={allChecked}
                            onChange={handleCheckStar}
                            id="select-star"
                        />
                    }
                    label={
                        <span>
                            All procedures{' '}
                            <span className={classes.cautionText}>(use with caution)</span>
                        </span>
                    }
                />
            </Box>
            <Box mb={2}>
                <CustomItemSelector
                    disabled={disabled || allChecked}
                    label="Procedures"
                    newTagOptionPostfix={' (new procedure)'}
                    value={value}
                    handleChange={handleChange}
                    suggestions={[]}
                />
            </Box>
        </Grid>
    );
};
