/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import React from 'react';
import { faBan, faChartColumn, faTable } from '@fortawesome/pro-regular-svg-icons';
import { paletteSwitch } from '../../themes/palette';
import { createUseStyles } from 'react-jss';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { Theme } from '@mui/material/styles';

interface NoDataProps {
    height: number;
    icon?: 'chart' | 'table' | 'ban';
    text?: string;
}

const useStyles = createUseStyles((theme: Theme) => ({
    icon: {
        color: paletteSwitch(theme).black12,
        opacity: 0.85,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    text: {
        color: paletteSwitch(theme).black54,
        marginLeft: 'auto',
        marginRight: 'auto',
        fontWeight: 400,
        fontSize: '1rem',
    },
}));

export const NoData: React.FunctionComponent<NoDataProps> = ({
    height,
    icon = 'chart',
    text = 'No data',
}) => {
    const classes = useStyles();

    const getIcon = (): IconDefinition => {
        switch (icon) {
            case 'ban':
                return faBan;
            case 'chart':
                return faChartColumn;
            case 'table':
                return faTable;
        }
    };

    return (
        <Box display={'flex'} flexDirection={'column'} style={{ height }}>
            <FontAwesomeIcon
                className={classes.icon}
                style={{
                    marginTop: height > 150 ? height / 12 : 0,
                    fontSize: Math.min(128, height * 0.7),
                }}
                icon={getIcon()}
            />
            <Box
                className={classes.text}
                style={{ marginTop: icon === 'chart' ? '0.25rem' : '0.5rem' }}>
                {text}
            </Box>
        </Box>
    );
};
