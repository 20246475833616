/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useState } from 'react';
import { saveFile } from '../../../utils/fileDownloadUtils';
import { LoadingButton } from '../../../components/LoadingButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/pro-regular-svg-icons';

interface FilterChipsProps {
    fetchCsv: () => Promise<Blob>;
    fileName: string;
    disabled: boolean;
}

export const DownloadCsvButton: React.FunctionComponent<FilterChipsProps> = ({
    fetchCsv,
    fileName,
    disabled,
}) => {
    const [downloadingCsv, setDownloadingCsv] = useState(false);

    const onDownloadCsv = useCallback(async () => {
        try {
            setDownloadingCsv(true);
            const blob = await fetchCsv();
            saveFile(fileName, blob);
        } finally {
            setDownloadingCsv(false);
        }
    }, [fetchCsv]);
    return (
        <LoadingButton
            loading={downloadingCsv}
            color="primary"
            onClick={onDownloadCsv}
            disabled={disabled}>
            <FontAwesomeIcon icon={faDownload} style={{ marginRight: '8px' }} />
            Download CSV
        </LoadingButton>
    );
};
