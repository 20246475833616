/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { Theme } from '@mui/material/styles';
import { paletteSwitch } from '../../../themes/palette';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Ellipsis } from '../../../components/ellipsis/Ellipsis';
import { AccessMode } from '../../../api/savedQueriesApi';

const selectSharingOptions: { mode: AccessMode; label: string }[] = [
    { mode: 'CAN_EDIT', label: 'Can edit' },
    { mode: 'CAN_VIEW', label: 'Can view and run' },
];

const selectSharingOptionsWithRemove: { mode: AccessMode; label: string }[] = [
    ...selectSharingOptions,
    { mode: 'NONE', label: 'Remove access' },
];

const useStyles = createUseStyles((theme: Theme) => ({
    textFieldRoot: {
        width: '100%',
        '& .MuiFormLabel-root': {
            marginTop: '0.125rem',
        },
        '& .MuiOutlinedInput-root': {
            height: '3rem',
        },
        '& .MuiSelect-select': {
            padding: '0 0.8rem',
            fontFamily: theme.typography.fontFamily,
            typography: 'primary',
            fontWeight: 400,
            fontSize: '1rem',
            lineHeight: '24px',
            letterSpacing: '0.144px',
            color: `${paletteSwitch(theme).black}`,
        },
    },
}));

interface QuerySharingModesSelectProps {
    mode: AccessMode;
    onModeChange: (mode: AccessMode) => void;
    canRemove: boolean;
}

export const QuerySharingModesSelect: React.FunctionComponent<QuerySharingModesSelectProps> = ({
    onModeChange,
    mode,
    canRemove,
}) => {
    const classes = useStyles();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const selectedValue = value as AccessMode;

        onModeChange(selectedValue);
    };
    const options = useMemo(
        () => (canRemove ? selectSharingOptionsWithRemove : selectSharingOptions),
        [canRemove]
    );
    return (
        <TextField
            select
            label="Access"
            variant="outlined"
            id="filled-select-readonly"
            value={mode}
            onChange={handleChange}
            classes={{ root: classes.textFieldRoot }}>
            {options.map(({ mode, label }) => (
                <MenuItem key={mode} value={mode}>
                    <Ellipsis>{label}</Ellipsis>
                </MenuItem>
            ))}
        </TextField>
    );
};
