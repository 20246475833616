/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import Typography from '@mui/material/Typography';
import { createUseStyles } from 'react-jss';
import { SettingsSubTitle, SettingsTitle } from '../util/SettingsTypography';
import Box from '@mui/material/Box';
import { paletteSwitch } from '../../../themes/palette';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { LoadingButton } from '../../../components/LoadingButton';
import size from 'lodash/size';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import {
    CustomizeLoginSettings,
    getLoginSettingsConfig,
    updateConfig,
} from '../../../api/loginApi';
import { Spinner } from '../../../components/spinner/Spinner';
import { ErrorBox } from '../../../components/error/ErrorBox';
import { Canceler } from 'axios';
import { formatBytes } from '../../../utils/formatBytes';
import { useTextInputValueChange } from '../../../utils/useTextInputValueChange';
import { AuthorizedUserBiac } from '../../../components/biac/AuthorizedUserBiac';
import { UiFeatures } from '../../../api/configApi';
import { Theme } from '@mui/material/styles';

const useStyles = createUseStyles((theme: Theme) => ({
    info: {
        maxWidth: '600px',
        letterSpacing: '0.5px',
        lineHeight: '24px',
    },
    imgUploadBox: {
        borderColor: paletteSwitch(theme).nebulaNavy100,
    },
    imgConstraints: {
        fontSize: '0.75rem',
        marginTop: '0.25rem',
        lineHeight: '1rem',
        letterSpacing: '0.4px',
        color: paletteSwitch(theme).black54,
    },
    imgLabel: {
        marginLeft: '1rem',
        color: paletteSwitch(theme).black54,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '350px',
        overflow: 'hidden',
    },
    imgPreview: {
        maxWidth: '300px',
        maxHeight: '150px',
        margin: '1.5rem 0 0 2rem',
    },
    deleteIcon: {
        color: paletteSwitch(theme).error,
        padding: 0,
        marginLeft: '0.5rem',
        fontSize: '2rem',
    },
}));

const CHARACTER_LIMIT = 300;
const MAX_FILE_SIZE = 102400; //MAX_FILE_SIZE_IN_BYTES
type Status = 'loading' | 'error' | 'success';

interface Validation {
    validationFailed?: boolean;
    isNewUpload?: boolean;
    displayMsg?: string;
    selectedImgName?: string;
    status?: Status;
}

const validationStateFields = {
    validationFailed: false,
    isNewUpload: true,
    displayMsg: '',
    selectedImgName: '',
};
export const CustomizeLogin: React.FunctionComponent = () => {
    const classes = useStyles();

    const loginSettingsInitialState = {
        bannerText: '',
        bannerTextSize: CHARACTER_LIMIT,
        companyLogo: '',
        companyLogoSize: MAX_FILE_SIZE,
    };

    const [loginSettings, setLoginSettings] =
        useState<CustomizeLoginSettings>(loginSettingsInitialState);

    const [validation, setValidation] = useState<Validation>({
        ...validationStateFields,
        status: 'loading',
    });

    const cancelerRef = useRef<Canceler>();

    useEffect(() => {
        getLoginSettingsConfig()
            .then((settings) => {
                setLoginSettings(settings);
                setValidation({
                    ...validationStateFields,
                    status: 'success',
                    isNewUpload: !settings.companyLogo,
                });
            })
            .catch(() => {
                setValidation({ status: 'error' });
            });
        return () => {
            cancelerRef.current && cancelerRef.current();
        };
    }, []);

    const updateBannerText = (val: string) => {
        setLoginSettings((prevState) => ({
            ...prevState,
            bannerText: val,
        }));
    };

    const handleChange = useTextInputValueChange((value) => updateBannerText(value), []);

    if (validation.status === 'loading') {
        return <Spinner position={'absolute'} />;
    } else if (validation.status === 'error') {
        return <ErrorBox text={'Could not load the customize login settings. Try again later'} />;
    }

    const resetLogo = () => {
        setLoginSettings((prevState) => ({
            ...prevState,
            companyLogo: '',
        }));
    };

    const handleImgError = (errorMsg: string) => {
        setValidation({
            ...validationStateFields,
            displayMsg: errorMsg,
            validationFailed: true,
        });
        resetLogo();
    };

    const deleteIcon = (isNewUpload: boolean) => {
        return (
            <IconButton
                className={classes.deleteIcon}
                aria-label="delete-img"
                onClick={() => {
                    removeImg(isNewUpload);
                }}
                size="large">
                <DeleteOutline />
            </IconButton>
        );
    };

    const removeImg = (isNewUpload: boolean) => {
        resetLogo();
        if (isNewUpload) {
            setValidation((prevState) => ({
                ...prevState,
                isNewUpload: true,
            }));
        }
    };

    const convertToBase64AndUpdate = (file: File) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setLoginSettings((prevState) => ({
                ...prevState,
                companyLogo: reader.result ? reader.result.toString() : '',
            }));
            setValidation({
                ...validationStateFields,
                selectedImgName: file.name,
            });
        };
    };

    const handleCapture = (event: ChangeEvent<HTMLInputElement>) => {
        if (event && event.target && event.target.files) {
            const uploadedLogo = event.target.files[0];
            if (!uploadedLogo.name.match(/\.(jpg|jpeg|png|svg)$/)) {
                handleImgError('Image format is not valid');
                return;
            } else if (uploadedLogo.size > loginSettings.companyLogoSize) {
                handleImgError(
                    `Image size must not exceed ${formatBytes(loginSettings.companyLogoSize)} `
                );
                return;
            } else {
                convertToBase64AndUpdate(uploadedLogo);
                event.target.value = '';
            }
        }
    };

    const handleSave = async () => {
        await updateConfig(loginSettings)
            .then((settings) => {
                setLoginSettings(settings);
                setValidation((prevState) => ({
                    ...prevState,
                    displayMsg: 'Updated successfully',
                    validationFailed: false,
                }));
            })
            .catch(() => {
                setValidation((prevState) => ({
                    ...prevState,
                    displayMsg: 'Failed to update the configuration',
                    validationFailed: true,
                }));
            });
    };

    return (
        <AuthorizedUserBiac uiFeature={UiFeatures.SETTINGS_LOGIN}>
            <>
                <SettingsTitle text={'Customize login'} />
                <Typography className={classes.info}>
                    You can customize your login screen by uploading a company logo and adding a
                    banner message.
                </Typography>
                {validation.displayMsg && (
                    <Box mt={2} width={480}>
                        <Alert
                            severity={validation.validationFailed ? 'error' : 'success'}
                            variant="standard">
                            {validation.displayMsg}
                        </Alert>
                    </Box>
                )}
                <SettingsSubTitle text={'Logo upload'} />
                {!validation.isNewUpload && (
                    <Box mb={1} alignItems={'center'} display={'flex'}>
                        <b style={{ marginRight: '0.5rem' }}>Current logo: </b>
                        {'Uploaded logo'}
                        {deleteIcon(true)}
                    </Box>
                )}
                {validation.isNewUpload && (
                    <>
                        <Box display="flex" alignItems="center" height={60}>
                            <Box
                                border={1}
                                borderRadius="4px"
                                width={480}
                                height={56}
                                p={1}
                                className={classes.imgUploadBox}>
                                <input
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    id="raised-button-file"
                                    type="file"
                                    onChange={handleCapture}
                                />
                                <Box display={'flex'} alignItems={'center'} pr={1}>
                                    <label htmlFor="raised-button-file">
                                        <Button variant="outlined" color="primary" component="span">
                                            Choose
                                        </Button>
                                    </label>
                                    <label className={classes.imgLabel}>
                                        {loginSettings.companyLogo
                                            ? validation.selectedImgName
                                            : 'No file selected'}
                                    </label>
                                </Box>
                            </Box>
                            {loginSettings.companyLogo && deleteIcon(false)}
                        </Box>
                        <Typography
                            className={classes.imgConstraints}
                            style={{ marginLeft: '0.875rem' }}>
                            Max file size: {formatBytes(loginSettings.companyLogoSize)}; file
                            formats: .png .jpg .jpeg .svg
                        </Typography>
                    </>
                )}
                {loginSettings.companyLogo && (
                    <>
                        <div>
                            <img
                                className={classes.imgPreview}
                                alt={'logo'}
                                src={loginSettings.companyLogo}
                            />
                        </div>
                        <Typography className={classes.imgConstraints}>
                            Image scaled to max width 300px, max height 150px to fit on login
                            screen.
                        </Typography>
                    </>
                )}
                <SettingsSubTitle text={'Banner messaging'} />
                <Box maxWidth={600}>
                    <TextField
                        id="outlined-multiline-static"
                        inputProps={{
                            maxLength: loginSettings.bannerTextSize,
                        }}
                        value={loginSettings.bannerText}
                        placeholder="Banner message content (optional)"
                        helperText={`${
                            loginSettings.bannerTextSize - size(loginSettings.bannerText)
                        } characters remaining`}
                        multiline
                        rows={5}
                        variant="outlined"
                        onChange={handleChange}
                        fullWidth
                    />
                    <Box display="flex" mt={2} style={{ float: 'right' }} alignItems="center">
                        {loginSettings.updatedAt && (
                            <Typography
                                className={classes.imgConstraints}
                                style={{ marginRight: '1rem' }}>
                                Last saved at {new Date(loginSettings.updatedAt).toLocaleString()}{' '}
                                by {loginSettings.updatedBy}
                            </Typography>
                        )}
                        <LoadingButton
                            id="save-button"
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={handleSave}>
                            Save
                        </LoadingButton>
                    </Box>
                </Box>
            </>
        </AuthorizedUserBiac>
    );
};
