/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { useCallback, useEffect } from 'react';
import {
    ColumnMaskInfo,
    deleteColumnMask,
    deleteRowFilter,
    getColumnMasks,
    getRowFilters,
    RowFilterInfo,
} from '../../../api/biac/biacRolesApi';
import { useFetchingState } from '../../dataproduct/domain/useFetchingState';

export const useColumnMasksFetchingState = (currentRoleName: string, roleId: number) => {
    const state = useFetchingState<ColumnMaskInfo[]>();

    const reload = useCallback(() => {
        state.setFetching();
        return getColumnMasks(currentRoleName, roleId).subscribe(state.observer);
    }, [currentRoleName, roleId]);

    useEffect(() => {
        const subscription = reload();
        return () => subscription.unsubscribe();
    }, [reload]);

    const doDelete = useCallback(
        (id: number) => {
            return deleteColumnMask(currentRoleName, roleId, id);
        },
        [currentRoleName, roleId]
    );

    return { ...state, reload, doDelete };
};

export const useRowFiltersFetchingState = (currentRoleName: string, roleId: number) => {
    const state = useFetchingState<RowFilterInfo[]>();

    const reload = useCallback(() => {
        state.setFetching();
        return getRowFilters(currentRoleName, roleId).subscribe(state.observer);
    }, [currentRoleName, roleId]);

    useEffect(() => {
        const subscription = reload();
        return () => subscription.unsubscribe();
    }, [reload]);

    const doDelete = useCallback(
        (id: number) => {
            return deleteRowFilter(currentRoleName, roleId, id);
        },
        [currentRoleName, roleId]
    );

    return { ...state, reload, doDelete };
};
