/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import TableCell from '@mui/material/TableCell';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { paletteSwitch } from '../../../themes/palette';
import { QueriesTableRowData } from './QueriesTable';
import { Theme } from '@mui/material/styles';

const useStyles = createUseStyles((theme: Theme) => ({
    root: {
        flex: 1,
        display: 'flex!important',
        alignItems: 'center',
        boxSizing: 'border-box',
        cursor: 'initial',
        justifyContent: 'flex-end',
        borderBottom: 'none!important',
    },
    content: {
        fontStyle: 'italic',
        color: paletteSwitch(theme).black54,
        fontSize: '0.75rem',
        marginRight: '1rem',
    },
    row: {
        justifyContent: 'flex-end',
    },
}));

const maxRows = 1000;

export const isFooterRow = (rowData: QueriesTableRowData): boolean => {
    return rowData === 'footer-row';
};

interface TableFooterProps {
    columnIndex: number;
    rowHeight: number;
    foundRows: number;
}

export const TableFooter: React.FunctionComponent<TableFooterProps> = ({
    columnIndex,
    rowHeight,
    foundRows,
}) => {
    const classes = useStyles();

    if (columnIndex !== 0) {
        return null;
    }

    return (
        <TableCell
            component="div"
            className={`${classes.root}`}
            variant="body"
            style={{
                height: rowHeight,
            }}>
            <div className={classes.content}>
                {foundRows < maxRows
                    ? `${foundRows} queries found`
                    : `Displaying first ${foundRows} queries. Narrow the search criteria to see more.`}
            </div>
        </TableCell>
    );
};

export const useTableFooterRowClassName = (): string => {
    const classes = useStyles();
    return classes.row;
};
