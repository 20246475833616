/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { ReactElement } from 'react';
import { FullScreenDialog } from '../../../../components/dialog/FullScreenDialog';
import { createUseStyles } from 'react-jss';
import Fullscreen from '@mui/icons-material/Fullscreen';
import { QueryLivePlan } from '../advanced/live-plan/QueryLivePlan';
import Button from '@mui/material/Button';

const useStyles = createUseStyles({
    button: {
        marginTop: '8px',
        float: 'right',
    },
    content: {
        height: '92vh',
        padding: '1.5rem',
        display: 'flex',
        alignItems: 'stretch',
    },
    icon: {
        marginRight: '8px',
    },
});

interface QueryLivePlanGraphFullScreenProps {
    queryId: string;
}

export const QueryLivePlanGraphFullScreen: React.FunctionComponent<
    QueryLivePlanGraphFullScreenProps
> = ({ queryId }) => {
    const classes = useStyles();
    const liveQueryPlan = (
        button: (open: () => void) => ReactElement<HTMLElement>
    ): ReactElement<HTMLElement> | null => {
        if (!queryId) {
            return null;
        }

        return (
            <FullScreenDialog header="Query live plan" button={button}>
                <div className={classes.content}>
                    <QueryLivePlan queryId={queryId} />
                </div>
            </FullScreenDialog>
        );
    };

    return (
        <div className={classes.button}>
            {liveQueryPlan((open) => (
                <Button variant="text" color="primary" onClick={open}>
                    <Fullscreen className={classes.icon} />
                    View plan in full screen
                </Button>
            ))}
        </div>
    );
};
