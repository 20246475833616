/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';
import { QueriesByUserOverviewChart } from './chart/QueriesByUserOverviewChart';
import { QueriesByUser, CpuTimeByUser } from '../../api/queryReportApi';
import { createUseStyles } from 'react-jss';
import { formatDistanceToNowStrict, parseISO } from 'date-fns';
import Box from '@mui/material/Box';
import { CpuTimeByUserOverviewChart } from './chart/CpuTimeByUserOverviewChart';
import { GroupedReports } from '../../components/chart/GroupedReports';
import { OverviewChartsHeight } from './Overview';

interface TopUsersOverviewProps {
    queriesByUser: QueriesByUser | 'loading' | 'error';
    cpuTimeByUser: CpuTimeByUser | 'loading' | 'error';
    oldestQueryTime: string | undefined;
    height: OverviewChartsHeight;
}

const useStyles = createUseStyles({
    card: {
        height: '100%',
    },
    header: {
        fontSize: '1.125rem',
    },
    container: {
        display: 'block',
    },
});

type Tab = 'cpu' | 'count';

export const TopUsersOverview: React.FunctionComponent<TopUsersOverviewProps> = ({
    queriesByUser,
    cpuTimeByUser,
    oldestQueryTime,
    height,
}) => {
    const classes = useStyles();

    const getPeriod = (): string => {
        if (!oldestQueryTime) {
            return '';
        }

        return `(last ${formatDistanceToNowStrict(parseISO(oldestQueryTime))})`;
    };
    const period = getPeriod();

    return (
        <GroupedReports<Tab>
            title="Top users"
            description={period}
            tabs={[
                { tab: 'cpu', label: 'By CPU time' },
                { tab: 'count', label: 'By queries' },
            ]}>
            {(tab) => (
                <Box className={classes.container} pt={1} pb={1}>
                    {tab === 'cpu' && (
                        <div>
                            <CpuTimeByUserOverviewChart
                                height={height.bigChart}
                                cpuTimeByUser={cpuTimeByUser}
                            />
                        </div>
                    )}
                    {tab === 'count' && (
                        <div>
                            <QueriesByUserOverviewChart
                                height={height.bigChart}
                                queriesByUser={queriesByUser}
                            />
                        </div>
                    )}
                </Box>
            )}
        </GroupedReports>
    );
};
