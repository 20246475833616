/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import { DataProductSortKey } from '../../api/dataProduct/dataProductApi';
import { SortingState } from '../../components/table/SortableColumn';

export enum SortCategory {
    NAME_ASC = 'alpha_asc',
    NAME_DESC = 'alpha_desc',
    CREATED_DATE_ASC = 'created_date_asc',
    CREATED_DATE_DESC = 'created_date_desc',
    LAST_QUERIED_AT_DESC = 'last_queried_at_desc',
    LAST_QUERIED_AT_ASC = 'last_queried_at_asc',
    STATUS_ASC = 'status_asc',
    STATUS_DESC = 'status_desc',
    BOOKMARK_ASC = 'bookmark_asc',
    BOOKMARK_DESC = 'bookmark_desc',
    RATING_AVERAGE_ASC = 'average_rating_asc',
    RATING_AVERAGE_DESC = 'average_rating_desc',
}
const defaultSortCategory = SortCategory.NAME_ASC;
export type SortDescriptor = SortingState<DataProductSortKey>;

const nameAscSortDescriptor: SortDescriptor = {
    sortBy: 'NAME',
    sortOrder: 'asc',
};

const nameDescSortDescriptor: SortDescriptor = {
    sortBy: 'NAME',
    sortOrder: 'desc',
};

const createdDateAscSortDescriptor: SortDescriptor = {
    sortBy: 'CREATED_AT',
    sortOrder: 'asc',
};

const createdDateDescSortDescriptor: SortDescriptor = {
    sortBy: 'CREATED_AT',
    sortOrder: 'desc',
};

const lastQueriedAtDescSortDescriptor: SortDescriptor = {
    sortBy: 'LAST_QUERIED_AT',
    sortOrder: 'desc',
};

const lastQueriedAtAscSortDescriptor: SortDescriptor = {
    sortBy: 'LAST_QUERIED_AT',
    sortOrder: 'asc',
};

const statusAscSortDescriptor: SortDescriptor = {
    sortBy: 'STATUS',
    sortOrder: 'asc',
};

const statusDescSortDescriptor: SortDescriptor = {
    sortBy: 'STATUS',
    sortOrder: 'desc',
};

const bookmarkAscSortDescriptor: SortDescriptor = {
    sortBy: 'BOOKMARK',
    sortOrder: 'asc',
};

const bookmarkDescSortDescriptor: SortDescriptor = {
    sortBy: 'BOOKMARK',
    sortOrder: 'desc',
};

const ratingAverageAscSortDescriptor: SortDescriptor = {
    sortBy: 'RATINGS_AVERAGE',
    sortOrder: 'asc',
};

const ratingAverageDescSortDescriptor: SortDescriptor = {
    sortBy: 'RATINGS_AVERAGE',
    sortOrder: 'desc',
};

const sortCategoryToSortDescriptor = {
    [SortCategory.NAME_ASC]: nameAscSortDescriptor,
    [SortCategory.NAME_DESC]: nameDescSortDescriptor,
    [SortCategory.CREATED_DATE_ASC]: createdDateAscSortDescriptor,
    [SortCategory.CREATED_DATE_DESC]: createdDateDescSortDescriptor,
    [SortCategory.LAST_QUERIED_AT_DESC]: lastQueriedAtDescSortDescriptor,
    [SortCategory.LAST_QUERIED_AT_ASC]: lastQueriedAtAscSortDescriptor,
    [SortCategory.STATUS_ASC]: statusAscSortDescriptor,
    [SortCategory.STATUS_DESC]: statusDescSortDescriptor,
    [SortCategory.BOOKMARK_ASC]: bookmarkAscSortDescriptor,
    [SortCategory.BOOKMARK_DESC]: bookmarkDescSortDescriptor,
    [SortCategory.RATING_AVERAGE_ASC]: ratingAverageAscSortDescriptor,
    [SortCategory.RATING_AVERAGE_DESC]: ratingAverageDescSortDescriptor,
};

export function mapCategoryToDescriptor(category: SortCategory): SortDescriptor {
    return sortCategoryToSortDescriptor[category];
}

export function mapDescriptorToCategory(descriptor: SortDescriptor): SortCategory {
    const allCategories: SortCategory[] = Object.values(SortCategory) as SortCategory[];
    return (
        allCategories.find((category: SortCategory) => {
            const currentDescriptor = sortCategoryToSortDescriptor[category];
            return (
                descriptor.sortOrder === currentDescriptor.sortOrder &&
                descriptor.sortBy === currentDescriptor.sortBy
            );
        }) ?? defaultSortCategory
    );
}
