/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useMemo } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Edit from '@mui/icons-material/Edit';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import { useContextMenuStyles } from '../../../../components/ContextMenu/useContextMenuStyles';
import { getEntityDisplayName, RowExpressionType } from '../MasksAndFilters';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import { Tooltip } from '../../../../components/tooltip/Tooltip';

interface ExpressionContextMenuProps {
    anchorElement?: HTMLElement;
    close: () => void;
    edit: () => void;
    deleteExpression: () => void;
    rowExpressionType: RowExpressionType;
    isDeleteEnabled: boolean;
    isOpen: boolean;
}

export const ExpressionContextMenu: React.FunctionComponent<ExpressionContextMenuProps> = (
    params
) => {
    const styles = useContextMenuStyles();

    const edit = useCallback(() => {
        params.edit();
        params.close();
    }, [params.edit, params.close]);

    const deleteExpression = useCallback(() => {
        params.deleteExpression();
        params.close();
    }, [params.deleteExpression, params.close]);
    const displayEntityName = useMemo(
        () => getEntityDisplayName(params.rowExpressionType).toLowerCase(),
        [params.rowExpressionType]
    );

    const deleteMenuItem = (
        <Box>
            <MenuItem
                className={clsx({
                    [styles.menuItemEnabledDangerous]: params.isDeleteEnabled,
                })}
                disabled={!params.isDeleteEnabled}
                onClick={params.isDeleteEnabled ? deleteExpression : undefined}>
                <DeleteOutline className={styles.icon} />
                Delete {displayEntityName}
            </MenuItem>
        </Box>
    );
    return (
        <Menu anchorEl={params.anchorElement} open={params.isOpen} onClose={params.close}>
            <MenuItem onClick={edit}>
                <Edit className={styles.icon} />
                Edit {displayEntityName}
            </MenuItem>
            {params.isDeleteEnabled ? (
                <Box>{deleteMenuItem}</Box>
            ) : (
                <Tooltip
                    title={`Cannot delete ${displayEntityName} because it is used in a filter.`}>
                    {deleteMenuItem}
                </Tooltip>
            )}
        </Menu>
    );
};
