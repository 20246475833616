/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { useContext } from 'react';
import { ConfigContext } from '../../../app/ConfigContextProvider';

export const useIsLoggedInUserCommentAuthor = (commentAuthor: string) => {
    const userDetails = useContext(ConfigContext);
    return userDetails?.user === commentAuthor;
};
