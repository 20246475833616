/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import clsx from 'clsx';
import { userRolePrivileges } from '../userRolePrivileges';
import { Spinner } from '../../../components/spinner/Spinner';
import { ErrorBox } from '../../../components/error/ErrorBox';
import { PrivilegesEntry } from './entry/PrivilegeEntry';
import Link from '@mui/material/Link';
import { useRoleDetailsStyles } from './RoleDetailsStyles';

interface PrivilegesListProps {
    currentRoleName: string;
    roleId: number;
}

export const PrivilegesList: React.FunctionComponent<PrivilegesListProps> = ({
    currentRoleName,
    roleId,
}) => {
    const classes = useRoleDetailsStyles();
    const { privileges, error, setError, reload } = userRolePrivileges(currentRoleName, roleId);
    const privilegesCount = Array.isArray(privileges) ? privileges.length : 0;
    return (
        <>
            {error && (
                <Grid item>
                    <Alert
                        variant="standard"
                        severity="error"
                        classes={{ message: classes.errorMessage }}>
                        <Box display="flex" justifyContent="space-between">
                            <div>{error}</div>
                            <Link color="error" onClick={reload} className={classes.reloadLink}>
                                Reload
                            </Link>
                        </Box>
                    </Alert>
                </Grid>
            )}
            <Grid
                item
                className={clsx(classes.list, {
                    [classes.listShort]: !!error,
                })}>
                {privileges === 'loading' && <Spinner position="relative" />}
                {privileges === 'error' && <ErrorBox height={200} text="Cannot load privileges" />}
                {privilegesCount > 0 && (
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        className={classes.header}>
                        <div style={{ width: '16.5rem' }}>Entity</div>
                        <div style={{ flexGrow: 1 }}>Privilege</div>
                    </Box>
                )}
                {Array.isArray(privileges) &&
                    privileges.map(
                        ({
                            grantId,
                            entityCategory,
                            entityValue,
                            entitySpecified,
                            grantOption,
                            action,
                            canManage,
                            effect,
                        }) => (
                            <PrivilegesEntry
                                key={grantId}
                                currentRoleName={currentRoleName}
                                grantId={grantId}
                                entityCategory={entityCategory}
                                entityValue={entityValue}
                                entitySpecified={entitySpecified}
                                grantOption={grantOption}
                                action={action}
                                canManage={canManage}
                                onDelete={() => setError(null)}
                                onDeleteSuccess={reload}
                                onDeleteFailure={(e) => setError(e.message)}
                                effect={effect}
                            />
                        )
                    )}
                {privilegesCount > 0 && <div className={classes.endBorder} />}
                {Array.isArray(privileges) && privilegesCount === 0 && (
                    <div className={classes.noContent}>No privileges</div>
                )}
            </Grid>
        </>
    );
};
