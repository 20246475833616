/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useState } from 'react';
import { paletteSwitch } from '../../themes/palette';
import { NodeSummaries } from '../../api/overviewApi';
import { formatDuration } from '../../utils/formatDuration';
import Pagination from '@mui/material/Pagination';
import ceil from 'lodash/ceil';
import orderBy from 'lodash/orderBy';
import { SortableColumn, SortingState } from '../../components/table/SortableColumn';
import { Spinner } from '../../components/spinner/Spinner';
import { ValueTrending } from './ValueTrending';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { createUseStyles } from 'react-jss';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { ErrorBox } from '../../components/error/ErrorBox';
import { formatBytes } from '../../utils/formatBytes';
import { Theme, useTheme } from '@mui/material/styles';

const useStyles = createUseStyles((theme: Theme) => ({
    actions: {
        justifyContent: 'flex-end',
    },
    statusRow: {
        display: 'flex',
        alignItems: 'center',
    },
    statusIcon: {
        minWidth: '0.5rem',
        minHeight: '0.5rem',
        backgroundColor: paletteSwitch(theme).green,
        borderRadius: '0.5rem',
        marginRight: '0.375rem',
    },
    noRowBorder: {
        '&:last-child th, &:last-child td': {
            borderBottom: 0,
        },
    },
    pagination: {
        borderTop: '1px solid rgba(224, 224, 224, 1)',
    },
    tableRow: {
        '& td,th': {
            padding: '12px',
            backgroundColor: paletteSwitch(theme).bgLevel3,
        },
        '& td:first-child': {
            paddingLeft: '16px',
        },
    },
    truncated: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    separator: {
        marginLeft: '4px',
        marginRight: '4px',
        color: paletteSwitch(theme).black54,
    },
    tableCell: {
        fontWeight: 600,
    },
}));

interface WorkersTableProps {
    nodeSummaries: NodeSummaries | 'loading' | 'error';
    previousNodeSummaries: NodeSummaries | undefined;
    maxHeight: number;
}

type NodeSummary = NodeSummaries[number] & {
    heapUsage: number;
    queryMemoryUsage: number;
};

export const WorkersTable: React.FunctionComponent<WorkersTableProps> = ({
    nodeSummaries: inputNodeSummaries,
    previousNodeSummaries: inputPreviousNodeSummaries,
    maxHeight,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const toNodeSummaryArray = <X extends string | undefined>(
        input: NodeSummaries | X
    ): NodeSummary[] | X => {
        return typeof input === 'string' || !input
            ? input
            : input.map((node) => ({
                  ...node,
                  heapUsage: node.heapUsed / node.heapAvailable,
                  queryMemoryUsage: node.usedQueryMemory / node.totalQueryMemory,
              }));
    };

    const nodeSummaries: NodeSummary[] | 'loading' | 'error' =
        toNodeSummaryArray(inputNodeSummaries);
    const previousNodeSummaries: NodeSummary[] | undefined = toNodeSummaryArray(
        inputPreviousNodeSummaries
    );

    const [page, setPage] = useState<number>(1);
    const pageSize = 25;
    const pages = typeof nodeSummaries == 'string' ? 1 : ceil(nodeSummaries.length / pageSize);
    const [sorting, setSorting] = useState<SortingState<keyof NodeSummary>>({
        sortBy: 'uptime',
        sortOrder: 'desc',
    });

    const formatMemoryUsage = (usage: number): string => {
        return `${(100 * usage).toFixed(usage < 0.1 ? 1 : 0)}%`;
    };

    const memoryDetailedUsage = (usedBytes: number, availableBytes: number): string => {
        return ` (${formatBytes(usedBytes)} / ${formatBytes(availableBytes)})`;
    };

    const cpuUsage = (usage: number): string => {
        return (usage * 100).toFixed(usage < 0.1 ? 2 : 1) + '%';
    };

    const setSortingState = (newState: SortingState<keyof NodeSummary>): void => {
        setSorting(newState);
        setPage(1);
    };

    const processNodes = (
        processFn: (nodes: NodeSummary[]) => React.ReactNode
    ): React.ReactNode => {
        if (nodeSummaries === 'error') {
            return <ErrorBox text={'Could not load worker data.'} />;
        } else if (nodeSummaries === 'loading') {
            return <Spinner position="relative" delay={500} />;
        } else {
            return processFn(nodeSummaries);
        }
    };

    return (
        <>
            <Divider />

            {processNodes((nodes) => (
                <TableContainer style={{ maxHeight }}>
                    <Table stickyHeader style={{ tableLayout: 'fixed' }}>
                        <colgroup>
                            <col style={{ width: '10%' }} />
                            <col style={{ width: '13%' }} />
                            <col style={{ width: '18%' }} />
                            <col style={{ width: '18%' }} />
                            <col style={{ width: '20%' }} />
                            <col style={{ width: '10%' }} />
                            <col style={{ width: '11%' }} />
                        </colgroup>
                        <TableHead>
                            <TableRow className={classes.tableRow}>
                                <SortableColumn<keyof NodeSummary>
                                    label="Node ID"
                                    sortingKey="nodeId"
                                    sortingState={sorting}
                                    setSortingState={setSortingState}
                                    component="td"
                                />
                                <TableCell className={classes.tableCell}>
                                    Internal address
                                </TableCell>
                                <SortableColumn<keyof NodeSummary>
                                    label="Heap memory usage"
                                    sortingKey="heapUsage"
                                    sortingState={sorting}
                                    setSortingState={setSortingState}
                                    component="td"
                                />
                                <SortableColumn<keyof NodeSummary>
                                    label="Query memory usage"
                                    sortingKey="queryMemoryUsage"
                                    sortingState={sorting}
                                    setSortingState={setSortingState}
                                    component="td"
                                />
                                <SortableColumn<keyof NodeSummary>
                                    label="CPU usage (process / system)"
                                    sortingKey="averageProcessCpuLoad"
                                    sortingState={sorting}
                                    setSortingState={setSortingState}
                                    component="td"
                                />
                                <SortableColumn<keyof NodeSummary>
                                    label="Processors"
                                    sortingKey="processors"
                                    sortingState={sorting}
                                    setSortingState={setSortingState}
                                    component="td"
                                />
                                <SortableColumn<keyof NodeSummary>
                                    label="Uptime"
                                    sortingKey="uptime"
                                    sortingState={sorting}
                                    setSortingState={setSortingState}
                                    component="td"
                                />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {nodeSummaries === 'loading' && <Spinner position="relative" />}
                            {orderBy(nodes, [sorting.sortBy], [sorting.sortOrder])
                                .slice((page - 1) * pageSize, page * pageSize)
                                .map((node) => (
                                    <TableRow
                                        hover
                                        key={node.nodeId}
                                        className={clsx(classes.noRowBorder, classes.tableRow)}>
                                        <TableCell className={classes.truncated}>
                                            {node.nodeId}
                                        </TableCell>
                                        <TableCell className={classes.truncated}>
                                            {node.internalAddress}
                                        </TableCell>
                                        <TableCell>
                                            <ValueTrending<NodeSummary>
                                                object={node}
                                                previousObjects={previousNodeSummaries}
                                                idKey={'nodeId'}
                                                valueKey={'heapUsage'}>
                                                {formatMemoryUsage(node.heapUsage)}
                                                <span
                                                    style={{ color: paletteSwitch(theme).black54 }}>
                                                    {memoryDetailedUsage(
                                                        node.heapUsed,
                                                        node.heapAvailable
                                                    )}
                                                </span>
                                            </ValueTrending>
                                        </TableCell>
                                        <TableCell>
                                            <ValueTrending<NodeSummary>
                                                object={node}
                                                previousObjects={previousNodeSummaries}
                                                idKey={'nodeId'}
                                                valueKey={'queryMemoryUsage'}>
                                                {formatMemoryUsage(node.queryMemoryUsage)}
                                                <span
                                                    style={{ color: paletteSwitch(theme).black54 }}>
                                                    {memoryDetailedUsage(
                                                        node.usedQueryMemory,
                                                        node.totalQueryMemory
                                                    )}
                                                </span>
                                            </ValueTrending>
                                        </TableCell>
                                        <TableCell>
                                            <ValueTrending<NodeSummary>
                                                object={node}
                                                previousObjects={previousNodeSummaries}
                                                idKey={'nodeId'}
                                                valueKey={'averageProcessCpuLoad'}>
                                                <span>{cpuUsage(node.averageProcessCpuLoad)}</span>
                                            </ValueTrending>
                                            <span className={classes.separator}>{' / '}</span>
                                            <ValueTrending<NodeSummary>
                                                object={node}
                                                previousObjects={previousNodeSummaries}
                                                idKey={'nodeId'}
                                                valueKey={'averageSystemCpuLoad'}>
                                                <span>{cpuUsage(node.averageSystemCpuLoad)}</span>
                                            </ValueTrending>
                                        </TableCell>
                                        <TableCell>{node.processors}</TableCell>
                                        <TableCell>
                                            <div className={classes.statusRow}>
                                                <div className={classes.statusIcon} />
                                                <div>{formatDuration(node.uptime)}</div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ))}

            {pages > 1 && (
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    py={2}
                    px={1}
                    className={classes.pagination}>
                    <Pagination
                        page={page}
                        count={pages}
                        onChange={(_, page): void => setPage(page)}
                        color="primary"
                    />
                </Box>
            )}
        </>
    );
};
