/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, FunctionComponent } from 'react';
import { createUseStyles } from 'react-jss';
import ThumbUp from '@mui/icons-material/ThumbUp';
import IconButton from '@mui/material/IconButton';
import clsx from 'clsx';
import { paletteSwitch } from '../../../themes/palette';
import { deleteCommentVote, upvoteComment } from '../../../api/dataProduct/comment/commentApi';
import { Theme } from '@mui/material/styles';

const useStyles = createUseStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    voted: {
        color: paletteSwitch(theme).nebulaNavy700,
    },
    abstention: {
        color: paletteSwitch(theme).nebulaNavy100,
    },
    voteCount: {
        backgroundColor: paletteSwitch(theme).nebulaNavy50,
        marginLeft: '0.625rem',
        padding: '0.125rem 0.5rem',
        borderRadius: '100%',
    },
}));

interface ThumbVoteProps {
    dataProductId: string;
    commentId: string;
    isVoted: boolean;
    voteCount: number;
    className?: string;
}

export const ThumbVote: FunctionComponent<ThumbVoteProps> = ({
    dataProductId,
    commentId,
    isVoted,
    voteCount,
    className,
}: ThumbVoteProps) => {
    const styles = useStyles();
    const handleClick = useCallback(() => {
        const apiHandler = isVoted ? deleteCommentVote : upvoteComment;
        apiHandler(dataProductId, commentId);
    }, [isVoted, dataProductId, commentId]);
    return (
        <div className={clsx(styles.root, className)}>
            <IconButton color="inherit" size="small" onClick={handleClick}>
                <ThumbUp className={isVoted ? styles.voted : styles.abstention} />
            </IconButton>
            <div className={styles.voteCount}>{voteCount}</div>
        </div>
    );
};
