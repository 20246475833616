/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import { createUseStyles } from 'react-jss';
import { shadows } from '../../themes/shadows';
import { paletteSwitch } from '../../themes/palette';
import { Theme } from '@mui/material/styles';

const useStyles = createUseStyles((theme: Theme) => ({
    root: {
        boxShadow: shadows[1],
        padding: '12px',
        borderRadius: '4px',
        backgroundColor: paletteSwitch(theme).bgLevel3,
        overflow: 'hidden',
        flexGrow: 1,
    },
    title: {
        fontSize: '0.875rem',
        fontWeight: 600,
    },
}));

interface ReportContainerProps {
    title: string | React.ReactNode;
    children: React.ReactNode;
    className?: string;
    titleAmendment?: React.ReactNode;
}

export const ReportContainer: React.FunctionComponent<ReportContainerProps> = ({
    className,
    title,
    titleAmendment = null,
    children,
}) => {
    const classes = useStyles();

    return (
        <div className={clsx(classes.root, className)}>
            <Box mb={2} display="flex">
                {typeof title === 'string' ? (
                    <Box mr="auto">
                        <div className={classes.title}>{title}</div>
                    </Box>
                ) : (
                    title
                )}

                {titleAmendment}
            </Box>

            {children}
        </div>
    );
};
