/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';
import { mergeLines, prepareLineChartData } from '../../../utils/lineChart';
import { defaultChartPalette } from '../../../themes/palette';
import { ChartWrapper } from '../../../components/chart/ChartWrapper';
import { HighlightAreaProps, TimeLineChart } from '../../../components/chart/TimeLineChart';
import { formatDateInterval } from '../../../utils/formatDateInterval';
import { formatBytes } from '../../../utils/formatBytes';

interface MemoryUsageChartProps {
    memoryUsage:
        | Array<{
              sampleIntervalStart: string;
              sampleIntervalEnd: string;
              usedQueryMemory: number;
              totalQueryMemory: number;
              usedHeapSize: number;
              totalHeapSize: number;
          }>
        | 'loading'
        | 'error';
    height: number;
    highlightArea?: HighlightAreaProps;
}

export const MemoryUsageChart: React.FunctionComponent<MemoryUsageChartProps> = ({
    memoryUsage,
    height,
    highlightArea,
}) => {
    const heapMemoryUsage = prepareLineChartData(
        'Heap',
        memoryUsage,
        'sampleIntervalStart',
        ({ usedHeapSize }) => usedHeapSize,
        { fill: '-2', color: defaultChartPalette[3] }
    );
    const queryMemoryUsage = prepareLineChartData(
        'Query mem.',
        memoryUsage,
        'sampleIntervalStart',
        ({ usedQueryMemory }) => usedQueryMemory,
        { fill: 'origin', color: defaultChartPalette[4] }
    );

    const maxHeapMemoryLabel = 'Max heap';
    const maxHeapMemoryUsage = prepareLineChartData(
        maxHeapMemoryLabel,
        memoryUsage,
        'sampleIntervalStart',
        ({ totalHeapSize }) => totalHeapSize,
        { fill: false, color: defaultChartPalette[2] }
    );

    const maxQueryMemoryLabel = 'Max query mem.';
    const maxQueryMemoryUsage = prepareLineChartData(
        maxQueryMemoryLabel,
        memoryUsage,
        'sampleIntervalStart',
        ({ totalQueryMemory }) => totalQueryMemory,
        { fill: false, color: defaultChartPalette[0] }
    );

    const data = mergeLines([
        queryMemoryUsage,
        maxQueryMemoryUsage,
        heapMemoryUsage,
        maxHeapMemoryUsage,
    ]);

    return (
        <ChartWrapper
            data={data}
            title={'Memory'}
            height={height}
            legend={{
                type: 'dataset',
                variant: 'inline',
                valueFormatter: () => null,
                hiddenItems: [maxHeapMemoryLabel, maxQueryMemoryLabel],
            }}>
            {(chartData): React.ReactNode => (
                <TimeLineChart
                    data={chartData}
                    height={height}
                    tooltipValueFormatter={formatBytes}
                    yTicksLimit={6}
                    xTicksLimit={5}
                    xTicksMaxRotation={0}
                    tooltipDateFormat={formatDateInterval(
                        memoryUsage,
                        ({ sampleIntervalStart }) => sampleIntervalStart,
                        ({ sampleIntervalEnd }) => sampleIntervalEnd
                    )}
                    labelWidth={70}
                    yLabelFormatter={formatBytes}
                    highlightArea={highlightArea}
                />
            )}
        </ChartWrapper>
    );
};
