/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useLayoutEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import Tooltip from '@mui/material/Tooltip';

const useStyles = createUseStyles({
    truncated: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: '100%',
    },
    ellipsis: {
        position: 'absolute',
        bottom: '0',
        left: '0',
        width: '100%',
        height: '1rem',
        padding: '3px 0',
    },
    container: {
        position: 'relative',
        height: '100%',
    },
});

interface MultilineEllipsisProps {
    children: React.ReactNode;
    ellipsisClassName?: string;
}

export const MultilineEllipsis: React.FunctionComponent<MultilineEllipsisProps> = ({
    children,
    ellipsisClassName = '',
}) => {
    const classes = useStyles();
    const ref = useRef<HTMLDivElement>(null);
    const [ellipsisActive, setEllipsisActive] = useState(false);

    function doesTextOverflow(): boolean {
        return ref.current !== null && ref.current.clientHeight < ref.current.scrollHeight;
    }

    useLayoutEffect(() => {
        setEllipsisActive(doesTextOverflow());
    }, [children]);

    const content = (
        <div ref={ref} className={classes.truncated}>
            {children}
        </div>
    );

    if (ellipsisActive) {
        return (
            <Tooltip title={content}>
                <div className={classes.container}>
                    {content}
                    <div className={`${classes.ellipsis} ${ellipsisClassName}`}>...</div>
                </div>
            </Tooltip>
        );
    } else {
        return content;
    }
};
