/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { navigateBackInHistoryParam } from '../query/details/QueryDetails';
import { createUseStyles } from 'react-jss';
import { Theme } from '@mui/material/styles';
import { paletteSwitch } from '../../themes/palette';
import clsx from 'clsx';

interface QueryDetailsLinkProps {
    queryId?: string;
    className?: string;
}

const useStyles = createUseStyles((theme: Theme) => ({
    link: {
        color: paletteSwitch(theme).black,
    },
}));

export const QueryAccelerationLink: React.FunctionComponent<QueryDetailsLinkProps> = ({
    className,
    queryId,
    children,
}) => {
    const classes = useStyles();
    return (
        <Link
            className={clsx(className, classes.link)}
            component={RouterLink}
            underline="none"
            to={{
                pathname: `/query/${queryId}/lake-acceleration`,
                state: { [navigateBackInHistoryParam]: true },
            }}>
            {children}
        </Link>
    );
};
