/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useMemo } from 'react';
import { TextFieldWithHint } from '../components/TextFieldWithHint';
import { generateSqlObjectName, maximumTitleLength } from '../util/formUtils';

const tooltipText = `This is the name of the dataset you will be creating within your data product. Limited to ${maximumTitleLength} characters.`;

interface DatasetNameInputProps {
    value: string;
    onChange: (newValue: string) => void;
    catalogName: string;
    schemaName: string;
    enableToolTip?: boolean;
    fullWidth?: boolean;
    namesAlreadyUsed?: string[];
    className?: string;
}

export const DatasetNameInput = ({
    value,
    onChange,
    catalogName,
    schemaName,
    namesAlreadyUsed = [],
    enableToolTip = true,
    fullWidth,
    className,
}: DatasetNameInputProps) => {
    const onValueChange = useCallback(
        (newValue: string) => onChange(generateSqlObjectName(newValue)),
        [onChange]
    );
    const isDuplicateDatasetName = useMemo(
        () => namesAlreadyUsed.includes(value),
        [value, namesAlreadyUsed]
    );
    return (
        <TextFieldWithHint
            required
            className={className}
            value={value}
            label="Published dataset name"
            error={isDuplicateDatasetName}
            onValueChange={onValueChange}
            maxLength={maximumTitleLength}
            tooltipText={enableToolTip ? tooltipText : undefined}
            fullWidth={fullWidth}
            helperText={
                value.length > 0
                    ? isDuplicateDatasetName
                        ? 'Dataset with the same name already exists'
                        : `Reference this as: ${catalogName}.${schemaName}.${value}`
                    : ''
            }
        />
    );
};
