/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

let scrollbarWidth: number | undefined = undefined;

export const calculateScrollbarWidth = (): number => {
    if (scrollbarWidth === undefined) {
        const outer = document.createElement('div');
        outer.style.visibility = 'hidden';
        outer.style.overflow = 'scroll';
        document.body.appendChild(outer);

        const inner = document.createElement('div');
        outer.appendChild(inner);

        scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

        if (outer.parentNode) {
            outer.parentNode.removeChild(outer);
        }
    }

    return scrollbarWidth;
};
