/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { ReactElement, useCallback } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { FilterBy, Filter } from './auditLogFilters';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import { Select } from '../../../components/select/Select';
import { SelectChangeEvent } from '@mui/material/Select';
import { FilterCriteria } from '../../../api/biac/audit/auditLogApi';

export interface FiltersRowModel {
    id: string;
    filterBy: FilterBy;
    filterValue: FilterCriteria[FilterBy];
    valid: boolean;
}

interface FilterRowProps {
    model: FiltersRowModel;
    setModel: (setFn: (newState: FiltersRowModel) => FiltersRowModel) => void;
    availableFilters: FilterBy[];
    DeleteRowComponent: ReactElement<HTMLElement>;
    allowedFilters: Map<FilterBy, Filter>;
}

export const FilterRow: React.FunctionComponent<FilterRowProps> = ({
    model,
    setModel,
    availableFilters,
    DeleteRowComponent,
    allowedFilters,
}) => {
    const onFilterByChange: (event: SelectChangeEvent<unknown>) => void = (event) => {
        const filterBy = event.target.value as FilterBy;
        setModel((prevState) => ({
            id: prevState.id,
            filterBy,
            filterValue: null,
            valid: true,
        }));
    };

    const onFilterValueChange = useCallback((newValue: FilterCriteria[FilterBy]): void => {
        setModel((prevState) => ({
            ...prevState,
            filterValue: newValue,
        }));
    }, []);

    const onValidityChange = useCallback((valid: boolean) => {
        setModel((prevState) => ({
            ...prevState,
            valid,
        }));
    }, []);

    return (
        <Grid container spacing={1}>
            <Grid item lg={3} sm={3} xl={3} xs={3}>
                <FormControl variant="outlined" margin="dense" fullWidth>
                    <Select value={model.filterBy} onChange={onFilterByChange}>
                        {availableFilters.map((filterBy) => (
                            <MenuItem key={filterBy} value={filterBy}>
                                {allowedFilters.get(filterBy)?.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            {allowedFilters
                .get(model.filterBy)
                ?.component(model.filterValue, onFilterValueChange, onValidityChange)}
            <Grid item xs={1}>
                {DeleteRowComponent}
            </Grid>
        </Grid>
    );
};
