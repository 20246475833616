/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { ReactElement } from 'react';
import { DeleteDataProduct } from '../DeleteDataProduct';
import { CloneDataProduct } from '../CloneDataProduct';

interface ActionMenuDialogsProps {
    dataProductId: string;
    dataProductName: string;
    needMaterialization: boolean;
    openers: (
        openCloneDialog: () => void,
        openDeleteDialog: () => void
    ) => ReactElement<HTMLElement>;
}

export const ActionMenuDialogs = ({
    dataProductId,
    dataProductName,
    needMaterialization,
    openers,
}: ActionMenuDialogsProps) => {
    return (
        <CloneDataProduct
            dataProductId={dataProductId}
            dataProductName={dataProductName}
            needMaterialization={needMaterialization}
            opener={(openCloneDialog) => (
                <DeleteDataProduct
                    dataProductId={dataProductId}
                    dataProductName={dataProductName}
                    canUserDelete
                    opener={(openDeleteDialog) => openers(openCloneDialog, openDeleteDialog)}
                />
            )}
        />
    );
};
