/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { QueriesByUser, CpuTimeByUser } from './queryReportApi';
import { baseUrl } from './api';
import axios from 'axios';

export type RecentQueriesInTime = {
    time: string;
    metric: {
        runningQueries: number;
        failedQueries: number;
        queuedQueries: number;
    };
}[];

export type CpuLoadHistory = {
    time: string;
    metric: {
        processCpuUsage: number;
        systemCpuUsage: number;
        nodeCount: number;
    };
}[];

export type NodeSummaries = {
    nodeId: string;
    averageProcessCpuLoad: number;
    averageSystemCpuLoad: number;
    uptime: number;
    externalAddress: string;
    internalAddress: string;
    processors: number;
    totalNodeMemory: number;
    heapUsed: number;
    heapAvailable: number;
    usedQueryMemory: number;
    totalQueryMemory: number;
}[];

export type QueriesOverviewResponse = {
    history: RecentQueriesInTime;
    queriesByUser: QueriesByUser;
    cpuTimeByUser: CpuTimeByUser;
    oldestQueryTime?: string;
    samplingFrequency: number;
};

export type NodesOverviewResponse = {
    cpuLoadHistory: CpuLoadHistory;
    nodeSummaries: NodeSummaries;
    samplingFrequency: number;
    lastScalingTime: string;
};

export const getQueriesOverview = (): Promise<QueriesOverviewResponse> => {
    return axios.get(`${baseUrl}/overview/queries`).then((response) => response.data);
};

export const getNodesOverview = (): Promise<NodesOverviewResponse> => {
    return axios.get(`${baseUrl}/overview/nodes`).then((response) => response.data);
};
