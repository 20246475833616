/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';
import Typography from '@mui/material/Typography';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineConnector from '@mui/lab/TimelineConnector';
import Paper from '@mui/material/Paper';
import { createUseStyles } from 'react-jss';
import { HintTooltip } from '../../../../components/tooltip/HintTooltip';
import Box from '@mui/material/Box';
import clsx from 'clsx';

const useStyles = createUseStyles({
    paper: {
        height: 'fit-content',
        flexBasis: '0px',
        boxShadow:
            '0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
    },
    inactivePaper: {
        marginBottom: '0.5rem',
        verticalAlign: 'middle',
    },
    hidePaper: {
        boxShadow: 'none',
    },
    separator: {
        minHeight: '70px',
        marginLeft: '16px',
        marginRight: '16px',
    },
    text: {
        padding: '6px 12px',
    },
    inactiveText: {
        opacity: '0.6',
    },
    primaryText: {
        display: 'inline-flex',
        fontWeight: 600,
        fontSize: '0.875rem',
        lineHeight: '1.5rem',
        letterSpacing: '0.1px',
    },
    secondaryText: {
        fontSize: '0.75rem',
        lineHeight: '1rem',
        letterSpacing: '0.4px',
    },
    hintIcon: {
        fontSize: '1.0625rem',
    },
    hint: {
        height: '1.375rem',
        fontSize: '1.0625rem',
        width: '1.375rem',
        marginLeft: '0.25rem',
    },
    hintRightAlign: {
        marginRight: '-4px',
    },
    connector: {
        backgroundColor: 'rgba(225,225,225)',
    },
    dot: {
        marginBottom: '8px',
        marginTop: '8px',
    },
});

interface QueryTimelineItemProps {
    name: string | null;
    time: string | null;
    icon: React.ReactElement<HTMLElement>;
    variant: 'left' | 'right';
    tooltip?: React.ReactNode;
    color?: 'primary' | 'secondary' | string;
    timeColor?: string;
    lines?: 'none' | 'bottom' | 'top' | 'both';
    fullWidth?: boolean;
    fullHeight?: boolean;
    hidePaper?: boolean;
}

export const QueryTimelineItem: React.FunctionComponent<QueryTimelineItemProps> = ({
    name,
    time,
    icon,
    tooltip,
    color,
    timeColor,
    lines = 'bottom',
    variant,
    fullWidth = false,
    fullHeight = false,
    hidePaper = false,
}) => {
    const classes = useStyles();

    const getPaperStyles = (): React.CSSProperties => {
        let styles: React.CSSProperties = { flexGrow: 3 };

        if (lines === 'top') {
            styles = { ...styles, marginTop: 'auto' };
        }
        if (lines === 'both') {
            styles = { ...styles, marginTop: 'auto', marginBottom: 'auto' };
        }

        return styles;
    };

    const content = (
        <Paper
            style={getPaperStyles()}
            elevation={hidePaper ? 0 : 1}
            className={clsx(classes.paper, {
                [classes.inactivePaper]: !time,
                [classes.hidePaper]: hidePaper,
            })}>
            <Box style={variant === 'left' ? { textAlign: 'right' } : {}} className={classes.text}>
                <Typography
                    className={clsx(classes.primaryText, {
                        [classes.inactiveText]: !time,
                    })}>
                    <span>{name}</span>
                    {tooltip && (
                        <HintTooltip
                            title={tooltip}
                            classes={{
                                icon: classes.hintIcon,
                                button: clsx(
                                    classes.hint,
                                    variant === 'left' ? classes.hintRightAlign : undefined
                                ),
                            }}
                        />
                    )}
                </Typography>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    className={classes.secondaryText}
                    style={timeColor ? { color: timeColor } : undefined}>
                    {time}
                </Typography>
            </Box>
        </Paper>
    );

    const separator = (
        <TimelineSeparator className={classes.separator}>
            {(lines === 'both' || lines === 'top') && (
                <TimelineConnector className={classes.connector} />
            )}
            <TimelineDot
                color={color === 'primary' || color === 'secondary' ? color : undefined}
                style={
                    color === 'primary' || color === 'secondary' ? {} : { backgroundColor: color }
                }
                className={classes.dot}>
                {icon}
            </TimelineDot>
            {(lines === 'both' || lines === 'bottom') && (
                <TimelineConnector className={classes.connector} />
            )}
        </TimelineSeparator>
    );

    return (
        <Box display="flex" style={fullHeight ? { height: '100%' } : {}}>
            {variant === 'left' && (
                <>
                    {content}
                    {separator}
                    {!fullWidth && <Box flexBasis={'0px'} flexGrow={3} />}
                </>
            )}

            {variant === 'right' && (
                <>
                    {!fullWidth && <Box flexBasis={'0px'} flexGrow={3} />}
                    {separator}
                    {content}
                </>
            )}
        </Box>
    );
};
