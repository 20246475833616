/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useMemo } from 'react';
import Chip from '@mui/material/Chip';
import { createUseStyles } from 'react-jss';
import { Filter, FilterBy } from './auditLogFilters';
import { FiltersRowModel } from './FilterRow';

const useStyles = createUseStyles({
    chip: {
        marginLeft: '0.375rem',
        maxWidth: '300px',
        '&:focus': {
            backgroundColor: 'transparent!important',
        },
    },
});

interface FilterChipsProps {
    appliedFilters: FiltersRowModel[];
    removeFilter: (filterId: string) => void;
    allowedFilters: Map<FilterBy, Filter>;
}

export const FilterChips: React.FunctionComponent<FilterChipsProps> = ({
    appliedFilters,
    removeFilter,
    allowedFilters,
}) => {
    const classes = useStyles();
    const onlyAllowedFilters = useMemo(
        () => appliedFilters.filter((it) => allowedFilters.get(it.filterBy)),
        [appliedFilters, allowedFilters]
    );

    return (
        <>
            {onlyAllowedFilters.map((filterRow) => {
                const filter = allowedFilters.get(filterRow.filterBy);
                return filter ? (
                    <Chip
                        key={filterRow.id}
                        label={`${filter.label}: ${filter.formatValue(filterRow.filterValue)}`}
                        onDelete={
                            onlyAllowedFilters.length > 1
                                ? () => removeFilter(filterRow.id)
                                : undefined
                        }
                        variant="outlined"
                        className={classes.chip}
                    />
                ) : null;
            })}
        </>
    );
};
