/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useRef, useState } from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { SortableColumn, SortingState } from '../../components/table/SortableColumn';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import orderBy from 'lodash/orderBy';
import clsx from 'clsx';
import TableContainer from '@mui/material/TableContainer';
import { createUseStyles } from 'react-jss';
import { Theme } from '@mui/material/styles';
import { paletteSwitch } from '../../themes/palette';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/pro-solid-svg-icons/faEye';
import { faUserPlus } from '@fortawesome/pro-regular-svg-icons/faUserPlus';
import IconButton from '@mui/material/IconButton';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons/faEllipsisV';
import { RoleContextMenu } from './RoleContextMenu';
import { DeleteRoleDialog } from './DeleteRoleDialog';
import { Loadable } from '../../utils/loadable';
import { Spinner } from '../../components/spinner/Spinner';
import { ErrorBox } from '../../components/error/ErrorBox';
import { RoleEntry } from './useRoles';
import { faLockAlt } from '@fortawesome/pro-regular-svg-icons/faLockAlt';
import { Tooltip } from '../../components/tooltip/Tooltip';
import Button from '@mui/material/Button';
import { SYSTEM_ROLE_ID } from '../../api/biac/biacRolesApi';

const useStyles = createUseStyles((theme: Theme) => ({
    container: {
        maxHeight: 'calc(100vh - 13.75rem)',
        border: `1px solid ${paletteSwitch(theme).nebulaNavy50}`,
    },
    button: {
        marginLeft: '0.5rem',
        width: '1.75rem',
        padding: '0.25rem 0.5rem',
    },
    lockIcon: {
        color: paletteSwitch(theme).black38,
    },
    actions: {
        justifyContent: 'flex-end',
    },
    statusRow: {
        display: 'flex',
        alignItems: 'center',
    },
    statusIcon: {
        minWidth: '0.5rem',
        minHeight: '0.5rem',
        backgroundColor: paletteSwitch(theme).green,
        borderRadius: '0.5rem',
        marginRight: '0.375rem',
    },
    noRowBorder: {
        '&:last-child th, &:last-child td': {
            borderBottom: 0,
        },
    },
    tableCell: {
        fontWeight: 600,
        letterSpacing: '0.5px',
        fontFamily: theme.typography.fontFamily,
        backgroundColor: paletteSwitch(theme).white,
    },
    tableRow: {
        '& td,th': {
            padding: '12px',
        },
        '& td:first-child': {
            paddingLeft: '16px',
        },
    },
    truncated: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    noWrap: {
        whiteSpace: 'nowrap',
    },
}));

interface RolesTableProps {
    roles: Loadable<RoleEntry[]>;
    deleteRole(roleId: number): Promise<void>;
    showGrantSidePanel(roleId: number): void;
    showPrivilegesSidePanel(roleId: number): void;
    reload(): Promise<void>;
}

export const RolesTable: React.FunctionComponent<RolesTableProps> = ({
    roles,
    deleteRole,
    showGrantSidePanel,
    showPrivilegesSidePanel,
    reload,
}) => {
    const classes = useStyles();

    const [deleteDialogVisible, setDeleteDialogVisible] = useState<boolean>(false);
    const showDeleteDialog = useCallback(() => {
        setDeleteDialogVisible(true);
        closeRoleContextMenu();
    }, []);

    const closeDeleteDialog = useCallback(() => {
        setDeleteDialogVisible(false);
    }, []);

    const [roleContextMenuAnchorEl, setRoleContextMenuAnchorEl] = useState<HTMLElement | null>(
        null
    );
    const activeRole = useRef<RoleEntry | undefined>(undefined);

    const showRoleContextMenu =
        (role: RoleEntry) => (event: React.MouseEvent<HTMLButtonElement>) => {
            activeRole.current = role;
            setRoleContextMenuAnchorEl(event.currentTarget);
        };
    const closeRoleContextMenu = () => {
        setRoleContextMenuAnchorEl(null);
    };

    const deleteSelectedRole = (): Promise<void> => {
        const role = activeRole.current;
        if (role) {
            return deleteRole(role.id).then(() => closeRoleContextMenu());
        }
        return Promise.resolve();
    };

    const [sorting, setSorting] = useState<SortingState<keyof RoleEntry>>({
        sortBy: 'name',
        sortOrder: 'asc',
    });

    return (
        <>
            {deleteDialogVisible && (
                <DeleteRoleDialog
                    roleName={activeRole.current?.name || ''}
                    deleteRole={deleteSelectedRole}
                    closeDialog={closeDeleteDialog}
                    reload={reload}
                />
            )}
            <RoleContextMenu
                anchorEl={roleContextMenuAnchorEl}
                deleteRole={showDeleteDialog}
                onClose={closeRoleContextMenu}
            />
            <TableContainer className={classes.container}>
                <Table stickyHeader style={{ tableLayout: 'fixed' }}>
                    <colgroup>
                        <col style={{ width: '2rem' }} />
                        <col style={{ width: '28%' }} />
                        <col />
                        <col style={{ width: '12rem' }} />
                    </colgroup>
                    <TableHead>
                        <TableRow className={classes.tableRow}>
                            <TableCell />
                            <SortableColumn<keyof RoleEntry>
                                label="Role name"
                                sortingKey="name"
                                sortingState={sorting}
                                setSortingState={setSorting}
                                component="td"
                            />
                            <SortableColumn<keyof RoleEntry>
                                label="Role description"
                                sortingKey="description"
                                sortingState={sorting}
                                setSortingState={setSorting}
                                component="td"
                            />
                            <TableCell className={classes.tableCell}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {roles === 'loading' && (
                            <TableRow>
                                <TableCell colSpan={4}>
                                    <Spinner position="relative" />
                                </TableCell>
                            </TableRow>
                        )}
                        {roles === 'error' && (
                            <TableRow>
                                <TableCell colSpan={4}>
                                    <ErrorBox height={200} text="Could not load roles" />
                                </TableCell>
                            </TableRow>
                        )}
                        {Array.isArray(roles) &&
                            orderBy(roles, [sorting.sortBy], [sorting.sortOrder]).map(
                                (role: RoleEntry) => {
                                    const canManageRole = role.id < 0 || !role.grantOption;
                                    return (
                                        <TableRow
                                            hover
                                            key={role.id}
                                            className={clsx(classes.noRowBorder, classes.tableRow)}>
                                            <TableCell style={{ padding: 0 }}>
                                                {canManageRole ? (
                                                    <Tooltip title="Cannot remove a role">
                                                        <span className={classes.button}>
                                                            <FontAwesomeIcon
                                                                className={classes.lockIcon}
                                                                icon={faLockAlt}
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                ) : (
                                                    <IconButton
                                                        size="small"
                                                        className={classes.button}
                                                        onClick={showRoleContextMenu(role)}>
                                                        <FontAwesomeIcon icon={faEllipsisV} />
                                                    </IconButton>
                                                )}
                                            </TableCell>
                                            <TableCell className={classes.truncated}>
                                                {role.name}
                                            </TableCell>
                                            <TableCell className={classes.truncated}>
                                                {role.description}
                                            </TableCell>
                                            <TableCell
                                                className={classes.noWrap}
                                                style={{ padding: '0 6px' }}>
                                                {role.id != SYSTEM_ROLE_ID ? (
                                                    <Button
                                                        size="small"
                                                        color="primary"
                                                        onClick={() =>
                                                            showPrivilegesSidePanel(role.id)
                                                        }>
                                                        <FontAwesomeIcon
                                                            style={{ marginRight: '6px' }}
                                                            icon={faEye}
                                                        />
                                                        Details
                                                    </Button>
                                                ) : (
                                                    <Tooltip title="System role already has all of privileges">
                                                        <span>
                                                            <Button
                                                                size="small"
                                                                color="primary"
                                                                disabled>
                                                                <FontAwesomeIcon
                                                                    style={{ marginRight: '6px' }}
                                                                    icon={faEye}
                                                                />
                                                                Details
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                )}
                                                {!canManageRole || role.id === SYSTEM_ROLE_ID ? (
                                                    <Button
                                                        size="small"
                                                        color="primary"
                                                        onClick={() => showGrantSidePanel(role.id)}>
                                                        <FontAwesomeIcon
                                                            style={{ marginRight: '6px' }}
                                                            icon={faUserPlus}
                                                        />
                                                        Assign
                                                    </Button>
                                                ) : (
                                                    <Tooltip title="Assigning users, groups and roles to this role is not possible">
                                                        <span>
                                                            <Button
                                                                size="small"
                                                                color="primary"
                                                                disabled>
                                                                <FontAwesomeIcon
                                                                    style={{ marginRight: '6px' }}
                                                                    icon={faUserPlus}
                                                                />
                                                                Assign
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                }
                            )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};
