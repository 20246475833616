/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Theme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import AppsIcon from '@mui/icons-material/Apps';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import { getAllTags$, Tag } from '../../api/dataProduct/dataProductApi';
import { DataDomain, getDomains } from '../../api/dataProduct/dataDomain/dataDomainApi';
import Button from '@mui/material/Button';
import { DataProductCard } from './DataProductCard';
import { SearchField } from './components/search/SearchField';
import { SortDataProductList } from './list/SortDataProductList';
import { MultiSelect } from './components/select/MultiSelect';
import ButtonGroup from '@mui/material/ButtonGroup';
import { DataProductList } from './DataProductList';
import {
    mapCategoryToDescriptor,
    mapDescriptorToCategory,
    SortDescriptor,
} from './dataProductSortingService';
import { useDataProductCreateRedirection } from './routing/dataProductRoutingUtils';
import { Tooltip } from '../../components/tooltip/Tooltip';
import { useUserGlobalPermissionContext } from './permission/UserGlobalPermissionContext';
import { PageContent } from '../../layout/PageContent';
import { useDataProductListManager } from './useDataProductListManager';
import { Spinner } from '../../components/spinner/Spinner';
import { ErrorIndicator } from '../../components/error/ErrorIndicator';
import { NoData } from '../../components/error/NoData';
import { LoadMoreIntersectionButton } from './pagination/LoadMoreIntersectionButton';

const useStyles = createUseStyles((theme: Theme) => ({
    topBanner: {
        color: theme.palette.primary.contrastText,
        justifyContent: 'space-between',
        marginTop: '1.5rem',
        marginBottom: '1.5rem',
    },
    createButton: {
        marginTop: '1.5rem',
        marginBottom: '1.5rem',
    },
    searchField: {
        marginTop: '1rem',
    },
    layoutChooser: {
        marginLeft: 'auto',
        right: '10px',
        marginBottom: '1.5rem',
    },
    cardContainer: {
        '--gap-x': '1.75rem',
        '--gap-y': '1.25rem',
        display: 'flex',
        flexWrap: 'wrap',
        margin: 'calc(-1 * var(--gap-y)) calc(-1 * var(--gap-x))',
        '& > *': {
            height: '273px',
            width: '336px',
            margin: 'var(--gap-y) var(--gap-x)',
        },
    },
}));

const useToggleButtonStyles = createUseStyles({
    root: {
        fontSize: '1rem',
        height: '2.5rem',
    },
    label: {
        justifyContent: 'center',
    },
});

export const DataProductDashboard: React.FunctionComponent = () => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [tags, setTags] = useState<Tag[]>([]);
    const [filterTagValues, setFilterTagValues] = useState<string[]>([]);
    const [domains, setDomains] = useState<DataDomain[]>([]);
    const [filterDomainNames, setFilterDomainNames] = useState<string[]>([]);
    const [cardView, setCardView] = useState<boolean>(true);
    const goToCreateDataProduct = useDataProductCreateRedirection();
    const {
        records: dataProducts,
        changeSearchOptions,
        isFetchingFirstRecords,
        isFetchingPage,
        isError,
        errorMessage,
        hasMoreRecords,
        fetchMore,
    } = useDataProductListManager();

    useEffect(() => {
        const subscription = getAllTags$().subscribe({
            next: setTags,
        });

        return () => subscription.unsubscribe();
    }, []);

    const [sort, setSort] = useState<SortDescriptor>({
        sortOrder: 'asc',
        sortBy: 'NAME',
    });

    useEffect(() => {
        const filterTagIds = mapTagValuesToIds(filterTagValues, tags);
        const filterDomainIds = mapDomainNamesToIds(filterDomainNames, domains);
        changeSearchOptions({
            searchString: searchTerm,
            dataDomainIds: filterDomainIds,
            tagIds: filterTagIds,
            sortKey: sort.sortBy,
            sortDirection: sort.sortOrder,
        });
    }, [filterTagValues, filterDomainNames, searchTerm, sort]);

    useEffect(() => {
        getDomains().then(setDomains);
    }, []);

    const canUserCreateDataProducts = useUserGlobalPermissionContext().canCreateDataProducts;

    const classes = useStyles();
    const toggleButtonClasses = useToggleButtonStyles();

    return (
        <PageContent title="Data products">
            <Grid container className={classes.topBanner} direction="row" alignItems="center">
                <Grid item className={classes.createButton}>
                    <Tooltip
                        title={
                            !canUserCreateDataProducts
                                ? 'You do not have permission to create data products'
                                : ''
                        }>
                        <span>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={goToCreateDataProduct}
                                disabled={!canUserCreateDataProducts}
                                style={{ marginTop: '-0.45rem' }}>
                                Create data product
                            </Button>
                        </span>
                    </Tooltip>
                </Grid>
                <Grid item>
                    {cardView && (
                        <SortDataProductList
                            value={mapDescriptorToCategory(sort)}
                            onValueChange={(newValue) => setSort(mapCategoryToDescriptor(newValue))}
                        />
                    )}
                    <MultiSelect
                        label="Filter by tag"
                        options={tags.map((tag) => tag.value)}
                        selectedOptions={filterTagValues}
                        setSelectedOptions={setFilterTagValues}
                    />
                    <MultiSelect
                        label="Filter by domain"
                        options={domains.map((domain) => domain.name)}
                        selectedOptions={filterDomainNames}
                        setSelectedOptions={setFilterDomainNames}
                    />
                    <SearchField
                        value={searchTerm}
                        onValueChange={setSearchTerm}
                        className={classes.searchField}
                        minLength={0}
                    />
                </Grid>
            </Grid>
            <Grid container direction="column">
                <Grid item className={classes.layoutChooser}>
                    <ButtonGroup color="primary">
                        <Button
                            variant={cardView ? 'contained' : 'outlined'}
                            onClick={() => setCardView(true)}
                            classes={toggleButtonClasses}>
                            <AppsIcon />
                        </Button>
                        <Button
                            variant={cardView ? 'outlined' : 'contained'}
                            onClick={() => setCardView(false)}
                            classes={toggleButtonClasses}>
                            <ViewHeadlineIcon />
                        </Button>
                    </ButtonGroup>
                </Grid>
                {isFetchingFirstRecords ? (
                    <Spinner size={50} position="relative" />
                ) : isError ? (
                    <ErrorIndicator text={errorMessage} />
                ) : dataProducts?.length ? (
                    cardView ? (
                        <div className={classes.cardContainer}>
                            {dataProducts.map((dataProduct) => (
                                <DataProductCard key={dataProduct.id} dataProduct={dataProduct} />
                            ))}
                            {hasMoreRecords &&
                                (isFetchingPage ? (
                                    <Spinner size={50} position="relative" />
                                ) : (
                                    <LoadMoreIntersectionButton onLoadMore={fetchMore} />
                                ))}
                        </div>
                    ) : (
                        <DataProductList
                            dataProducts={dataProducts}
                            sorting={sort}
                            setSorting={setSort}
                            fetchMore={fetchMore}
                            hasMoreRecords={hasMoreRecords}
                            isFetchingPage={isFetchingPage}
                        />
                    )
                ) : (
                    <NoData height={250} text="No data product available" icon="table" />
                )}
            </Grid>
        </PageContent>
    );
};

function mapTagValuesToIds(values: string[], tags: Tag[]): string[] {
    return values
        .map((value) => tags.find((tag) => tag.value === value))
        .filter<Tag>(isTag)
        .map(({ id }) => id);
}

function isTag(tagLike: Tag | undefined): tagLike is Tag {
    return tagLike !== undefined;
}

function mapDomainNamesToIds(names: string[], domains: DataDomain[]): string[] {
    return names
        .map((name) => domains.find((domain) => domain.name === name))
        .filter<DataDomain>(isDomain)
        .map(({ id }) => id);
}

function isDomain(domainLike: DataDomain | undefined): domainLike is DataDomain {
    return domainLike !== undefined;
}
