/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { FunctionComponent, ReactNode, useCallback, useMemo, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { createUseStyles } from 'react-jss';

import { SimpleDataProduct } from '../../../../api/dataProduct/dataProductApi';
import { DataDomain, reassignDomain } from '../../../../api/dataProduct/dataDomain/dataDomainApi';
import { SimpleDialog } from '../../../../components/dialog/SimpleDialog';
import { useDialogOpenState } from '../../../../components/dialog/useDialogOpenState';
import { useTextInputValueChange } from '../../../../utils/useTextInputValueChange';

interface ReassignDomainDialogProps {
    product: SimpleDataProduct;
    domain: DataDomain;
    allDomains: DataDomain[];
}

export const ReassignDomainDialog: React.FunctionComponent<ReassignDomainDialogProps> = ({
    domain,
    product,
    allDomains,
}: ReassignDomainDialogProps) => {
    const { isOpen, close, open } = useDialogOpenState();
    const products = useMemo(() => [product], [product]);
    return (
        <>
            <span onClick={open}>Reassign</span>
            {isOpen && (
                <ReassignFormDialog
                    description={
                        <>
                            Select a new domain for <b>{product.name}</b>
                        </>
                    }
                    isOpen={isOpen}
                    close={close}
                    allDomains={allDomains}
                    domain={domain}
                    products={products}
                />
            )}
        </>
    );
};

interface ReassignFormDialogProps {
    products: SimpleDataProduct[];
    description: ReactNode;
    isOpen: boolean;
    close: () => void;
    domain: DataDomain;
    allDomains: DataDomain[];
}

export const ReassignFormDialog: FunctionComponent<ReassignFormDialogProps> = ({
    allDomains,
    description,
    domain,
    products,
    close,
    isOpen,
}: ReassignFormDialogProps) => {
    const [newDomainId, setNewDomainId] = useState<string>(domain.id);
    const onConfirm = useCallback(
        () =>
            reassignDomain(
                products.map(({ id }) => id),
                newDomainId
            ),
        [products, newDomainId]
    );
    return (
        <SimpleDialog
            isOpen={isOpen}
            close={close}
            title="Reassign domain"
            confirmButtonLabel="Reassign domain"
            onConfirm={onConfirm}
            isConfirmationButtonDisabled={newDomainId === domain.id}
            fullWidth={true}
            Content={ReassignForm}
            contentProps={{
                allDomains,
                description,
                domainId: newDomainId,
                onChange: (domainId) => setNewDomainId(domainId),
            }}
        />
    );
};

interface ReassignFormProps {
    allDomains: DataDomain[];
    error?: ReactNode;
    description: ReactNode;
    domainId: string;
    onChange: (newDomainId: string) => void;
}

const useStyles = createUseStyles({
    select: {
        margin: '1rem 0 2rem 0',
    },
});

export const ReassignForm: FunctionComponent<ReassignFormProps> = ({
    allDomains,
    domainId,
    onChange,
    description,
    error,
}: ReassignFormProps) => {
    const styles = useStyles();
    const handleNewDomainChange = useTextInputValueChange(
        (newValue) => onChange(newValue),
        [onChange]
    );
    return (
        <>
            {description}
            <TextField
                select
                value={domainId}
                onChange={handleNewDomainChange}
                className={styles.select}
                label="Domain name"
                variant="outlined"
                margin="dense"
                fullWidth>
                {allDomains.map((availableDomain) => (
                    <MenuItem key={availableDomain.id} value={availableDomain.id}>
                        {availableDomain.name}
                    </MenuItem>
                ))}
            </TextField>
            {error}
        </>
    );
};
