/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import Box from '@mui/material/Box';
import React from 'react';

interface DataProductWizardStepProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export const DataProductWizardStep: React.FunctionComponent<DataProductWizardStepProps> = ({
    children,
    index,
    value,
}) => {
    return <Box>{value === index && children}</Box>;
};
