/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { palette, stateColor } from '../../../../themes/palette';
import { StageState } from '../../../../api/queryApi';
import { formatBytes } from '../../../../utils/formatBytes';
import { formatDuration } from '../../../../utils/formatDuration';

const MILLIS_PER_MICROSECOND = 0.001;
const MILLIS_PER_MILLISECOND = 1;
const MILLIS_PER_SECOND = 1000;
const MILLIS_PER_MINUTE = 1000 * 60;
const MILLIS_PER_HOUR = 1000 * 60 * 60;
const MILLIS_PER_DAY = 1000 * 60 * 60 * 24;
const BYTES_PER_MEGABYTE = 1048576;

enum UnitsOfTime {
    NANOSECOND = 'ns',
    MICROSECOND = 'us',
    MILLISECOND = 'ms',
    SECOND = 's',
    MINUTE = 'm',
    HOUR = 'h',
    DAY = 'd',
}

export const getColorByState = (stageState: StageState, fullyBlocked: boolean): string => {
    switch (stageState) {
        case 'PLANNED':
            return stateColor.PLANNED;
        case 'SCHEDULING':
            return stateColor.SCHEDULING;
        case 'SCHEDULING_SPLITS':
            return stateColor.SCHEDULING_SPLITS;
        case 'SCHEDULED':
            return stateColor.SCHEDULED;
        case 'RUNNING':
            if (fullyBlocked) {
                return palette.black54;
            }
            return stateColor.RUNNING;
        case 'FLUSHING':
            return stateColor.FLUSHING;
        case 'FINISHED':
            return stateColor.FINISHED;
        case 'CANCELED':
            return stateColor.CANCELED;
        case 'ABORTED':
            return stateColor.ABORTED;
        case 'FAILED':
            return stateColor.FAILED;
        default:
            return palette.black54;
    }
};

export const computeRate = (count: number, ms: number): number => {
    if (ms === 0) {
        return 0;
    }
    return (count / ms) * 1000.0;
};

const precisionRound = (n: number): string => {
    if (n < 1000) {
        return n.toFixed(2);
    }
    return Math.round(n).toString();
};

const getUnitOfTime = (time: number): UnitsOfTime => {
    if (time < MILLIS_PER_MICROSECOND) {
        return UnitsOfTime.NANOSECOND;
    }
    if (time < MILLIS_PER_MILLISECOND) {
        return UnitsOfTime.MICROSECOND;
    }
    if (time < MILLIS_PER_SECOND) {
        return UnitsOfTime.MILLISECOND;
    }
    if (time < MILLIS_PER_MINUTE) {
        return UnitsOfTime.SECOND;
    }
    if (time < MILLIS_PER_HOUR) {
        return UnitsOfTime.MINUTE;
    }
    if (time < MILLIS_PER_DAY) {
        return UnitsOfTime.HOUR;
    }
    return UnitsOfTime.DAY;
};

const compareCpuTime = (currentCpuTime: number, maxCpuTime: number): boolean => {
    return getUnitOfTime(currentCpuTime) === getUnitOfTime(maxCpuTime);
};

export const formatDisplayTime = (currentCpuTime: number, maxCpuTime: number): string => {
    if (compareCpuTime(currentCpuTime, maxCpuTime)) {
        return formatTimeDuration(currentCpuTime);
    }
    return `< 1${getUnitForDisplay(getUnitOfTime(maxCpuTime))}`;
};

const getUnitForDisplay = (timeUnit: UnitsOfTime): UnitsOfTime => {
    switch (timeUnit) {
        case UnitsOfTime.MICROSECOND:
            return UnitsOfTime.MILLISECOND;
        case UnitsOfTime.MILLISECOND:
            return UnitsOfTime.MILLISECOND;
        case UnitsOfTime.SECOND:
            return UnitsOfTime.SECOND;
        case UnitsOfTime.MINUTE:
            return UnitsOfTime.SECOND;
        case UnitsOfTime.HOUR:
            return UnitsOfTime.MINUTE;
        case UnitsOfTime.DAY:
            return UnitsOfTime.HOUR;
        default:
            return UnitsOfTime.NANOSECOND;
    }
};

export const formatTimeDuration = (time: number): string => {
    if (time < MILLIS_PER_MICROSECOND) {
        return precisionRound(time * 100000) + UnitsOfTime.NANOSECOND;
    }
    if (time < MILLIS_PER_MILLISECOND) {
        return precisionRound(time * 1000) + UnitsOfTime.MICROSECOND;
    }
    if (time < MILLIS_PER_SECOND) {
        return precisionRound(time) + UnitsOfTime.MILLISECOND;
    }
    return formatDuration(time);
};

export const formatBuffer = (bytes: number): string => {
    if (bytes === 0) {
        return '0 MiB';
    }
    if (bytes > 0 && bytes < BYTES_PER_MEGABYTE) {
        return '< 1 MiB';
    }
    return formatBytes(bytes);
};
