/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React, { useCallback, useState } from 'react';
import { UpdatedWorksheet, updateWorksheet } from '../../../api/savedQueriesApi';
import Dialog from '@mui/material/Dialog';
import { DialogHeader } from '../../../components/dialog/DialogHeader';
import DialogContent from '@mui/material/DialogContent';
import { SavedQueryEditForm } from './SavedQueryEditForm';
import { createUseStyles } from 'react-jss';
import { Theme } from '@mui/material/styles';

interface SavedQueryEditDialogProps {
    worksheet: UpdatedWorksheet;
    onClose: () => void;
    reloadWorksheets: () => void;
}
const useStyles = createUseStyles((theme: Theme) => ({
    dialogContent: {
        paddingBottom: theme.spacing(3),
    },
    dialogPaper: {
        width: '37.5rem',
    },
}));
const SavedQueryEditDialog: React.FunctionComponent<SavedQueryEditDialogProps> = ({
    worksheet,
    onClose,
    reloadWorksheets,
}) => {
    const classes = useStyles();
    const [errorMessage, setErrorMessage] = useState<string>('');
    const onSubmit = useCallback(
        async (worksheet: UpdatedWorksheet) => {
            try {
                await updateWorksheet({
                    id: worksheet.id,
                    name: worksheet.name,
                    queryText: worksheet.queryText,
                });
                onClose();
                reloadWorksheets();
                setErrorMessage('');
            } catch (e) {
                setErrorMessage(e.message);
            }
        },
        [updateWorksheet, onClose]
    );

    return (
        <Dialog open={true} classes={{ paper: classes.dialogPaper }}>
            <DialogHeader title="Change query name" onClose={onClose} />
            <DialogContent className={classes.dialogContent}>
                <SavedQueryEditForm
                    worksheet={worksheet}
                    errorMessage={errorMessage}
                    onSubmit={onSubmit}
                    onClose={onClose}
                />
            </DialogContent>
        </Dialog>
    );
};

export default SavedQueryEditDialog;
