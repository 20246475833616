/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React, { useCallback, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import { createUseStyles } from 'react-jss';
import { Theme } from '@mui/material/styles';
import { SqlHighlight } from '../../../components/sql-highlight/SqlHighlight';
import { paletteSwitch } from '../../../themes/palette';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { LoadingButton } from '../../../components/LoadingButton';
import { openWorksheet, Worksheet } from '../../../api/savedQueriesApi';
import { persistActiveTabId } from '@starburstdata/query-editor';
import { useHistory } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { DialogHeader } from '../../../components/dialog/DialogHeader';
import Typography from '@mui/material/Typography';

interface SavedQueryPreviewDialogProps {
    worksheetId: Worksheet['id'];
    queryText?: Worksheet['queryText'];
    openDialog: boolean;
    onClose: () => void;
}

const useStyles = createUseStyles((theme: Theme) => ({
    paper: {
        width: '37.5rem',
    },
    content: {
        padding: '1rem',
        maxHeight: '13.125rem',
        overflow: 'auto',
        border: `1px solid ${paletteSwitch(theme).sqlHighlightBorder}`,
        backgroundColor: paletteSwitch(theme).nebulaNavy25,
        borderRadius: '0.25rem',
    },
    actions: {
        padding: '1rem 1.5rem 1.5rem 1.5rem',
    },
    dialogContent: {
        paddingBottom: theme.spacing(3),
    },
    seeMoreDescription: {
        fontSize: '0.75rem',
        marginTop: '1rem',
        color: paletteSwitch(theme).black54,
        fontStyle: 'italic',
        letterSpacing: '0.5px',
    },
}));

export const SavedQueryPreviewDialog: React.FunctionComponent<SavedQueryPreviewDialogProps> = ({
    worksheetId,
    queryText,
    openDialog,
    onClose,
}) => {
    const classes = useStyles();
    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState<string>('');

    const openInEditor = useCallback(
        async (worksheetId: string) => {
            try {
                await openWorksheet(worksheetId);
                persistActiveTabId(worksheetId);
                history.push('/ide');
                setErrorMessage('');
            } catch (e) {
                setErrorMessage(e.message);
            }
        },
        [worksheetId, openWorksheet, history, persistActiveTabId]
    );

    return (
        <Dialog open={openDialog} classes={{ paper: classes.paper }}>
            <DialogHeader title="Preview query" onClose={onClose} />
            <DialogContent className={classes.dialogContent} style={{ overflow: 'hidden' }}>
                {errorMessage && (
                    <Box mb={2}>
                        <Alert severity="error">{errorMessage}</Alert>
                    </Box>
                )}
                <div id="query-preview-dialog" className={classes.content}>
                    <SqlHighlight
                        sql={queryText ?? ''}
                        condensedSql={false}
                        lines={50}
                        fontSize={'0.875rem'}
                        ellipsis={
                            <Typography className={classes.seeMoreDescription}>
                                Open in Editor to see all
                            </Typography>
                        }
                    />
                </div>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button
                    autoFocus={true}
                    variant="outlined"
                    onClick={onClose}
                    style={{ marginRight: 8 }}>
                    Close
                </Button>

                <LoadingButton
                    color="primary"
                    variant="contained"
                    onClick={() => openInEditor(worksheetId)}>
                    Open in editor
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
