/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { FunctionComponent, MouseEventHandler } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStarSharp } from '@fortawesome/pro-solid-svg-icons';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';

const strokeWidth = 30;
const useStyles = createUseStyles({
    root: {
        display: 'inline-block',
        position: 'relative',
        marginRight: '0.3125rem',
    },
    foregroundIconContainer: {
        position: `absolute`,
        top: 0,
        left: 0,
        overflowX: 'hidden',
    },
});

interface SingleScorePartProps {
    color: string;
    strokeColor: string;
    score: number;
    onClick?: () => void;
    onMouseEnter?: MouseEventHandler;
    onMouseLeave?: MouseEventHandler;
    className?: string;
}

export const SingleScorePart: FunctionComponent<SingleScorePartProps> = ({
    color,
    strokeColor,
    score,
    onClick,
    onMouseEnter,
    onMouseLeave,
    className,
}: SingleScorePartProps) => {
    const styles = useStyles();
    return (
        <div
            className={clsx(styles.root, className)}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}>
            <FontAwesomeIcon
                color="transparent"
                stroke={strokeColor}
                strokeWidth={strokeWidth}
                icon={faStarSharp}
            />
            {score > 0 && (
                <div
                    className={styles.foregroundIconContainer}
                    style={{
                        width: `${score * 100}%`,
                    }}>
                    <FontAwesomeIcon
                        color={color}
                        stroke={strokeColor}
                        strokeWidth={strokeWidth}
                        icon={faStarSharp}
                    />
                </div>
            )}
        </div>
    );
};
