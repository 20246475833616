/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { formatISO } from 'date-fns';
import { FilterCriteria } from '../features/query/history/queryFilters';
import { baseUrl } from './api';
import axios from 'axios';

export type QueryState =
    | 'QUEUED'
    | 'WAITING_FOR_RESOURCES'
    | 'DISPATCHING'
    | 'PLANNING'
    | 'STARTING'
    | 'RUNNING'
    | 'FINISHING'
    | 'FINISHED'
    | 'FAILED';

export type GetQueryApiResponse = Array<QueryDetails>;

export interface QueryDetails {
    queryId: string;
    user: string;
    state: QueryState;
    query: string;
    createTime: string;
    cpuTime: number;
    executionTime: number;
}

type QueriesSortingKey = 'createDate' | 'cpuTime' | 'elapsedTime' | 'user' | 'id';

export interface QueriesSorting {
    sortBy: QueriesSortingKey;
    sortOrder: 'asc' | 'desc';
}

export const formatQueryCriteria = (criteria: FilterCriteria | null): Record<string, unknown> => ({
    filter: {
        query: criteria?.sql ?? undefined,
        user: criteria?.user ?? undefined,
        id: criteria?.id ?? undefined,
        startDate: criteria?.startDate ? formatISO(criteria.startDate) : undefined,
        endDate: criteria?.endDate ? formatISO(criteria.endDate) : undefined,
        status: criteria?.status ?? undefined,
        group: criteria?.group ?? undefined,
        catalog: criteria?.catalog ?? undefined,
        schema: criteria?.schema ?? undefined,
    },
});

export const getQueries = (
    criteria: FilterCriteria | null,
    { sortBy, sortOrder }: QueriesSorting
): Promise<GetQueryApiResponse> => {
    return axios
        .get(`${baseUrl}/history/queries`, {
            params: {
                sortBy,
                sortOrder,
                ...formatQueryCriteria(criteria),
            },
        })
        .then((response) => response.data);
};
