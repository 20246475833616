/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';
import { createUseStyles } from 'react-jss';
import Grid from '@mui/material/Grid';
import clsx from 'clsx';
import { Theme } from '@mui/material/styles';
import { paletteSwitch } from '../../themes/palette';

const useStyles = createUseStyles((theme: Theme) => ({
    row: {
        padding: '6px 16px 6px 0',
    },
    even: {
        backgroundColor: paletteSwitch(theme).black1,
        borderRadius: '4px',
    },
}));

interface DetailsRowProps {
    className?: string;
    children: React.ReactNode;
}

export const DetailsRow: React.FunctionComponent<DetailsRowProps> = ({ children, className }) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.row, className)}>
            <Grid container spacing={3}>
                {children}
            </Grid>
        </div>
    );
};
